import { LkAxiosInstance } from '@/api';
import { ILink } from './Links.interface';

export interface ILinksService {
  request(link: ILink): Promise<void>;
}

export const linksService = ($axios: LkAxiosInstance): ILinksService => ({
  async request(link) {
    return $axios.$request({ ...link, url: link.href });
  },
});