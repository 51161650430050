// eslint-disable-next-line vue/max-len
const PublicationIndex = () => import(/* webpackChunkName: "publication" */ '@/views/properties/id/publication/index.vue');
// eslint-disable-next-line vue/max-len
const PublicationHistory = () => import(/* webpackChunkName: "publication" */ '@/views/properties/id/publication/panels/history.vue');

export default [
  {
    children: [
      {
        component: PublicationHistory,
        name: 'publication_history',
        path: 'historique',
      },
    ],
    component: PublicationIndex,
    name: 'publication',
    path: '/biens/:propertyId/diffusion',
  },
];