


































































import { Component, Prop } from 'vue-property-decorator';

import BaseTips from '@/components/common/molecules/BaseTips.vue';
import FieldComponent from '@/components/common/molecules/forms/FieldComponent.vue';

let inputId = 0;

@Component({
  components: {
    BaseTips,
  },
})
export default class BaseCheckbox extends FieldComponent {
  @Prop({ default: false, type: Boolean }) readonly checked!: boolean;
  @Prop({ default: false, type: Boolean }) readonly forceSelected!: boolean;
  @Prop({ default: false, type: Boolean }) readonly hasError!: boolean;
  @Prop({ default: true, type: Boolean }) readonly hasPadding!: boolean;
  @Prop({ default: false, type: Boolean }) readonly isBordered!: boolean;

  id = `checkbox-${inputId++}`;

  get showPreviousValue(): boolean {
    return this.previousValue !== null && this.checked !== this.previousValue;
  }

  onChange({ target }: { target: HTMLInputElement }): void {
    if (!this.forceSelected) {
      this.$emit('update', this.value !== undefined ? this.value : target.checked);
    }
  }
}
