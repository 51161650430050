import { EPropertyWaterRoomType } from '@/enums/PropertyWaterRoomType.enum';
import { ERoomType } from '@/enums/RoomType.enum';
import { getFormattedRoom } from '..';
import {
  ICreatePropertyWaterRoomPayload,
  IPropertyWaterRoom,
  IUpdatePropertyWaterRoomPayload,
} from './WaterRooms.interface';
import { LkAxiosInstance } from '@/api';

const BASE_ENDPOINT = '/property_water_rooms';
const FIELDS_NUMBER = 10;
const ROOM_TYPE = ERoomType.WaterRoom;
const TOILET_FIELDS_NUMBER = 7;

const getFormattedWaterRoom = (response: IPropertyWaterRoom) => {
  const fieldsNumber = response.type === EPropertyWaterRoomType.Toilet ? TOILET_FIELDS_NUMBER : FIELDS_NUMBER;

  return getFormattedRoom(response, ROOM_TYPE, fieldsNumber);
};

export interface IWaterRoomsService {
  create(payload: ICreatePropertyWaterRoomPayload): Promise<IPropertyWaterRoom>;
  delete(id: string): Promise<void>;
  get(id: string): Promise<IPropertyWaterRoom>;
  update(payload: IUpdatePropertyWaterRoomPayload): Promise<IPropertyWaterRoom>;
}

export const waterRoomsService = ($axios: LkAxiosInstance): IWaterRoomsService => ({
  async create(payload) {
    const response = await $axios.$post<IPropertyWaterRoom>(BASE_ENDPOINT, payload);

    return getFormattedWaterRoom(response);
  },
  delete(id) {
    return $axios.$delete(`${BASE_ENDPOINT}/${id}`);
  },
  async get(id) {
    const response = await $axios.$get<IPropertyWaterRoom>(`${BASE_ENDPOINT}/${id}`);

    return getFormattedWaterRoom(response);
  },
  async update(payload) {
    const response = await $axios.$post<IPropertyWaterRoom>(`${BASE_ENDPOINT}/${payload.id}`, payload);

    return getFormattedWaterRoom(response);
  },
});
