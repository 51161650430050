

































































import { Component, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import Intercom, { show, shutdown } from '@intercom/messenger-js-sdk';

import { bottomBarRoutes, routeIcons, primaryRoutes, HashRoutes } from '@/router/dome';
import { EAgentPerformanceRoutes } from '@/router/dome/agent-performance';
import { ESessionStorageKeys } from '@/enums/Storage.enum';
import { IBadges } from '@/interfaces/Badges.interface';
import { icons } from '@/helpers/icons';
import { IItem, IItemWithAction } from '../common/molecules/menu/DropdownList.interface';
import { INavItem, INavItemWithAction } from '../common/molecules/navigation/NavItem.interface';
import { IOption } from '@/interfaces/Option.interface';
import { IPerson } from '@/api/dome/persons/Persons.interface';
import { IProperty } from '@/api/dome/properties/Properties.interface';

import DropdownList from '@/components/common/molecules/menu/DropdownList.vue';
import GlobalSearch from '@/views/global-search/index.vue';
import Navigation from '@/components/common/organisms/navigation/Navigation.vue';
import NavigationHelper from '@/components/common/organisms/navigation/NavigationHelper.vue';
import NavigationTop from '@/components/common/organisms/navigation/NavigationTop.vue';
import Notifications from '@/views/panels/notifications.vue';
import {
  Appointment,
  Buyer,
  Meeting,
  Negotiation,
  Property,
  RentingProperty,
  SellerLead,
  Task,
  Visit,
} from '@/views/entity/index';
import IssueReportDialog from '@/components/dialogs/IssueReportDialog.vue';

const entityList = [
  { icon: icons.prospecting, name: 'seller_lead', value: 'seller-lead' },
  { icon: icons.menu_properties, value: 'property' },
  { icon: icons.menu_properties, name: 'renting_property', value: 'renting-property' },
  { icon: icons.contact, value: 'buyer' },
  { icon: icons.offer, name: 'offer', value: 'negotiation' },
  { icon: icons.estimation, value: 'meeting' },
  { icon: icons.calendar, value: 'visit' },
  { icon: icons.time, value: 'appointment' },
  { icon: icons.task, value: 'task' },
];

@Component({
  components: {
    Appointment,
    Buyer,
    DropdownList,
    GlobalSearch,
    IssueReportDialog,
    Meeting,
    Navigation,
    NavigationTop,
    Negotiation,
    Notifications,
    Property,
    RentingProperty,
    SellerLead,
    Task,
    Visit,
  },
})
export default class AppNavigation extends NavigationHelper {
  @Prop({ default: false, type: Boolean }) isLoading!: boolean;

  @State('appIntercomId') appIntercomId!: string;
  @State('badges') badges!: IBadges;
  @State('isNotificationsMenuVisible') isNotificationsMenuVisible!: boolean;
  @State('suggestionList') suggestionList!: { agency: string[], person: IPerson[], property: IProperty[] };

  @Action('getBadgesData') getBadgesData!: () => void;
  @Action('logOut') logOut!: () => void;
  @Action('setDialogTriggeredFromOtherView') setDialogTriggeredFromOtherView!: () => void;
  @Action('toggleNotificationsMenu') toggleNotificationsMenu!: (show: boolean) => void;

  creationEntities: IItem[] = entityList.map((item, index) => ({
    ...item,
    isLeftItem: index < 5,
    name: this.$t(`entity_creation.dropdown.${item.name || item.value}`),
  }));
  intercom: typeof Intercom | void | null = null;

  get accountEntities(): IItemWithAction[] {
    const items = [
      {
        action: () => this.showIntercom(),
        icon: this.$icons.question_outlined,
        isLeftItem: false,
        name: this.$t('support.navigation_item'),
        value: 'support',
      },
      {
        action: () => this.handleLogout(),
        icon: this.$icons.logout,
        isLeftItem: false,
        name: this.$t('common.log_out'),
        userName: this.loggedInAgent ? `(${this.loggedInAgent.firstName} ${this.loggedInAgent.lastName})` : undefined,
        value: 'logout',
      },
    ];

    if (!this.$env.isProduction) {
      items.unshift({
        action: () => this.showFeaturesDialog(),
        icon: this.$icons.eye,
        isLeftItem: false,
        name: this.$t('feature_toggle.manage_dialog.title'),
        value: 'features',
      });
    }

    return items;
  }

  get bottomBarRoutes(): INavItem[] {
    return this.getFormattedRoutes(bottomBarRoutes);
  }

  get formattedSuggestionList(): Record<'agency' | 'person' | 'property', IOption<string>[]> {
    const { agency, person, property } = this.suggestionList;

    return {
      agency: agency.map(value => ({ label: this.$t('common.agency'), value })),
      person: person?.map(({ firstName, lastName, fullName, tags, id }) => (
        { label: fullName || `${firstName} ${lastName}`, tags, value: id }
      )),
      property: property?.map(({ address, city, fullAddress, id, zipCode }) => (
        { label: fullAddress ?? `${address}, ${zipCode} ${city}`, value: id }
      )),
    };
  }

  get isBackButtonVisible(): boolean {
    const matches = this.$route.matched;
    const isPrimaryRoute = matches[0]?.name && primaryRoutes.includes(matches[0].name);

    return !isPrimaryRoute && matches.length > 0
      ? matches[matches.length - 1].path.split('/').length - 1 > 1
      : false;
  }

  get primaryRoutes(): INavItem[] {
    return this.getFormattedRoutes(primaryRoutes);
  }

  get notificationBadge(): number | undefined {
    return this.getBadge('notifications');
  }

  get secondaryRoutes(): INavItemWithAction[] {
    const routes: INavItemWithAction[] = [
      {
        action: () => undefined,
        component: 'router-link',
        icon: this.$icons.menu_stats,
        id: EAgentPerformanceRoutes.Home,
        name: this.$t('navigation.routes.agent_performance'),
        props: { to: { name: 'agent_performance' } },
      },
      {
        action: () => this.toggleSupportDropdown(true),
        component: 'div',
        icon: 'question_outlined',
        id: 'support',
        name: this.$t('navigation.routes.help'),
        portalName: 'help-support',
      },
    ];

    return routes;
  }

  get title(): string {
    return this.$t(`navigation.routes.${this.$route.name}`);
  }

  closeNotificationPanel(): void {
    this.toggleNotificationsMenu(false);
    this.getBadgesData();
  }

  getBadge(route: string): number | undefined {
    switch (route) {
      case 'buyer_leads': return this.badges.leadBuyersCount;
      case 'properties': return this.badges.propertiesToFollowUpCount;
      case 'seller_leads': return this.badges.sellerLeadsCount;
      case 'notifications': return this.badges.notificationsCount;
      case 'tasks': return this.badges.tasksCount;
      default: return undefined;
    }
  }

  getFormattedRoutes(routes: string[]): INavItem[] {
    return routes.map(route => ({
      badge: this.getBadge(route),
      component: 'router-link',
      icon: routeIcons[route],
      id: route,
      isBordered: ['seller_leads', 'buyers_search'].includes(route),
      name: this.$t(`navigation.routes.${route}`),
      props: { to: { name: route } },
    }));
  }

  async handleLogout(): Promise<void> {
    sessionStorage.removeItem(ESessionStorageKeys.IssueReport);
    this.logOut();
    // INFO : Hide intercom if opened
    shutdown();
    await this.$router.push({ name: 'log_in' });
  }

  async openGlobalSearch(): Promise<void> {
    await this.$router.replace({ hash: HashRoutes.globalSearch, params: this.$route.params, query: this.$route.query });
  }

  setEmailSent(isSent: boolean): void {
    if (isSent) {
      this.setDialogTriggeredFromOtherView();
    }
  }

  showIntercom(): void {
    if (!this.intercom) {
      this.intercom = Intercom({
        app_id: this.appIntercomId,
        created_at: Math.floor(Number(this.loggedInAgent.createdAt) / 1000),
        email: this.loggedInAgent.email,
        name: `${this.loggedInAgent.firstName} ${this.loggedInAgent.lastName}`,
        user_hash: this.loggedInAgent.agentIntercomId,
        user_id: this.loggedInAgent.id,
      });
    }

    this.toggleSupportDropdown(false);

    setTimeout(() => {
      if (window.Intercom) {
        show();
      } else {
        console.error('Intercom has not launched');
      }
    }, 1000);
  }
}
