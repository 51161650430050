





















import { Vue, Component, Prop } from 'vue-property-decorator';
import sanitizeHTML from 'sanitize-html';

import i18n from '@/plugins/i18n';

import ActionableItem from '@/components/common/atoms/actionable-item/ActionableItem.vue';

@Component({
  components: {
    ActionableItem,
  },
})
export default class BaseTips extends Vue {
  @Prop({ default: false, type: Boolean }) isCentered!: boolean;
  @Prop({ default: i18n.t('base.tips.validate'), type: String }) submitText!: string;
  @Prop({ required: true, type: String }) tips!: string;
  @Prop({ default: i18n.t('base.tips.title'), type: String }) title!: string;

  isVisible = false;

  get sanitizedTips() {
    return sanitizeHTML(this.tips);
  }
}
