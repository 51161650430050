














































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationError } from 'yup';

import { EDialogActionType } from '@/enums/DialogActionType.enum';
import { getPhoneNumber } from '@/utils/phone';
import { IErrorMessages } from '@/interfaces/ErrorMessages.interface';
import { INegotiationRecipient } from '@/api/dome/negotiationRecipients/NegotiationRecipients.interface';
import { recipientBuyerSchema, setErrorMessage } from '@/utils/validation';

@Component
export default class RecipientsBuyersDialog extends Vue {
  @Prop({ default: false, type: Boolean }) isVisible!: boolean;
  @Prop({ default: EDialogActionType.Add, type: String }) dialogType!: EDialogActionType;
  @Prop({ type: Object }) recipient!: INegotiationRecipient;

  currentRecipient = { ...this.recipient };
  errorMessages: IErrorMessages = {
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    phoneNumber: undefined,
    proxyName: undefined,
  };

  @Watch('recipient')
  resetBuyer(): void {
    this.currentRecipient = { ...this.recipient };

    this.errorMessages = {
      email: undefined,
      firstName: undefined,
      lastName: undefined,
      phoneNumber: undefined,
      proxyName: undefined,
    };
  }

  removeErrorMessage(key: string): void {
    this.errorMessages[key] = undefined;
  }

  submit(): void {
    try {
      recipientBuyerSchema.validateSync(this.currentRecipient, { abortEarly: false });

      // TODO: format phone number in related service
      this.$emit('save', {
        ...this.currentRecipient,
        phoneNumber: getPhoneNumber(this.currentRecipient.phoneNumber) ?? '',
        proxyName: this.currentRecipient.isProxy ? this.currentRecipient.proxyName : '',
      });
    } catch (error) {
      if (error instanceof ValidationError) {
        error.inner.forEach(({ errors, path }) => {
          if (path) {
            this.errorMessages[path] = setErrorMessage(this, errors);
          }
        });
      }
    }
  }

  updateIsProxy(value: boolean): void {
    if (!value) {
      this.removeErrorMessage('proxyName');
    }

    this.updateRecipient('isProxy', value);
  }

  updateRecipient(key: string, value: string | boolean): void {
    this.currentRecipient[key] = value;
  }

  validateProperty(key: string): void {
    try {
      recipientBuyerSchema.validateSyncAt(key, this.currentRecipient);
    } catch (error) {
      if (error instanceof ValidationError && error.path) {
        this.errorMessages[error.path] = setErrorMessage(this, error.errors);
      }
    }
  }
}
