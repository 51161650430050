













































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { endOfDay, startOfTomorrow } from 'date-fns';

import { EFundingType } from '@/enums/FundingType.enum';
import { EWizardActionType } from '@/enums/WizardActionType.enum';
import { getDeepObjectCopy } from '@/utils/objectHelpers';
import { IErrorMessages } from '@/interfaces/ErrorMessages.interface';
import { IFunding } from '@/api/dome/negotiations/Negotiations.interface';
import { IOption } from '@/interfaces/Option.interface';
import { NegotiationFunding as NegotiationFundingModel } from '@/views/entity/steps/NegotiationFunding';
import { NOTARY_FEES_INCLUDED } from '@/utils/constants';

const i18nFundingTypes = 'entity_creation.steps.negotiation_funding.funding_types';

@Component
export default class NegotiationFunding extends Vue {
  @Prop({ required: true, type: Object }) entities!: { negotiationFunding: NegotiationFundingModel };
  @Prop({ default: null, type: Object }) errorMessages!: IErrorMessages;
  @Prop({ default: EWizardActionType.Create, type: String }) type!: string;

  EWizardActionType = Object.freeze(EWizardActionType);
  fundingTypeCash = Object.freeze(EFundingType.Cash);
  fundingTypes: string[] = Object.values(EFundingType);
  fundingTypeOptions: readonly IOption[] = Object.freeze([
    { label: this.$t(`${i18nFundingTypes}.loan`), value: EFundingType.Loan },
    { label: this.$t(`${i18nFundingTypes}.bridging_loan`), value: EFundingType.BridgingLoan },
    { label: this.$t(`${i18nFundingTypes}.both`), value: 'both' },
  ]);
  localFunding: IFunding[] = getDeepObjectCopy(this.entities.negotiationFunding.funding);

  get estimatedAmount(): number {
    return this.entities.negotiationFunding.price + (this.entities.negotiationFunding.price * NOTARY_FEES_INCLUDED);
  }

  get fundings(): IFunding[] {
    return this.entities.negotiationFunding.funding.filter((funding: IFunding) => funding.type !== EFundingType.Cash);
  }

  get fundingType(): string | null {
    const loanIndex = this.localFunding.findIndex(funding => funding.type === EFundingType.Loan);

    const bridingLoanIndex = this.localFunding.findIndex(funding => funding.type === EFundingType.BridgingLoan);

    if (loanIndex >= 0 && bridingLoanIndex >= 0) {
      return 'both';
    } else if (loanIndex >= 0) {
      return EFundingType.Loan;
    } else if (bridingLoanIndex >= 0) {
      return EFundingType.BridgingLoan;
    }

    return null;
  }

  get tomorrow(): Date {
    return startOfTomorrow();
  }

  fundingIndex(type: EFundingType): number {
    return this.entities.negotiationFunding.funding.findIndex(funding => funding.type === type);
  }

  initFunding(type: EFundingType = EFundingType.Cash): IFunding {
    return { amount: 0, duration: 0, maximumInterestRate: 0, type };
  }

  updateEntity(key: string, value): void {
    this.$emit('update-entity', 'negotiationFunding', key, value);
  }

  updateEntityFunding(value: IFunding[]): void {
    const funding = [...value].sort((fundingA, fundingB) =>
      this.fundingTypes.indexOf(fundingA.type) - this.fundingTypes.indexOf(fundingB.type),
    );

    this.updateEntity('funding', funding);
  }

  updateExpirationDate(value: string): void {
    const date = value ? endOfDay(new Date(value)).toISOString() : '';
    this.updateEntity('expiresOn', date);
  }

  updateFunding(type: EFundingType, key?: string, value?: number): void {
    let funding = this.localFunding.find(currentFunding => currentFunding.type === type);

    if (funding && key) {
      funding[key] = value;
    } else if (!funding) {
      funding = this.initFunding(type);

      if (key) {
        funding[key] = value;
      }

      this.localFunding.push(funding);
    }

    this.updateEntityFunding(this.localFunding);
  }

  updateFundingType(value?: string): void {
    if (value === 'both') {
      this.updateFunding(EFundingType.Loan);
      this.updateFunding(EFundingType.BridgingLoan);
    } else if (value === EFundingType.Loan || value === EFundingType.BridgingLoan) {
      const loanType = value === EFundingType.Loan ? EFundingType.BridgingLoan : EFundingType.Loan;
      this.localFunding = this.localFunding.filter(funding => funding.type !== loanType);

      if (!this.localFunding.find(funding => funding.type === value)) {
        const funding = this.initFunding(value);
        this.localFunding.push(funding);
      }

      this.updateEntityFunding(this.localFunding);
    } else {
      const negotiationFunding = this.localFunding.filter(funding => funding.type === EFundingType.Cash);
      this.updateEntityFunding(negotiationFunding);
    }
  }

  removeErrorMessage(key: string): void {
    this.$emit('remove-error', 'negotiationFunding', key);
  }

  validateEntityKey(key: string): void {
    this.$emit('validate-entity-key', 'negotiationFunding', key);
  }
}
