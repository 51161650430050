































import { Component, Prop } from 'vue-property-decorator';
import { Component as VueComponent } from 'vue';

import { AgentEntityModel } from '@/views/bo/entity/models';
import { IAgent, IAgentSellerLeadsSettings, IMarketingLeadTypeSettings } from '@/api/bo/agents/Agents.interface';
import { IApproverAgents, IIsAgentAllowed, SourcesSettingsErrorMessages } from '.';
import { IGeoLocation } from '@/interfaces/models/GeoLocation.interface';
import { IStep } from '@/components/common/molecules/stepper/StepperWithStatus.interface';
import { parseError } from '@/utils/validation';

import Agent from '@/views/bo/entity/steps/Agent.vue';
import EntityCreationWizard from '@/views/entity/wizard.vue';
import ManageRights from '@/views/bo/entity/steps/ManageRights.vue';
import ManageSellerLeads from '@/views/bo/entity/steps/ManageSellerLeads.vue';
import SelectAgency from '@/views/bo/entity/steps/SelectAgency.vue';
import Wizard from '@/components/common/templates/wizard/Wizard.vue';

type IStepValue = keyof typeof stepHelper;

interface IStepWithComponent<T> extends IStep<T> {
  component: VueComponent;
  hasLoaded: boolean;
}

const stepHelper = {
  agent: Agent,
  manage_rights: ManageRights,
  manage_seller_leads: ManageSellerLeads,
  select_agency: SelectAgency,
};

@Component({
  components: {
    Wizard,
  },
})
export default class AgentEntity extends EntityCreationWizard {
  @Prop({ default: () => ({}), type: Object }) agent!: IAgent;
  @Prop({ default: false, type: Boolean }) isEditMode!: boolean;

  approverAgents: IApproverAgents = { estimation: null, mandate: null };
  isAgentAllowed: IIsAgentAllowed = { toEditSources: false, toSeeOtherAgentsBusiness: false };
  isStepLoading = false;
  sellerLeadsSettings: IAgentSellerLeadsSettings | null = null;

  get currentStep(): IStepWithComponent<IStepValue> {
    return this.steps[this.stepIndex];
  }

  get sourcesSettingsErrorMessages(): SourcesSettingsErrorMessages {
    return this.sellerLeadsSettings?.marketingLeadTypeSettings.sourcesSettings.map((source) => ({
      quotaDuration: source.quotaObjective && !source.quotaDuration ? this.$t('errors.field_required') : '',
      quotaObjective: source.quotaDuration && !source.quotaObjective ? this.$t('errors.field_required') : '',
    })) ?? [];
  }

  get stepList(): IStepValue[] {
    const steps: IStepValue[] = ['select_agency', 'agent', 'manage_seller_leads', 'manage_rights'];

    if (this.isEditMode) {
      steps.splice(steps.indexOf('select_agency'), 1);
    }

    return steps;
  }

  get steps(): IStepWithComponent<IStepValue>[] {
    return this.stepList.map(step => ({
      component: stepHelper[step],
      hasError: false,
      hasLoaded: false,
      isFilled: false,
      name: this.$t(`bo.entity_${this.isEditMode ? 'edition' : 'creation'}.steps.${step}.title`),
      value: step,
    }));
  }

  async created(): Promise<void> {
    await this.setData();
  }

  async createAgent(): Promise<void> {
    const agent = this.entities.agent;

    this.isSubmitting = true;

    if (!this.isEntityValid(this.stepList) || !agent) {
      this._handleError('invalid data', this.errorStepIndex);

      return;
    }

    // Create agent
    let agentId: string;

    const apiCall = this.isEditMode
      ? this.$boApi.agents.update(agent)
      : this.$boApi.agents.create(agent);

    try {
      agentId = (await apiCall).id;
    } catch (error) {
      this._handleError(error, this.stepList.indexOf('agent'), true);

      return;
    }

    // Save seller-leads settings
    if (this.sellerLeadsSettings) {
      try {
        await this.$boApi.agents.updateSellerLeadsSettings(agentId, this.sellerLeadsSettings);
      } catch (error) {
        this._handleError(error, this.stepList.indexOf('manage_seller_leads'), true);

        return;
      }
    }

    // Save rights settings
    try {
      await this.$boApi.agents.setIsAgentAllowedToEditSources(agentId, this.isAgentAllowed.toEditSources);
      await this.$boApi.agents.setSettings(agentId, this.isAgentAllowed.toSeeOtherAgentsBusiness);
    } catch (error) {
      this._handleError(error, this.stepList.indexOf('manage_rights'), true);

      return;
    }

    if (this.isEditMode) {
      await this.closeWizard();
    } else {
      await this.$router.replace({
        hash: undefined,
        name: 'bo_agency',
        params: { agencyId: this.entities.agent!.agencyId! },
      });
    }
  }

  async getDataForNextStep(step: IStepValue): Promise<void> {
    switch (step) {
      case 'agent': return this.initStepAgent();
      case 'manage_rights': return this.initStepManageRights();
      case 'manage_seller_leads': return this.initStepManageSellerLeads();
      default: return;
    }
  }

  getStepValidation(step: IStepValue): boolean {
    return step === 'manage_seller_leads'
      ? !this.sourcesSettingsErrorMessages.some(source => source?.quotaDuration || source?.quotaObjective)
      : this.isStepValid(step);
  }

  initStepAgent(): void {
    this.$set(this.entities, 'agent', { ...this.agent, hasEstimationApprover: false, hasMandateApprover: false });
  }

  async initStepManageRights(): Promise<void> {
    const { isAgentAllowedToSeeOtherAgentsBusiness } = await this.$boApi.agents.getSettings(this.agent.id);
    this.updateAgentSetting('toSeeOtherAgentsBusiness', isAgentAllowedToSeeOtherAgentsBusiness);

    const { isAgentAllowedToEditSellerSources } =
      await this.$boApi.agents.getIsAgentAllowedToEditSources(this.agent.id);

    this.updateAgentSetting('toEditSources', isAgentAllowedToEditSellerSources);

    if (this.agent.estimationApproverAgentId) {
      this.entities.agent!.hasEstimationApprover = true;
      this.approverAgents.estimation = await this.$boApi.agents.getOneAgent(this.agent.estimationApproverAgentId);
    }

    if (this.agent.mandateApproverAgentId) {
      this.entities.agent!.hasMandateApprover = true;
      this.approverAgents.mandate = await this.$boApi.agents.getOneAgent(this.agent.mandateApproverAgentId);
    }
  }

  async initStepManageSellerLeads(): Promise<void> {
    this.sellerLeadsSettings = await this.$boApi.agents.getSellerLeadsSettings(this.agent.id);
  }

  async setData(): Promise<void> {
    if (this.isEditMode) {
      await this.getDataForNextStep('agent');
    } else {
      this.$set(this.entities, 'agent', new AgentEntityModel());
      this.approverAgents = { estimation: null, mandate: null };
      this.isAgentAllowed = { toEditSources: false, toSeeOtherAgentsBusiness: false };
      this.sellerLeadsSettings = null;
      this.steps.forEach(step => step.hasLoaded = true);
    }
  }

  updateAgentSetting(key: 'toEditSources' | 'toSeeOtherAgentsBusiness', value: boolean): void {
    this.isAgentAllowed[key] = value;
  }

  updateSellerLeadsSettings(
    key: keyof IAgentSellerLeadsSettings,
    value: IGeoLocation[] & IMarketingLeadTypeSettings,
  ): void {
    if (!this.sellerLeadsSettings) {
      this.sellerLeadsSettings = {
        marketingLeadTypeSettings: { nearZoneMaxRadius: 8, sourcesSettings: [] },
        primaryZones: [],
        secondaryZones: [],
      };
    }

    this.sellerLeadsSettings[key] = value;
  }

  async updateStep(stepIndex: number): Promise<void> {
    this.currentStep.hasError = !this.getStepValidation(this.currentStep.value);
    this.currentStep.isFilled = true;

    const nextStep = this.steps[stepIndex];
    this.stepIndex = stepIndex;

    if (nextStep.hasLoaded) {
      return;
    }

    this.isStepLoading = true;

    try {
      await this.getDataForNextStep(nextStep.value);
      nextStep.hasLoaded = true;
      this.isStepLoading = false;
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
    }
  }
}
