



















































































import { Component, Prop } from 'vue-property-decorator';
import { Duration } from 'date-fns';

import FormChip from '@/components/common/atoms/forms/chips/FormChip.vue';
import FormLabel from '@/components/common/atoms/forms/label/FormLabel.vue';
import FieldComponent from '../FieldComponent.vue';

const defaultValueIndex = 2;

const defaultOptions: Duration[] = [
  { hours: 0, minutes: 5 },
  { hours: 0, minutes: 15 },
  { hours: 0, minutes: 30 },
  { hours: 0, minutes: 45 },
  { hours: 1 },
  { hours: 1, minutes: 30 },
  { hours: 2 },
];

@Component({
  components: {
    FormChip,
    FormLabel,
  },
})
export default class DurationInput extends FieldComponent {
  @Prop({ default: () => ([]), type: Array }) readonly inputClass!: string[];
  @Prop({ default: false, type: Boolean }) readonly isShort!: boolean;
  @Prop({ default: () => defaultOptions, type: Array }) readonly options!: Duration[];
  @Prop({ default: () => defaultOptions[defaultValueIndex], type: Object }) declare readonly value: Duration;

  fieldValue = this.value;
  focus = false;
  selectedOption = defaultValueIndex;

  checkNumbersOnly(value: string): number {
    return Number(value.replace(/[^0-9]/g, ''));
  }

  getLabel(duration: Duration): string {
    const optionProperty = duration.hours ? 'hours-minutes-option' : 'minutes-option';

    return this.$t(`base.duration.${optionProperty}`, duration);
  }

  onBlur(): void {
    this.focus = false;
    this.$emit('blur');
  }

  onFocus(): void {
    this.focus = true;
    this.$emit('focus');
  }

  onInput({ target }: { target: HTMLInputElement }, property): void {
    let number = this.checkNumbersOnly(target.value);

    if (property === 'minutes') {
      number = number > 59 ? 59 : number;
    }

    if (property === 'hours') {
      number = number > 9 ? parseInt(number.toString()[1]) : number;
    }

    if (property === 'hours' || this.fieldValue[property] !== number || number === 0) {
      this.fieldValue = Object.assign({}, this.fieldValue, {
        [property]: number,
      });

      this.$emit('update', this.fieldValue);
    }
  }

  updateDuration(duration: Duration): void {
    this.fieldValue = Object.assign({ hours: 0, minutes: 0 }, duration);
    this.selectedOption = this.options.indexOf(duration);
    this.$emit('on-button-click', this.fieldValue);
  }
}
