import { LkAxiosInstance } from '@/api';
import { toQueryString } from '@/utils/url';

import { IGeoCity } from '@/interfaces/models/GeoCity.interface';
import { IGeoLocationResponse } from '@/interfaces/dto/geoLocationService/GeoLocationResponse.interface';

const BASE_ENDPOINT = '/geo_cities_search';

export interface IGeoLocationService {
  searchCities(query: string): Promise<IGeoCity[]>;
}

export const geoLocationService = ($axios: LkAxiosInstance): IGeoLocationService => ({
  async searchCities(query, limit = 10) {
    const queryParams = toQueryString({ limit, q: query });
    const { geoLocations } = await $axios.$get<IGeoLocationResponse>(`${BASE_ENDPOINT}?${queryParams}`);

    return geoLocations;
  },
});