import { isAfter } from 'date-fns';

import { EActionStatus } from '@/enums/ActionStatus.enum';
import { EEmailTargetType } from '@/enums/EmailTargetType.enum';
import { EEventType } from '@/enums/EventType.enum';
import { ILink } from '@/api/dome/links/Links.interface';

export interface IEmailResponse {
  buyerEmailSentAt: string | null;
  links: ILink[];
  sellerEmailSentAt: string | null;
  targetId: string;
  targetType: EEmailTargetType ;
}

export class Email implements IEmailResponse {
  actionStatus: EActionStatus | null = null;
  buyerEmailSentAt: string | null;
  links: ILink[];
  sellerEmailSentAt: string | null;
  targetId: string;
  targetType: EEmailTargetType;

  constructor(email: IEmailResponse) {
    this.buyerEmailSentAt = email.buyerEmailSentAt;
    this.links = email.links;
    this.sellerEmailSentAt = email.sellerEmailSentAt;
    this.targetId = email.targetId;
    this.targetType = email.targetType;
  }

  get emailSentAt(): string | null {
    if (this.targetType?.toUpperCase() === EEventType.Meeting) {
      return this.sellerEmailSentAt || null;
    }

    if (this.buyerEmailSentAt && this.sellerEmailSentAt) {
      return isAfter(new Date(this.buyerEmailSentAt), new Date(this.sellerEmailSentAt))
        ? this.buyerEmailSentAt
        : this.sellerEmailSentAt;
    }

    return this.sellerEmailSentAt || this.buyerEmailSentAt || null;
  }
}