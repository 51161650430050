






























































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Component as VueComponent } from 'vue';
import { Getter } from 'vuex-class';
import { Item } from '@vuex-orm/core';

import { customLogger } from '@/utils/errorHandler';
import { EConditions } from '@/enums/Conditions.enum';
import { EContactRole } from '@/enums/ContactRole.enum';
import { EDialogType } from '@/enums/DialogType.enum';
import { EDocumentEsignStatus } from '@/enums/DocumentEsignStatus.enum';
import { EOfferStatus } from '@/enums/OfferStatus.enum';
import { EOfferType } from '@/enums/OfferType.enum';
import { EPersonContactType } from '@/enums/PersonContactType.enum';
import { EPropertyProgress } from '@/enums/PropertyProgress.enum';
import { extractContactInformation } from '@/helpers/contacts';
import { getTypesToRequest } from '@/utils/events';
import { IEntities } from '@/interfaces/entity/Entities.interface';
import { IManualEmailContact } from '@/interfaces/ManualEmailContact.interface';
import { INegotiation } from '@/api/dome/negotiations/Negotiations.interface';
import { INegotiationMainBuyer } from '@/views/entity/steps/NegotiationMainBuyer.interface';
import { INegotiationRecipient, INegotiationRecipientsRequest }
  from '@/api/dome/negotiationRecipients/NegotiationRecipients.interface';
import { IOffer } from '@/api/dome/offers/Offers.interface';
import { IOption } from '@/interfaces/Option.interface';
import { IPerson } from '@/api/dome/persons/Persons.interface';
import { isPastDate } from '@/utils/date';
import { IStep } from '@/components/common/molecules/stepper/StepperWithStatus.interface';
import { NegotiationConditions as NegotiationConditionsModel } from '@/views/entity/steps/NegotiationConditions';
import { NegotiationFunding as NegotiationFundingModel } from '@/views/entity/steps/NegotiationFunding';
import { parseError } from '@/utils/validation';
import { Property } from '@/models';
import { PropertyEntityModel } from './models';

import ManualEmailDialog from '@/components/common/organisms/dialog/ManualEmailDialog.vue';
import EntityCreationWizard from './wizard.vue';
import NegotiationBuyers from './steps/NegotiationBuyers.vue';
import NegotiationConditions from './steps/NegotiationConditions.vue';
import NegotiationDialog from './dialogs/NegotiationDialog.vue';
import NegotiationFunding from './steps/NegotiationFunding.vue';
import NegotiationHelpers from './helpers/negotiation.vue';
import PersonHelpers from './helpers/person.vue';
import SelectPropertyWithDesignation from './steps/SelectPropertyWithDesignation.vue';
import Wizard from '@/components/common/templates/wizard/Wizard.vue';

interface IEmailDialog {
  contacts: IManualEmailContact[];
  isVisible: boolean;
  offer: {
    address: string;
    name: string;
    offerId: string;
    percent: number | null;
    price: number;
  } | null;
  type: EDialogType | null;
}

type IStepValue = keyof typeof stepHelper;

interface IStepWithComponent<T> extends IStep<T> {
  component: VueComponent;
  hasLoaded: boolean;
}

const stepHelper = {
  negotiation_buyers: NegotiationBuyers,
  negotiation_conditions: NegotiationConditions,
  negotiation_funding: NegotiationFunding,
  'select-property-with-designation': SelectPropertyWithDesignation,
};

@Component({
  components: {
    ManualEmailDialog,
    NegotiationDialog,
    Wizard,
  },
})
export default class NegotiationEntity extends Mixins(EntityCreationWizard, PersonHelpers, NegotiationHelpers) {
  @Prop({ default: false, type: Boolean }) isModifyEntity?: boolean;
  @Prop({ type: Object }) negotiation?: INegotiation;
  @Prop({ type: Object }) suggestionList?: Record<'agency' | 'person' | 'property', IOption<string>[]>;

  @Getter('isDesktop') isDesktop!: boolean;

  buyer: IPerson | null = null;
  emailDialog: IEmailDialog = { contacts: [], isVisible: false, offer: null, type: null };
  isConfirmDialogVisible = false;
  isDisclaimerDialogVisible = false;
  offer: IOffer | null = null;
  offerSuggestionList: Record<'person' | 'property', IOption<string>[]> = { person: [], property: [] };
  propertyDesignation: string | null = null;
  stepIndex = 0;

  get currentStep(): IStepWithComponent<IStepValue> {
    return this.steps[this.stepIndex];
  }

  get offerType(): EOfferType {
    if (this.isModifyEntity && this.negotiation) {
      return this.negotiation.offer.isSellerOffer ? EOfferType.CounterSeller : EOfferType.CounterBuyer;
    }

    return EOfferType.Simple;
  }

  get personFullName(): string {
    if (this.isModifyEntity && this.negotiation) {
      return this.negotiation.offer.isSellerOffer
        ? this.property?.seller?.fullName || ''
        : `${this.negotiation.mainBuyerPerson.firstName} ${this.negotiation.mainBuyerPerson.lastName}`;
    } else if (!this.entities.negotiationBuyers?.mainBuyer.id) {
      const mainBuyer = this.entities.negotiationBuyers?.mainBuyer;

      return `${mainBuyer?.firstName} ${mainBuyer?.lastName}`;
    }

    return `${this.buyer?.firstName} ${this.buyer?.lastName}`;
  }

  get property(): Item<Property> | null {
    if (this.isModifyEntity && this.negotiation) {
      return Property.query()
        .whereId(this.propertyId)
        .withAllRecursive()
        .first();
    } else if (!this.selectedEntities.property) {
      return null;
    }

    return Property.query()
      .whereId(this.selectedEntities.property)
      .first();
  }

  get propertyId(): string {
    return this.$route.params.propertyId;
  }

  get stepList(): readonly IStepValue[] {
    return this.isModifyEntity
      ? ['negotiation_funding', 'negotiation_conditions']
      : ['select-property-with-designation', 'negotiation_buyers', 'negotiation_funding', 'negotiation_conditions'];
  }

  get steps(): IStepWithComponent<IStepValue>[] {
    return this.stepList.map(step => ({
      component: stepHelper[step],
      hasError: false,
      hasLoaded: false,
      isFilled: false,
      name: this.$t(`entity_creation.steps.${step}.title`),
      value: step,
    }));
  }

  @Watch('suggestionList.property', { immediate: true })
  updatePropertySuggestions(properties: IOption<string>[]) {
    this.offerSuggestionList.property = properties;
  }

  async created(): Promise<void> {
    if (!this.property && this.propertyId) {
      await Property.api().getPropertyDetails(this.propertyId);
    }

    if (this.isWizardVisible) {
      await this.setData();
    }

    await this.getBuyer();
  }

  async addNegotiation(): Promise<void> {
    await this.handleMainBuyer();

    try {
      const { negotiationConditions, negotiationFunding, negotiationBuyers } = this.entities;
      const isCompanyBuying = !!negotiationBuyers?.recipientsBuyers.isCompanyBuying;

      const { id, offer, mainBuyerPerson } = await this.$api.negotiations.add({
        buyingCompanyName: isCompanyBuying && negotiationBuyers
          ? negotiationBuyers.recipientsBuyers.buyingCompanyName
          : '',
        isCompanyBuying,
        mainBuyerPersonId: negotiationBuyers?.mainBuyer.id as string,
        offer: this.generateOfferDataForRequest(negotiationFunding, negotiationConditions),
        propertyDesignation: this.propertyDesignation as string,
        propertyId: this.selectedEntities.property as string,
      });

      await this.createNegotiationBuyers(id, mainBuyerPerson);

      this.offer = offer;
      // extract personId and propertyId before data reset due to redirect
      const personId = (this.selectedEntities?.person || this.entities.negotiationBuyers?.mainBuyer?.id) as string;
      const propertyId = this.selectedEntities?.property as string;

      this.getPropertyStatus(propertyId);

      await this.setDataForCreationManualEmailDialog(
        offer,
        EDialogType.OfferCreation,
        personId,
        propertyId,
      );

      this.emailDialog.isVisible = true;
    } catch (error) {
      this._handleError(error, this.stepList.indexOf('negotiation_conditions'), true);
    }
  }

  async closeDisclaimerDialog(): Promise<void> {
    this.isDisclaimerDialogVisible = false;
    await this.closeWizard();
  }

  async closeManualEmailDialog(isEmailSent = false): Promise<void> {
    const propertyId = this.property!.id;
    this.emailDialog.isVisible = false;
    this.emailDialog.contacts = [];
    this.emailDialog.type = null;
    await this.closeWizard();

    if (this.offer?.negotiationId) {
      await this.$router.push({
        hash: undefined,
        name: this.isDesktop ? 'offer_history' : 'offer',
        params: { negotiationId: this.offer.negotiationId, propertyId },
      });
    }

    this.$emit('email-sent', isEmailSent);
  }

  async createEntity(): Promise<void> {
    await this.getBuyer();

    if (!this.isEntityValid(this.stepList)) {
      this._handleError('invalid data', this.errorStepIndex);

      return;
    }

    const hasAnotherOffer = await this.getHasAnotherOffer(this.property!.id);

    if (hasAnotherOffer) {
      this.isDisclaimerDialogVisible = true;
    } else {
      this.isConfirmDialogVisible = true;
    }
  }

  async createNegotiationBuyers(negotiationId: string, buyer: IPerson): Promise<void> {
    try {
      const primaryContacts = buyer.personContacts.filter(contact => contact.isPrimary);

      const mainBuyer = {
        email: primaryContacts.find(contact => contact.type === EPersonContactType.Email)?.value,
        firstName: buyer.firstName,
        isProxy: false,
        lastName: buyer.lastName,
        personId: buyer.id,
        phoneNumber: primaryContacts.find(contact => contact.type === EPersonContactType.Phone)?.value || '',
        proxyName: '',
        type: EContactRole.Buyer,
      };

      const recipientBuyers = this.entities.negotiationBuyers?.recipientsBuyers.recipients as INegotiationRecipient[];
      const negotiationRecipients = [mainBuyer, ...recipientBuyers];

      await this.$api.negotiationRecipients.upsert({
        negotiationId,
        negotiationRecipients,
      } as INegotiationRecipientsRequest);
    } catch (error) {
      return this.$snackbar.error(parseError(this, error));
    }
  }

  async getBuyer(): Promise<void> {
    if (this.entities.negotiationBuyers?.mainBuyer.id) {
      try {
        this.buyer = await this.$api.persons.getById(this.entities.negotiationBuyers.mainBuyer.id);
      } catch (error) {
        this.$snackbar.error(parseError(this, error));
      }
    }
  }

  getPropertyStatus(propertyId: string): void {
    if (this.property?.progress === EPropertyProgress.AcceptedOffer) {
      Property.api().getPropertyDetails(propertyId);
    }
  }

  async handleEntitySelection(name: string, id: string): Promise<void> {
    this.selectEntity(name, id);
    await this.updateSuggestionList();
  }

  async handleMainBuyer(): Promise<void> {
    // Person
    try {
      const selectedEntities = { person: this.entities.negotiationBuyers?.mainBuyer?.id || '' };
      const entities = { person: this.entities.negotiationBuyers?.mainBuyer as INegotiationMainBuyer };

      const personId = await this.createPerson(selectedEntities, entities);

      if (!personId) {
        this._handleError('empty', this.stepList.indexOf('negotiation_buyers'));

        return;
      } else if (this.entities.negotiationBuyers?.mainBuyer && !this.entities.negotiationBuyers.mainBuyer.id) {
        this.entities.negotiationBuyers.mainBuyer.id = personId;
      }
    } catch (error) {
      this._handleError(error, this.stepList.indexOf('negotiation_buyers'), true);

      return;
    }
  }

  handlePropertyDesignation(designation: string): void {
    this.propertyDesignation = designation;
  }

  async handleSubmitEmails(contacts: IManualEmailContact[]): Promise<void> {
    const offerId = this.emailDialog.offer?.offerId;
    const offer = this.negotiation?.offer || this.offer;

    if (!offerId || !offer) {
      return;
    }

    if (this.isModifyEntity) {
      offer.isInitialOffer = false;
    }

    offer.acceptedAt = null;
    const typesToRequest = getTypesToRequest(offer, contacts.map(contact => contact.role));

    if (!typesToRequest) {
      return;
    }

    try {
      await Promise.all(typesToRequest.map(type => this.$api.offers.sendEmail(offerId, type)));
      await this.closeManualEmailDialog(true);
    } catch (error) {
      await this.closeManualEmailDialog(false);
      customLogger(error);
    }
  }

  async handleValidateEntityKey(key: keyof IEntities, value: string): Promise<void> {
    if (key === 'negotiationBuyers' && value === 'mainBuyer.email') {
      await this.validatePersonEmail();
    } else {
      this.validateEntityKey(key, value);
    }
  }

  async getHasAnotherOffer(propertyId: string): Promise<boolean> {
    const negotiations = await this.$api.negotiations.getNegotiations(propertyId);

    return negotiations.some(negotiation => negotiation.offer.status === EOfferStatus.Accepted
      && (negotiation.offer.esignStatus === EDocumentEsignStatus.Pending
        || negotiation.offer.esignStatus === EDocumentEsignStatus.Signed),
    );
  }

  async sendNegotiation(): Promise<void> {
    this.isSubmitting = true;
    this.isConfirmDialogVisible = false;

    if (this.isModifyEntity) {
      await this.updateNegotiation();
    } else {
      await this.addNegotiation();
    }
  }

  async setContactsManualEmail(
    personId: string, propertyId: string,
  ): Promise<{ buyer?: IManualEmailContact, property?: Item<Property>}> {
    let property: Item<Property> | undefined;
    const buyer = await this.$api.persons.getById(personId);

    this.emailDialog.contacts = [{
      ...extractContactInformation(buyer, buyer.personContacts),
      role: EContactRole.Buyer,
    }];

    if (propertyId) {
      property = Property.query().whereId(propertyId).first();

      if (property?.seller) {
        this.emailDialog.contacts.push({
          ...extractContactInformation(property.seller, property.seller.personContacts),
          role: EContactRole.Seller,
        });
      }
    }

    return { buyer: this.emailDialog.contacts[0], property };
  }

  async setData(): Promise<void> {
    if (this.negotiation) {
      const {
        additionalSuspensiveConditions,
        expiresOn: previousOfferExpiresOn,
        funding,
        isSellerOffer,
        otherAdditionalModalities,
        price,
        sequestrationRate,
        suspensiveConditions,
      } = this.negotiation.offer;

      const expiresOn = isPastDate(previousOfferExpiresOn) ? '' : previousOfferExpiresOn;

      const negotiationFunding = new NegotiationFundingModel(funding, price, isSellerOffer, expiresOn);

      const negotiationConditions = new NegotiationConditionsModel(
        suspensiveConditions,
        sequestrationRate,
        additionalSuspensiveConditions,
        otherAdditionalModalities,
      );

      this.entities = { negotiationConditions, negotiationFunding };
    } else {
      let sequestrationRate;

      try {
        const { defaultSequestrationRate } = await this.$api.negotiations.getNegotiationOfferDefault(this.propertyId);
        sequestrationRate = defaultSequestrationRate;
      } catch {
        sequestrationRate = 0;
      }

      this.entities = {
        negotiationBuyers: {
          mainBuyer: {
            email: '',
            firstName: '',
            id: '',
            lastName: '',
            phone: '',
            tags: [EContactRole.Buyer],
          },
          recipientsBuyers: {
            buyingCompanyName: '',
            isCompanyBuying: false,
            recipients: [],
          },
        },
        negotiationConditions: new NegotiationConditionsModel([EConditions.Funding], sequestrationRate),
        negotiationFunding: new NegotiationFundingModel(),
        property: new PropertyEntityModel(),
      };

      // TODO(refactor: remove it)
      this.selectedEntities = { property: undefined };
      this.propertyDesignation = null;
    }

    await this.updateSuggestionList();
  }

  async setDataForCreationManualEmailDialog(
    offer: IOffer,
    dialogType: EDialogType,
    personId: string,
    propertyId: string,
  ): Promise<boolean> {
    this.emailDialog.type = dialogType;
    const { buyer, property } = await this.setContactsManualEmail(personId, propertyId);

    if (buyer && property) {
      this.emailDialog.offer = {
        address: property.fullAddress,
        name: offer?.isSellerOffer ? this.emailDialog.contacts[1].name : buyer.name,
        offerId: offer?.id,
        percent: property.price ? offer?.price / property.price : null,
        price: offer?.price,
      };
    }

    return !!(buyer && property);
  }

  async updateNegotiation(): Promise<void> {
    const negotiationId = this.$route.params.negotiationId;

    let newOffer: IOffer | undefined;

    try {
      const { negotiationConditions, negotiationFunding } = this.entities;
      const offerData = this.generateOfferDataForRequest(negotiationFunding, negotiationConditions);

      const { offer } = await this.$api.negotiations.addOffer(negotiationId, offerData);
      newOffer = offer;

      if (this.negotiation) {
        await this.$api.negotiations.updateNegotiation(negotiationId, {
          buyingCompanyName: this.negotiation.buyingCompanyName,
          companyOwningPropertyName: this.negotiation.companyOwningPropertyName,
          isCompanyBuying: this.negotiation.isCompanyBuying,
          isPropertyOwnedByCompany: this.negotiation.isPropertyOwnedByCompany,
          propertyDesignation: this.negotiation.propertyDesignation,
        });
      }
    } catch (error) {
      this._handleError(error, this.stepList.indexOf('negotiation_conditions'), true);
    }

    if (this.negotiation) {
      this.getPropertyStatus(this.negotiation.propertyId);
    }

    if (newOffer && this.negotiation) {
      await this.setDataForCreationManualEmailDialog(
        newOffer,
        newOffer.isSellerOffer ? EDialogType.OfferCounterOfferSeller : EDialogType.OfferCounterOfferBuyer,
        this.negotiation.mainBuyerPerson.id,
        this.negotiation.propertyId,
      );
    }

    this.emailDialog.isVisible = true;
  }

  async updateSuggestionList(): Promise<void> {
    const persons = this.suggestionList?.person.filter(user => user.tags?.includes('buyer')) || [];
    const sellerId = this.property?.seller?.id;
    let filteredPersons = persons;

    if (this.selectedEntities.property && sellerId) {
      filteredPersons = persons.filter((user: IOption<string>) => user.value !== sellerId);
    }

    for (const person of filteredPersons) {
      if (!person.label) {
        try {
          person.label = (await this.$api.persons.getById(person.value)).fullName;
        } catch (error) {
          this.$snackbar.error(parseError(this, error));
        }
      }
    }

    this.offerSuggestionList.person = filteredPersons;
  }

  async validatePersonEmail(): Promise<void> {
    this.validateEntityKey('negotiationBuyers', 'mainBuyer.email');

    if (!this.entities.negotiationBuyers || this.errorMessages.person.email) {
      return;
    }

    const { id, email } = this.entities.negotiationBuyers.mainBuyer;
    this.isEmailUnique = id ? true : await this.getIsEmailUnique(email);
  }
}
