




























































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { buyerQualifications } from '@/helpers/qualifications/buyer-qualifications';
import { EBuyingPurpose } from '@/enums/BuyingPurpose.enum';
import { ELoanStatus } from '@/enums/LoanStatus.enum';
import { EPropertyAge } from '@/enums/PropertyAge.enum';
import { EPropertySearchProgress } from '@/enums/PropertyPurchasePlan.enum';
import { EPropertyType } from '@/enums/PropertyType.enum';
import { EWizardActionType } from '@/enums/WizardActionType.enum';
import { FormGroup } from '@/utils/form';
import { getYesNoOptions } from '@/utils/options';
import { IDomeSettings } from '@/api/dome/agents/Agents.interface';
import { IGeoCity } from '@/interfaces/models/GeoCity.interface';
import { IOption } from '@/interfaces/Option.interface';
import { IPropertyPurchasePlanForm } from '@/api/dome/property-purchase-plans/PropertyPurchasePlans.interface';
import { parseError } from '@/utils/validation';
import { PropertyPurchasePlanEntityModel } from '@/views/entity/models';

import BaseInputTags from '@/components/common/molecules/forms/input-tags/BaseInputTags.vue';

@Component({
  components: {
    BaseInputTags,
  },
})
export default class PropertyPurchasePlanStep extends Vue {
  @Prop({ required: true, type: Object }) entities!: { propertyPurchasePlan: PropertyPurchasePlanEntityModel };
  @Prop({ required: true, type: Object }) form!: FormGroup<IPropertyPurchasePlanForm>;
  @Prop({ default: EWizardActionType.Create, type: String }) type!: string;

  @State('settings') settings!: IDomeSettings;

  buyerQualificationOptions = Object.freeze(Object.entries(buyerQualifications)
    .map(([qualification, option]) => ({ ...option, value: qualification })));
  buyingPurposeOptions: readonly IOption[] = Object.freeze(
    [EBuyingPurpose.Reside, EBuyingPurpose.Invest].map(value => ({
      label: this.$t(`property_purchase_plan.buying_purpose.${value}`),
      value,
    })),
  );
  EWizardActionType = Object.freeze(EWizardActionType);
  fundingStatusOptions: readonly IOption[] = Object.freeze(
    [ELoanStatus.Validated, ELoanStatus.Ongoing, ELoanStatus.NotStarted].map(value => ({
      label: this.$t(`property_purchase_plan.funding_status.${value}`),
      value,
    })),
  );
  geoCitiesSearchResults: IGeoCity[] = [];
  houseOrAptOptions: readonly IOption[] = Object.freeze(
    [EPropertyType.Apartment, EPropertyType.House, EPropertyType.Both].map(value => ({
      label: this.$t(`property_type.${value}`),
      value,
    })),
  );
  propertyAgeOptions: readonly IOption[] = Object.freeze(
    [EPropertyAge.Old, EPropertyAge.New].map(value => ({
      label: this.$t(`property_purchase_plan.property_age.${value}`),
      value,
    })),
  );
  propertySearchProgressOptions: readonly IOption[] = Object.freeze(
    [EPropertySearchProgress.Advanced, EPropertySearchProgress.Recent, EPropertySearchProgress.Beginning]
      .map(value => ({
        label: this.$t(`property_purchase_plan.property_search_progress.${value}`),
        value,
      })),
  );

  @Watch('entities.propertyPurchasePlan.geoCities')
  updateGeoCitiesIds(geoCities: IGeoCity[]) {
    this.form.get('geoCitiesIds').setValue(geoCities.map(city => city.id));
    this.form.get('geoCitiesIds').validate();
  }

  get isPropertyPurchasePlanDisabled(): boolean {
    return this.form.get('isDisabled').value || !this.form.get('propertyType').value;
  }

  get yesNoOptions(): IOption[] {
    return getYesNoOptions();
  }

  removeGeoCities(index: number): void {
    this.entities.propertyPurchasePlan.geoCities.splice(index, 1);
  }

  async searchCities(value: string): Promise<void> {
    if (value === '') {
      this.geoCitiesSearchResults = [];

      return;
    }

    try {
      this.geoCitiesSearchResults = await this.$api.geoLocation.searchCities(value);
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
      this.geoCitiesSearchResults = [];
    }
  }

  updateGeoCities(geoCity: IGeoCity): void {
    this.entities.propertyPurchasePlan.geoCities.push(geoCity);
  }

  updateIsDisabled(value: boolean): void {
    this.form.get('isDisabled').setValue(value);
    this.form.resetErrors();
  }

  updateMinimumRoomsCount(): void {
    this.form.get('minimumRoomsCount').validate();

    const minimumBedroomsCount = this.form.get('minimumBedroomsCount').value || 0;

    if (this.form.get('minimumRoomsCount').value > minimumBedroomsCount) {
      this.form.get('minimumBedroomsCount').resetErrors();
    }
  }

  updatePropertyType(value: EPropertyType): void {
    this.form.get('propertyType').setValue(value);
    this.form.get('propertyType').validate();
    this.form.get('propertyType').resetErrors();
  }
}
