





































import { Component, Prop } from 'vue-property-decorator';

import FieldComponent from '@/components/common/molecules/forms/FieldComponent.vue';

let inputId = 0;

@Component
export default class BaseSwitch extends FieldComponent {
  @Prop({ default: false, type: Boolean }) readonly checked?: boolean;
  @Prop({ default: '', type: String }) readonly name?: string;
  @Prop({ default: false, type: Boolean }) declare readonly value: boolean;

  id = `switch-${inputId++}`;

  get showPreviousValue(): boolean {
    return !!this.previousValue && this.checked !== this.previousValue;
  }

  onChange({ target }: { target: HTMLInputElement }): void {
    this.$emit('update', this.value ? target.value : target.checked);
  }
}
