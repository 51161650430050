




































import { Vue, Component, Prop } from 'vue-property-decorator';
import Placeholder from '@/assets/illustrations/placeholder.svg';

@Component({
  components: {
    Placeholder,
  },
})
export default class NoResultsPlaceholder extends Vue {
  @Prop({ default: true, type: Boolean }) displayIcon!: boolean;
  @Prop({ required: false, type: String }) hint?: string;
  @Prop({ required: true, type: String }) noResultsText!: string;
  @Prop({ default: '', type: String }) noResultsTitle!: string;
}
