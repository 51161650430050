import { EPropertyType } from '@/enums/PropertyType.enum';
import {
  ICreatePropertyPurchasePlanPayload,
  IPropertyPurchasePlan,
  IPropertyPurchasePlanFollowed,
  IPropertyPurchasePlanList,
  IFollowedPropertyPurchasePlans,
  IUpdatePropertyPurchasePlanPayload,
} from './PropertyPurchasePlans.interface';
import { IPaginationParams } from '@/interfaces/models/Pagination.interface';
import { LkAxiosInstance } from '@/api';
import { toQueryString } from '@/utils/url';

const BASE_ENDPOINT = '/property_purchase_plans';

const getFormattedPurchasePlan = (
  propertyPurchasePlan: IPropertyPurchasePlan,
): IPropertyPurchasePlan => {
  if (propertyPurchasePlan.propertyType === null) {
    propertyPurchasePlan.propertyType = EPropertyType.Both;
  }

  return propertyPurchasePlan;
};

const getPurchasePlanPayload = (
  propertyPurchasePlan: ICreatePropertyPurchasePlanPayload | IUpdatePropertyPurchasePlanPayload,
): ICreatePropertyPurchasePlanPayload | IUpdatePropertyPurchasePlanPayload => {
  if (propertyPurchasePlan.propertyType === EPropertyType.Both) {
    propertyPurchasePlan.propertyType = null;
  }

  return propertyPurchasePlan;
};

export interface IPropertyPurchasePlansService {
  cancel(id: string, token?: string): Promise<void>;
  cancelByToken(id: string, token: string): Promise<void>;
  create(propertyPurchasePlan: ICreatePropertyPurchasePlanPayload): Promise<IPropertyPurchasePlan>;
  follow(id: string): Promise<void>;
  getByPersonId(personId: string): Promise<IPropertyPurchasePlan | null>;
  getFollowUp(id: string, agentId?: string): Promise<IPropertyPurchasePlanFollowed>;
  getFollowUps(agentId?: string): Promise<IFollowedPropertyPurchasePlans>;
  getFromPerson(personId: string, params: IPaginationParams): Promise<IPropertyPurchasePlan[]>;
  unfollow(id: string): Promise<void>;
  update(id: string, propertyPurchasePlan: IUpdatePropertyPurchasePlanPayload): Promise<IPropertyPurchasePlan>;
  updatePrivateNotes(id: string, text: string): Promise<void>;
}

export const propertyPurchasePlansService = ($axios: LkAxiosInstance): IPropertyPurchasePlansService => ({
  cancel(id) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/cancel`);
  },
  cancelByToken(id, token) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/cancel_by_token?token=${token}`);
  },
  async create(propertyPurchasePlan) {
    const response = await $axios.$post<IPropertyPurchasePlan>(BASE_ENDPOINT, {
      propertyPurchasePlan: getPurchasePlanPayload(propertyPurchasePlan),
    });

    return getFormattedPurchasePlan(response);
  },
  follow(id) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/follow`);
  },
  async getByPersonId(personId) {
    const query = toQueryString({ order: 'updated_at', person_id: personId, reverse: true });
    const { propertyPurchasePlans } = await $axios.$get<IPropertyPurchasePlanList>(`${BASE_ENDPOINT}?${query}`);

    return propertyPurchasePlans.map(plan => getFormattedPurchasePlan(plan))[0];
  },
  async getFollowUp(id, agentId) {
    const query = toQueryString({ agent_id: agentId });

    return $axios.$get(`${BASE_ENDPOINT}/${id}/followed/view_for_panel?${query}`);
  },
  async getFollowUps(agentId) {
    const query = toQueryString({ agent_id: agentId });

    return await $axios.$get(`${BASE_ENDPOINT}_followed/view_for_listing?${query}`);
  },
  // INFO: unused for now
  async getFromPerson(personId, params = {}) {
    const query = toQueryString({ person_id: personId, ...params });
    const { propertyPurchasePlans } = await $axios.$get<IPropertyPurchasePlanList>(`${BASE_ENDPOINT}?${query}`);

    return propertyPurchasePlans.map(plan => getFormattedPurchasePlan(plan));
  },
  unfollow(id) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/followed/unfollow`);
  },
  async update(id, propertyPurchasePlan) {
    const response = await $axios.$post<IPropertyPurchasePlan>(`${BASE_ENDPOINT}/${id}`, {
      propertyPurchasePlan: getPurchasePlanPayload(propertyPurchasePlan),
    });

    return getFormattedPurchasePlan(response);
  },
  updatePrivateNotes(id, text) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/followed/update_private_notes`, { notes: text });
  },
});