const F95Index = () => import(/* webpackChunkName: "f95" */ '@/views/properties/id/f95/index.vue');
const F95ShowKitchen = () => import(/* webpackChunkName: "f95" */ '@/views/properties/id/f95/kitchens/edit.vue');
const F95ShowRoom = () => import(/* webpackChunkName: "f95" */ '@/views/properties/id/f95/rooms/edit.vue');
const F95ShowOtherRoom = () => import(/* webpackChunkName: "f95" */ '@/views/properties/id/f95/other-rooms/edit.vue');
const F95ShowWaterRoom = () => import(/* webpackChunkName: "f95" */ '@/views/properties/id/f95/water-rooms/edit.vue');
const F95ShowCellar = () => import(/* webpackChunkName: "f95" */ '@/views/properties/id/f95/cellars/edit.vue');
const F95ShowParking = () => import(/* webpackChunkName: "f95" */ '@/views/properties/id/f95/parkings/edit.vue');
const F95ShowExterior = () => import(/* webpackChunkName: "f95" */ '@/views/properties/id/f95/exteriors/edit.vue');
const F95ShowMarketing = () => import(/* webpackChunkName: "f95" */ '@/views/properties/id/f95/marketing/edit.vue');
const F95ShowIndoor = () => import(/* webpackChunkName: "f95" */ '@/views/properties/id/f95/indoor/edit.vue');
const F95ShowOutdoor = () => import(/* webpackChunkName: "f95" */ '@/views/properties/id/f95/outdoor/edit.vue');
const F95ShowExpenses = () => import(/* webpackChunkName: "f95" */ '@/views/properties/id/f95/expenses/edit.vue');
const F95ShowCondominium = () => import(/* webpackChunkName: "f95" */ '@/views/properties/id/f95/condominium/edit.vue');
// eslint-disable-next-line vue/max-len
const F95ShowRoomSelector = () => import(/* webpackChunkName: "f95" */ '@/views/properties/id/f95/room-selector/edit.vue');
const F95ShowOverallComment = () => import(/* webpackChunkName: "f95" */ '@/views/properties/id/f95/notes/edit.vue');

export default [
  {
    children: [
      {
        component: F95ShowKitchen,
        name: 'kitchen',
        path: 'cuisines/:roomId',
      },
      {
        component: F95ShowRoom,
        name: 'bedroom',
        path: 'chambres/:roomId',
      },
      {
        component: F95ShowRoom,
        name: 'living_room',
        path: 'salons/:roomId',
      },
      {
        component: F95ShowRoom,
        name: 'home_office',
        path: 'bureaux/:roomId',
      },
      {
        component: F95ShowOtherRoom,
        name: 'other_home_office',
        path: 'autres-bureaux/:roomId',
      },
      {
        component: F95ShowRoom,
        name: 'dining_room',
        path: 'salles-a-manger/:roomId',
      },
      {
        component: F95ShowOtherRoom,
        name: 'dressing',
        path: 'dressings/:roomId',
      },
      {
        component: F95ShowOtherRoom,
        name: 'entrance',
        path: 'entrees/:roomId',
      },
      {
        component: F95ShowOtherRoom,
        name: 'pantry',
        path: 'celliers/:roomId',
      },
      {
        component: F95ShowOtherRoom,
        name: 'loggia',
        path: 'loggias/:roomId',
      },
      {
        component: F95ShowWaterRoom,
        name: 'toilet',
        path: 'toilettes/:roomId',
      },
      {
        component: F95ShowWaterRoom,
        name: 'bathroom',
        path: 'salles-de-bains/:roomId',
      },
      {
        component: F95ShowCellar,
        name: 'cellar',
        path: 'caves/:roomId',
      },
      {
        component: F95ShowParking,
        name: 'parking',
        path: 'parkings/:roomId',
      },
      {
        component: F95ShowExterior,
        name: 'balcony',
        path: 'balconies/:roomId',
      },
      {
        component: F95ShowExterior,
        name: 'terrace',
        path: 'terraces/:roomId',
      },
      {
        component: F95ShowExterior,
        name: 'garden',
        path: 'gardens/:roomId',
      },
      {
        component: F95ShowExterior,
        name: 'land',
        path: 'lands/:roomId',
      },
      {
        component: F95ShowExterior,
        name: 'rooftop',
        path: 'rooftops/:roomId',
      },
      {
        component: F95ShowExterior,
        name: 'veranda',
        path: 'verandas/:roomId',
      },
      {
        component: F95ShowMarketing,
        name: 'marketing',
        path: 'raison-de-la-vente',
      },
      {
        component: F95ShowIndoor,
        name: 'indoor',
        path: 'informations-interieures',
      },
      {
        component: F95ShowOutdoor,
        name: 'outdoor',
        path: 'informations-exterieures',
      },
      {
        component: F95ShowExpenses,
        name: 'expense',
        path: 'depenses-et-revenus',
      },
      {
        component: F95ShowCondominium,
        name: 'condominium',
        path: 'copropriete',
      },
      {
        component: F95ShowRoomSelector,
        name: 'room_selector',
        path: 'pièces',
      },
      {
        component: F95ShowOverallComment,
        name: 'overall_comment',
        path: 'notes',
      },
    ],
    component: F95Index,
    name: 'property-f95',
    path: '/biens/:propertyId/f95',
  },
];