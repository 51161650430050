












































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { French } from 'flatpickr/dist/l10n/fr.js';
import { isSameDay } from 'date-fns';

import { IBaseDateTimeConfig } from './BaseDatetime.interface';

import FieldComponent from '@/components/common/molecules/forms/FieldComponent.vue';
import FlatPickr from 'vue-flatpickr-component';

let inputId = 0;

@Component({
  components: {
    FlatPickr,
  },
})
export default class BaseDatetime extends FieldComponent<string> {
  @Prop({ default: null, type: Object }) config?: IBaseDateTimeConfig;
  @Prop({ default: false, type: Boolean }) isClearable!: boolean;
  @Prop({ default: false, type: Boolean }) isNullable!: boolean;
  @Prop({ default: false, type: Boolean }) isValueWatched!: boolean;
  @Prop({ default: 'date', type: String }) type!: 'date' | 'time';

  defaultConfig: IBaseDateTimeConfig = Object.freeze({
    altFormat: this.type === 'time' ? 'H\\hi' : 'j F Y',
    altInput: true,
    enableTime: this.type === 'time',
    locale: French,
    noCalendar: this.type === 'time',
    time24hr: true,
  });
  error: string | null = null;
  fieldValue: string | null = this.value;
  flatPickr: { toggle: () => void } | null = null;
  focus = false;
  id = `input-datetime-${inputId++}`;

  @Watch('focus')
  emitFocus(val: boolean): void {
    if (val) {
      this.$emit('focus');
    }
  }

  @Watch('value')
  manageValueUpdate(value: string): void {
    if (this.isValueWatched) {
      this.fieldValue = value;
    }
  }

  get mergedConfig(): IBaseDateTimeConfig {
    return { ...this.defaultConfig, ...this.config };
  }

  get showPreviousValue(): boolean {
    return !!(this.previousValue && this.fieldValue)
      && !isSameDay(new Date(this.fieldValue), new Date(this.previousValue));
  }

  clearInput(): void {
    this.onInput('');
  }

  onClose(): void {
    this.focus = false;
    // Delayed so that input is fired before blur
    setTimeout(() => this.$emit('blur'), 1);
  }

  onFocus(): void {
    this.focus = true;
    this.flatPickr?.toggle();
  }

  onInput(value: string): void {
    const _value = this.isNullable ? value || null : value;

    if (this.isValueWatched) {
      this.$emit('update', _value);
    } else {
      this.fieldValue = _value;
      this.$emit('update', _value);
    }
  }

  onOpen(): void {
    this.focus = true;

    if (this.type === 'time' && !this.value) {
      this.fieldValue = `${this.mergedConfig.defaultHour || '12'}:${this.mergedConfig.defaultMinute || '00'}`;
    }
  }

  onReady(_x, _y, instance: { toggle: () => void }): void {
    this.flatPickr = instance;
  }
}
