


















































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { getYesNoOptions } from '@/utils/options';
import { IAgent } from '@/api/bo/agents/Agents.interface';
import { IApproverAgents, IIsAgentAllowed } from '..';
import { IEntities } from '@/interfaces/entity/Entities.interface';
import { IErrorMessages } from '@/interfaces/ErrorMessages.interface';
import { IResultItem } from '@/interfaces/ResultItem.interface';
import { parseError } from '@/utils/validation';

interface IAgentSearch {
  isLoading: boolean;
  results: IResultItem[];
  search: string;
}

const getFormattedResults = (agents: IAgent[], agentIdToExclude?: string): IResultItem[] =>
  agents.reduce<IResultItem[]>((arr, agent) => {
    if (agent.id !== agentIdToExclude) {
      arr.push({ ...agent, content: `<span class="mr-2 group-hover:text-lk-blue">${agent.fullName}</span>` });
    }

    return arr;
  }, []);

@Component
export default class ManageRightsStep extends Vue {
  @Prop({ required: true, type: Object }) approverAgents!: IApproverAgents;
  @Prop({ required: true, type: Object }) entities!: IEntities;
  @Prop({ default: () => ({}), type: Object }) errorMessages!: IErrorMessages;
  @Prop({ required: true, type: Object }) isAgentAllowed!: IIsAgentAllowed;
  @Prop({ default: () => ([]), type: Array }) sortedAgents!: IAgent[];

  estimationAgent: IAgentSearch = { isLoading: false, results: [], search: '' };
  mandateAgent: IAgentSearch = { isLoading: false, results: [], search: '' };
  yesNoOptions = Object.freeze(getYesNoOptions());

  clearEstimationAgent(isNeeded = true): void {
    this.estimationAgent.search = '';
    this.approverAgents.estimation = null;
    this.updateEntity('estimationApproverAgentId', isNeeded ? '' : null);
  }

  clearMandateAgent(isNeeded = true): void {
    this.mandateAgent.search = '';
    this.approverAgents.mandate = null;
    this.updateEntity('mandateApproverAgentId', isNeeded ? '' : null);
  }

  selectEstimationAgent(agent: IAgent): void {
    this.approverAgents.estimation = agent;
    this.updateEntity('estimationApproverAgentId', agent.id);
    this.validateEntityKey('estimationApproverAgentId');
  }

  selectMandateAgent(agent: IAgent): void {
    this.approverAgents.mandate = agent;
    this.updateEntity('mandateApproverAgentId', agent.id);
    this.validateEntityKey('mandateApproverAgentId');
  }

  async updateEstimationAgentSearch(value: string): Promise<void> {
    this.estimationAgent.isLoading = true;
    this.estimationAgent.search = value;

    try {
      const { agents } = await this.$boApi.agents.search({ agencyId: this.entities.agent?.agencyId, q: value });
      this.estimationAgent.results = getFormattedResults(agents, this.entities.agent?.id);
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
    } finally {
      this.estimationAgent.isLoading = false;
    }
  }

  async updateMandateAgentSearch(value: string): Promise<void> {
    this.mandateAgent.isLoading = true;
    this.mandateAgent.search = value;

    try {
      const { agents } = await this.$boApi.agents.search({ agencyId: this.entities.agent?.agencyId, q: value });
      this.mandateAgent.results = getFormattedResults(agents, this.entities.agent?.id);
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
    } finally {
      this.mandateAgent.isLoading = false;
    }
  }

  removeErrorMessage(key: string): void {
    this.$emit('remove-error', 'manageRights', key);
  }

  updateEntity(key: string, value): void {
    this.$emit('update-entity', 'agent', key, value);
  }

  updateHasEstimationApprover(value: boolean): void {
    if (value !== this.entities.agent?.hasEstimationApprover) {
      this.updateEntity('hasEstimationApprover', value);
      this.clearEstimationAgent(value);
    }
  }

  updateHasMandateApprover(value: boolean): void {
    if (value !== this.entities.agent?.hasMandateApprover) {
      this.updateEntity('hasMandateApprover', value);
      this.clearMandateAgent(value);
    }
  }

  validateEntityKey(key: string): void {
    this.$emit('validate-entity-key', 'manageRights', key, 'agent');
  }
}
