import { format, formatDistance, isPast, isSameYear, isToday } from 'date-fns';
import { fr as locale } from 'date-fns/locale';
import i18n from '@/plugins/i18n';

type D = Date | string;

const getParsedDate = (date: D): Date => date instanceof Date ? date : new Date(date);
const isCurrentYear = (date: D): boolean => isSameYear(getParsedDate(date), now());
const getToday = (date: D): string | null => isToday(getParsedDate(date)) ? i18n.t('base.date.today') : null;
const localeTime = `HH'${i18n.t('common.hour_short')}'mm`;
const now = () => new Date();

export const datePatterns = {
  fullDayAndMonth: 'EEEE d MMMM', // Ex: "jeudi 3 septembre"
  fullMonth: 'd MMMM', // Ex: "3 septembre"
};

export const getDate = (date: D, pattern = 'yyyy-MM-dd', isLocale = false): string =>
  format(getParsedDate(date), pattern, isLocale ? { locale } : undefined);

export const getISODate = (date: string, time: string): string => getParsedDate(`${date}T${time}`).toISOString();

export const getLocaleDate = (date: D, pattern: string = datePatterns.fullMonth): string =>
  getDate(date, isCurrentYear(date) ? pattern : pattern.replace('MMMM', 'MMMM yyyy'), true);

export const getLocaleDateAndTime = (date: D, pattern: string = datePatterns.fullMonth): string =>
  getLocaleDate(date, `${pattern}' ${i18n.t('common.at') } '${localeTime}`);

export const getLocaleTime = (date: D): string => getDate(date, localeTime, true);

export const getSavedAtText = (savedAt: D | null | undefined): string =>
  savedAt ? i18n.t('common.saved_less_than', {
    timeAgo: formatDistance(now(), getParsedDate(savedAt), { includeSeconds: true, locale }),
  }) : '';

export const getTime = (date: D): string => getDate(date, 'HH:mm');

export const isBeforeToday = (date: D) => !isToday(getParsedDate(date)) && isPastDate(date);

export const isPastDate = (date: D) => isPast(getParsedDate(date));

export const getTodayOrDate = (date: D): string => getToday(date) || getLocaleDate(date);

export const getTodayOrDateAndTime = (date: D): string => {
  const today = getToday(date);

  return today ? `${today} ${i18n.t('common.at')} ${getLocaleTime(date)}` : getLocaleDateAndTime(date);
};