var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input relative mb-8 flex flex-col",class:{ 'opacity-35': _vm.disabled }},[_c('div',{staticClass:"relative flex flex-col"},[(_vm.label)?_c('FormLabel',_vm._b({staticClass:"input-label",attrs:{"text":_vm.label,"unselectable":_vm.readonly || _vm.disabled}},'FormLabel',{ hint: _vm.hint, id: _vm.id, required: _vm.required },false)):_vm._e(),(_vm.helper)?_c('FormHelper',{attrs:{"text":_vm.helper}}):_vm._e(),_c('div',{staticClass:"relative flex"},[_c('div',{staticClass:"relative w-full"},[_c('input',_vm._b({staticClass:"w-full min-w-0 rounded-md rounded-r-none border border-r-0 border-lk-gray-light bg-white p-2 pl-4 leading-6 outline-none focus:border-r-0",class:{
            'border-lk-red bg-lk-red-light': _vm.isErrorVisible,
            'transition-all duration-300 ease-in-out': !_vm.readonly,
            'focus:border-lk-blue': !_vm.readonly && _vm.focus,
            'cursor-default bg-lk-gray-lighter': _vm.readonly,
            'select-none': _vm.readonly || _vm.disabled,
          },attrs:{"data-test-id":_vm.dataTestId,"type":"number"},domProps:{"value":_vm.fieldValue},on:{"blur":_vm.onBlur,"focus":_vm.onFocus,"input":_vm.onInput,"keypress":_vm.manageKeyPress,"wheel":function (event) { return event.preventDefault; }}},'input',{ disabled: _vm.disabled, id: _vm.id, min: _vm.min, max: _vm.max, readonly: _vm.readonly, required: _vm.required },false))]),_c('label',{staticClass:"flex items-center rounded-md rounded-l-none border border-l-0 border-lk-gray-light bg-white pr-4 leading-4 text-lk-gray outline-none",class:{
          'border-lk-red bg-lk-red-light': _vm.isErrorVisible,
          'transition-all duration-300 ease-in-out': !_vm.readonly,
          'cursor-default border-lk-gray-light bg-lk-gray-lighter': _vm.readonly,
          'border-lk-blue': !_vm.readonly && _vm.focus,
          'border-lk-white': !_vm.readonly && _vm.focus,
          'select-none': _vm.readonly || _vm.disabled,
        },attrs:{"for":_vm.id}},[_vm._v(" % ")])]),_c('div',{staticClass:"absolute top-full mt-1 flex w-full justify-end"},[(_vm.isErrorVisible)?_c('p',{staticClass:"error-message flex-1 py-1 text-xs leading-3 text-lk-red",attrs:{"data-test-id":'error-' + _vm.dataTestId}},[_vm._v(" "+_vm._s(_vm.errorMessage || _vm.error)+" ")]):(_vm.showPreviousValue)?_c('p',{staticClass:"flex-1 whitespace-nowrap py-1 italic leading-3 text-lk-dark-gray"},[_vm._v(" "+_vm._s(_vm.previousValueText)+" ")]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }