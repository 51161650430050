















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BaseAlert extends Vue {
  @Prop({ default: 'warning', type: String }) type!: string;
}
