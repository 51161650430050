import { Route } from 'vue-router';
import { createPersonRoute } from '../person';

/* eslint-disable vue/max-len */
const PreContractIndex = () => import(/* webpackChunkName: "pre-contract" */ '@/views/properties/id/pre-contract/index.vue');
const PreContractDates = () => import(/* webpackChunkName: "pre-contract" */ '@/components/common/organisms/pre-contract/PreContractDatesPanel.vue');
const PreContractNotary = () => import(/* webpackChunkName: "pre-contract" */ '@/components/common/organisms/pre-contract/PreContractNotaryPanel.vue');
/* eslint-enable vue/max-len */

export enum EPreContractRoutes {
  Dates = 'pre_contract_dates',
  Home = 'pre_contract',
  Notary = 'pre_contract_notary',
  Person = 'pre_contract_person',
}

export default [
  {
    children: [
      createPersonRoute(EPreContractRoutes.Home),
      {
        component: PreContractDates,
        name: EPreContractRoutes.Dates,
        path: 'dates',
      },
      {
        component: PreContractNotary,
        name: EPreContractRoutes.Notary,
        path: 'notary/:notaryId',
        props: ({ params }: Route) => ({ notaryId: params.notaryId }),
      },
    ],
    component: PreContractIndex,
    name: EPreContractRoutes.Home,
    path: '/biens/:propertyId/pre-contrat/:offerId',
    props: ({ params }: Route) => ({ offerId: params.offerId, propertyId: params.propertyId }),
  },
];