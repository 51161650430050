import { BaseToggles } from './base-toggles';

export class ReleaseToggles extends BaseToggles {
  get canDownloadAllDocuments(): boolean {
    return this.router.isEnabled('downloadAllDocuments');
  }

  get isRentalMandateRegistryEnabled(): boolean {
    return this.router.isEnabled('rentalMandateRegistry');
  }
}
