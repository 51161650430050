export const debounce= <T extends unknown[]>(
  func: (...args: T) => void,
  delay = 300,
) => {
  let timeout: ReturnType<typeof setTimeout>;

  return (...args: T): void => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};