



































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { EPersonManualSource } from '@/enums/PersonManualSource.enum';
import { HashRoutes } from '@/router/dome';
import { IDomeSettings } from '@/api/dome/agents/Agents.interface';
import { IEntities } from '@/interfaces/entity/Entities.interface';
import { sourceList } from '@/helpers/sources';
import { IOption } from '@/interfaces/Option.interface';

import BaseAddressAutocomplete from '@/components/common/molecules/forms/input/BaseAddressAutocomplete.vue';

// INFO: EPersonManualSource.Other used to be a source to exclude when the hash was not HashRoutes.editLead
const sourcesToExclude = [EPersonManualSource.Showcase, EPersonManualSource.Portal];

@Component({
  components: {
    BaseAddressAutocomplete,
  },
})
export default class SellerLead extends Vue {
  @Prop({ required: true, type: Object }) entities!: IEntities;
  @Prop({ required: true, type: Object }) errorMessages!: unknown;
  @Prop({ default: false, type: Boolean }) isLeadSourceMandatory!: boolean;
  @Prop({ default: false, type: Boolean }) isManualLead!: boolean;

  @State('settings') settings!: IDomeSettings;

  get disableEditManualSources(): boolean {
    return this.$route.hash == HashRoutes.editLead;
  }

  get sourceOptions(): IOption[] {
    return sourceList.reduce<IOption[]>((arr, value) => {
      if (!sourcesToExclude.includes(value)) {
        arr.push({ label: this.$t(`seller_leads.sources.${value}`), value });
      }

      return arr;
    }, []);
  }

  removeErrorMessage(key: string): void {
    this.$emit('remove-error', 'sellerLead', key);
  }

  updateEntity(key: string, value: unknown): void {
    this.$emit('update-entity', 'sellerLead', key, value);
  }

  validateEntityKey(key: string): void {
    this.$emit('validate-entity-key', 'sellerLead', key);
  }

  validateUrl(): void {
    if (this.entities?.sellerLead?.sourceUrl && !this.entities.sellerLead.sourceUrl.match(/https?:\/\//gi)) {
      this.updateEntity('sourceUrl', `https://${this.entities.sellerLead.sourceUrl}`);
    }

    this.validateEntityKey('sourceUrl');
  }
}
