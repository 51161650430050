var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"relative flex h-10 items-center justify-between bg-lk-gray-lighter px-4"},[_vm._l((_vm.steps),function(step,index){return _c('li',{key:("step-" + (step.name)),staticClass:"relative z-1 h-6 w-6 cursor-pointer rounded-full",class:{
      'is-active': _vm.stepIndex === index,
      'is-inactive': _vm.stepIndex !== index && !step.isFilled,
      'pointer-events-none': _vm.disabled
    },on:{"click":function($event){return _vm.$emit('update', index)}}},[(_vm.stepIndex !== index && (step.hasError || step.hasWarning || step.isFilled))?_c('div',{staticClass:"m-1 flex h-4 w-4 items-center justify-center rounded-full bg-white ring-1 ring-current",class:{
        'text-lk-red': step.hasError,
        'text-lk-yellow': step.hasWarning,
        'text-lk-blue': step.isFilled
      }},[_c('BaseIcon',{attrs:{"icon":(step.hasError || step.hasWarning) ? _vm.$icons.warning : _vm.$icons.check,"size":_vm.$sizes.xs}})],1):_vm._e()])}),_c('span',{style:({ width: _vm.width })})],2)}
var staticRenderFns = []

export { render, staticRenderFns }