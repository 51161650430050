var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Wizard',_vm._b({attrs:{"submit-text":_vm.$t('common.validate'),"title":_vm.$t(("bo.entity_" + (_vm.isEditMode ? 'edition' : 'creation') + ".steps.agent.title"))},on:{"close":_vm.closeWizard,"submit":_vm.createAgent,"update-step":_vm.updateStep}},'Wizard',{ isStepLoading: _vm.isStepLoading, isSubmitting: _vm.isSubmitting, isWizardVisible: _vm.isWizardVisible, stepIndex: _vm.stepIndex, steps: _vm.steps },false),[_c(_vm.currentStep.component,_vm._b({tag:"component",attrs:{"agent":_vm.entities.agent},on:{"remove-error":_vm.removeErrorMessage,"select-entity":_vm.selectEntity,"update-agent-setting":_vm.updateAgentSetting,"update-entity":_vm.updateEntity,"update-seller-leads-settings":_vm.updateSellerLeadsSettings,"validate-entity-key":_vm.validateEntityKey}},'component',{
      approverAgents: _vm.approverAgents,
      entities: _vm.entities,
      errorMessages: _vm.errorMessages,
      isAgentAllowed: _vm.isAgentAllowed,
      isEditMode: _vm.isEditMode,
      sellerLeadsSettings: _vm.sellerLeadsSettings,
      sourcesSettingsErrorMessages: _vm.sourcesSettingsErrorMessages,
    },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }