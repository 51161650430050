var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"radio-rate relative mb-8 w-full max-w-xl"},[(_vm.label)?_c('FormLabel',{staticClass:"mb-6 block",attrs:{"text":_vm.label,"unselectable":""}}):_vm._e(),_c('div',{staticClass:"progress"},[_c('span',{staticClass:"progress_bar",style:({
        width: (((_vm.valueIndex !== -1 ? _vm.valueIndex * 100 : 0) /
          (_vm.options.length - 1)) + "%")
      })})]),_c('div',{staticClass:"radio-rate-container relative"},_vm._l((_vm.options),function(option,index){return _c('div',{key:option.value,staticClass:"radio-rate__element absolute top-0",class:option.value === _vm.fieldValue ? 'active' : '',style:({ left: (((index * 100) / (_vm.options.length - 1)) + "%") })},[_c('input',{staticClass:"hidden",attrs:{"id":(_vm.id + "-item-" + index),"type":"radio"},domProps:{"checked":option.value === _vm.fieldValue,"value":option.value},on:{"change":function($event){return _vm.onChange(option.value)}}}),_c('label',{staticClass:"relative -m-2 block cursor-pointer p-2 text-center text-xs",class:_vm.statusClass(index),attrs:{"for":(_vm.id + "-item-" + index)},on:{"click":function($event){return _vm.onClick(option.value, $event)}}},[_c('div',{staticClass:"shape"}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
            (!_vm.showLabels &&
              (index === 0 ||
                index === _vm.options.length - 1 ||
                index === _vm.valueIndex)) ||
              _vm.showLabels
          ),expression:"\n            (!showLabels &&\n              (index === 0 ||\n                index === options.length - 1 ||\n                index === valueIndex)) ||\n              showLabels\n          "}],staticClass:"absolute mt-3 whitespace-nowrap px-3 py-1"},[_vm._v(" "+_vm._s(option.label)+" ")])])])}),0),(_vm.errorMessage)?_c('p',{staticClass:"error-message text-s absolute top-full text-lk-red"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }