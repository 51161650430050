import { LkAxiosInstance } from '@/api';
import { EActivityType } from '@/enums/ActivityType.enum';
import { IActivity, IActivityResponse } from './Activities.interface';

export interface IActivitiesService {
  generateFeed(personId: string): Promise<IActivity[]>;
}

export const activitiesService = ($axios: LkAxiosInstance): IActivitiesService => ({
  async generateFeed(personId) {
    const { activities } = await $axios.$post<IActivityResponse>(`/persons/${personId}/generate_feed`);

    return activities.map(activity => ({
      ...activity,
      actionStatus: null,
      extra: activity.extra || EActivityType.Creation,
      personId,
    }));
  },
});
