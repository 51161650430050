import parsePhoneNumberFromString, { E164Number, PhoneNumber } from 'libphonenumber-js';

const country = 'FR';

export const getLocalePhoneNumber = (phone: string): string | undefined => {
  const phoneNumber = getParsedPhoneNumber(phone);

  return phoneNumber?.isValid() && phoneNumber.country === country
    ? phoneNumber.formatNational()
    : phoneNumber?.formatInternational();
};

export const getParsedPhoneNumber = (phone: string): PhoneNumber | undefined =>
  parsePhoneNumberFromString(phone, country);

export const getPhoneNumber = (phone: string): E164Number | undefined =>
  getParsedPhoneNumber(phone)?.number;
