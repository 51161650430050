const MatchingIndex = () => import(/* webpackChunkName: "matching" */ '@/views/properties/id/matching/index.vue');
const MatchingDetail = () => import(/* webpackChunkName: "matching" */ '@/views/panels/match.vue');
const MatchingPerson = () => import(/* webpackChunkName: "matching" */ '@/views/panels/person.vue');

export default [
  {
    children: [
      {
        component: MatchingDetail,
        name: 'property_matching_panel',
        path: ':matchId',
      },
      {
        component: MatchingPerson,
        name: 'property_matching_panel_person',
        path: ':matchId/personne/:personId',
      },
    ],
    component: MatchingIndex,
    name: 'property_matching',
    path: '/biens/:propertyId/matching',
  },
];