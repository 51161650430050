










































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { Duration } from 'date-fns';

import { calculateDurationFromProps, timeNumberToString, timeDurationToNumber, timeStringToNumber } from '@/utils/time';
import { EventEntityModel } from '../models';
import { IAgent } from '@/api/dome/agents/Agents.interface';
import { IErrorMessages } from '@/interfaces/ErrorMessages.interface';

import DurationInput from '@/components/common/molecules/forms/duration-input/DurationInput.vue';

@Component({
  components: {
    DurationInput,
  },
})

export default class Event extends Vue {
  @Prop({ required: true, type: Object }) entities!: { event: EventEntityModel };
  @Prop({ default: null, type: Object }) errorMessages!: IErrorMessages;

  @State('loggedInAgent') loggedInAgent!: IAgent;

  duration = {} as Duration;

  created(): void {
    this.$emit('update-entity', 'event', 'agentID', this.loggedInAgent.id);
  }

  getDurationDefaultValue(): Duration {
    const { endTime, startTime } = this.entities.event;

    if (endTime !== '') {
      const formattedDuration = calculateDurationFromProps(endTime, startTime);
      this.duration = formattedDuration;

      return formattedDuration;
    }

    const formattedDuration = { hours: 0, minutes: 30 };
    this.duration = formattedDuration;
    this.updateEndTime();

    return formattedDuration;
  }

  removeErrorMessage(key: string): void {
    this.$emit('remove-error', 'event', key);
  }

  updateDuration(value: Duration): void {
    this.duration = value;
    this.updateEndTime();
  }

  updateDurationFromButton(value: Duration): void {
    this.updateDuration(value);
    this.validateEntityKey('endTime');
    this.removeErrorMessage('endTime');
  }

  updateEndTime(): void {
    const duration = timeDurationToNumber(this.duration);
    const startTime = timeStringToNumber(this.entities.event.startTime);
    const endTimeString = timeNumberToString(startTime + duration);
    this.updateEntity('endTime', endTimeString);
  }

  updateEntity(key: string, value: unknown): void {
    this.$emit('update-entity', 'event', key, value);
  }

  updateStartTime(value: string): void {
    this.updateEntity('startTime', value);
    this.updateEndTime();
  }

  validateEntityKey(key: string): void {
    this.$emit('validate-entity-key', 'event', key);
  }
}
