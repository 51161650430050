import { Icons, icons } from '@/helpers/icons';
import { RouteConfig } from 'vue-router';

const BoLogin = () => import(/* webpackChunkName: "BO" */ '@/views/bo/log-in.vue');
const BoAgencies = () => import(/* webpackChunkName: "BO" */ '@/views/bo/wrapper.vue');
const BoAgenciesIndex = () => import(/* webpackChunkName: "BO" */ '@/views/bo/agencies/index.vue');
const BoAgencyIndex = () => import(/* webpackChunkName: "BO" */ '@/views/bo/agencies/id/index.vue');
const BoAgents = () => import(/* webpackChunkName: "BO" */ '@/views/bo/agents/index.vue');

export const routeIcons: Record<string, Icons> = {
  bo_agencies: icons.menu_properties,
  bo_agents: icons.agent,
};

export const bottomBarRoutes = [
  'bo_agencies',
  'create_entity',
  'bo_agents',
];

export const primaryRoutes = [
  'bo_agencies',
  'bo_agents',
];

export enum HashRoutes {
  createEntity = '#create-entity',
  editEntity = '#edit-entity',
  editSettings = '#edit-settings',
  navOpen = '#nav-open',
}

const meta = { isBo: true };

export const routes: RouteConfig[] = [
  {
    component: BoLogin,
    meta: { ...meta, isAuthenticationRequired: false, isLobby: true },
    name: 'bo_log_in',
    path: '/connexion-bo',
  },
  {
    children: [
      {
        component: BoAgenciesIndex,
        meta,
        name: 'bo_agencies',
        path: 'agences',
      },
      {
        component: BoAgencyIndex,
        meta,
        name: 'bo_agency',
        path: 'agences/:agencyId',
        props: true,
      },
      {
        component: BoAgents,
        meta,
        name: 'bo_agents',
        path: 'agents',
      },
    ],
    component: BoAgencies,
    meta,
    name: 'bo_home',
    path: '/bo',
    redirect: { name: 'bo_agencies' },
  },
];
