import { LkAxiosInstance } from '@/api';
import {
  IAdvertisementBaseUrl,
  IAreAgentsAllowedToEditSources,
  IEstimationReportSettings,
  IIsProperyPurchasePlanMandatory,
  IPublicationDescriptionSettings,
  ISettings,
  ISettingsResponse,
  ISmsSettings,
} from './Settings.interface';
import { IDecomposedSettings } from '@/interfaces/bo/models/DecomposedSettings.interface';

const BASE_ENDPOINT = '/settings';

export interface IAgenciesSettingsService {
  getAdvertisementBaseUrl(agencyId: string): Promise<IAdvertisementBaseUrl>;
  getAreAgentsAllowedToEditSources(agencyId: string): Promise<IAreAgentsAllowedToEditSources>;
  getEstimationReportSettings(agencyId: string): Promise<IEstimationReportSettings>;
  getIsPropertyPurchasePlanMandatory(agencyId: string): Promise<IIsProperyPurchasePlanMandatory>;
  getPublicationDescriptionSettings(agencyId: string): Promise<IPublicationDescriptionSettings>;
  getSettings(agencyId: string): Promise<ISettings[]>;
  getSmsSettings(agencyId: string): Promise<ISmsSettings>;
  postSettings(settings: IDecomposedSettings[], agencyId: string): Promise<ISettingsResponse>;
  setAdvertisementBaseUrl(agencyId: string, advertisementBaseUrl: string): Promise<void>;
  setAreAgentsAllowedToEditSources(agencyId: string, areAgentsAllowedToEditSellerSources: boolean): Promise<void>;
  setEstimationReportSettings(agencyId: string, estimationSettings: IEstimationReportSettings): Promise<void>;
  setIsPropertyPurchasePlanMandatory(agencyId: string, isPropertyPurchasePlanMandatory: boolean): Promise<void>;
  setPublicationDescriptionSettings(agencyId: string, settings: IPublicationDescriptionSettings): Promise<void>;
  setSmsSettings(agencyId: string, smsSettings: ISmsSettings): Promise<void>;
}

export const agenciesSettingsService = ($axios: LkAxiosInstance): IAgenciesSettingsService => ({
  getAdvertisementBaseUrl(agencyId) {
    return $axios.$get(`${BASE_ENDPOINT}/external_showcase_base_url?agency_id=${agencyId}`);
  },
  getAreAgentsAllowedToEditSources(agencyId) {
    return $axios.$get(`${BASE_ENDPOINT}/are_agents_allowed_to_edit_seller_sources?agency_id=${agencyId}`);
  },
  getEstimationReportSettings(agencyId) {
    return $axios.$get(`${BASE_ENDPOINT}/estimation_report?agency_id=${agencyId}`);
  },
  getIsPropertyPurchasePlanMandatory(agencyId) {
    return $axios.$get(`${BASE_ENDPOINT}/is_property_purchase_plan_mandatory?agency_id=${agencyId}`);
  },
  getPublicationDescriptionSettings(agencyId) {
    return $axios.$get(`${BASE_ENDPOINT}/publication_description?agency_id=${agencyId}`);
  },
  async getSettings(agencyId) {
    const { settings } = await $axios.$get<ISettingsResponse>(`${BASE_ENDPOINT}?agency_id=${agencyId}`);

    return settings;
  },
  getSmsSettings(agencyId) {
    return $axios.$get(`${BASE_ENDPOINT}/agency_sms_settings?agency_id=${agencyId}`);
  },
  postSettings(settings, agencyId) {
    return $axios.$post(BASE_ENDPOINT, { agencyId, settings });
  },
  setAdvertisementBaseUrl(agencyId, advertisementBaseUrl) {
    return $axios.$post(`${BASE_ENDPOINT}/external_showcase_base_url`, {
      advertisementBaseUrl,
      agencyId,
    });
  },
  setAreAgentsAllowedToEditSources(agencyId, areAgentsAllowedToEditSellerSources) {
    return $axios.$post(`${BASE_ENDPOINT}/are_agents_allowed_to_edit_seller_sources`, {
      agencyId,
      areAgentsAllowedToEditSellerSources,
    });
  },
  setEstimationReportSettings(agencyId, estimationReportSettings) {
    return $axios.$post(`${BASE_ENDPOINT}/estimation_report`, { agencyId, ...estimationReportSettings });
  },
  setIsPropertyPurchasePlanMandatory(agencyId, isPropertyPurchasePlanMandatory) {
    return $axios.$post(`${BASE_ENDPOINT}/is_property_purchase_plan_mandatory`, {
      agencyId,
      isPropertyPurchasePlanMandatory,
    });
  },
  setPublicationDescriptionSettings(agencyId, { isEnabled, model, prompt }) {
    return $axios.$post(`${BASE_ENDPOINT}/publication_description`, { agencyId, isEnabled, model, prompt });
  },
  setSmsSettings(agencyId, smsSettings) {
    return $axios.$post(`${BASE_ENDPOINT}/agency_sms_settings`, { agencyId, ...smsSettings });
  },
});