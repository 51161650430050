import { LkAxiosInstance } from '@/api';
import { EGeoLocationType } from '@/enums/GeoLocationType.enum';
import { IGeoLocation } from '@/interfaces/models/GeoLocation.interface';

interface IGeoLocationSearchResponse {
  geoLocations: IGeoLocation[];
}

export interface IGeoLocationsService {
  search(query: string, limit: number, type?: EGeoLocationType): Promise<IGeoLocation[]>;
}

export const geoLocationsService = ($axios: LkAxiosInstance): IGeoLocationsService => ({
  async search(query: string, limit = 5, type?: EGeoLocationType): Promise<IGeoLocation[]> {
    let queryParams = `limit=${limit}&q=${query}`;

    if (type) {
      queryParams += `&type=${type}`;
    }

    const { geoLocations } = await $axios.$get<IGeoLocationSearchResponse>(`/geo_locations_search?${queryParams}`);

    return geoLocations;
  },
});