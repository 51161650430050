import { ILoginRequest } from '@/interfaces/dto/authService/authRequest.interface';
import { ILoginResponse } from '@/interfaces/dto/authService/authResponse.interface';
import { LkAxiosInstance } from '@/api';

export interface IAuthService {
  login(params: ILoginRequest): Promise<ILoginResponse>;
}

export const authService = ($axios: LkAxiosInstance): IAuthService => ({
  login(params: ILoginRequest): Promise<ILoginResponse> {
    return $axios.$post<ILoginResponse>('/login', params);
  },
});
