
































































































import { Component, Mixins, Prop } from 'vue-property-decorator';

import { getPrimaryContacts } from '@/helpers/contacts';
import { IOption } from '@/interfaces/Option.interface';

import PersonStepHelper from './helpers/person-step-helper.vue';
import PropertyStepHelper from './helpers/property-step-helper.vue';

@Component
export default class DefineTaskStep extends Mixins(PersonStepHelper, PropertyStepHelper) {
  @Prop({ type: Object }) suggestionList?: Record<'agency' | 'person' | 'property', IOption<string>[]>;

  get selectedPersonEmail(): string | null {
    return getPrimaryContacts(this.selectedPerson?.personContacts).email;
  }

  removeErrorMessage(key: string): void {
    this.$emit('remove-error', 'task', key);
  }

  updateEntity(key: string, value): void {
    this.$emit('update-entity', 'task', key, value);
  }

  validateEntityKey(key: string): void {
    this.$emit('validate-entity-key', 'task', key);
  }
}
