





































import { Component, Prop } from 'vue-property-decorator';
import { IOption } from '@/interfaces/Option.interface';
import PropertyStepHelper from './helpers/property-step-helper.vue';
import { Property } from '@/models';

@Component
export default class SelectPropertyWithDesignation extends PropertyStepHelper {
  @Prop({ type: String }) propertyDesignation?: string;
  @Prop({ required: true, type: Object }) suggestionList!: Record<'person' | 'property', IOption<string>[]>;

  propertySearch = '';

  clearValue(): void {
    this.$emit('property-designation-update', null);
    this.clearPropertyValue();
  }

  async clickPropertyResult(value: Property): Promise<void> {
    if (value) {
      await this.getDesignation(value.id);
    }

    this.selectProperty(value);
  }

  private async getDesignation(propertyId: string): Promise<void> {
    try {
      const { propertyDesignation } = await this.$api.negotiations.getNegotiationOfferDefault(propertyId);
      this.updateDesignation(propertyDesignation || '');
    } catch (error) {
      this.$snackbar.error(this.$t('errors.something_happened'));
    }
  }

  async handlePropertySuggestion(item: IOption<string>): Promise<void> {
    await this.getDesignation(item.value);
    this.$emit('select-entity', 'property', item.value);
  }

  updateDesignation(designation: string | null): void {
    this.$emit('property-designation-update', designation || '');
  }
}
