export enum EPropertyProgress {
  ToEstimate = 'to_estimate',
  Estimated = 'estimated',
  WithMandate = 'with_mandate',
  AcceptedOffer = 'accepted_offer',
  Sold = 'sold',
}

export enum EPropertyProgressCamelized {
  ToEstimate = 'toEstimate',
  Estimated = 'estimated',
  WithMandate = 'withMandate',
  AcceptedOffer = 'acceptedOffer',
  Sold = 'sold',
}
