import { customLogger } from '@/utils/errorHandler';
import { EActionStatus } from '@/enums/ActionStatus.enum';
import { EContactRole } from '@/enums/ContactRole.enum';
import { Email } from '@/api/dome/emails/Emails.interface';
import { IManualEmailContact } from '@/interfaces/ManualEmailContact.interface';
import { IOffer } from '@/api/dome/offers/Offers.interface';
import domeApi from '@/api/dome';

const sendError = (error: unknown): void => {
  customLogger(error);
  console.error(error);
};

export const submitEmails = async(
  email: Email | null,
  selectedContacts: IManualEmailContact[],
): Promise<void> => {

  if (!email?.links || !selectedContacts.length) {
    console.error('Links or contacts are missing.');

    return;
  }

  for (const contact of selectedContacts) {
    const roleRel = `send-email-${contact.role}`;
    const link = email.links.find(({ rel }) => rel === roleRel);

    if (!link) {
      sendError(new Error(`Hateoas link missing: ${roleRel}`));

      continue;
    }

    email.actionStatus = EActionStatus.Loading;

    try {
      await domeApi.links.request(link);
    } catch (error) {
      sendError(error);
    }

    const property: keyof Email = contact.role === EContactRole.Buyer ? 'buyerEmailSentAt' : 'sellerEmailSentAt';
    email[property] = new Date().toISOString();
    email.actionStatus = EActionStatus.Done;
  }
};

export const getTypesToRequest = (
  offer: IOffer,
  roles: EContactRole[],
): string[] => {
  // `offer-('new' || 'counter')(-'accepted'?)-('sent' || 'received')-('buyer' || 'seller')`
  const builtTypes = ['offer'];
  builtTypes.push(offer.isInitialOffer ? 'new' : 'counter');

  if (offer.acceptedAt) {
    builtTypes.push('accepted');
  }

  const emailTypes: string[] = [];

  if (offer.isSellerOffer) {
    roles.forEach((role: EContactRole) => {
      if (offer.acceptedAt) {
        emailTypes.push(role === EContactRole.Buyer ? 'sent-buyer' : 'received-seller');
      } else {
        emailTypes.push(role === EContactRole.Seller ? 'sent-seller' : 'received-buyer');
      }
    });
  } else {
    roles.forEach((role: EContactRole) => {
      if (offer.acceptedAt) {
        emailTypes.push(role === EContactRole.Seller ? 'sent-seller' : 'received-buyer');
      } else {
        emailTypes.push(role === EContactRole.Buyer ? 'sent-buyer' : 'received-seller');
      }
    });
  }

  return emailTypes.map(type => `${builtTypes.join('-')}-${type}`);
};
