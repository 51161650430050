











import { Component, Prop, Vue } from 'vue-property-decorator';

import { AppointmentEntityModel } from '../models';
import { IOption } from '@/interfaces/Option.interface';

import BaseAddressAutocomplete from '@/components/common/molecules/forms/input/BaseAddressAutocomplete.vue';

@Component({
  components: {
    BaseAddressAutocomplete,
  },
})
export default class DefinePlaceStep extends Vue {
  @Prop({ required: true, type: Object }) entities!: { appointment: AppointmentEntityModel };
  @Prop({ required: true, type: Object }) suggestionList!: Record<'address' | 'title', IOption<string>[]>;

  updateEntity(key: string, value): void {
    this.$emit('update-entity', 'appointment', key, value);
  }
}
