


















import { Vue, Component } from 'vue-property-decorator';

import { ESellerLeadsTab } from '@/enums/SellerLeadsTab.enum';
import { getLocaleDateAndTime } from '@/utils/date';
import { getLocalePhoneNumber } from '@/utils/phone';
import { ISellerLeadResult } from '@/interfaces/SearchLeadHit.interface';

import { AisHighlight } from 'vue-instantsearch';
import SearchList from '@/components/common/molecules/search-list/SearchList.vue';
import SellerLeadCardSearch from '@/components/common/organisms/seller-lead/SellerLeadCardSearch.vue';

@Component({
  components: {
    AisHighlight,
    SearchList,
    SellerLeadCardSearch,
  },
})
export default class SearchSellerLeadResults extends Vue {
  get ESellerLeadsTab() {
    return ESellerLeadsTab;
  }

  transformSellerItems(items: ISellerLeadResult[]): ISellerLeadResult[] {
    return items.map((lead) => {

      // Format property price
      if (lead.property.price) {
        lead.property.displayedPrice = this.$n(lead.property.price, 'currency');
      }

      // Format property address
      if (lead.property.address) {
        lead.property.displayedAddress = lead._highlightResult.property.address.value
          .split(',')[0]
          .replaceAll('<mark>', '<mark class=\'ais-Highlight-highlighted\'>');
      }

      // Format phone number
      // TODO : Format phone to allow highlight
      const phoneNumber = lead._phones ? lead._phones[2] : lead.phone;

      if (phoneNumber) {
        lead.displayedPhone = getLocalePhoneNumber(phoneNumber);
      }

      // Format date
      if (lead.published_at) {
        lead.displayedDate = getLocaleDateAndTime(lead.published_at);
      }

      return lead;
    });
  }
}
