import { ERoomType } from '@/enums/RoomType.enum';
import { getFormattedRoom } from '..';
import {
  ICreatePropertyCellarPayload,
  IPropertyCellar,
  IUpdatePropertyCellarPayload,
} from './Cellars.interface';
import { LkAxiosInstance } from '@/api';

const BASE_ENDPOINT = '/property_cellars';
const FIELDS_NUMBER = 6;
const ROOM_TYPE = ERoomType.Cellar;

export interface ICellarsService {
  create(payload: ICreatePropertyCellarPayload): Promise<IPropertyCellar>;
  delete(id: string): Promise<void>;
  get(id: string): Promise<IPropertyCellar>;
  update(payload: IUpdatePropertyCellarPayload): Promise<IPropertyCellar>;
}

export const cellarsService = ($axios: LkAxiosInstance): ICellarsService => ({
  async create(payload) {
    const response = await $axios.$post<IPropertyCellar>(BASE_ENDPOINT, payload);

    return getFormattedRoom(response, ROOM_TYPE, FIELDS_NUMBER);
  },
  delete(id) {
    return $axios.$delete(`${BASE_ENDPOINT}/${id}`);
  },
  async get(id) {
    const response = await $axios.$get<IPropertyCellar>(`${BASE_ENDPOINT}/${id}`);

    return getFormattedRoom(response, ROOM_TYPE, FIELDS_NUMBER);
  },
  async update(payload) {
    const response = await $axios.$post<IPropertyCellar>(`${BASE_ENDPOINT}/${payload.id}`, payload);

    return getFormattedRoom(response, ROOM_TYPE, FIELDS_NUMBER);
  },
});
