
















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import i18n from '@/plugins/i18n';

import { getSavedAtText } from '@/utils/date';
import { IStep } from '@/components/common/molecules/stepper/StepperWithStatus.interface';

import Modal from '@/components/common/molecules/modal/Modal.vue';
import StepperTopBar from '@/components/common/molecules/stepper/StepperTopBar.vue';
import StepperWithStatus from '@/components/common/molecules/stepper/StepperWithStatus.vue';

@Component({
  components: {
    Modal,
    StepperTopBar,
    StepperWithStatus,
  },
})
export default class Wizard extends Vue {
  @Prop({ default: i18n.t('common.cancel'), type: String }) closeText?: string;
  @Prop({ default: false, type: Boolean }) isStepLoading!: boolean;
  @Prop({ default: false, type: Boolean }) isSubmitting!: boolean;
  @Prop({ default: false, type: Boolean }) isWizardVisible!: boolean;
  @Prop({ default: i18n.t('common.next_step'), type: String }) nextText?: string;
  @Prop({ default: i18n.t('common.save_and_close'), type: String }) saveAndCloseText?: string;
  @Prop({ default: null, type: Date }) savedAt!: Date | null;
  @Prop({ default: 0, type: Number }) stepIndex!: number;
  @Prop({ required: true, type: Array }) steps!: IStep[];
  @Prop({ default: i18n.t('common.submit'), type: String }) submitText?: string;
  @Prop({ required: true, type: String }) title!: string;

  interval: ReturnType<typeof setInterval> | null = null;
  isStepperVisible = false;
  savedAgo: string = getSavedAtText(this.savedAt);

  get currentStep(): IStep | undefined {
    return this.steps[this.stepIndex];
  }

  get hasMultipleSteps(): boolean {
    return this.steps.length > 1;
  }

  created(): void {
    this.interval = setInterval(() => {
      this.savedAgo = getSavedAtText(this.savedAt);
    }, 2000);
  }

  beforeDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  toggleMenu(): void {
    this.isStepperVisible = !this.isStepperVisible;
  }

  updateStep(step: number): void {
    // TODO : investigate why we lose the content ref sometimes
    (this.$refs.content as HTMLElement)?.scrollTo(0, 0);
    this.$emit('update-step', step);
    this.isStepperVisible = false;
  }
}
