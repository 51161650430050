






























import { Component, Prop, Vue } from 'vue-property-decorator';

import { ENotificationTopic } from '@/enums/NotificationTopic.enum';
import { getLocaleDate, getLocaleTime } from '@/utils/date';
import { Icons, icons } from '@/helpers/icons';
import { INotification } from '@/interfaces/Notification.interface';
import { isSameMonth, isToday, isYesterday } from 'date-fns';

@Component
export default class NotificationItem extends Vue {
  @Prop({ required: true, type: Object }) notification!: INotification;

  get colors(): { background: string, icon: string, iconBackground: string } {
    return this.isWarning
      ? {
        background: this.notification.clicked ? 'hover:bg-lk-red-light-hover' : 'bg-lk-red-light-hover',
        icon: 'text-lk-red',
        iconBackground: 'bg-lk-red-light',
      } : {
        background: this.notification.clicked? 'hover:bg-lk-blue-selection' : 'bg-lk-blue-light-hover',
        icon: 'text-lk-blue',
        iconBackground: 'bg-lk-blue-status',
      };
  }

  get icon(): Icons | null {
    switch (this.notification.topic) {
      case ENotificationTopic.CustomerSpaceDataroomDocumentsUploaded:
      case ENotificationTopic.EstimationValidated:
      case ENotificationTopic.MandateDocumentCanceled:
      case ENotificationTopic.MandateDocumentSignedByEverybody:
      case ENotificationTopic.MandateDocumentSignedByRecipientsButAgent:
      case ENotificationTopic.MandateDocumentValidate:
        return icons.notif_document;
      case ENotificationTopic.AppointmentAgentUpdated:
      case ENotificationTopic.MeetingAgentUpdated:
      case ENotificationTopic.VisitAgentUpdated:
        return icons.notif_calendar;
      case ENotificationTopic.LeadBuyerAgentUpdated:
        return icons.notif_contact;
      case ENotificationTopic.CustomerSpaceCounterOffer:
      case ENotificationTopic.NegotiationOfferAccepted:
      case ENotificationTopic.NegotiationOfferDeclined:
      case ENotificationTopic.NegotiationOfferExpired:
      case ENotificationTopic.NegotiationOfferExpiresIn48h:
      case ENotificationTopic.NegotiationOfferSignedByAll:
        return icons.notif_offer;
      case ENotificationTopic.TaskAssigned:
        return icons.notif_task;
      default:
        return null;
    }
  }

  get isWarning(): boolean {
    return [
      ENotificationTopic.LeadBuyerAgentUpdated,
      ENotificationTopic.MandateDocumentCanceled,
      ENotificationTopic.NegotiationOfferDeclined,
      ENotificationTopic.NegotiationOfferExpired,
      ENotificationTopic.NegotiationOfferExpiresIn48h,
    ].includes(this.notification.topic);
  }

  get notificationDate(): string {
    const date = new Date(this.notification.createdAt);

    if (isToday(date)) {
      return getLocaleTime(date);
    } else if (isYesterday(date)) {
      return this.$t('common.yesterday');
    } else if (isSameMonth(new Date(), date)) {
      return getLocaleDate(date, 'EEE d'); // Ex: "jeu. 3"
    }

    return getLocaleDate(date, 'EEE d MMM'); // Ex: "jeu. 3 janv." ou "jeu. 3 janv. 2023"
  }
}
