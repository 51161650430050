



























































import { Component, Prop, Vue } from 'vue-property-decorator';

import { EPropertyType } from '@/enums/PropertyType.enum';
import { IErrorMessages } from '@/interfaces/ErrorMessages.interface';
import { IProperty } from '@/api/dome/properties/Properties.interface';
import { rentingPropertyTypeOptions } from '@/utils/options';

import BaseAddressAutocomplete from '@/components/common/molecules/forms/input/BaseAddressAutocomplete.vue';

@Component({
  components: {
    BaseAddressAutocomplete,
  },
})
export default class RentingProperty extends Vue {
  @Prop({ required: true, type: Object }) entities!: { property: IProperty };
  @Prop({ default: null, type: Object }) errorMessages!: IErrorMessages;

  EPropertyType = Object.freeze(EPropertyType);
  typeOptions = Object.freeze(rentingPropertyTypeOptions);

  updateEntity(key: string, value: unknown): void {
    this.$emit('update-entity', 'property', key, value);
  }

  validateEntityKey(key: string): void {
    this.$emit('validate-entity-key', 'property', key);
  }

  removeErrorMessage(key: string): void {
    this.$emit('remove-error', 'property', key);
  }

  onAddressUpdate(value: string): void {
    this.removeErrorMessage('address');
    this.updateEntity('address', value);
  }

  updatePropertyType(value: string): void {
    this.updateEntity('type', value);

    if (value === EPropertyType.Apartment) {
      this.updateEntity('carrezSize', this.entities.property.propertySize);
      this.updateEntity('propertySize', null);
    } else {
      this.updateEntity('propertySize', this.entities.property.carrezSize);
      this.updateEntity('carrezSize', null);
    }
  }
}
