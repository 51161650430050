



























































































































import { Component, Prop } from 'vue-property-decorator';
import { IOption } from '@/interfaces/Option.interface';

import FieldComponent from '@/components/common/molecules/forms/FieldComponent.vue';

let selectId = 0;

@Component
export default class BaseSelect extends FieldComponent {
  @Prop({ default: false, type: Boolean }) isTopOrientation!: boolean;
  @Prop({ default: false, type: Boolean }) nullable!: boolean;
  @Prop({ required: true, type: [Array, Object] }) options!: IOption[];

  focus = false;
  id = `select-${selectId++}`;

  get currentOption(): IOption | undefined {
    return this.options.find(option => option.value === this.value);
  }

  get isOpen(): boolean {
    return (!this.focus && !this.isTopOrientation) || (this.focus && this.isTopOrientation);
  }

  get showPreviousValue(): boolean {
    return !!this.previousValue && this.value !== this.previousValue;
  }

  closeSelect(): void {
    if (this.focus) {
      this.$emit('blur');
    }

    this.focus = false;
  }

  onSelect(index: number): void {
    if (index === -1) {
      this.$emit('update');
    } else {
      this.$emit('update', this.options[index].value);
      this.closeSelect();
    }
  }

  toggleSelect(): void {
    if (!this.disabled && !this.readonly) {
      this.focus = !this.focus;
      this.$emit(this.focus ? 'focus' : 'blur');
    }
  }
}
