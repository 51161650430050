import { IEventHelper, IEventResponse, IFormattedEventResponse } from './Events.interface';
import { ILink } from '@/api/dome/links/Links.interface';
import { LkAxiosInstance } from '@/api';

export interface IEventsService {
  getByIdAndType(id: string, type: string): Promise<IFormattedEventResponse>;
  hateoasRequest(link: ILink, event?: IEventHelper): Promise<void>;
}

const formatEvent = (event: IEventResponse) => ({
  ...event,
  fullName: event.person ? `${event.person.firstName} ${event.person.lastName}` : '',
});

export const eventsService = ($axios: LkAxiosInstance): IEventsService => ({
  async getByIdAndType(id, type) {
    const response = await $axios.$get<IEventResponse>(`/${type}/${id}`);

    return formatEvent(response);
  },

  hateoasRequest(link, event) {
    return $axios.$request({ ...link, data: event, url: link.href });
  },
});
