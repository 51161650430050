export enum ELocalStorageKeys {
  BoAuthToken = 'boAuthToken',
  AuthToken = 'authToken',
  GlobalSearchRecents = 'globalSearchRecents',
  NotificationsToken = 'notificationsToken',
}

export enum ESessionStorageKeys {
  IssueReport = 'issueReport',
  Refreshing = 'refreshing',
}