export const formatEnumAttribute = (
  translatedValue: string,
  matchedWords,
): string => {
  if (matchedWords && matchedWords.length > 0) {
    const matchingString = matchedWords[0];

    const translatedNormalize = translatedValue
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    const matchNormalize = matchingString
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    const index = translatedNormalize.indexOf(matchNormalize);

    if (index > -1) {
      const matchString = translatedValue.slice(index, matchNormalize.length);

      return translatedValue.replace(
        matchString,
        `<mark class="ais-Highlight-highlighted">${matchString}</mark>`,
      );
    }
  }

  return translatedValue;
};

/** @deprecated https://liberkeys.slite.com/app/channels/jvDflALzE9/notes/ozX~7EKT2e#2456aa99 */
export const formatNumberAttribute = (
  formatted: string,
  highlighted,
): string => {
  if (
    highlighted.matchLevel === 'partial' ||
    highlighted.matchLevel === 'full'
  ) {
    const match = highlighted.value.match(/^(?!.*\.)<mark>(.*?)<\/mark>/g);

    if (match) {
      const matchingNumber = match[0].replace(/<\/?mark>/g, '');
      const formattedArray = formatted.split('');

      const formattedNumberArray = formattedArray
        .map((value, index) => value.match(/^\d+$/) ? index : -1)
        .filter(value => value !== -1);

      if (formattedNumberArray.length > 0) {
        const lastNumberPosition =
          formattedNumberArray[formattedNumberArray.length - 1];

        const matchingEndIndex =
          matchingNumber.length < formattedNumberArray.length
            ? formattedNumberArray[matchingNumber.length]
            : lastNumberPosition + 1;

        return `<mark class='ais-Highlight-highlighted'>${formattedArray
          .slice(0, matchingEndIndex)
          .join('')}</mark>${formattedArray.slice(matchingEndIndex).join('')}`;
      }
    }
  }

  return formatted;
};
