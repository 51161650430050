import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import '@/assets/scss/index.scss';

// Libraries
import { register } from 'swiper/element/bundle';
import infiniteScroll from 'vue-infinite-scroll';
import PortalVue from 'portal-vue';
import Snotify, { SnotifyPosition } from 'vue-snotify';
import vClickOutside from 'v-click-outside';
import VueClipboard from 'vue-clipboard2';
import VueLazyload from 'vue-lazyload';
import VueScrollactive from 'vue-scrollactive';
import VueShowdown from 'vue-showdown';

// Configs

const snotifyConfig = {
  toast: {
    position: SnotifyPosition.rightTop,
    timeout: 3000,
  },
};

// Plugins
import '@/plugins/apis';
import '@/plugins/feature-toggle';
import '@/plugins/firebase';
import '@/plugins/prototypes';
import '@/plugins/sentry';
import '@/utils/platformSpecificConfig';
import '@/utils/registerBaseComponents';
import i18n from '@/plugins/i18n';
import Mixpanel from '@/plugins/mixpanel';
import SnackbarService from '@/plugins/snackbar.service';

Vue.use(infiniteScroll);
Vue.use(Mixpanel);
Vue.use(PortalVue);
Vue.use(Snotify, snotifyConfig);
Vue.use(vClickOutside);
Vue.use(VueClipboard);
Vue.use(VueLazyload);
Vue.use(VueScrollactive);
Vue.use(VueShowdown);
Vue.use(SnackbarService);
register();

Vue.config.productionTip = false;
Vue.config.devtools = !Vue.prototype.$env.isProduction;
Vue.config.performance = !Vue.prototype.$env.isProduction;

const app = new Vue({
  i18n,
  render: h => h(App),
  router,
  store,
}).$mount('#app');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).dome = app;

export default app;
