import { ERoomType } from '@/enums/RoomType.enum';
import { getFormattedRoom } from '..';
import {
  ICreatePropertyOtherRoomPayload,
  IPropertyOtherRoom,
  IUpdatePropertyOtherRoomPayload,
} from './OtherRooms.interface';
import { LkAxiosInstance } from '@/api';

const BASE_ENDPOINT = '/property_other_rooms';
const FIELDS_NUMBER = 8;
const ROOM_TYPE = ERoomType.OtherRoom;

export interface IOtherRoomsService {
  create(payload: ICreatePropertyOtherRoomPayload): Promise<IPropertyOtherRoom>;
  delete(id: string): Promise<void>;
  get(id: string): Promise<IPropertyOtherRoom>;
  update(payload: IUpdatePropertyOtherRoomPayload): Promise<IPropertyOtherRoom>;
}

export const otherRoomsService = ($axios: LkAxiosInstance): IOtherRoomsService => ({
  async create(payload) {
    const response = await $axios.$post<IPropertyOtherRoom>(BASE_ENDPOINT, payload);

    return getFormattedRoom(response, ROOM_TYPE, FIELDS_NUMBER);
  },
  delete(id) {
    return $axios.$delete(`${BASE_ENDPOINT}/${id}`);
  },
  async get(id) {
    const response = await $axios.$get<IPropertyOtherRoom>(`${BASE_ENDPOINT}/${id}`);

    return getFormattedRoom(response, ROOM_TYPE, FIELDS_NUMBER);
  },
  async update(payload) {
    const response = await $axios.$post<IPropertyOtherRoom>(`${BASE_ENDPOINT}/${payload.id}`, payload);

    return getFormattedRoom(response, ROOM_TYPE, FIELDS_NUMBER);
  },
});
