































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';

import { EBuyerSteps } from '@/views/entity/steps.enum';
import { EContactRole } from '@/enums/ContactRole.enum';
import { EWizardActionType } from '@/enums/WizardActionType.enum';
import { getOtherPersonContacts } from '@/helpers/contacts';
import { IEntities } from '@/interfaces/entity/Entities.interface';
import { IOption } from '@/interfaces/Option.interface';
import { IPerson } from '@/api/dome/persons/Persons.interface';
import { IPropertyPurchasePlan } from '@/api/dome/property-purchase-plans/PropertyPurchasePlans.interface';
import { ISelectedEntities } from '@/interfaces/entity/SelectedEntities.interface';
import { IStep } from '@/components/common/molecules/stepper/StepperWithStatus.interface';
import { PersonEntityModel, PropertyPurchasePlanEntityModel } from './models';

import Person from './steps/Person.vue';
import PersonContactComponent from './steps/PersonContact.vue';
import PersonHelpers from './helpers/person.vue';
import PropertyPurchasePlan from './steps/PropertyPurchasePlan.vue';
import PropertyPurchasePlanHelpers from './helpers/propertyPurchasePlan.vue';
import Wizard from '@/components/common/templates/wizard/Wizard.vue';

@Component({
  components: {
    Person,
    PersonContactComponent,
    PropertyPurchasePlan,
    Wizard,
  },
})
export default class BuyerEntity extends Mixins(PersonHelpers, PropertyPurchasePlanHelpers) {
  @Prop({ default: '', type: String }) disabledStep!: string;
  @Prop({ type: Object }) entity?: IPerson;
  @Prop({ default: null, type: Object }) propertyPurchasePlan!: IPropertyPurchasePlan | null;
  @Prop({ default: false, type: Boolean }) isModifyEntity!: boolean;
  @Prop({ default: false, type: Boolean }) noRedirection!: boolean;
  @Prop({ default: null, type: Object }) prefillPerson!: PersonEntityModel | null;
  @Prop({ type: Object }) suggestionList?: Record<'agency' | 'person' | 'property', IOption<string>[]>;

  EBuyerSteps = Object.freeze(EBuyerSteps);
  EWizardActionType = Object.freeze(EWizardActionType);

  @Watch('selectedEntities.person')
  async onPersonChange(personId?: string | null): Promise<void> {
    this.entities.propertyPurchasePlan = await this.getPropertyPurchasePlanEntity(personId);
  }

  get hasContactBothRoles(): boolean {
    return !!this.person?.tags.includes(EContactRole.Seller) && this.person.tags.includes(EContactRole.Buyer);
  }

  get isModifySeller(): boolean {
    return this.isModifyEntity && this.hasContactBothRoles;
  }

  get stepList(): EBuyerSteps[] {
    return [EBuyerSteps.Person, EBuyerSteps.PropertyPurchasePlan].filter(step => step !== this.disabledStep);
  }

  get steps(): IStep[] {
    return this.stepList.map(step => {
      let stepName: EBuyerSteps | string = step;

      if (step === EBuyerSteps.Person) {
        stepName = this.isModifyEntity && this.hasContactBothRoles ? 'contact' : 'buyer';
      }

      return {
        hasError: false,
        isFilled: false,
        isOptional: step === EBuyerSteps.PropertyPurchasePlan && !this.settings.isPropertyPurchasePlanMandatory,
        name: this.$t(`${this.isModifyEntity ? 'entity_edition' : 'entity_creation'}.steps.${stepName}.title`),
        value: step,
      };
    });
  }

  get title(): string {
    if (!this.isModifyEntity) {
      return this.$t('entity_creation.buyer.title');
    }

    const step = this.stepList[this.stepIndex];
    const contactKey = this.hasContactBothRoles ? 'contact' : 'buyer';

    return this.$t(`entity_edition.steps.${contactKey}.${step}`);
  }

  created(): void {
    this.setData();
  }

  async createEntity(): Promise<void> {
    this.isSubmitting = true;
    let personId!: string | undefined;

    for (const [index, step] of this.stepList.entries()) {
      if (!(await this.validateBuyerSteps(step))) {
        this._handleError('invalid data', index);

        return;
      }
    }

    if (this.isModifyEntity) {
      const currentStep = this.stepList[this.stepIndex];

      if (currentStep === EBuyerSteps.Person) {
        await this.savePerson();
      } else {
        await this.savePerson();
        await this.savePropertyPurchasePlan();
      }

      if (
        !this.noRedirection
        && currentStep === EBuyerSteps.PropertyPurchasePlan
        && this.$route.name?.indexOf('contact') === -1
      ) {
        await this.$router.push({ name: 'contacts' });
      }

      this.$emit('update-person', this.entity!.id);
      this.handleCloseWizard();

      return;
    }

    // Person
    try {
      let params: [ISelectedEntities, IEntities] = [this.selectedEntities, this.entities];

      if (this.selectedEntities.person) {
        const person = await this.$api.persons.getById(this.selectedEntities.person);

        if (!person.tags.includes(EContactRole.Buyer)) {
          person.tags.push(EContactRole.Buyer);
          params = [{}, { person: this.getFormattedPerson(person) }];
        }
      }

      personId = await this.createPerson(...params);

      if (!personId) {
        this._handleError('empty', this.stepList.indexOf(EBuyerSteps.Person));

        return;
      }
    } catch (error) {
      this._handleError(error, this.stepList.indexOf(EBuyerSteps.Person), true);

      return;
    }

    // PropertyPurchasePlan
    try {
      if (this.form.get('propertyType').value) {
        await this.updatePropertyPurchasePlan(this.form.value, personId, this.entities.propertyPurchasePlan);
      }
    } catch (error) {
      this._handleError(error, this.stepList.indexOf(EBuyerSteps.PropertyPurchasePlan), true);

      return;
    }

    // TODO implement / should replace callbackIfValid
    if (!this.noRedirection) {
      await this.handleCloseWizard();

      await this.$router.push({
        hash: undefined,
        name: 'contacts_person',
        params: { personId },
      });
    } else {
      this.$emit('person-created', personId);
      this.handleCloseWizard();
    }
  }

  async handleCloseWizard(): Promise<void> {
    this.form.resetErrors();
    this.resetIsEmailUnique();
    await this.closeWizard();
  }

  async savePerson(): Promise<void> {
    if (!this.entities.person) {
      this._handleError('empty');

      return;
    }

    try {
      await this.updatePerson(this.entities.person);
    } catch (error) {
      this._handleError(error, 0, true);
    }
  }

  async savePropertyPurchasePlan(): Promise<void> {
    try {
      await this.updatePropertyPurchasePlan(this.form.value, this.entity!.id, this.entities.propertyPurchasePlan);
    } catch (error) {
      this._handleError(error, this.stepIndex, true);
    }
  }

  setData(): void {
    if (this.isModifyEntity) {
      this.selectedEntities = {};

      if (!this.entity) {
        return;
      }

      this.entities = {
        person: this.getFormattedPerson(this.entity),
        propertyPurchasePlan: new PropertyPurchasePlanEntityModel(this.propertyPurchasePlan),
      };

      this.currentPerson = { ...this.entities.person } as PersonEntityModel;
      this.otherContacts = getOtherPersonContacts(this.entity.personContacts);
    } else {
      this.entities = {
        person: this.prefillPerson || new PersonEntityModel(EContactRole.Buyer),
        propertyPurchasePlan: new PropertyPurchasePlanEntityModel(),
      };

      this.selectedEntities = { person: '' };
    }
  }

  async updateStep(step: number): Promise<void> {
    const currentStepIndex = this.stepIndex;
    this.changeStep(step);
    this.steps[currentStepIndex].hasError = !(await this.validateBuyerSteps(this.stepList[currentStepIndex]));
  }

  async validateBuyerSteps(step: EBuyerSteps | string): Promise<boolean> {
    if (step === EBuyerSteps.Person) {
      await this.validatePersonEmail();

      return this.isStepValid(EBuyerSteps.Person);
    } else if (step === EBuyerSteps.PropertyPurchasePlan) {
      return this.validateForm();
    }

    return false;
  }
}
