var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input relative mb-8 flex flex-col",class:{ 'opacity-35': _vm.disabled }},[(_vm.label)?_c('FormLabel',_vm._b({attrs:{"text":_vm.label,"unselectable":_vm.readonly || _vm.disabled}},'FormLabel',{ id: _vm.id, required: _vm.required },false)):_vm._e(),_c('div',{staticClass:"relative flex"},[_c('input',_vm._b({ref:"input",staticClass:"w-full min-w-0 rounded-md border border-lk-gray-light p-2 pl-4 leading-6 outline-none",class:[{
        error: _vm.error || _vm.errorMessage || _vm.hasError,
        'bg-white transition-all duration-300 ease-in-out': !_vm.readonly,
        'cursor-default bg-lk-gray-lighter': _vm.readonly,
        'select-none': _vm.readonly || _vm.disabled,
      } ].concat( _vm.inputClass ),attrs:{"data-test-id":_vm.dataTestId,"type":"text"},domProps:{"value":_vm.fieldValue},on:{"blur":_vm.manageBlur,"focus":_vm.manageFocus,"input":_vm.manageInput,"keypress":_vm.manageKeyPress}},'input',{ autocomplete: _vm.autocomplete, disabled: _vm.disabled, id: _vm.id, placeholder: _vm.placeholder, readonly: _vm.readonly, required: _vm.required },false)),_c('label',{staticClass:"absolute right-0 my-2 mr-4 leading-7 text-lk-gray",class:[{
        'text-lk-red': _vm.error || _vm.errorMessage || _vm.hasError,
        'transition-all duration-300 ease-in-out': !_vm.readonly,
      }],attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.displayedUnit)+" ")])]),_c('div',{staticClass:"absolute top-full mt-1 flex w-full justify-end"},[(_vm.error || _vm.errorMessage)?_c('p',{staticClass:"error-message flex-1 py-1 text-xs leading-3 text-lk-red",attrs:{"data-test-id":'error-' + _vm.dataTestId}},[_vm._v(" "+_vm._s(_vm.errorMessage || _vm.error)+" ")]):(_vm.showPreviousValue)?_c('p',{staticClass:"flex-1 whitespace-nowrap py-1 italic leading-3 text-lk-dark-gray"},[_vm._v(" "+_vm._s(_vm.previousValueText)+" ")]):_vm._e(),(_vm.tips)?_c('BaseTips',{staticClass:"py-1 text-xs",attrs:{"is-centered":_vm.tipsCentered,"tips":_vm.tips}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }