import App from '@/main';
import services from '@/api/dome';

import { firebaseApp } from '@/plugins/firebase';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';

import { ELocalStorageKeys } from '@/enums/Storage.enum';
import { getCamelizedJSON } from './objectHelpers';
import { redirectTo } from './notifications';

const { VUE_APP_FIREBASE_PUBLIC_KEY: vapidKey } = process.env;

const getFirebaseMessaging = () => isSupported().then((isFirebaseSupported) => {
  const errorMessage = vapidKey ? 'Firebase is not supported' : 'No vapidKey configured.';

  if (vapidKey && isFirebaseSupported) {
    return getMessaging(firebaseApp);
  }

  console.error(errorMessage);

  return null;
}).catch(error => {
  console.error('Error during Firebase initialization.', error);

  return null;
});

export const initPushNotifications = (async() => {
  const messaging = await getFirebaseMessaging();

  if (messaging) {
    Notification.requestPermission()
      .then(async() => {
        try {
          const token = await getToken(messaging, { vapidKey });
          localStorage.setItem(ELocalStorageKeys.NotificationsToken, token);
          services.notifications.subscribe(token);
        } catch (error) {
          console.error('No Instance ID token available. Request permission to generate one.', error);
        }
      })
      .catch((error) => {
        console.error('Unable to get permission to notify.', error);
      });
  }
});

export const setupPushNotificationReceived = (async() => {
  const messaging = await getFirebaseMessaging();

  if (messaging) {
    onMessage(messaging, ({ data, notification }) => {
      if (notification) {
        App.$snackbar
          .simple(notification.body || '', notification.title || '')
          .on('click', () => {
            if (data?.topic && data.extra) {
              const extra = getCamelizedJSON<Record<string, string>>(data.extra);

              if (extra) {
                redirectTo({ extra, topic: data.topic });
              }
            } else {
              App.$store.dispatch('toggleNotificationsMenu', true);
            }
          });
      }
    });
  }
});
