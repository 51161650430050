import _Vue from 'vue';
import mixpanel from 'mixpanel-browser';

export class MixpanelService {
  isAvailable!: boolean;
  track!: (name: string, props: { [key: string]: string | number | boolean }) => void;
  identify!: (props: { firstName: string, id: string, lastName: string }) => void;
}

export default {
  install: (Vue: typeof _Vue) => {
    const token: string | undefined = process.env.VUE_APP_MT;

    if (token) {
      mixpanel.init(process.env.VUE_APP_MT);
    }

    Vue.prototype.$mixpanel = {
      identify: (props: { firstName: string, id: string, lastName: string }): void => {
        if (token && props.id) {
          mixpanel.identify(props.id);

          mixpanel.people.set({
            name: `${props.firstName} ${props.lastName}`,
          });
        }
      },
      isAvailable: Boolean(token),
      track: (name: string, ...props: []): void => {
        if (token) {
          mixpanel.track(name, ...props);
        }
      },
    };
  },
};
