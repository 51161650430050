import { EBuyerQualification, EPropertySearchProgress } from '@/enums/PropertyPurchasePlan.enum';
import { EBuyingPurpose } from '@/enums/BuyingPurpose.enum';
import { EContactRole } from '@/enums/ContactRole.enum';
import { ELoanStatus } from '@/enums/LoanStatus.enum';
import { EPersonManualSource } from '@/enums/PersonManualSource.enum';
import { EPropertyAge } from '@/enums/PropertyAge.enum';
import { EPropertyPurchasePlanSource } from '@/enums/PropertyPurchasePlanSource.enum';
import { EPropertyTransactionMode } from '@/enums/PropertyTransactionMode.enum';
import { EPropertyType } from '@/enums/PropertyType.enum';
import { ESellerLeadType } from '@/enums/SellerLeadType.enum';
import { ICreatePropertyPurchasePlanPayload, IPropertyPurchasePlan }
  from '@/api/dome/property-purchase-plans/PropertyPurchasePlans.interface';
import { IGeoCity } from '@/interfaces/models/GeoCity.interface';
import { INegotiationRecipient } from '@/api/dome/negotiationRecipients/NegotiationRecipients.interface';
import { IRecipientsBuyers } from '@/interfaces/recipients/Buyers.interface';
import { IRecipientsSellers } from '@/interfaces/recipients/Sellers.interface';
import { ISellerLeadForPanel } from '@/api/dome/seller-leads/SellerLeads.interface';
import { PersonContactModel } from '@/api/dome/person-contacts/PersonContacts.interface';

export class PersonEntityModel {
  email: string;
  firstName: string;
  id?: string;
  lastName: string;
  manualSource?: EPersonManualSource | string | null;
  personContacts?: PersonContactModel[];
  phone: string;
  propertyPurchasePlans?: IPropertyPurchasePlan[];
  tags: EContactRole[];

  constructor(type: EContactRole) {
    this.email = '';
    this.firstName = '';
    this.lastName = '';
    this.manualSource = null;
    this.phone = '';
    this.tags = [type];
  }
}

export class PropertyPurchasePlanEntityModel implements Omit<
  ICreatePropertyPurchasePlanPayload, 'geoCitiesIds' | 'personId'
> {
  buyerQualification: EBuyerQualification | null;
  buyingPurpose: EBuyingPurpose | null;
  cancelledAt?: string | null;
  comment: string | null;
  fundingStatus: ELoanStatus | null;
  geoCities: IGeoCity[];
  hasElevator: boolean | null;
  hasExterior: boolean | null;
  hasParking: boolean | null;
  hasSaleProject: boolean;
  id?: string;
  isDisabled?: boolean;
  isGroundFloor: boolean | null;
  isOpenToRenovations: boolean | null;
  isSingleBuyer: boolean | null;
  maximumBuyingPrice: number;
  minimumBedroomsCount: number;
  minimumRoomsCount: number;
  minimumSurface: number;
  personalContribution: number;
  propertyAge: EPropertyAge | null;
  propertySearchProgress: EPropertySearchProgress | null;
  propertyType: EPropertyType | null;
  source: EPropertyPurchasePlanSource;

  constructor(propertyPurchasePlan?: IPropertyPurchasePlan | null) {
    this.buyerQualification = propertyPurchasePlan?.buyerQualification ?? null;
    this.buyingPurpose = propertyPurchasePlan?.buyingPurpose ?? null;
    this.cancelledAt = propertyPurchasePlan?.cancelledAt;
    this.comment = propertyPurchasePlan?.comment ?? null;
    this.fundingStatus = propertyPurchasePlan?.fundingStatus ?? null;
    this.geoCities = propertyPurchasePlan?.geoCities ?? [];
    this.hasElevator = propertyPurchasePlan?.hasElevator ?? null;
    this.hasExterior = propertyPurchasePlan?.hasExterior ?? null;
    this.hasParking = propertyPurchasePlan?.hasParking ?? null;
    this.hasSaleProject = propertyPurchasePlan?.hasSaleProject ?? false;
    this.id = propertyPurchasePlan?.id;
    this.isDisabled = !!propertyPurchasePlan?.cancelledAt ?? false;
    this.isGroundFloor = propertyPurchasePlan?.isGroundFloor ?? null;
    this.isOpenToRenovations = propertyPurchasePlan?.isOpenToRenovations ?? null;
    this.isSingleBuyer = propertyPurchasePlan?.isSingleBuyer ?? null;
    this.maximumBuyingPrice = propertyPurchasePlan?.maximumBuyingPrice ?? 0;
    this.minimumBedroomsCount = propertyPurchasePlan?.minimumBedroomsCount ?? 0;
    this.minimumRoomsCount = propertyPurchasePlan?.minimumRoomsCount ?? 0;
    this.minimumSurface = propertyPurchasePlan?.minimumSurface ?? 0;
    this.personalContribution = propertyPurchasePlan?.personalContribution ?? 0;
    this.propertyAge = propertyPurchasePlan?.propertyAge ?? null;
    this.propertySearchProgress = propertyPurchasePlan?.propertySearchProgress ?? null;
    this.propertyType = propertyPurchasePlan?.propertyType ?? null;
    this.source = propertyPurchasePlan?.source ?? EPropertyPurchasePlanSource.Agent;
  }
}

export class PropertyEntityModel {
  accessNote: string | null;
  address: string;
  carrezSize: number | null;
  digitalCode: string | null;
  floorNumber: number | null;
  intercom: string | null;
  propertySize: number | null;
  transactionMode: EPropertyTransactionMode;
  type: EPropertyType;

  constructor(
    transactionMode = EPropertyTransactionMode.Selling,
    address = '',
    surface: number | null = null,
  ) {
    this.accessNote = null;
    this.address = address;
    this.carrezSize = surface;
    this.digitalCode = null;
    this.floorNumber = null;
    this.intercom = null;
    this.propertySize = surface;
    this.transactionMode = transactionMode;
    this.type = EPropertyType.Apartment;
  }
}

export class EventEntityModel {
  agentID: string;
  date: string;
  endTime: string;
  startTime: string;

  constructor() {
    this.agentID = '';
    this.date = '';
    this.endTime = '';
    this.startTime = '';
  }
}

export class AppointmentEntityModel {
  address?: string;
  description?: string;
  title: string;

  constructor() {
    this.address = '';
    this.description = '';
    this.title = '';
  }
}

export class NegotiationDesignationModel {
  property: {
    address: string;
    designation: string | null;
  };

  constructor(property = { address: '', designation: null as string | null }) {
    this.property = property;
  }
}

export class NegotiationParticipantsModel {
  recipientsBuyers: IRecipientsBuyers;
  recipientsSellers: IRecipientsSellers;

  constructor(recipientsBuyers = {
    buyingCompanyName: '',
    isCompanyBuying: false,
    recipients: [] as INegotiationRecipient[],
  }, recipientsSellers = {
    companyOwningPropertyName: '',
    isPropertyOwnedByCompany: false,
    recipients: [] as INegotiationRecipient[],
  }) {
    this.recipientsBuyers = recipientsBuyers;
    this.recipientsSellers = recipientsSellers;
  }
}

export class SellerLeadEntityModel {
  address?: string | null;
  comment?: string | null;
  id?: string;
  leadType: ESellerLeadType;
  price?: number | null;
  roomsCount?: number | null;
  sellerEmail?: string | null;
  sellerFirstName?: string | null;
  sellerLastName?: string;
  sellerPhone?: string | null;
  source?: string | null;
  sourceUrl?: string | null;
  surface?: number | null;

  constructor(sellerLead: ISellerLeadForPanel | undefined) {
    this.address = sellerLead?.address ?? '';
    this.comment = sellerLead?.comment ?? null;
    this.id = sellerLead?.id;
    this.leadType = sellerLead?.leadType ?? ESellerLeadType.Manual;
    this.price = sellerLead?.price ?? null;
    this.roomsCount = sellerLead?.roomsCount ?? null;
    this.sellerEmail = sellerLead?.seller.email ?? null;
    this.sellerFirstName = sellerLead?.seller.firstName ?? null;
    this.sellerLastName = sellerLead?.seller.lastName ?? '';
    this.sellerPhone = sellerLead?.seller.phone ?? null;
    this.source = sellerLead?.source ?? null;
    this.sourceUrl = sellerLead?.adUrl ?? null;
    this.surface = sellerLead?.surface ?? null;
  }
}

export class TaskEntityModel {
  date: string;
  notes?: string;
  time?: string;
  title: string;

  constructor() {
    this.date = '';
    this.notes = undefined;
    this.time = undefined;
    this.title = '';
  }
}
