












































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { EContactRole } from '@/enums/ContactRole.enum';
import { EEventType } from '@/enums/EventType.enum';
import { EHttpErrorName } from '@/enums/HttpErrorName.enum';
import { EventEntityModel, PersonEntityModel, PropertyPurchasePlanEntityModel, PropertyEntityModel } from './models';
import { EVisitSteps } from './steps.enum';
import { extractContactInformation } from '@/helpers/contacts';
import { ICreateVisitResponse } from '@/api/dome/visits/Visits.interface';
import { IDomeSettings } from '@/api/dome/agents/Agents.interface';
import { IManualEmailContact } from '@/interfaces/ManualEmailContact.interface';
import { IOption } from '@/interfaces/Option.interface';
import { isPastDate } from '@/utils/date';
import { IStep } from '@/components/common/molecules/stepper/StepperWithStatus.interface';
import { Property } from '@/models';

import DialogsHelpers from '@/views/entity/helpers/dialogs.vue';
import Event from './steps/Event.vue';
import EventHelpers from './helpers/event.vue';
import ManualEmailDialog from '@/components/common/organisms/dialog/ManualEmailDialog.vue';
import Person from './steps/Person.vue';
import PersonHelpers from './helpers/person.vue';
import PropertyPurchasePlan from './steps/PropertyPurchasePlan.vue';
import PropertyPurchasePlanHelpers from './helpers/propertyPurchasePlan.vue';
import SelectProperty from './steps/SelectProperty.vue';
import Wizard from '@/components/common/templates/wizard/Wizard.vue';

@Component({
  components: {
    Event,
    ManualEmailDialog,
    Person,
    PropertyPurchasePlan,
    SelectProperty,
    Wizard,
  },
})
export default class VisitEntity extends Mixins(
  PersonHelpers,
  EventHelpers,
  DialogsHelpers,
  PropertyPurchasePlanHelpers,
) {
  @Prop({ type: String }) existingPersonId?: string;
  @Prop({ type: Boolean }) noRedirection?: boolean;
  @Prop({ type: Object }) prefillPerson?: PersonEntityModel;
  @Prop({ type: String }) propertyId?: string;
  @Prop({ type: Object }) suggestionList?: Record<'agency' | 'person' | 'property', IOption<string>[]>;

  @State('settings') settings!: IDomeSettings;

  buyersSuggestionList: Record<'person' | 'property', IOption<string>[]> = { person: [], property: [] };
  manualEmailDisclaimers: string[] = [];
  stepList = Object.freeze([
    EVisitSteps.Person,
    EVisitSteps.PropertyPurchasePlan,
    EVisitSteps.SelectProperty,
    EVisitSteps.Event,
  ]);
  visitId: string | null = null;

  @Watch('selectedEntities.person')
  async onPersonChange(personId?: string | null): Promise<void> {
    if (personId) {
      this.entities.propertyPurchasePlan = await this.getPropertyPurchasePlanEntity(personId);
      this.form.setValue(this.entities.propertyPurchasePlan);
    } else {
      this.form.reset();
    }
  }

  get steps(): IStep[] {
    return this.stepList.map(step => {
      let name: EVisitSteps | string = step;

      if (step === EVisitSteps.Person) {
        name = 'buyer';
      } else if (step === EVisitSteps.Event) {
        name = 'visit';
      }

      return {
        hasError: false,
        isFilled: false,
        isOptional: name === EVisitSteps.PropertyPurchasePlan && !this.settings.isPropertyPurchasePlanMandatory,
        name: this.$t(`entity_creation.steps.${name}.title`),
        value: step,
      };
    });
  }

  created(): void {
    this.setData();
  }

  async createEntity(): Promise<void> {
    this.isSubmitting = true;
    let personId!: string | undefined;
    let propertyId!: string | undefined;

    if (!this.isEntityValid(this.stepList)) {
      this._handleError('invalid data', this.errorStepIndex);

      return;
    } else if (!this.validateForm()) {
      this._handleError('invalid data', this.stepList.indexOf(EVisitSteps.PropertyPurchasePlan));

      return;
    }

    // Person
    try {
      personId = await this.createPerson(this.selectedEntities, this.entities);

      if (!personId) {
        this._handleError('empty', this.stepList.indexOf(EVisitSteps.Person));

        return;
      }
    } catch (error) {
      this._handleError(error, this.stepList.indexOf(EVisitSteps.Person), true);

      return;
    }

    // PropertyPurchasePlan
    try {
      if (this.form.get('propertyType').value) {
        await this.updatePropertyPurchasePlan(this.form.value, personId, this.entities.propertyPurchasePlan);
      }
    } catch (error) {
      this._handleError(error, this.stepList.indexOf(EVisitSteps.PropertyPurchasePlan), true);

      return;
    }

    // Property
    if (this.selectedEntities.property?.length) {
      propertyId = this.selectedEntities.property;
    } else {
      this._handleError('empty', this.stepList.indexOf(EVisitSteps.SelectProperty));

      return;
    }

    // Event
    try {
      const visitInfos = this.formatEvent(this.entities.event, propertyId, personId);
      const visitResponse = await this.$api.visits.add(visitInfos);
      this.visitId = visitResponse.id;
      this.$emit('visit-created', this.visitId);

      if (!this.visitId) {
        this._handleError('empty', this.stepList.indexOf(EVisitSteps.Event));

        return;
      }

      if (this.settings.isSmsReminderEnabled) {
        this.manualEmailDisclaimers.push(this.$t('email.dialog.meeting.disclaimers.sms_reminder'));
      }

      if (visitResponse && !isPastDate(visitResponse.endTime)) {
        await this.openManualEmailDialog(visitResponse);
      }

      if (this.isManualEmailVisitCreationVisible) {
        return;
      }

      await this.handleCloseWizard();
      await this.redirectToCalendar();
    } catch (error) {
      this._handleError(error, this.stepList.indexOf(EVisitSteps.Event), true);

      return;
    }
  }

  async handleCloseDialog(): Promise<void> {
    this.closeManualEmailDialogs();
    await this.handleCloseWizard();
    await this.redirectToCalendar();
  }

  async handleCloseWizard(): Promise<void> {
    this.resetIsEmailUnique();
    await this.closeWizard();
  }

  handleEntitySelection(name: string, id: string): void {
    this.selectEntity(name, id);
    this.updateSuggestionList();
  }

  async openManualEmailDialog(visit: ICreateVisitResponse): Promise<void> {
    this.contactsManualEmail = [{
      ...extractContactInformation(visit.person, visit.person.personContacts),
      eventId: visit.id,
      role: EContactRole.Buyer,
    }];

    const property = Property.query().whereId(visit.propertyId).first();

    if (property?.seller) {
      this.contactsManualEmail.push({
        ...extractContactInformation(property.seller, property.seller.personContacts),
        eventId: visit.id,
        role: EContactRole.Seller,
      });
    }

    this.email = await this.generateEmail(visit.id, EEventType.Visit);
    this.isManualEmailVisitCreationVisible = true;
  }

  async redirectToCalendar(): Promise<void> {
    if (this.noRedirection || !this.visitId) {
      return;
    }

    await this.$router.push({
      hash: undefined,
      name: 'visit',
      params: { eventId: this.visitId },
    }).catch(error => {
      if (error.name !== EHttpErrorName.NavigationDuplicated) {
        console.error(error);
      }
    });
  }

  setData(): void {
    this.entities = {
      event: new EventEntityModel(),
      person: this.prefillPerson || new PersonEntityModel(EContactRole.Buyer),
      property: new PropertyEntityModel(),
      propertyPurchasePlan: new PropertyPurchasePlanEntityModel(),
    };

    this.selectedEntities = { person: this.existingPersonId || '', property: this.propertyId || '' };
    this.manualEmailDisclaimers = [];
    this.updateSuggestionList();
  }

  async submitEmails(contacts: IManualEmailContact[]): Promise<void> {
    await this.handleSubmitEmails(contacts);
    await this.handleCloseWizard();
    await this.redirectToCalendar();
  }

  updateStep(index: number): void {
    const currentStepIndex = this.stepIndex;
    this.changeStep(index);

    if (this.stepList[currentStepIndex] === EVisitSteps.PropertyPurchasePlan) {
      // TODO: stop bad practice mutating computed data
      this.steps[currentStepIndex].hasError = !this.validateForm();
    }
  }

  updateSuggestionList(): void {
    const persons = this.suggestionList?.person.filter(user => user.tags?.includes(EContactRole.Buyer)) ?? [];
    const propertySuggestions = this.suggestionList?.property || [];
    let buyingProperties: IOption<string>[] = [];

    if (!this.selectedEntities.person) {
      this.buyersSuggestionList = { person: persons, property: propertySuggestions };

      return;
    }

    Property.api()
      .getProperties({ visitorPersonId: this.selectedEntities.person })
      .then(response => {
        if (response.entities?.properties) {
          const properties = response.entities.properties;
          buyingProperties = properties.map(({ fullAddress, id }) => ({ label: fullAddress, value: id }));
        }

        Property.api()
          .getProperties({ sellerPersonId: this.selectedEntities.person })
          .then(res => {
            let sellingProperties: IOption<string>[] = [];

            if (res.entities?.properties) {
              const sProperties = res.entities.properties;
              sellingProperties = sProperties.map(({ fullAddress, id }) => ({ label: fullAddress, value: id }));
            }

            const sellingPropertiesMap = new Map(
              sellingProperties.map(option => [`${option.value}-${option.label}`, option]),
            );

            const properties = [...buyingProperties, ...propertySuggestions].reduce((propertiesMap, option) => {
              const key = `${option.value}-${option.label}`;

              if (!propertiesMap.has(key) && !sellingPropertiesMap.has(key)) {
                propertiesMap.set(key, option);
              }

              return propertiesMap;
            }, new Map<string, IOption<string>>());

            this.buyersSuggestionList = { person: persons, property: [...properties.values()] };
          });
      });
  }
}
