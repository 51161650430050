import App from '@/main';
import store from '@/store';
import * as Sentry from '@sentry/vue';

import { EHttpStatusCode } from '@/enums/HttpStatusCode.enum';
import { ELocalStorageKeys } from '@/enums/Storage.enum';
import { ENodeEnv } from '@/enums/NodeEnv.enum';

const logOut = () => {
  if (localStorage.getItem(ELocalStorageKeys.AuthToken)) {
    store.dispatch('logOut');
    App.$router.push({ name: 'log_in' });
  }

  if (localStorage.getItem(ELocalStorageKeys.BoAuthToken)) {
    store.dispatch('bo/logOut');
    App.$router.push({ name: 'bo_log_in' });
  }
};

const notLoggedIn = 'not-logged-in';

export function handleError(error): Promise<never> {
  if (error?.response?.status === EHttpStatusCode.Unauthorized) {
    if (error.response.data.error.type === notLoggedIn) {
      logOut();
    }
  } else if (process.env.NODE_ENV !== ENodeEnv.Development) {
    Sentry.captureException(error);
  }

  return Promise.reject(error);
}

/**
 * Handles global error. Currently it only send the error to Sentry.
 *
 * @param error - Could be any kind of error.
 *
 */
export function customLogger(error): void {
  if (process.env.NODE_ENV !== ENodeEnv.Development) {
    Sentry.captureException(error);
  }
}
