export enum EBuyerSteps {
  Person = 'person',
  PropertyPurchasePlan = 'property-purchase-plan'
}

export enum EPropertyPurchasePlanSteps {
  Person = 'person',
  PropertyPurchasePlan = 'property-purchase-plan',
}

export enum EVisitSteps {
  Event = 'event',
  Person = 'person',
  PropertyPurchasePlan = 'property-purchase-plan',
  SelectProperty = 'select-property'
}