






























import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { createWidgetMixin } from 'vue-instantsearch';
import { connectSearchBox } from 'instantsearch.js/es/connectors';

@Component
export default class SearchBoxDebounce extends Mixins(createWidgetMixin({ connector: connectSearchBox })) {
  @Prop({ default: 300, type: Number }) delay!: number;
  @Prop({ required: true, type: String }) globalSearchId!: string;
  @Prop({ default: '', type: String }) searchInputText!: string;

  @Getter('isDesktop') isDesktop!: boolean;

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  declare state: any;
  declare unwatchInitialSearch: () => void;
  localQuery = '';
  timeout: ReturnType<typeof setTimeout> | null= null;

  @Watch('searchInputText')
  updateLocalQuery(searchInputText: string) {
    this.setLocalQuery(searchInputText);
    this.emitSearch();
  }

  created() {
    this.localQuery = this.searchInputText;

    // If a search was 'saved', trigger search when re-opining GlobalSearch once 'state' is ready
    if (!this.localQuery) {
      return;
    }

    this.unwatchInitialSearch = this.$watch('state', (state) => {
      if (state) {
        this.emitSearch();
        this.unwatchInitialSearch();
      }
    });
  }

  mounted() {
    if (this.isDesktop) {
      this.searchBoxFocus();
    }
  }

  clearLocalQuery() {
    this.setLocalQuery();
    this.searchBoxFocus();
  }

  emitSearch(delay = 0) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => this.state.refine(this.localQuery), delay);
  }

  onQueryInput({ target }) {
    this.setLocalQuery(target.value);
    this.emitSearch(this.delay);
  }

  searchBoxFocus() {
    (this.$refs[`globalSearchBox-${this.globalSearchId}`] as HTMLInputElement).focus();
  }

  setLocalQuery(value = '') {
    this.localQuery = value;
    this.$emit('update', this.localQuery);
  }
}
