import { camelizeKeys } from 'humps';

export const getCamelizedJSON = <T>(value: string): T | undefined => {
  try {
    return camelizeKeys(JSON.parse(value));
  } catch {
    return undefined;
  }
};

export const getDeepObjectCopy = <T>(object: T): T => JSON.parse(JSON.stringify(object));

export const hasNullValues = (obj: object, relevantValuesLength?: number): boolean => {
  const values = Object.values(obj);
  const emptyFields = values.filter(value => value === null);
  const valuesLength = values.length;

  return emptyFields.length >= Math.min(relevantValuesLength ?? valuesLength, valuesLength);
};

export const removeEmptyProperties = (obj) => Object.keys(obj)
  .filter((k) => !!obj[k])
  .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});

export type IDictionary<Key extends string | number | symbol, Value> = Partial<Record<Key, Value>>;