// Icons

export type Icons =
  'add' |
  'agency' |
  'agent' |
  'apartment' |
  'balcony' |
  'bathroom' |
  'bedroom' |
  'calendar' |
  'camera' |
  'cellar' |
  'chair' |
  'check_circle' |
  'check' |
  'chevron_down' |
  'chevron_left' |
  'chevron_right' |
  'circle' |
  'close' |
  'cloud' |
  'comment' |
  'common_area' |
  'contact_checked' |
  'contact_unchecked' |
  'contact' |
  'dataroom' |
  'delete' |
  'dining_room' |
  'download' |
  'dressing_room' |
  'edit_house' |
  'edit' |
  'email' |
  'estimation' |
  'estimation_report' |
  'eye_slash' |
  'eye' |
  'file_doc' |
  'file_img' |
  'file_other' |
  'file_outlined' |
  'file_pdf' |
  'filter' |
  'fire' |
  'format_landscape' |
  'format_portrait' |
  'garden' |
  'house' |
  'income_expenses' |
  'info_outlined' |
  'info' |
  'inside_info' |
  'key' |
  'kitchen' |
  'leaf' |
  'link' |
  'living_room' |
  'loader_blue' |
  'loader_gray' |
  'location' |
  'loggia' |
  'logout' |
  'menu_burger' |
  'menu_buyers_search' |
  'menu_contacts' |
  'menu_dot' |
  'menu_followed_property_purchase_plan' |
  'menu_notifications' |
  'menu_properties' |
  'menu_stats' |
  'menu_visits' |
  'moon' |
  'note' |
  'notif_calendar' |
  'notif_contact' |
  'notif_document' |
  'notif_offer' |
  'notif_task' |
  'offer' |
  'office' |
  'order' |
  'orientation' |
  'outside_info' |
  'parking' |
  'phone' |
  'poster' |
  'price' |
  'print' |
  'priorisation' |
  'prospecting' |
  'qualification' |
  'question_outlined' |
  'renew' |
  'resume_bedroom' |
  'resume_room' |
  'resume_surface' |
  'rooftop' |
  'search' |
  'settings' |
  'store_room' |
  'summary' |
  'sunrise' |
  'snowflake' |
  'star' |
  'task_auto' |
  'task' |
  'terrace' |
  'terrain' |
  'thermometer' |
  'time' |
  'transaction' |
  'veranda' |
  'warning' |
  'warning_outlined' |
  'wc' |
  'window';

export const icons = require.context('@/assets/icons/', false, /.*\.svg$/).keys().map(key => key.slice(2, -4))
  .reduce<Record<Icons, Icons>>((obj, key) => {
    obj[key] = key;

    return obj;
  }, {} as Record<Icons, Icons>);

// Sizes

export type Sizes = 'xs' | 'sm' | 'base' | 'lg' | 'xl';

export const sizes = ['xs', 'sm', 'base', 'lg', 'xl']
  .reduce<Record<Sizes, Sizes>>((obj, key) => {
    obj[key] = key;

    return obj;
  }, {} as Record<Sizes, Sizes>);