















































import { Vue, Component } from 'vue-property-decorator';

import { EPersonContactType } from '@/enums/PersonContactType.enum';
import { formatEnumAttribute } from '@/utils/globalSearch';
import { getLocalePhoneNumber } from '@/utils/phone';
import { ISearchHighlightResult } from '@/interfaces/SearchHighlightResult.interface';
import { ISearchPersonHit } from '@/interfaces/SearchPersonHit.interface';

import { AisHighlight } from 'vue-instantsearch';
import SearchList from '@/components/common/molecules/search-list/SearchList.vue';
import PersonItem from '@/components/common/organisms/persons/PersonItem.vue';

interface IPersonResult extends ISearchPersonHit {
  _highlightResult: ISearchHighlightResult;
}

@Component({
  components: {
    AisHighlight,
    PersonItem,
    SearchList,
  },
})
export default class SearchPersonResults extends Vue {
  transformPersonItems(items: IPersonResult[]): IPersonResult[] {
    return items.map(item => {
      const highlightResult = item._highlightResult;
      const { Email, Phone } = EPersonContactType;
      // Format phone number
      const phoneContact = item.person_contacts.find(({ type }) => type === Phone);

      if (phoneContact) {
        const phoneNumber = phoneContact._values ? phoneContact._values[2] : phoneContact.value;

        if (phoneNumber) {
          item[Phone] = getLocalePhoneNumber(phoneNumber);
        }
      }

      // Format email
      const emailIndex = item.person_contacts.findIndex(({ type }) => type === Email);

      if (emailIndex > -1) {
        item[Email] = highlightResult.person_contacts[emailIndex].value.value
          .replaceAll('<mark>', '<mark class=\'ais-Highlight-highlighted\'>');
      }

      // Format roles
      if (item.tags.length) {
        item.roles = item.tags.map((tag, index) => {
          const role = this.$t(`person.roles.${tag}`);
          const { matchedWords } = highlightResult.tags[index];

          return formatEnumAttribute(role, matchedWords);
        }).join(', ');
      }

      return item;
    });
  }
}
