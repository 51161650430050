import { LkAxiosInstance } from '@/api';
import { EEstimationStatus } from '@/enums/EstimationStatus.enum';
import {
  ICreateEstimationRequestPayload,
  IEstimation,
  IEstimationResponse,
  IEstimationsResponse,
  IEstimationView,
  IGenerateEstimationReport,
  ISendEstimateDialog,
  ISendEstimatePayload,
} from '@/api/dome/estimations/Estimations.interface';

const BASE_ENDPOINT = '/estimations';

const getFormattedEstimation = <T extends IEstimationResponse>(estimation: T): T & { status: EEstimationStatus } => {
  let status = EEstimationStatus.Draft;

  if (estimation.validatedAt) {
    status = estimation.review ? EEstimationStatus.Approved : EEstimationStatus.Saved;
  } else if (estimation.submittedAt) {
    status = estimation.review ? EEstimationStatus.WaitingForCorrection : EEstimationStatus.WaitingForApproval;
  }

  return { ...estimation, status };
};

export interface IEstimationsService {
  create(estimation: ICreateEstimationRequestPayload): Promise<IEstimation>;
  generateEstimationReport(propertyId: string): Promise<string>;
  getByPropertyId(propertyId: string): Promise<IEstimation[]>;
  getEstimateDialogData(estimationId: string): Promise<ISendEstimateDialog>;
  getEstimationView(propertyId: string): Promise<IEstimationView>;
  requestCorrection(estimationId: string, comment: string): Promise<void>;
  sendEstimate(estimationId: string, payload: ISendEstimatePayload): Promise<void>;
  submit(estimationId: string): Promise<void>;
  update(estimation: IEstimation): Promise<IEstimation>;
  validate(estimationId: string, comment?: string): Promise<void>;
}

export const estimationsService = ($axios: LkAxiosInstance): IEstimationsService => ({
  async create(estimation) {
    const response = await $axios.$post<IEstimationResponse>(BASE_ENDPOINT, estimation);

    return getFormattedEstimation(response);
  },
  async generateEstimationReport(propertyId) {
    const { reportUrl } = await $axios.$post<IGenerateEstimationReport>('/estimation_reports/generate', { propertyId });

    return reportUrl;
  },
  async getByPropertyId(propertyId) {
    const { estimations } = await $axios.$get<IEstimationsResponse>(`${BASE_ENDPOINT}?property_id=${propertyId}`);

    return estimations.map(getFormattedEstimation);
  },
  getEstimateDialogData(estimationId) {
    return $axios.$get(`${BASE_ENDPOINT}/${estimationId}/email_dialog_data`);
  },
  async getEstimationView(propertyId) {
    return await $axios.$get<IEstimationView>(`/view_for_estimation_details/${propertyId}`);
  },
  requestCorrection(estimationId, comment) {
    return $axios.$post(`${BASE_ENDPOINT}/${estimationId}/request_correction`, { comment });
  },
  sendEstimate(estimationId, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${estimationId}/send_email`, payload);
  },
  submit(estimationId) {
    return $axios.$post(`${BASE_ENDPOINT}/${estimationId}/submit_for_approval`);
  },
  async update(estimation) {
    const response = await $axios.$put<IEstimationResponse>(`${BASE_ENDPOINT}/${estimation.id}`, estimation);

    return getFormattedEstimation(response);
  },
  validate(estimationId, comment) {
    return $axios.$post(`${BASE_ENDPOINT}/${estimationId}/validate`, comment ? { comment } : {});
  },
});
