import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

const sizeUnits = ['Octets', 'Ko', 'Mo', 'Go', 'To'];
const sizeDivier = 1000;

export const getFileName = (headers: RawAxiosResponseHeaders | AxiosResponseHeaders): string => {
  const contentDisposition = headers['content-disposition'];

  return contentDisposition && typeof contentDisposition === 'string' ? contentDisposition.split('"')[1] : '';
};

export const getFormattedFileSize = (size: number | undefined): string => {
  if (!size) {
    return '';
  }

  const i = Math.floor(Math.log(size) / Math.log(sizeDivier));
  const unit = sizeUnits[i];

  return i === 0 ? `${size} ${unit})` : `${(size / (sizeDivier ** i)).toFixed(0)} ${unit}`;
};