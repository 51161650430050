const DocumentsIndex = () => import(/* webpackChunkName: "documents" */ '@/views/properties/id/dataroom/index.vue');
// eslint-disable-next-line vue/max-len
const DocumentsDetail = () => import(/* webpackChunkName: "documents" */ '@/views/properties/id/dataroom/panels/index.vue');

export default [
  {
    children: [
      {
        component: DocumentsDetail,
        name: 'dataroom_documents',
        path: ':documentType',
      },
    ],
    component: DocumentsIndex,
    name: 'dataroom',
    path: '/biens/:propertyId/documents',
  },
];