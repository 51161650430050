


























































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { State } from 'vuex-class';

import { EContactRole } from '@/enums/ContactRole.enum';
import { EEventType } from '@/enums/EventType.enum';
import { EHttpErrorName } from '@/enums/HttpErrorName.enum';
import { EventEntityModel, PersonEntityModel, PropertyEntityModel } from './models';
import { extractContactInformation } from '@/helpers/contacts';
import { getISODate, isPastDate } from '@/utils/date';
import { IAgent, IBasicAgent, IDomeSettings } from '@/api/dome/agents/Agents.interface';
import { ICreateMeetingPayload, ICreateMeetingResponse } from '@/api/dome/meetings/Meetings.interface';
import { IOption } from '@/interfaces/Option.interface';
import { IResultItem } from '@/interfaces/ResultItem.interface';
import { IStep } from '@/components/common/molecules/stepper/StepperWithStatus.interface';

import AgentSearch from '@/components/common/molecules/forms/agent-search/AgentSearch.vue';
import DefineProperty from './steps/DefineProperty.vue';
import Event from './steps/Event.vue';
import EventHelpers from './helpers/event.vue';
import ManualEmailDialog from '@/components/common/organisms/dialog/ManualEmailDialog.vue';
import Person from './steps/Person.vue';
import PersonHelpers from './helpers/person.vue';
import DialogsHelpers from './helpers/dialogs.vue';
import PropertyHelpers from './helpers/property.vue';
import PropertyInformation from '@/views/entity/steps/PropertyInformation.vue';
import Wizard from '@/components/common/templates/wizard/Wizard.vue';

@Component({
  components: {
    AgentSearch,
    DefineProperty,
    Event,
    ManualEmailDialog,
    Person,
    PropertyInformation,
    Wizard,
  },
})
export default class MeetingEntity extends Mixins(PersonHelpers, EventHelpers, DialogsHelpers, PropertyHelpers) {
  @Prop({ default: false, type: Boolean }) isFromLead!: boolean;
  @Prop({ type: Object }) prefillPerson?: PersonEntityModel;
  @Prop({ type: Object }) prefillProperty?: PropertyEntityModel;
  @Prop({ type: String }) readonly sellerLeadId?: string;
  @Prop({ type: Object }) suggestionList?: Record<'agency' | 'person' | 'property', IOption<string>[]>;

  @State('agents') agents!: IBasicAgent[];
  @State('loggedInAgent') loggedInAgent!: IAgent;
  @State('settings') settings!: IDomeSettings;

  currentMeetingId: string | undefined;
  manualEmailDisclaimers: string[] = [];
  search = '';
  selectedAgentId: string | undefined;
  stepList = Object.freeze(['person', 'define-property', 'property-information', 'event']);

  @Watch('isManualEmailMeetingCreationVisible')
  async onDialogChange(value: boolean, oldValue: boolean): Promise<void> {
    if (!value && oldValue) {
      await this.redirect();
    }
  }

  get agentName(): string {
    return this.agents.find(({ id }) => id === this.selectedAgentId)?.fullName || '';
  }

  get formattedAgentsResult(): IResultItem[] {
    return this.agents.reduce<IResultItem[]>((arr, agent) => {
      if (agent.fullName.toLowerCase().includes(this.search.toLowerCase())) {
        arr.push({ ...agent, content: `<span class="mr-2 group-hover:text-lk-blue">${agent.fullName}</span>` });
      }

      return arr;
    }, []);
  }

  get isSourceSelectDisplayed(): boolean {
    return this.isFromLead ? !this.prefillPerson?.manualSource : true;
  }

  get sellersSuggestionList(): Record<'person' | 'property', IOption<string>[]> {
    return {
      person: this.suggestionList?.person.filter(person => person.tags?.includes(EContactRole.Seller)) || [],
      property: this.suggestionList?.property || [],
    };
  }

  get steps(): IStep[] {
    return this.stepList.map(step => {
      let name = step;

      if (step === 'person') {
        name = 'seller';
      } else if (step === 'event') {
        name = 'meeting';
      }

      return {
        hasError: false,
        isFilled: false,
        isOptional: step === 'property-information',
        name: this.$t(`entity_creation.steps.${name}.title`),
        value: step,
      };
    });
  }

  created(): void {
    this.setData();
  }

  clearPerson(): void {
    this.searchAgents('');
    this.updateEntity('event', 'agentID', '');
  }

  async createEntity(): Promise<void> {
    this.isSubmitting = true;
    let personId!: string | undefined;
    let meetingResponse: ICreateMeetingResponse | undefined;
    let isSellerFirstMeeting = false;
    let isOnboardingEmailNeverSent = false;

    if (!this.isEntityValid(this.stepList)) {
      this._handleError('invalid data', this.errorStepIndex);

      return;
    }

    // Person
    try {
      personId = await this.createPerson(this.selectedEntities, this.entities);

      if (!personId) {
        this._handleError('empty', this.stepList.indexOf('person'));

        return;
      }
    } catch (error) {
      this._handleError(error, this.stepList.indexOf('person'), true);

      return;
    }

    const event = this.entities.event as EventEntityModel;
    this.selectedAgentId = event.agentID;

    const estimationMeeting: ICreateMeetingPayload = {
      assignedAgentId: this.selectedAgentId,
      endTime: getISODate(event.date, event.endTime),
      personId,
      property: this.selectedEntities.property ? null : this.entities.property,
      propertyId: this.selectedEntities.property ? this.selectedEntities.property : null,
      propertyInformation: this.entities.propertyInformation,
      sellerLeadId: this.sellerLeadId,
      startTime: getISODate(event.date, event.startTime),
    };

    try {
      meetingResponse = await this.$api.meetings.createEstimationMeeting(estimationMeeting);
      this.currentMeetingId = meetingResponse.id;

      if (this.settings.isCustomerSpaceEnabled) {
        const response = await this.$api.activities.generateFeed(personId);

        isSellerFirstMeeting = response.filter(activity => activity.targetType === 'meeting').length === 1;

        const { onboardingEmailSentAt } = await this.$api.persons.getCustomerSpaceDetails(personId);
        isOnboardingEmailNeverSent = !onboardingEmailSentAt;
      }

      if (!this.currentMeetingId) {
        this._handleError('empty', this.stepList.indexOf('event'));

        return;
      }
    } catch (error) {
      this._handleError(error, this.stepList.indexOf('event'), true);

      return;
    }

    await this.handleCloseWizard();

    if (isSellerFirstMeeting && isOnboardingEmailNeverSent) {
      await this.$api.persons.sendOnboardingEmail(personId);
      this.manualEmailDisclaimers.push(this.$t('email.dialog.meeting.disclaimers.customer_space'));
    }

    if (this.settings.isSmsReminderEnabled) {
      this.manualEmailDisclaimers.push(this.$t('email.dialog.meeting.disclaimers.sms_reminder'));
    }

    if (isPastDate(meetingResponse.endTime)) {
      await this.redirect();
    } else {
      await this.openManualEmailDialog(meetingResponse);
    }
  }

  handleAgentValidation(): void {
    // Due to BaseSelect behavior, we delay blur event since its emitted at the same time as update
    setTimeout(() => {
      this.handleValidateEntityKey('event', 'agentID');
    }, 100);
  }

  async handleCloseWizard(): Promise<void> {
    this.resetIsEmailUnique();
    await this.closeWizard();
  }

  async openManualEmailDialog(meeting: ICreateMeetingResponse): Promise<void> {
    this.contactsManualEmail = [{
      ...extractContactInformation(meeting.person, meeting.person.personContacts),
      eventId: meeting.id,
      role: EContactRole.Seller,
    }];

    this.isManualEmailMeetingCreationVisible = true;
    this.email = await this.generateEmail(meeting.id, EEventType.Meeting);
  }

  async redirect() {
    if (!this.currentMeetingId) {
      return;
    }

    if (this.isFromLead) {
      this.$emit('entity-created', this.currentMeetingId);
    } else if (this.selectedAgentId === this.loggedInAgent.id) {
      try {
        await this.$router.push({
          hash: undefined,
          name: 'meeting',
          params: { eventId: this.currentMeetingId },
        });
      } catch (error) {
        if (error instanceof AxiosError && error.name !== EHttpErrorName.NavigationDuplicated) {
          console.error(error);
        }
      }
    }
  }

  searchAgents(value: string): void {
    this.search = value;
  }

  selectAgent(agent: IAgent): void {
    this.searchAgents(agent.fullName);
    this.updateEntity('event', 'agentID', agent.id);
    this.handleRemoveErrorMessage('event', 'agentID');
  }

  setData(): void {
    this.entities = {
      event: new EventEntityModel(),
      person: this.prefillPerson ? { ...this.prefillPerson } : new PersonEntityModel(EContactRole.Seller),
      property: this.prefillProperty ? { ...this.prefillProperty } : new PropertyEntityModel(),
      propertyInformation: {
        bridgeLoan: null,
        exclusivityOpinion: null,
        isRented: null,
        lastBuyingDate: null,
        preEstimatedSellingPrice: null,
        saleReason: null,
        saleStrategy: null,
        stepsTaken: null,
        usage: null,
      },
    };

    this.selectedEntities = { person: '', property: '' };
    this.manualEmailDisclaimers = [];
    this.selectAgent(this.loggedInAgent);
  }
}
