







import { Component, Prop, Vue } from 'vue-property-decorator';
import { icons, sizes } from '@/helpers/icons';

@Component
export default class BaseIcon extends Vue {
  @Prop({ required: true, type: String, validator: (value) => Object.keys(icons).includes(value) }) icon!: string;
  @Prop({ default: 'base', type: String, validator: (value) => Object.keys(sizes).includes(value) }) size!: string;

  get iconComponent(): typeof Component | unknown {
    const icon = this.icon; // For storybook reactivity

    return () => import(`@/assets/icons/${icon}.svg`);
  }

  get sizeClass(): string | null {
    switch (this.size) {
      case 'xs': return 'w-2 h-2';
      case 'sm': return 'w-3 h-3';
      case 'lg': return 'w-6 h-6';
      case 'xl': return 'w-8 h-8';
      default: return 'w-4 h-4';
    }
  }
}
