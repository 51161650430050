import { LkAxiosInstance } from '@/api';
import { IGeoLocation } from '@/interfaces/models/GeoLocation.interface';

const BASE_ENDPOINT = '/seller_lead_geo_locations';

export interface ISellerLeadGeoLocationsService {
  getGeoLocations(): Promise<{geoLocations: IGeoLocation[]}>;
}

export const sellerLeadGeoLocationsService = ($axios: LkAxiosInstance): ISellerLeadGeoLocationsService => ({
  getGeoLocations() {
    return $axios.$get(`${BASE_ENDPOINT}_for_agent`);
  },
});
