import { axiosConfig, LkAxiosInstance } from '@/api';
import { createBlobUrl } from '@/utils/blobHelper';
import { EMandateDocumentType } from '@/enums/mandate/MandateDocumentType.enum';
import {
  IMandateDocument,
  IMandateDocumentResponse,
  IMandateDocumentsResponse,
  IMandatePreviewText,
  IMandatePreviewTextsResponse,
} from '@/api/dome/mandates/MandateDocuments.interface';
import { toQueryString } from '@/utils/url';

const BASE_ENDPOINT = '/mandate_documents';

export interface IMandateDocumentsService {
  amend(id: string): Promise<IMandateDocument>;
  cancel(id: string): Promise<IMandateDocument>;
  generatePdf(id: string, type: EMandateDocumentType): Promise<string>;
  generateText(id: string): Promise<IMandatePreviewText[]>;
  get(id: string): Promise<IMandateDocument>;
  getAll(mandateId?: string): Promise<IMandateDocument[]>;
  markAsSigned(id: string, date: string): Promise<void>;
  resend(id: string): Promise<IMandateDocument>;
  revise(id: string): Promise<IMandateDocument>;
  saveDraft(id: string, mandateDocument: IMandateDocument): Promise<void>;
  submit(id: string): Promise<void>;
  uploadPdfSigned(id: string, formData: FormData): Promise<void>;
  validate(id: string): Promise<void>;
}

export const mandateDocumentsService = ($axios: LkAxiosInstance): IMandateDocumentsService => ({
  async amend(id) {
    const { mandateDocument } = await $axios.$post<IMandateDocumentResponse>(`${BASE_ENDPOINT}/${id}/amend`);

    return mandateDocument;
  },

  async cancel(id) {
    const { mandateDocument } = await $axios.$post<IMandateDocumentResponse>(`${BASE_ENDPOINT}/${id}/cancel`);

    return mandateDocument;
  },

  async generatePdf(id, documentType) {
    const pdfTypes: Record<EMandateDocumentType, string> = {
      [EMandateDocumentType.Preview]: 'preview_pdf',
      [EMandateDocumentType.Raw]: 'fetch_pdf_raw',
      [EMandateDocumentType.Signed]: 'fetch_pdf_signed',
    };

    const response = await $axios.post<Blob>(`${BASE_ENDPOINT}/${id}/${pdfTypes[documentType]}`, {}, axiosConfig.blob);

    return createBlobUrl(response.data);
  },
  async generateText(id) {
    const { previewTexts } = await $axios.$post<IMandatePreviewTextsResponse>(`${BASE_ENDPOINT}/${id}/preview_text`);

    return previewTexts;
  },
  async get(id) {
    const { mandateDocument } = await $axios.$get<IMandateDocumentResponse>(`${BASE_ENDPOINT}/${id}`);

    return mandateDocument;
  },
  async getAll(mandateId) {
    const query = toQueryString({ mandate_id: mandateId });
    const { mandateDocuments } = await $axios.$get<IMandateDocumentsResponse>(`${BASE_ENDPOINT}?${query}`);

    return mandateDocuments;
  },
  markAsSigned(id, date) {
    return $axios.$post(`/mandate_documents/${id}/mark_as_signed`, { signedAt: date });
  },
  async resend(id) {
    const { mandateDocument } = await $axios.$post<IMandateDocumentResponse>(`${BASE_ENDPOINT}/${id}/resend`);

    return mandateDocument;
  },
  async revise(id) {
    const { mandateDocument } = await $axios.$post<IMandateDocumentResponse>(`${BASE_ENDPOINT}/${id}/revise`);

    return mandateDocument;
  },
  saveDraft(id, mandateDocument) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}`, { mandateDocument });
  },
  submit(id) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/submit_for_approval`);
  },
  uploadPdfSigned(id, formData) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/upload_pdf_signed`, formData, axiosConfig.formData);
  },
  validate(id) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/validate`);
  },
});
