




































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import PanelPlaceholder from '@/components/common/atoms/panel-placeholder/PanelPlaceholder.vue';

@Component({
  components: {
    PanelPlaceholder,
  },
})
export default class NavigationTop extends Vue {
  @Prop({ default: false, type: Boolean }) isBackButtonVisible!: boolean;
  @Prop({ default: 0, type: Number }) notificationBadge?: number;
  @Prop({ required: true, type: String }) title!: string;
  @Action('toggleNotificationsMenu') toggleNotificationsMenu!: (show: boolean) => void;
}
