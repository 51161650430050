import { LkAxiosInstance } from '@/api';
import { IMandateRecipient, IMandateRecipientResponse, IMandateRecipientsResponse }
  from '@/api/dome/mandates/MandateRecipients.interface';

const BASE_ENDPOINT = '/mandate_recipients';

function formatMandateRecipient(mandateRecipient: IMandateRecipient): IMandateRecipient {
  return {
    ...mandateRecipient,
    fullName: `${mandateRecipient.firstName} ${mandateRecipient.lastName}`,
  };
}

export interface IMandateRecipientsService {
  create(mandateRecipient: IMandateRecipient): Promise<IMandateRecipient>;
  delete(mandateRecipientId: string): Promise<void>;
  getByMandateDocumentId(mandateDocumentId: string): Promise<IMandateRecipient[]>;
  update(mandateRecipient: IMandateRecipient): Promise<IMandateRecipient>;
}

export const mandateRecipientsService = ($axios: LkAxiosInstance): IMandateRecipientsService => ({
  async create(mandateRecipient) {
    const {
      mandateRecipient: mandateRecipientResponse,
    } = await $axios.$post<IMandateRecipientResponse>(BASE_ENDPOINT, { mandateRecipient });

    return formatMandateRecipient(mandateRecipientResponse);
  },
  delete(mandateRecipientId) {
    return $axios.$delete(`${BASE_ENDPOINT}/${mandateRecipientId}`);
  },
  async getByMandateDocumentId(mandateDocumentId) {
    const { mandateRecipients } =
      await $axios.$get<IMandateRecipientsResponse>(`${BASE_ENDPOINT}?mandate_document_id=${mandateDocumentId}`);

    return mandateRecipients.map(formatMandateRecipient);
  },
  async update(mandateRecipient) {
    const {
      mandateRecipient: mandateRecipientResponse,
    } = await $axios.$post<IMandateRecipientResponse>(`${BASE_ENDPOINT}/${mandateRecipient.id}`, { mandateRecipient });

    return formatMandateRecipient(mandateRecipientResponse);
  },
});
