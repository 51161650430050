import { decamelize } from 'humps';

import {
  IArchiveBuyerLeadPayload,
  IBuyerLead,
  IBuyerLeadDetails,
  IBuyerLeadsForPropertyResponse,
  IBuyerLeadsParameters,
  IBuyerLeadsResponse,
  IProcessBuyerLeadParameters,
  IBuyerLeadReminderPayload,
} from '@/api/dome/buyer-leads/BuyerLeads.interface';
import { LkAxiosInstance } from '@/api';
import { toQueryString } from '@/utils/url';

const BASE_ENDPOINT = '/lead_buyers';

export interface IBuyerLeadsService {
  archiveLead(id: string, payload: IArchiveBuyerLeadPayload): Promise<void>;
  createReminder(buyerLeadPayload: IBuyerLeadReminderPayload, buyerLeadId: string): Promise<IBuyerLeadReminderPayload>;
  editReminder(buyerLeadPayload: IBuyerLeadReminderPayload, buyerLeadId: string): Promise<IBuyerLeadReminderPayload>;
  getLead(id: string): Promise<IBuyerLeadDetails>;
  getLeads({ agentId, page, tab }: IBuyerLeadsParameters): Promise<IBuyerLeadsResponse>;
  getPropertyLeads(propertyId: string, { page, tab }: IBuyerLeadsParameters): Promise<IBuyerLeadsForPropertyResponse>;
  processLead(id: string, payload: IProcessBuyerLeadParameters): Promise<IBuyerLead>;
  setAgent(id: string, agentId: string): Promise<IBuyerLead>;
}

export const buyerLeadsService = ($axios: LkAxiosInstance): IBuyerLeadsService => ({
  archiveLead(id, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/archive`, payload);
  },
  createReminder(buyerLeadPayload, buyerLeadId) {
    return $axios.$post(`${BASE_ENDPOINT}/${buyerLeadId}/reminders`, buyerLeadPayload);
  },
  editReminder(buyerLeadPayload, buyerLeadId) {
    return $axios.$post(`${BASE_ENDPOINT}/${buyerLeadId}/edit_last_reminder`, buyerLeadPayload);
  },
  getLead(id) {
    return $axios.$get(`${BASE_ENDPOINT}/${id}`);
  },
  getLeads({ agentId, page, tab }) {
    const query = toQueryString({ agent_id: agentId, page });

    return $axios.$get(`${BASE_ENDPOINT}_${decamelize(tab)}?${query}`);
  },
  getPropertyLeads(propertyId, { page, tab }) {
    return $axios.$get(`properties/${propertyId}${BASE_ENDPOINT}_${decamelize(tab)}?page=${page}`);
  },
  processLead(id, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/process`, payload);
  },
  setAgent(id, agentId) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/set_agent`, { agentId });
  },
});
