import { initializeApp, FirebaseOptions } from 'firebase/app';

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  // authDomain: <AUTH-DOMAIN>,
  // measurementId: <MEASUREMENT-ID>
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  // storageBucket: <STORAGE-BUCKET>,
};

export const firebaseApp = initializeApp(firebaseConfig);
