import { LkAxiosInstance } from '@/api';
import {
  ICreatedTask,
  IResumeOrTerminateResponse,
  ITask,
  ITaskReminderPayload,
  ITasksResponse,
} from './Tasks.interface';

export interface ITasksService {
  create(task: ICreatedTask): Promise<ITask>;
  getAll(queryParams: string): Promise<ITasksResponse>;
  getById(taskId: string): Promise<ITask>;
  postpone(taskId: string, payload: ITaskReminderPayload): Promise<void>;
  revive(taskId: string): Promise<IResumeOrTerminateResponse>;
  setAgent(taskId: string, agentId: string): Promise<void>;
  terminate(taskId: string, closureReason: string | null): Promise<IResumeOrTerminateResponse>;
  update(taskId: string, task: ICreatedTask): Promise<void>;
}

const BASE_ENDPOINT = '/tasks';

export const tasksService = ($axios: LkAxiosInstance): ITasksService => ({
  create(task) {
    return $axios.$post(BASE_ENDPOINT, task);
  },
  getAll(queryParams) {
    return $axios.$get(`${BASE_ENDPOINT}?${queryParams}`);
  },
  getById(taskId) {
    return $axios.$get(`${BASE_ENDPOINT}/${taskId}`);
  },
  postpone(taskId, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${taskId}/postpone`, payload);
  },
  revive(taskId) {
    return $axios.$post(`${BASE_ENDPOINT}/${taskId}/revive`);
  },
  setAgent(taskId, agentId) {
    return $axios.$post(`${BASE_ENDPOINT}/${taskId}/set_assigned_agent`, { id: agentId });
  },
  terminate(taskId, closureReason) {
    return $axios.$post(`${BASE_ENDPOINT}/${taskId}/terminate`, { closureReason });
  },
  update(taskId, task) {
    return $axios.$put(`${BASE_ENDPOINT}/${taskId}`, task);
  },
});