const TasksIndex = () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/index.vue');
const TaskDetail = () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/id/index.vue');

import { createPersonRoute } from './person';

export default [
  {
    children: [
      createPersonRoute('tasks'),
      {
        component: TaskDetail,
        name: 'task',
        path: ':taskId',
      },
    ],
    component: TasksIndex,
    name: 'tasks',
    path: '/taches',
  },
];