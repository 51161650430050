























import { Component, Prop, Mixins } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { Dictionary } from 'vue-router/types/router';
import i18n from '@/plugins/i18n';

import { AppointmentEntityModel, EventEntityModel, PropertyEntityModel } from './models';
import { EHttpErrorName } from '@/enums/HttpErrorName.enum';
import { EPlanningRoute } from '@/router/dome/planning';
import { getISODate } from '@/utils/date';
import { IOption } from '@/interfaces/Option.interface';
import { IStep } from '@/components/common/molecules/stepper/StepperWithStatus.interface';
import { Property } from '@/models';
import { removeEmptyProperties } from '@/utils/objectHelpers';

import DefineAppointment from './steps/DefineAppointment.vue';
import DefinePlace from './steps/DefinePlace.vue';
import EntityCreationWizard from './wizard.vue';
import Event from './steps/Event.vue';
import EventHelpers from './helpers/event.vue';
import PropertyHelpers from './helpers/property.vue';
import Wizard from '@/components/common/templates/wizard/Wizard.vue';

const titles = [
  'estimation_report',
  'diagnostics',
  'photos',
  'sale_agreement_signature',
  'sale_property_act_signature',
  'agency_appointment',
  'renovations_estimation',
].map(key => i18n.t(`entity_creation.steps.define-appointment.titles.${key}`));

@Component({
  components: {
    DefineAppointment,
    DefinePlace,
    Event,
    Wizard,
  },
})
export default class AppointmentEntity extends Mixins(EntityCreationWizard, EventHelpers, PropertyHelpers) {
  @Prop({ type: Object }) readonly redirection!: { name: string, params: Dictionary<string> };
  @Prop({ required: true, type: Object }) suggestionList!: Record<'agency' | 'person' | 'property', IOption<string>[]>;

  isSubmitting = false;

  get appointmentSuggestionList(): Record<'address' | 'title', IOption<string>[]> {
    const address = [ ...this.suggestionList.agency ];

    if (this.selectedProperty) {
      address.push({ label: this.selectedProperty.fullAddress, value: this.selectedProperty.fullAddress });
    }

    return { address, title: titles.map(value => ({ label: value, value })) };
  }

  get selectedProperty(): Property | null {
    if (this.selectedEntities.property) {
      return Property.query()
        .whereId(this.selectedEntities.property)
        .first();
    }

    return null;
  }

  get stepList(): string[] {
    return ['define-appointment', 'define-place', 'event'];
  }

  get steps(): IStep[] {
    return this.stepList.map(step => {
      let name = step;

      if (step === 'event') {
        name = 'meeting';
      }

      return {
        hasError: false,
        isFilled: false,
        isOptional: step === 'define-place',
        name: this.$t(`entity_creation.steps.${name}.title`),
        value: step,
      };
    });
  }

  created(): void {
    this.setData();
  }

  async createEntity(): Promise<void> {
    this.isSubmitting = true;
    let appointmentId!: string | undefined;

    if (!this.isEntityValid(this.stepList)) {
      this._handleError('invalid data', this.errorStepIndex);

      return;
    }

    // Appointment
    try {
      const appointment = {
        ...removeEmptyProperties(this.entities.appointment),
        endTime: getISODate(this.entities.event!.date, this.entities.event!.endTime),
        startTime: getISODate(this.entities.event!.date, this.entities.event!.startTime),
      };

      if (this.selectedEntities.property?.length) {
        appointment['propertyId'] = this.selectedEntities.property;
      }

      const appointmentResponse = await this.$api.appointments.add(appointment);
      appointmentId = appointmentResponse.id;

      if (!appointmentId) {
        this._handleError('empty', this.stepList.indexOf('event'));

        return;
      }
    } catch (error) {
      this._handleError(error, this.stepList.indexOf('event'), true);

      return;
    }

    await this.closeWizard();

    if (this.redirection) {
      // Redirect to planning
      this.$emit('entity-created');

      try {
        await this.$router.push({
          hash: undefined,
          name: this.redirection.name,
          params: { ...this.redirection.params, eventId: appointmentId },
        });
      } catch (error) {
        if (error instanceof AxiosError && error.name !== EHttpErrorName.NavigationDuplicated) {
          console.error(error);
        }
      }
    } else {
      // Redirect to planning
      try {
        await this.$router.push({
          hash: undefined,
          name: EPlanningRoute.Appointment,
          params: { eventId: appointmentId },
        });
      } catch (error) {
        if (error instanceof AxiosError && error.name !== EHttpErrorName.NavigationDuplicated) {
          console.error(error);
        }
      }
    }
  }

  setData(): void {
    this.entities = {
      appointment: new AppointmentEntityModel(),
      event: new EventEntityModel(),
      property: new PropertyEntityModel(),
    };

    this.selectedEntities = { property: undefined };
  }

  updateEntity(name: string, key: string, value): void {
    this.entities[name][key] = value;

    if (key === 'title' && this.errorMessages[name][key]) {
      delete this.errorMessages[name][key];
    }
  }
}
