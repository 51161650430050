const EstimationIndex = () => import(/* webpackChunkName: "estimation" */ '@/views/properties/id/estimation/index.vue');
// eslint-disable-next-line vue/max-len
const EstimationHistory = () => import(/* webpackChunkName: "estimation" */ '@/views/properties/id/estimation/panels/history.vue');

export default [
  {
    children: [
      {
        component: EstimationHistory,
        name: 'estimation_history',
        path: 'historique',
      },
    ],
    component: EstimationIndex,
    name: 'estimation',
    path: '/biens/:propertyId/estimation',
  },
];