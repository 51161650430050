





















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FormLabel extends Vue {
  @Prop({ default: false, type: Boolean }) readonly disabled!: boolean;
  @Prop({ default: '', type: String }) readonly id!: string;
  @Prop({ default: '', type: String }) readonly hint!: string;
  @Prop({ default: false, type: Boolean }) readonly isOnBlueBg!: boolean;
  @Prop({ default: false, type: Boolean }) readonly required!: boolean;
  @Prop({ default: 'label', type: String }) readonly tag!: string;
  @Prop({ default: '', required: true, type: String }) readonly text!: string;
  @Prop({ default: false, type: Boolean }) readonly unselectable!: boolean;
}
