export enum EHeaterModeType {
  Aerothermy = 'aerothermy',
  Aquathermal = 'aquathermal',
  Biofuel = 'biofuel',
  Biomass = 'biomass',
  Coal = 'coal',
  Electric = 'electric',
  FuelOil = 'fuel_oil',
  Gas = 'gas',
  Geothermal = 'geothermal',
  HeatPump = 'heat_pump',
  ReversibleAirConditioning = 'reversible_air_conditioning',
  Solar = 'solar',
  WindTurbine = 'wind_turbine',
  Wood = 'wood',
  WoodPellet = 'wood_pellet',
}
