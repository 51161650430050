


























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PersonItem extends Vue {
  @Prop({ default: false, type: Boolean }) active!: boolean;
  @Prop({ type: String }) dataTestId?: string;
}
