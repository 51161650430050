import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { ENodeEnv } from '@/enums/NodeEnv.enum';

if (process.env.NODE_ENV !== ENodeEnv.Development) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    initialScope: (scope) => {
      if (process.env.VUE_APP_COMMIT_HASH) {
        scope.setTag('commit-hash', process.env.VUE_APP_COMMIT_HASH);
      }

      return scope;
    },
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [(process.env.VUE_APP_API_URL as string)],
      }),
    ],
    tracesSampleRate: Number(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE),
  });
}
