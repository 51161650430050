
































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { RootState } from '@/store';
import { State } from 'vuex-class';

import { ENodeEnv } from '@/enums/NodeEnv.enum';
import { IItem, IItemWithAction } from '../../molecules/menu/DropdownList.interface';
import { INavItem, INavItemWithAction } from '../../molecules/navigation/NavItem.interface';

import DomeLogo from '@/assets/logos/dome.svg';
import DropdownList from '@/components/common/molecules/menu/DropdownList.vue';
import NavItem from '@/components/common/molecules/navigation/NavItem.vue';

@Component({
  components: {
    DomeLogo,
    DropdownList,
    NavItem,
  },
})
export default class Navigation extends Vue {
  @Prop({ required: true, type: Array }) accountEntities!: IItemWithAction[];
  @Prop({ required: true, type: Array }) bottomBarRoutes!: INavItem[];
  @Prop({ required: true, type: Array }) creationEntities!: IItem[];
  @Prop({ default: false, type: Boolean }) isAccountDropdownVisible!: boolean;
  @Prop({ default: false, type: Boolean }) isBo!: boolean;
  @Prop({ default: false, type: Boolean }) isDrawerOpen!: boolean;
  @Prop({ default: false, type: Boolean }) isSupportDropdownVisible!: boolean;
  @Prop({ required: true, type: Array }) primaryRoutes!: INavItem[];
  @Prop({ required: true, type: Array }) secondaryRoutes!: INavItemWithAction[];

  @State((state: RootState) => state.breakpoints.sm) isBreakpointSm!: boolean;

  isEntityDropdownVisible = false;

  @Watch('isBreakpointSm', { immediate: true })
  handleBreakpointChange(value: boolean) {
    if (value && this.isDrawerOpen) {
      this.$emit('close-drawer');
    }
  }

  get env() {
    return !process.env.VUE_APP_ENV || process.env.VUE_APP_ENV === ENodeEnv.Production ? null : process.env.VUE_APP_ENV;
  }

  get routes(): INavItem[] {
    return this.isBreakpointSm
      ? this.primaryRoutes
      : this.primaryRoutes.filter(({ id }) => !this.bottomBarRoutes.find(route => route.id === id));
  }

  handleAccountEntityClick(value: string): void {
    this.accountEntities.find(item => item.value === value)?.action();
    this.$emit('toggle-account-dropdown', false);
  }

  middleware(event: { path: HTMLElement[] }): boolean {
    return !event.path?.some(element => element.classList && element.classList.contains('icon-plus'));
  }

  updateEntityType(entity: string): void {
    this.$emit('update-entity-type', entity);
    this.isEntityDropdownVisible = false;
  }
}
