

























import { Vue, Component, Prop } from 'vue-property-decorator';

import { IAgency } from '@/interfaces/bo/models/Agency.interface';
import { IEntities } from '@/interfaces/entity/Entities.interface';
import { IErrorMessages } from '@/interfaces/ErrorMessages.interface';
import { IResultItem } from '@/interfaces/ResultItem.interface';

@Component
export default class SelectAgencyStep extends Vue {
  @Prop({ required: true, type: Object }) entities!: IEntities;
  @Prop({ default: () => ({}), type: Object }) errorMessages!: IErrorMessages;
  @Prop({ default: false, type: Boolean }) isEditMode!: boolean;

  agencies: IAgency[] = [];
  search = '';

  get agencyId(): string | undefined {
    return this.entities.agent?.agencyId;
  }

  get results(): IResultItem[] {
    return this.agencies.map(agency => ({
      ...agency,
      content: `<span class="mr-2 group-hover:text-lk-blue">${agency.name}</span>`,
    }));
  }

  get selectedAgency(): IAgency | undefined {
    return this.agencies.find(({ id }) => id === this.agencyId);
  }

  async created(): Promise<void> {
    if (!this.agencyId) {
      return;
    }

    try {
      const agency = await this.$boApi.agencies.getAgency(this.agencyId);
      this.agencies.push(agency);
    } catch (error) {
      this.$snackbar.error(this.$t('bo.errors.default'));
    }
  }

  clearValue(): void {
    this.updateAgencyId(null);
    this.search = '';
  }

  updateAgencyId(value: string | null): void {
    this.$emit('update-entity', 'agent', 'agencyId', value);
  }

  async updateSearch(value: string): Promise<void> {
    this.search = value;

    try {
      const { agencies } = await this.$boApi.agencies.search({ q: value });
      this.agencies = agencies;
    } catch (error) {
      this.$snackbar.error(this.$t('bo.errors.default'));
    }
  }
}
