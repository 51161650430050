
import { Component, Vue } from 'vue-property-decorator';

import { EDialogType } from '@/enums/DialogType.enum';
import { EEventType } from '@/enums/EventType.enum';
import { Email } from '@/api/dome/emails/Emails.interface';
import { IManualEmailContact } from '@/interfaces/ManualEmailContact.interface';
import { parseError } from '@/utils/validation';
import { submitEmails } from '@/utils/events';

@Component
export default class DialogsHelpers extends Vue {
  contactsManualEmail: IManualEmailContact[] = [];
  email: Email | null = null;
  EDialogType = Object.freeze(EDialogType);
  isManualEmailMeetingCreationVisible = false;
  isManualEmailVisitCreationVisible = false;

  closeManualEmailDialogs(): void {
    this.isManualEmailMeetingCreationVisible = false;
    this.isManualEmailVisitCreationVisible = false;
    this.contactsManualEmail.length = 0;
  }

  async handleSubmitEmails(contacts: IManualEmailContact[]): Promise<void> {
    await submitEmails(this.email, contacts);
    this.closeManualEmailDialogs();
  }

  async generateEmail(eventId: string, eventType: EEventType): Promise<Email | null> {
    return this.$api.emails.generate(eventId, eventType).catch(error => {
      this.$snackbar.error(parseError(this, error));

      return null;
    });
  }
}
