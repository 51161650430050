import { axiosConfig, LkAxiosInstance } from '@/api';
import { getProgress } from '@/utils/download';
import { IMandateAnnex, IMandateAnnexesResponse, IMandateAnnexResponse, IMandateAnnexUploadRequest }
  from '@/api/dome/mandates/MandateAnnexes.interface';

const BASE_ENDPOINT = '/mandate_annexes';

export interface IMandateAnnexesService {
  delete(annexId: string): Promise<void>;
  getByMandateDocumentId(mandateDocumentId: string): Promise<IMandateAnnex[]>;
  upload({ formData, cancelToken, progressCallback }: IMandateAnnexUploadRequest): Promise<IMandateAnnex>;
}

export const mandateAnnexesService = ($axios: LkAxiosInstance): IMandateAnnexesService => ({
  delete(annexId) {
    return $axios.$delete(`${BASE_ENDPOINT}/${annexId}`);
  },
  async getByMandateDocumentId(mandateDocumentId) {
    const { mandateAnnexes } =
      await $axios.$get<IMandateAnnexesResponse>(`${BASE_ENDPOINT}?mandate_document_id=${mandateDocumentId}`);

    return mandateAnnexes;
  },
  async upload({ formData, cancelToken, progressCallback }) {
    const { mandateAnnex } = await $axios.$post<IMandateAnnexResponse>(BASE_ENDPOINT, formData, {
      ...axiosConfig.formData,
      cancelToken,
      onUploadProgress: (progressEvent) => progressCallback(getProgress(progressEvent)),
    });

    return mandateAnnex;
  },
});
