import Vue from 'vue';
import { datePatterns } from '@/utils/date';
import { EBaseInputType } from '@/components/common/molecules/forms/input/BaseInputType.enum';
import { ENodeEnv } from '@/enums/NodeEnv.enum';
import { EPreciseNumberType } from '@/enums/PreciseNumberType.enum';
import { icons, sizes } from '@/helpers/icons';

const env = process.env.VUE_APP_ENV;

Vue.use({
  install(VueInstance) {
    VueInstance.prototype.$env = {
      isProduction: Boolean(env) && env === ENodeEnv.Production,
    };

    VueInstance.prototype.$datePatterns = datePatterns;
    VueInstance.prototype.$icons = icons;
    VueInstance.prototype.$inputType = EBaseInputType;
    VueInstance.prototype.$preciseNumber = EPreciseNumberType;
    VueInstance.prototype.$sizes = sizes;
  },
});