


































































































































import { Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { EContactRole } from '@/enums/ContactRole.enum';
import { getPrimaryContacts } from '@/helpers/contacts';
import { IDomeSettings } from '@/api/dome/agents/Agents.interface';
import { IOption } from '@/interfaces/Option.interface';
import { IPerson } from '@/api/dome/persons/Persons.interface';
import { PersonEntityModel } from '../models';
import { sourceList } from '@/helpers/sources';

import PersonStepHelper from './helpers/person-step-helper.vue';

interface IErrorMessages {
  person: {
    email?: string;
    firstName?: string;
    lastName?: string;
    manualSource?: string;
    phone?: string;
  };
}

const entityKey = 'person';

@Component
export default class Person extends PersonStepHelper {
  @Prop({ type: Object }) currentPerson?: PersonEntityModel;
  @Prop({ required: true, type: Object }) entities!: { person: PersonEntityModel };
  @Prop({ default: null, type: Object }) errorMessages!: IErrorMessages;
  @Prop({ default: false, type: Boolean }) isAccountActivated!: boolean;
  @Prop({ default: false, type: Boolean }) isEmailUnique!: boolean;
  @Prop({ default: false, type: Boolean }) isLeadSourceMandatory!: boolean;
  @Prop({ default: false, type: Boolean }) isSearchTagsIgnored!: boolean;
  @Prop({ default: true, type: Boolean }) isSourceSelectDisplayed!: boolean;
  @Prop({ type: Object }) suggestionList?: Record<'person' | 'property', IOption<string>[]>;
  @Prop({ default: 'creation', type: String }) type!: string;

  @State('settings') settings!: IDomeSettings;

  readonly emailKey = 'email';
  isEmailFocused = false;
  sourceOptions = Object.freeze(sourceList.map(value => ({
    label: this.$t(`seller_leads.sources.${value}`),
    value,
  })));

  get alertMessage(): { message: string, type: string } {
    return this.isEmailUnique
      ? { message: this.$t('entity_creation.steps.person.customer_space_email_warning'), type: 'warning' }
      : { message: this.$t('errors.email_already_exists'), type: 'error' };
  }

  get emailErrorMessage(): string | null {
    return this.errorMessages.person.email || null;
  }

  get hasContactBothRoles(): boolean {
    return !!this.entities.person?.tags.includes(EContactRole.Seller)
      && this.entities.person.tags.includes(EContactRole.Buyer);
  }

  get isPersonSelected(): boolean {
    return !!this.selectedEntities?.person;
  }

  get isSelectionEnabled(): boolean {
    return this.selectedEntities?.person !== undefined;
  }

  get isSourceSelectDisabled(): boolean {
    return this.isPersonSelected || (this.type === 'edition' && !this.settings.isAgentAllowedToEditSellerSources);
  }

  get isWarningShown(): boolean {
    return this.isAccountActivated && !!this.currentPerson && this.entities.person.email !== this.currentPerson.email;
  }

  get selectedPersonEmail(): string | null {
    return getPrimaryContacts(this.selectedPerson?.personContacts).email;
  }

  created(): void {
    if (this.entities.person.email) {
      this.handleEmailBlur();
    }
  }

  clearPerson(): void {
    this.clearPersonValue();

    if (this.entities.person.email) {
      this.handleEmailBlur();
    }
  }

  handleEmailBlur(): void {
    this.isEmailFocused = false;
    this.validateEntityKey(this.emailKey);
  }

  handleEmailFocus(): void {
    this.isEmailFocused = true;
    this.removeErrorMessage(this.emailKey);
  }

  removeErrorMessage(key: string): void {
    this.$emit('remove-error', entityKey, key);
  }

  selectPersonResult(person: IPerson): void {
    this.removeErrorMessage(this.emailKey);
    this.selectPerson(person);
  }

  updateEntity(key: string, value: string): void {
    this.$emit('update-entity', entityKey, key, value);
  }

  updatePersonSearch(value: string): void {
    this.searchPerson(value, this.isSearchTagsIgnored ? undefined : this.entities.person.tags);
  }

  validateEntityKey(key: string): void {
    this.$emit('validate-entity-key', entityKey, key);
  }
}
