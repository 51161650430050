import { axiosConfig, LkAxiosInstance } from '@/api';
import { createBlobUrl } from '@/utils/blobHelper';
import { EDocumentType } from '@/enums/DocumentType.enum';
import { getProgress } from '@/utils/download';
import { IDocument, IDocumentsList, IDocumentUploadRequest } from './PropertyDocuments.interface';
import { ILink } from '@/api/dome/links/Links.interface';
import { toQueryString } from '@/utils/url';

export interface IPropertyDocumentsService {
  download(id: string): Promise<Blob>;
  getAll(propertyId: string): Promise<IDocumentsList>;
  getByType(propertyId: string, documentType: EDocumentType): Promise<IDocument[]>;
  previewPdf(id: string): Promise<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request(link: ILink): Promise<any>;
  upload(request: IDocumentUploadRequest): Promise<IDocument>;
}

const BASE_ENDPOINT = '/property_documents';

export const propertyDocumentsService = ($axios: LkAxiosInstance): IPropertyDocumentsService => ({
  download(id) {
    return $axios.$get(`${BASE_ENDPOINT}/${id}?render=attachment`, axiosConfig.blob);
  },
  getAll(propertyId) {
    return $axios.$get<IDocumentsList>(`${BASE_ENDPOINT}?property_id=${propertyId}`);
  },
  getByType(propertyId, documentType) {
    const query = toQueryString({ document_type: documentType, property_id: propertyId });

    return $axios.$get<IDocument[]>(`${BASE_ENDPOINT}_by_type?${query}`);
  },
  async previewPdf(id) {
    const document = await $axios.$get<Blob>(`${BASE_ENDPOINT}/${id}`, axiosConfig.blob);

    return createBlobUrl(document);
  },
  request(link) {
    return $axios.request({ ...link, url: link.href });
  },
  upload({ formData, progressCallback, signal }) {
    return $axios.$post(BASE_ENDPOINT, formData, {
      ...axiosConfig.formData,
      onUploadProgress: (progressEvent) => progressCallback(getProgress(progressEvent)),
      signal,
    });
  },
});
