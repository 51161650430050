

































































import { Component, Prop } from 'vue-property-decorator';
import { IOption } from '@/interfaces/Option.interface';

import FieldComponent from '@/components/common/molecules/forms/FieldComponent.vue';

let radioRateId = 0;

@Component
export default class RadioRate extends FieldComponent<string | number | boolean> {
  @Prop({ required: true, type: Array }) options!: IOption[];
  @Prop({ default: true, type: Boolean }) nullable!: boolean;
  @Prop({ default: false, type: Boolean }) showLabels!: boolean;

  fieldValue = this.value;
  id = `radio-${radioRateId++}`;

  get valueIndex(): number {
    return this.options.findIndex(option => option.value === this.fieldValue);
  }

  onChange(value: string | number | boolean) {
    this.fieldValue = value;
    this.$emit('change', this.fieldValue);
  }

  onClick(value: string | number | boolean, event: MouseEvent) {
    if (this.nullable && value === this.fieldValue) {
      this.fieldValue = '';
      event.preventDefault();
      this.$emit('change');
    }
  }

  statusClass(index: number) {
    if (index < this.valueIndex) {
      return 'active';
    } else if (index === this.valueIndex) {
      return 'filled';
    }

    return 'empty';

  }
}
