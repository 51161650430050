










































import { Component, Prop } from 'vue-property-decorator';
import { IOption } from '@/interfaces/Option.interface';

import PropertyStepHelper from './helpers/property-step-helper.vue';
import DurationInput from '@/components/common/molecules/forms/duration-input/DurationInput.vue';

@Component({
  components: {
    DurationInput,
  },
})
export default class DefineAppointmentStep extends PropertyStepHelper {
  @Prop({ required: true, type: Object }) suggestionList!: Record<'address' | 'title', IOption<string>[]>;

  removeErrorMessage(key: string): void {
    this.$emit('remove-error', 'appointment', key);
  }

  updateEntity(key: string, value): void {
    this.$emit('update-entity', 'appointment', key, value);
  }

  validateEntityKey(key: string): void {
    this.$emit('validate-entity-key', 'appointment', key);
  }
}
