var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeSelect),expression:"closeSelect"}],staticClass:"relative mb-8 flex flex-col"},[(_vm.label)?_c('FormLabel',_vm._b({class:_vm.labelClass,attrs:{"text":_vm.label,"unselectable":_vm.disabled || _vm.readonly},nativeOn:{"click":function($event){return _vm.toggleSelect.apply(null, arguments)}}},'FormLabel',{ id: _vm.id, disabled: _vm.disabled, focus: _vm.focus, required: _vm.required },false)):_vm._e(),_c('div',{staticClass:"select relative",class:{ 'cursor-pointer': !_vm.disabled }},[_c('div',{staticClass:"flex h-10 w-full items-center justify-between gap-2 rounded-md border border-lk-gray-light px-4 py-2 outline-none",class:[{
        'rounded-b-none': _vm.focus && !_vm.isTopOrientation,
        'rounded-t-none': _vm.focus && _vm.isTopOrientation,
        'error': _vm.errorMessage,
      }, _vm.disabled || _vm.readonly ? 'cursor-default bg-lk-gray-lighter' : 'bg-white'],attrs:{"data-test-id":(_vm.dataTestId + "-label"),"tabindex":"0"},on:{"click":_vm.toggleSelect}},[(_vm.value === null || _vm.value === '')?_c('p',{staticClass:"truncate text-lk-gray"},[_vm._v(" "+_vm._s(_vm.placeholder || _vm.$t('base.placeholder.select'))+" ")]):_c('p',{class:{ 'text-lk-gray': _vm.disabled }},[_vm._t("selectedOption",function(){return [_vm._v(" "+_vm._s(_vm.currentOption ? _vm.currentOption.label : _vm.$t('base.placeholder.select'))+" ")]},{"option":_vm.currentOption})],2),_c('span',{staticClass:"flex-none duration-200 ease-out",class:_vm.isOpen ? 'rotate-0': 'rotate-180'},[_c('BaseIcon',{staticClass:"text-lk-gray",attrs:{"icon":_vm.$icons.chevron_down,"size":_vm.$sizes.sm}})],1)]),_c('Transition',{attrs:{"name":"show-list"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.focus),expression:"focus"}],ref:"optionsList",staticClass:"absolute z-30 max-h-48 w-full overflow-y-auto rounded-md border border-lk-gray-light bg-white py-3",class:{
          'bottom-full rounded-b-none border-b-0': _vm.isTopOrientation,
          'rounded-t-none border-t-0': !_vm.isTopOrientation,
        }},[(_vm.nullable && !(_vm.value === null || _vm.value === ''))?_c('li',{staticClass:"px-4 py-3 leading-6 text-lk-gray outline-none hover:bg-lk-blue-light-hover hover:text-lk-blue-hover focus:bg-lk-blue-selection",attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.onSelect(-1)}}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('base.placeholder.select'))+" ")])]):_vm._e(),_vm._l((_vm.options),function(option,index){return _c('li',{key:("option-" + index),staticClass:"outline-none",attrs:{"data-test-id":(_vm.dataTestId + "-value-" + (option.value)),"tabindex":option.value === '' ? false : 0},on:{"click":function($event){return _vm.onSelect(index)}}},[_c('div',{staticClass:"group px-4 py-3 leading-6 hover:bg-lk-blue-light-hover hover:text-lk-blue focus:bg-lk-blue-selection",class:{
              'active': option.value === _vm.value,
              'disabled': option.value === '',
              'mb-3': option.hasSeparator,
            }},[_vm._t("option",function(){return [_c('p',[_vm._v(_vm._s(option.label))])]},{"option":option})],2),(option.hasSeparator)?_c('div',{staticClass:"mb-3 border-b border-lk-gray-light"}):_vm._e()])})],2)])],1),(_vm.errorMessage)?_c('p',{staticClass:"error-message absolute top-full mt-1 whitespace-nowrap text-xs text-lk-red"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):(_vm.showPreviousValue)?_c('p',{staticClass:"absolute top-full mt-1 whitespace-nowrap italic text-lk-dark-gray"},[_vm._v(" "+_vm._s(_vm.previousValueText)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }