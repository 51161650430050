import { LkAxiosInstance } from '@/api';
import {
  IAddAppointmentPayload,
  IAddAppointmentResponse,
} from '@/api/dome/appointments/Appointments.interface';

const BASE_ENDPOINT = '/appointments';

export interface IAppointmentsService {
  add(params: IAddAppointmentPayload): Promise<IAddAppointmentResponse>;
  cancel(id: string): Promise<void>;
}

export const appointmentsService = ($axios: LkAxiosInstance): IAppointmentsService => ({
  add(params) {
    return $axios.$post(BASE_ENDPOINT, params);
  },
  cancel(id) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/cancel`);
  },
});
