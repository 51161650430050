import { LkAxiosInstance } from '@/api';
import { IPersonContact, PersonContactModel } from '@/api/dome/person-contacts/PersonContacts.interface';
import { EPersonContactType } from '@/enums/PersonContactType.enum';
import { getPhoneNumber } from '@/utils/phone';

const getFormattedValue = (contact: PersonContactModel | IPersonContact) =>
  contact.type === EPersonContactType.Phone ? getPhoneNumber(contact.value) : contact.value;

export interface IPersonContactsService {
  create(contact: PersonContactModel): Promise<IPersonContact>;
  delete(id: string): Promise<void>;
  edit(contact: IPersonContact): Promise<IPersonContact>;
}

const BASE_ENDPOINT = '/person_contacts';

export const personContactsService = ($axios: LkAxiosInstance): IPersonContactsService => ({
  create(contact) {
    const value = getFormattedValue(contact);

    if (value) {
      return $axios.$post(BASE_ENDPOINT, { personContact: { ...contact, value } });
    }

    throw { message: 'person-contact-invalid-value-phone', type: 'bad-payload' };
  },
  delete(id) {
    return $axios.$delete(`${BASE_ENDPOINT}/${id}`);
  },
  async edit(contact) {
    const value = getFormattedValue(contact);

    if (value) {
      return $axios.$post(`${BASE_ENDPOINT}/${contact.id}`, { personContact: { ...contact, value } });
    }

    throw { message: 'person-contact-invalid-value-phone', type: 'bad-payload' };
  },
});
