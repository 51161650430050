const LobbyLogin = () => import(/* webpackChunkName: "lobby" */ '@/views/lobby/log-in.vue');
const LobbyResetPassword = () => import(/* webpackChunkName: "lobby" */ '@/views/lobby/reset-password.vue');

const meta = { isAuthenticationRequired: false, isLobby: true };

export default [
  {
    component: LobbyLogin,
    meta,
    name: 'log_in',
    path: '/connexion',
  },
  {
    component: LobbyResetPassword,
    meta,
    name: 'reset_password',
    path: '/nouveau-mot-de-passe',
  },
];