




















































import { Component, Prop, Vue } from 'vue-property-decorator';

import { IStep } from './StepperWithStatus.interface';

@Component
export default class StepperWithStatus extends Vue {
  @Prop({ default: false, type: Boolean }) disabled!: boolean;
  @Prop({ required: true, type: Number }) stepIndex!: number;
  @Prop({ required: true, type: Array }) steps!: IStep[];

  get height(): string {
    const height = (this.stepIndex * 100) / (this.steps.length - 1);

    return `calc(${height}% - ${height / 100} * 3.5rem)`;
  }
}
