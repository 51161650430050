










































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { IItem } from './DropdownList.interface';

@Component
export default class DropdownList extends Vue {
  @Prop({ default: false, type: Boolean }) hasContentBolded?: boolean;
  @Prop({ required: true, type: Array }) items!: IItem[];
  @Prop({ default: '', type: String }) title!: string;

  get distributedItems() {
    return this.items.reduce<{ left: IItem[], right: IItem[] }>((acc, item) => {
      acc[item.isLeftItem ? 'left' : 'right'].push(item);

      return acc;
    }, { left: [], right: [] });
  }
}
