













































import { Component, Vue, Prop } from 'vue-property-decorator';

import { EContactRole } from '@/enums/ContactRole.enum';
import { EDialogActionType } from '@/enums/DialogActionType.enum';
import { IListFormItem } from '@/components/common/molecules/items-list-form/ItemsListForm.interface';
import { INegotiationRecipient } from '@/api/dome/negotiationRecipients/NegotiationRecipients.interface';
import { IRecipientsBuyers } from '@/interfaces/recipients/Buyers.interface';
import { IRecipientsBuyersErrors } from './RecipientsBuyer.interface';

import ItemsListForm from '@/components/common/molecules/items-list-form/ItemsListForm.vue';
import RecipientsBuyersDialog from '@/components/common/organisms/recipients/buyers/RecipientsBuyersDialog.vue';

@Component({
  components: {
    ItemsListForm,
    RecipientsBuyersDialog,
  },
})
export default class RecipientsBuyers extends Vue {
  @Prop({ required: true, type: Object }) buyers!: IRecipientsBuyers;
  @Prop({ default: () => ({}), type: Object }) recipientsBuyersErrors!: IRecipientsBuyersErrors;
  @Prop({ default: '', type: String }) title!: string;

  isDialogDisplayed = false;
  currentRecipient = {} as INegotiationRecipient;
  currentRecipientIndex: number | null = null;
  emptyRecipient: INegotiationRecipient = {
    email: '',
    esignStatus: null,
    firstName: '',
    isProxy: false,
    lastName: '',
    personId: null,
    phoneNumber: '',
    proxyName: '',
    type: EContactRole.Buyer,
  };
  recipientsBuyers: IRecipientsBuyers = {} as IRecipientsBuyers;

  get dialogType(): string {
    return this.currentRecipientIndex === null ? EDialogActionType.Add : EDialogActionType.Edit;
  }

  get recipientItems(): IListFormItem[] {
    return this.buyers.recipients.map((recipientBuyer: INegotiationRecipient, index: number) => ({
      icon: this.$icons.contact_checked,
      id: index.toString(),
      isProtected: recipientBuyer.personId !== null,
      text: this.$t('recipients-buyers.form.completed'),
      title: `${recipientBuyer.firstName} ${recipientBuyer.lastName}`,
    }));
  }

  created(): void {
    this.recipientsBuyers = {
      buyingCompanyName: this.buyers.buyingCompanyName,
      isCompanyBuying: this.buyers.isCompanyBuying,
      recipients: this.buyers.recipients,
    };
  }

  beforeDestroy(): void {
    if (!this.recipientsBuyers.isCompanyBuying && this.recipientsBuyers.buyingCompanyName) {
      this.updateBuyingCompanyName('');
    }
  }

  closeRecipientDialog(): void {
    this.isDialogDisplayed = false;
  }

  deleteRecipient(id: string): void {
    const index = parseInt(id);
    const recipients = [...this.recipientsBuyers.recipients];
    recipients.splice(index, 1);

    this.updateRecipients(recipients);
  }

  handleRecipient(recipient: INegotiationRecipient): void {
    const recipients = [...this.recipientsBuyers.recipients];

    if (this.currentRecipientIndex !== null) {
      recipients[this.currentRecipientIndex] = recipient;
    } else {
      recipients.push(recipient);
    }

    this.updateRecipients(recipients);

    this.closeRecipientDialog();
  }

  openRecipientDialog(id: string | null = null): void {
    if (id === null) {
      this.currentRecipient = { ...this.emptyRecipient };
      this.currentRecipientIndex = null;
    } else {
      const index = parseInt(id);
      this.currentRecipient = { ...this.recipientsBuyers.recipients[index] };
      this.currentRecipientIndex = index;
    }

    this.isDialogDisplayed = true;
  }

  removeErrorMessage(key: string): void {
    this.$emit('remove-error', key);
  }

  updateBuyingCompanyName(value: string): void {
    this.recipientsBuyers.buyingCompanyName = value;
    this.$emit('update', this.recipientsBuyers);
  }

  updateIsCompanyBuying(value: boolean): void {
    this.recipientsBuyers.isCompanyBuying = value;

    if (!value) {
      this.removeErrorMessage('recipientsBuyers.buyingCompanyName');
    }

    this.$emit('update', this.recipientsBuyers);
  }

  updateRecipients(recipients: INegotiationRecipient[]): void {
    this.recipientsBuyers.recipients = recipients;
    this.$emit('update', this.recipientsBuyers);
  }

  validateEntityProperty(key: string): void {
    this.$emit('validate-entity-property', key);
  }
}
