/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { AnySchema, object, ValidationError } from 'yup';

import { FormControl } from './form-control';
import { IControl } from './control';
import { Validator, ValueChangesFn } from '.';

type ControlDescription<T = unknown> = [T] | [T, Validator];
type ControlsDescriptions<T> = {[K in Key<T>]: ControlDescription<T[K]> };
type Controls<T> = {[K in Key<T>]: FormControl<T[K]> };
type Key<T> = keyof T;

export interface IControlConfig {
  validateOnSetValue?: boolean;
}

export class FormGroup<T = any> implements IControl<T> {
  private readonly controls: Controls<T>;
  private schema: AnySchema;
  private validators: Record<string, Validator> = {};
  private valueChangesFn?: ValueChangesFn<T>;

  get errors(): Record<keyof T, string[]> {
    return this.keys().reduce((acc, key) => {
      if (this.controls[key].hasError) {
        acc[key] = this.controls[key].errors;
      }

      return acc;
    }, {} as Record<keyof T, string[]>);
  }

  get isInvalid(): boolean {
    return Object.values(this.controls).some((control: FormControl) => control.isInvalid);
  }

  get value(): T {
    const formGroupValue = Object.entries<FormControl>(this.controls).reduce((result, [key, { value }]) => {
      result[key] = typeof value === 'string' ? value.trim() : value;

      return result;
    }, {} as T);

    return formGroupValue;
  }

  constructor(descriptions: ControlsDescriptions<T>, config?: IControlConfig) {
    const [controls, schema] = this.buildFormGroup(descriptions, config);
    this.controls = controls;
    this.schema = schema;
  }

  get<K extends Key<T>>(key: K): FormControl<T[K]> {
    return this.controls[key];
  }

  keys(): Key<T>[] {
    return Object.keys(this.controls) as Key<T>[];
  }

  reset(config?: { emit: boolean }): void {
    const emit = config?.emit !== false;

    for (const control in this.controls) {
      this.controls[control].reset({ emit: emit && !this.valueChangesFn });
    }

    if (emit) {
      this.valueChangesFn?.(this.value);
    }
  }

  resetErrors(): void {
    for (const control in this.controls) {
      this.controls[control].resetErrors();
    }
  }

  setCurrentValueAsDefault(): void {
    Object.values<FormControl>(this.controls).forEach(control => {
      control.setCurrentValueAsDefault();
    });
  }

  setValue(value: Partial<T>, config?: { emit: boolean, validate?: boolean }): void {
    const emit = config?.emit !== false;

    this.keys().forEach(key => {
      const propertyValue: T[Key<T>] | undefined = value[key];

      if (propertyValue !== undefined) {
        this.controls[key].setValue(propertyValue, { emit: emit && !this.valueChangesFn, validate: config?.validate });
      }
    });

    if (emit) {
      this.valueChangesFn?.(this.value);
    }
  }

  validate(options?: { context: Record<string, unknown> }): boolean {
    try {
      this.schema.validateSync(this.value, { abortEarly: false, ...options });

      return true;
    } catch (error) {
      if (error instanceof ValidationError) {
        this.updateErrors(error.inner);
      }

      return false;
    }
  }

  valueChanges(fn: ValueChangesFn<T>): void {
    this.valueChangesFn = fn;
  }

  private buildFormGroup(
    descriptions: ControlsDescriptions<T>,
    config: IControlConfig | undefined,
  ): [Controls<T>, AnySchema] {
    const controls: Record<string, FormControl> = {};

    Object
      .entries<ControlDescription>(descriptions)
      .forEach(([key, [defaultValue, validator]]) => {
        controls[key] = new FormControl(defaultValue, validator, config);

        controls[key].connect(key, {
          setValidator: (newKey, newValidator) => this.setValidator(newKey, newValidator),
          validator:() => this.schema,
          value: () => this.value,
          valueChangesFn: () => this.valueChangesFn?.(this.value),
        });

        if (validator) {
          this.validators[key] = validator;
        }
      });

    return [controls as Controls<T>, object(this.validators)];
  }

  private setValidator(key: string, validator: Validator): void {
    this.validators[key] = validator;
    this.schema = object(this.validators);
  }

  private updateErrors(errors: ValidationError[]): void {
    this.resetErrors();

    for (const error of errors) {
      if (!error.path) {
        continue;
      }

      const control: FormControl = this.controls[error.path];

      if (!control) {
        continue;
      }

      control.setErrors(error.errors);
    }
  }
}
