import { render, staticRenderFns } from "./BoAccount.vue?vue&type=template&id=624d5c4d&scoped=true"
import script from "./BoAccount.vue?vue&type=script&lang=ts"
export * from "./BoAccount.vue?vue&type=script&lang=ts"
import style0 from "./BoAccount.vue?vue&type=style&index=0&id=624d5c4d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "624d5c4d",
  null
  
)

export default component.exports