import { render, staticRenderFns } from "./PanelPlaceholder.vue?vue&type=template&id=81d3ddc0&scoped=true"
import script from "./PanelPlaceholder.vue?vue&type=script&lang=ts"
export * from "./PanelPlaceholder.vue?vue&type=script&lang=ts"
import style0 from "./PanelPlaceholder.vue?vue&type=style&index=0&id=81d3ddc0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81d3ddc0",
  null
  
)

export default component.exports