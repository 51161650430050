import { LkAxiosInstance } from '@/api';
import { IPreContractDates, IPreContractInformation } from './PreContract.interface';

const BASE_ENDPOINT = '/negotiation_offers';

export interface IPreContractService {
  getDates(offerId: string): Promise<IPreContractDates>;
  getInformation(offerId: string): Promise<IPreContractInformation>;
  sendTrustpilotRequestToBuyer(offerId: string): Promise<void>;
  sendTrustpilotRequestToSeller(offerId: string): Promise<void>;
  updateDates(offerId: string, dates: IPreContractDates): Promise<void>;
}

export const preContractService = ($axios: LkAxiosInstance): IPreContractService => ({
  getDates(offerId) {
    return $axios.$get(`${BASE_ENDPOINT}/${offerId}/pre_contract_dates`);
  },
  getInformation(offerId) {
    return $axios.$get(`${BASE_ENDPOINT}/${offerId}/pre_contract_view`);
  },
  sendTrustpilotRequestToBuyer(offerId) {
    return $axios.$post(`${BASE_ENDPOINT}/${offerId}/send_buyer_review_request`);
  },
  sendTrustpilotRequestToSeller(offerId) {
    return $axios.$post(`${BASE_ENDPOINT}/${offerId}/send_seller_review_request`);
  },
  updateDates(offerId, dates) {
    return $axios.$post(`${BASE_ENDPOINT}/${offerId}/pre_contract_dates`, dates);
  },
});