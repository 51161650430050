
































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { contactContextOptions, contactTypeOptions } from '@/utils/options';
import { EPersonContactContext } from '@/enums/PersonContactContext.enum';
import { EPersonContactType } from '@/enums/PersonContactType.enum';
import { FormGroup } from '@/utils/form';
import { IOption } from '@/interfaces/Option.interface';
import { PersonContactModel } from '@/api/dome/person-contacts/PersonContacts.interface';

@Component
export default class PersonContact extends Vue {
  @Prop({ required: true, type: Array }) personContacts!: FormGroup<PersonContactModel>[];

  options = Object.freeze({
    context: contactContextOptions,
    type: contactTypeOptions,
  });

  getFilteredContactContextOptions(type: EPersonContactType): IOption[] {
    return type === EPersonContactType.Email
      ? this.options.context.filter(({ value }) => value !== EPersonContactContext.Mobile)
      : this.options.context;
  }

  getInputType(type: EPersonContactType): 'email' | 'tel' {
    return type === EPersonContactType.Phone ? 'tel' : type;
  }

  updateType(contact: FormGroup<PersonContactModel>, value: EPersonContactType): void {
    const oldValue = contact.get('type').value;

    if (value !== oldValue) {
      contact.get('type').setValue(value);
      contact.get('context').setValue(EPersonContactContext.Default);
      contact.get('value').validate();
    }
  }
}
