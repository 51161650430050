
























































import { Component, Prop, Vue } from 'vue-property-decorator';

import { EConditions } from '@/enums/Conditions.enum';
import { IErrorMessages } from '@/interfaces/ErrorMessages.interface';
import { NegotiationConditions } from '@/views/entity/steps/NegotiationConditions';

import BaseInputPercent from '@/components/common/molecules/forms/input/BaseInputPercent.vue';

@Component({
  components: {
    BaseInputPercent,
  },
})
export default class Conditions extends Vue {
  @Prop({ required: true, type: Object }) entities!: { negotiationConditions: NegotiationConditions };
  @Prop({ default: null, type: Object }) errorMessages!: IErrorMessages;

  hasOtherSuspensiveConditions = false;

  created(): void {
    this.hasOtherSuspensiveConditions = this.conditions.additionalSuspensiveConditions !== '';
  }

  get conditions(): NegotiationConditions {
    return this.entities.negotiationConditions;
  }

  get suspensiveConditionsOptions(): string {
    return this.$t('entity_creation.steps.negotiation_conditions.form.options');
  }

  removeErrorMessage(key: string): void {
    this.$emit('remove-error', 'negotiationConditions', key);
  }

  toggleHasOtherSuspensiveConditions(): void {
    this.hasOtherSuspensiveConditions = !this.hasOtherSuspensiveConditions;

    if (!this.hasOtherSuspensiveConditions) {
      this.updateAdditionalSuspensiveConditions('');
    }
  }

  updateAdditionalSuspensiveConditions(value: string): void {
    this.updateConditions('additionalSuspensiveConditions', value);
  }

  updateCheckboxValue(key: EConditions): void {
    let suspensiveConditions = this.conditions.suspensiveConditions;

    if (suspensiveConditions.includes(key)) {
      suspensiveConditions = suspensiveConditions.filter(item => item !== key);
    } else {
      suspensiveConditions.push(key);
    }

    this.updateConditions('suspensiveConditions', suspensiveConditions);
  }

  updateConditions(key: string, value: string | number | null | EConditions[]): void {
    this.$emit('update-entity', 'negotiationConditions', key, value);
  }

  validateEntityKey(key: string): void {
    this.$emit('validate-entity-key', 'negotiationConditions', key);
  }
}
