var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('NavigationTop',_vm._b({on:{"open-drawer":function($event){return _vm.toggleDrawer(true)}}},'NavigationTop',{ isBackButtonVisible: _vm.isBackButtonVisible, title: _vm.title },false)),_c('Navigation',_vm._b({attrs:{"is-bo":""},on:{"close-drawer":function($event){return _vm.toggleDrawer(false)},"toggle-account-dropdown":_vm.toggleAccountDropdown,"update-entity-type":_vm.updateEntityType},scopedSlots:_vm._u([{key:"logo",fn:function(){return [_c('img',{staticClass:"m-auto w-full max-w-[4rem]",attrs:{"alt":"Back office logo","src":require("@/assets/logos/bo.png")}})]},proxy:true}])},'Navigation',{
      accountEntities: _vm.accountEntities,
      bottomBarRoutes: _vm.bottomBarRoutes,
      creationEntities: _vm.creationEntities,
      isAccountDropdownVisible: _vm.isAccountDropdownVisible,
      isDrawerOpen: _vm.isDrawerOpen,
      primaryRoutes: _vm.primaryRoutes,
      secondaryRoutes: _vm.secondaryRoutes
    },false)),_vm._l((_vm.creationEntities),function(entity){return _c(entity.value,{key:entity.value,tag:"component",attrs:{"is-wizard-visible":entity.value === _vm.entityType && _vm.isWizardVisible},on:{"close":_vm.closeEntityCreation}})}),_c('IssueReportDialog',{attrs:{"is-displayed":_vm.isIssueReportDialogVisible},on:{"close":function($event){_vm.isIssueReportDialogVisible = false}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }