import Vue from 'vue';

import { FeatureToggles } from '@/helpers/feature-toggle/feature-toggles';
import featureToggles from '@/helpers/feature-toggle/feature-toggles.json';

export const features = Vue.observable(new FeatureToggles(featureToggles));

Vue.use({
  install(VueInstance) {
    VueInstance.prototype.$features = features;
  },
});