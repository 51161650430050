var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inline-block truncate rounded-full px-4",class:[
    {
      'label--active': _vm.active,
      'label--error': _vm.isError,
      'text-xs': _vm.isSmall
    },
    _vm.secondary
      ? 'bg-lk-white border border-lk-blue-status py-0.5 text-lk-blue'
      : 'bg-lk-gray-status py-1 text-lk-dark-gray'
  ],attrs:{"data-test-id":_vm.dataTestId}},[_vm._v(" "+_vm._s(_vm.status)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }