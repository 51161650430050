import { IFunding } from '@/api/dome/negotiations/Negotiations.interface';

export class NegotiationFunding {
  constructor(
    public funding: IFunding[] = [],
    public price = 0,
    public isSellerOffer = false,
    public expiresOn = '',
  ) {}
}
