export enum EPropertyType {
  Apartment = 'apartment',
  Both = 'both',
  Building = 'building',
  CarPark = 'car_park',
  CommercialPremises = 'commercial_premises',
  Garage = 'garage',
  House = 'house',
  Land = 'land',
  Office = 'office',
}
