import { LkAxiosInstance } from '@/api';
import { ISimpleTaskAuto } from '@/interfaces/bo/models/SimpleTaskAuto.interface';
import { ITaskAuto } from '@/interfaces/bo/models/TaskAuto.interface';
import { ITasksAutoResponse } from '@/interfaces/bo/dto/tasksService/TasksAutoResponse.interface';
import { ITaskTriggersResponse } from '@/interfaces/bo/dto/tasksService/TaskTriggersResponse.interface';

const BASE_ENDPOINT_RULES = '/automatic_task_rules';
const BASE_ENDPOINT_TRIGGERS = '/automatic_task_triggers';

export interface ITasksAutoService {
  addTask(taskAuto: ISimpleTaskAuto): Promise<ITaskAuto>;
  deleteTask(taskId: string): Promise<void>;
  editTask(taskAuto: ITaskAuto): Promise<ITaskAuto>;
  getAll(agencyId: string): Promise<ITasksAutoResponse>;
  getTaskTriggers(): Promise<ITaskTriggersResponse>;
}

export const tasksAutoService = ($axios: LkAxiosInstance): ITasksAutoService => ({
  addTask(taskAuto: ISimpleTaskAuto): Promise<ITaskAuto> {
    return $axios.$post<ITaskAuto>(BASE_ENDPOINT_RULES, taskAuto);
  },
  deleteTask(taskId: string): Promise<void> {
    return $axios.$delete(`${BASE_ENDPOINT_RULES}/${taskId}`);
  },
  editTask(taskAuto: ITaskAuto): Promise<ITaskAuto> {
    return $axios.$put<ITaskAuto>(`${BASE_ENDPOINT_RULES}/${taskAuto.id}`, taskAuto);
  },
  getAll(agencyId: string): Promise<ITasksAutoResponse> {
    return $axios.$get<ITasksAutoResponse>(`${BASE_ENDPOINT_RULES}?agency_id=${agencyId}`);
  },
  getTaskTriggers(): Promise<ITaskTriggersResponse> {
    return $axios.$get<ITaskTriggersResponse>(BASE_ENDPOINT_TRIGGERS);
  },
});