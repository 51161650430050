import * as yup from 'yup';

import { FormGroup } from '@/utils/form';
import { IManualEmailContact } from '@/interfaces/ManualEmailContact.interface';
import { IPerson } from '@/api/dome/persons/Persons.interface';
import { IPersonContact, PersonContactModel } from '@/api/dome/person-contacts/PersonContacts.interface';
import { personContactSchema } from '@/utils/validation';

export const extractContactInformation = (
  { firstName, fullName, id, lastName }: IPerson, personContacts: IPersonContact[],
): Omit<IManualEmailContact, 'role'> => ({
  email: getPrimaryContacts(personContacts).email,
  id,
  name: fullName || `${firstName} ${lastName}`,
});

export const getPrimaryContacts = (personContacts?: IPersonContact[]) =>
  personContacts?.reduce((obj, { isPrimary, type, value }) => {
    if (isPrimary && type) {
      obj[type] = value;
    }

    return obj;
  }, { email: '', phone: '' }) ?? { email: '', phone: '' };

export const getPersonContactForm = (existingContact?: PersonContactModel): FormGroup<PersonContactModel> => {
  const contact = existingContact ?? new PersonContactModel();

  return new FormGroup<PersonContactModel>({
    context: [contact.context, personContactSchema.fields.context],
    id: [contact.id ?? undefined, yup.string()],
    isPrimary: [contact.isPrimary, yup.boolean()],
    personId: [contact.personId, yup.string()],
    type: [contact.type, personContactSchema.fields.type],
    value: [contact.value, personContactSchema.fields.value],
  });
};

export const getOtherPersonContacts = (personContacts?: IPersonContact[]) =>
  personContacts?.reduce<FormGroup<PersonContactModel>[]>((arr, contact) => {
    if (!contact.isPrimary) {
      arr.push(getPersonContactForm(contact));
    }

    return arr;
  }, []) ?? [];