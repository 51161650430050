















































import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import { bottomBarRoutes, primaryRoutes, routeIcons } from '@/router/bo';
import { ESessionStorageKeys } from '@/enums/Storage.enum';
import { icons } from '@/helpers/icons';
import { IItem, IItemWithAction } from '@/components/common/molecules/menu/DropdownList.interface';
import { INavItem, INavItemWithAction } from '@/components/common/molecules/navigation/NavItem.interface';

import { Agency, Agent, BoAccount } from '@/views/bo/entity/index';
import DropdownList from '@/components/common/molecules/menu/DropdownList.vue';
import Navigation from '@/components/common/organisms/navigation/Navigation.vue';
import NavigationHelper from '@/components/common/organisms/navigation/NavigationHelper.vue';
import NavigationTop from '@/components/common/organisms/navigation/NavigationTop.vue';
import IssueReportDialog from '@/components/dialogs/IssueReportDialog.vue';

const entityList = [
  { icon: icons.menu_properties, value: 'agency' },
  { icon: icons.agent, value: 'agent' },
  { icon: icons.agent, name: 'bo_account', value: 'bo-account' },
];

@Component({
  components: {
    Agency,
    Agent,
    BoAccount,
    DropdownList,
    IssueReportDialog,
    Navigation,
    NavigationTop,
  },
})
export default class BoNavigation extends NavigationHelper {
  @Action('bo/logOut') logOut!: () => void;

  creationEntities: IItem[] = entityList.map(item => ({
    ...item,
    isLeftItem: false,
    name: this.$t(`bo.entity_creation.dropdown.${item.name || item.value}`),
  }));

  get accountEntities(): IItemWithAction[] {
    const items = [
      {
        action: () => this.toggleIssueDialog(true),
        icon: this.$icons.question_outlined,
        isLeftItem: false,
        name: this.$t('support.issue_report_problem'),
        value: 'support',
      },
      {
        action: () => this.handleLogout(),
        icon: this.$icons.logout,
        isLeftItem: false,
        name: this.$t('common.log_out'),
        value: 'logout',
      },
    ];

    if (!this.$env.isProduction) {
      items.unshift({
        action: () => this.showFeaturesDialog(),
        icon: this.$icons.eye,
        isLeftItem: false,
        name: this.$t('feature_toggle.manage_dialog.title'),
        value: 'features',
      });
    }

    return items;
  }

  get bottomBarRoutes(): INavItem[] {
    return this.getFormattedRoutes(bottomBarRoutes);
  }

  get isBackButtonVisible(): boolean {
    const matches = this.$route.matched;
    const isRouteFirstLevel = matches[1]?.name && primaryRoutes.includes(matches[1].name);

    return !isRouteFirstLevel && matches.length > 0
      ? matches[matches.length - 1].path.split('/').length - 1 > 1
      : false;
  }

  get primaryRoutes(): INavItem[] {
    return this.getFormattedRoutes(primaryRoutes);
  }

  get secondaryRoutes(): INavItemWithAction[] {
    return [
      {
        action: () => this.toggleAccountDropdown(true),
        component: 'div',
        content: {
          class: 'flex h-6 w-6 items-center justify-center rounded-full bg-lk-gray-light text-xs',
          text: this.loggedInAgent?.initials ?? '',
        },
        id: 'account',
        name: this.$t('navigation.routes.account'),
        portalName: 'account-dropdown',
      },
    ];
  }

  get title(): string {
    return this.$t(`bo.navigation.routes.${this.$route.name}`);
  }

  getFormattedRoutes(routes: string[]): INavItem[] {
    return routes.map(route => ({
      component: 'router-link',
      icon: routeIcons[route],
      id: route,
      name: this.$t(`bo.navigation.routes.${route}`),
      props: { to: { name: route } },
    }));
  }

  async handleLogout(): Promise<void> {
    sessionStorage.removeItem(ESessionStorageKeys.IssueReport);
    this.logOut();
    await this.$router.push({ name: 'bo_log_in' });
  }
}
