

































import { Component, Prop } from 'vue-property-decorator';

import { IOption } from '@/interfaces/Option.interface';
import { IResultItem } from '@/interfaces/ResultItem.interface';
import { ISearchAddress } from '@/api/dome/addresses/Addresses.interface';
import { parseError } from '@/utils/validation';

import FieldComponent from '@/components/common/molecules/forms/FieldComponent.vue';

let inputId = 0;

@Component
export default class BaseAddressAutocomplete extends FieldComponent<string> {
  @Prop({ default: false, type: Boolean }) acceptSearchAsValue!: boolean;
  @Prop({ default: () => ([]), type: Array }) suggestionList!: IOption<string>[];

  addresses: IResultItem[] = [];
  fieldValue = this.value;
  id = `input-address-autocomplete-${inputId++}`;
  isLoading = false;

  blur(): void {
    this.$emit('blur');
  }

  handleBlur(): void {
    if (this.acceptSearchAsValue) {
      this.updateValue(this.fieldValue);
    } else {
      this.blur();
    }
  }

  handleClear(): void {
    this.updateValue('');
    this.fieldValue = '';
  }

  async handleSearch(value: string): Promise<void> {
    this.isLoading = true;
    this.fieldValue = value;
    const addresses = await this.searchAddresses(value);
    this.addresses = addresses.map(({ formattedAddress }) => ({ content: formattedAddress }));
    this.isLoading = false;
  }

  async searchAddresses(query: string): Promise<ISearchAddress[]> {
    try {
      return await this.$api.addresses.search({ query });
    } catch (error) {
      this.$snackbar.error(parseError(this, error));

      return [];
    }
  }

  updateValue(value: string): void {
    this.$emit('update', value);
    this.blur();
  }
}
