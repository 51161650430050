





















































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as yup from 'yup';

import { FormControl } from '@/utils/form';
import { IAgentSellerLeadsSettings, IMarketingLeadTypeSettings } from '@/api/bo/agents/Agents.interface';
import { IEntities } from '@/interfaces/entity/Entities.interface';
import { IErrorMessages } from '@/interfaces/ErrorMessages.interface';
import { IGeoLocation } from '@/interfaces/models/GeoLocation.interface';
import { IResultItem } from '@/interfaces/ResultItem.interface';
import { parseError } from '@/utils/validation';
import { SourcesSettingsErrorMessages } from '..';

interface IGeoLocationResultItem extends IGeoLocation, IResultItem {}

interface IGeoLocations {
  results: IGeoLocation[];
  search: string;
  selected: IGeoLocation[];
}

type IZones = Record<'primary' | 'secondary', IGeoLocations>;

const getFormattedResults = (zone: IGeoLocations): IGeoLocationResultItem[] => zone.results.map(location => ({
  ...location,
  content: `${location.name} (${location.zipCode})`,
  isSelected: !!zone.selected.find(({ id }) => location.id === id),
}));

@Component
export default class ManageSellerLeadsStep extends Vue {
  @Prop({ required: true, type: Object }) entities!: IEntities;
  @Prop({ default: () => ({}), type: Object }) errorMessages!: IErrorMessages;
  @Prop({ default: false, type: Boolean }) isEditMode!: boolean;
  @Prop({ default: null, type: Object }) sellerLeadsSettings!: IAgentSellerLeadsSettings | null;
  @Prop({ default: () => ([]), type: Array }) sourcesSettingsErrorMessages!: SourcesSettingsErrorMessages;

  backfillSellerLeadsDialog: { isVisible: boolean, startDate: FormControl<string> } = {
    isVisible: false,
    startDate: new FormControl('', yup.string().required()),
  };
  zones: IZones = {
    primary: {
      results: [],
      search: '',
      selected: this.sellerLeadsSettings?.primaryZones ?? [],
    },
    secondary: {
      results: [],
      search: '',
      selected: this.sellerLeadsSettings?.secondaryZones ?? [],
    },
  };

  @Watch('zones.primary.selected')
  updatePrimaryZones(value: IGeoLocation[]): void {
    this.updateZones('primaryZones', value);
  }

  @Watch('zones.secondary.selected')
  updateSecondaryZones(value: IGeoLocation[]): void {
    this.updateZones('secondaryZones', value);
  }

  get canBackfillSellerLeads(): boolean {
    return this.isEditMode && !!this.zones.primary.selected.length;
  }

  get marketingSettings(): IMarketingLeadTypeSettings {
    return this.sellerLeadsSettings?.marketingLeadTypeSettings ?? { nearZoneMaxRadius: 0, sourcesSettings: [] };
  }

  get zonesFormattedResults(): Record<keyof IZones, IGeoLocationResultItem[]> {
    return {
      primary: getFormattedResults(this.zones.primary),
      secondary: getFormattedResults(this.zones.secondary),
    };
  }

  async backfillSellerLeads(): Promise<void> {
    const agentId = this.entities.agent?.id;

    if (
      !agentId
      || !this.sellerLeadsSettings
      || !this.backfillSellerLeadsDialog.startDate.validate()
    ) {
      return;
    }

    await this.$boApi.agents.updateSellerLeadsSettings(agentId, this.sellerLeadsSettings);

    try {
      await this.$boApi.agents.backfillSellerLeads(agentId, this.backfillSellerLeadsDialog.startDate.value);
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
    } finally {
      this.closeBackfillSellerLeadsDialog();
    }
  }

  clearGeoLocations(key: keyof IZones) {
    this.zones[key].results = [];
    this.zones[key].search = '';
  }

  closeBackfillSellerLeadsDialog(): void {
    this.backfillSellerLeadsDialog.isVisible = false;
    this.backfillSellerLeadsDialog.startDate.reset();
  }

  async searchGeoLocations(key: keyof IZones, query: string): Promise<void> {
    if (!query) {
      return this.clearGeoLocations(key);
    }

    this.zones[key].search = query;

    try {
      this.zones[key].results = await this.$boApi.geoLocations.search(query, 20);
    } catch (error) {
      this.$snackbar.error(this.$t('bo.errors.default'));
    }
  }

  selectGeoLocation(key: keyof IZones, location: IGeoLocationResultItem): void {
    const index = this.zones[key].selected.findIndex(({ id }) => id === location.id);

    if (index > -1) {
      this.unselectLocation(key, index);
    } else {
      this.zones[key].selected.push(location);
    }
  }

  unselectLocation(key: keyof IZones, index: number): void {
    this.zones[key].selected.splice(index, 1);
  }

  updateEntity(key: string, value: Date | IGeoLocation[] | null): void {
    this.$emit('update-entity', 'agent', key, value);
  }

  updateEntityDate(key: string, value: string | null): void {
    const date = value ? new Date(value) : null;
    this.updateEntity(key, date);
  }

  updateZones(key: keyof IAgentSellerLeadsSettings, value: IGeoLocation[]): void {
    this.$emit('update-seller-leads-settings', key, value);
  }
}
