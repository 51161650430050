import { AxiosProgressEvent } from 'axios';

export const checkIsMobile = (): boolean =>
  // eslint-disable-next-line vue/max-len
  /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)|(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent)
;

export const downloadBlob = (blob, filename = ''): void => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  // Request "Access-Control-Expose-Headers": "Content-Disposition" from back end
  link.download = decodeURIComponent(filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const downloadUrl = (url: string, filename = ''): void => {
  const link = document.createElement('a');
  link.href = url;
  link.download = decodeURIComponent(filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const getProgress = ({ loaded, total }: AxiosProgressEvent): number => total ? loaded / total : loaded;
