






































































































import { Component, Prop } from 'vue-property-decorator';
import { IResultItem } from '@/interfaces/ResultItem.interface';
import FieldComponent from '@/components/common/molecules/forms/FieldComponent.vue';

const DEFAULT_SIZE = 10;
let inputTagId = 0;

@Component
export default class BaseInputTags extends FieldComponent<string[]> {
  @Prop({ default: 300, type: Number }) debounceDelay!: number;
  @Prop({ default: false, type: Boolean }) isTagEditable!: boolean;
  @Prop({ required: true, type: Array }) results!: IResultItem[];

  editedTagId = -1;
  focus = false;
  id = `input-tag-${inputTagId++}`;
  inputValue = '';
  previousFocusState = false;
  showResult = false;
  timeout: ReturnType<typeof setTimeout> | null = null;

  get size(): number {
    return this.inputValue ? this.inputValue.length : DEFAULT_SIZE;
  }

  beforeDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  editTag(index: number): void {
    if (!this.isTagEditable) {
      return;
    }

    this.editedTagId = index;
    const tag = this.value.find((_, id) => id === index);
    this.removeTag(index);
    (this.$refs.input as HTMLElement).focus();

    if (tag) {
      this.inputValue = tag;
    }
  }

  handleInput(value: string): void {
    this.inputValue = value;
    this.showResult = true;
    this.updateInput(value);
  }

  manageBlurLikeEvent(): void {
    if (this.previousFocusState) {
      this.previousFocusState = false;
      this.$emit('blur');
    }

    this.showResult = false;
  }

  onBlur(): void {
    this.focus = false;

    if (this.editedTagId >= 0) {
      this.updateValue(this.inputValue);
    }
  }

  onFocus(): void {
    this.focus = true;
    this.previousFocusState = true;
    this.$emit('focus');
  }

  updateInput(value: string): void {
    if (this.debounceDelay) {
      this.clearTimeout();

      this.timeout = setTimeout(() => {
        this.$emit('input', value);
      }, this.debounceDelay);
    } else {
      this.$emit('input', value);
    }
  }

  removeTag(index: number): void {
    this.$emit('remove', index);
  }

  selectResult(item): void {
    this.updateValue(item);
  }

  updateValue(value: string): void {
    if (value !== '') {
      if (this.editedTagId >= 0) {
        this.$emit('update', value, this.editedTagId);
        this.editedTagId = -1;
      } else {
        this.$emit('update', value);
      }
    }

    this.inputValue = '';
    this.showResult = false;
  }
}
