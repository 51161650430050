import { LkAxiosInstance } from '@/api';
import { INewPublicKey } from '@/interfaces/bo/models/NewPublicKey.interface';
import { IPublicKey } from '@/interfaces/bo/models/PublicKey.interface';
import { IPublicKeysResponse } from '@/interfaces/bo/dto/publicKeysService/PublicKeysResponse.interface';
import { IPublicScopeResponse } from '@/interfaces/bo/dto/publicKeysService/PublicScopeResponse.interface';

const BASE_ENDPOINT = '/client_application_configurations';
const BASE_ENDPOINT_SCOPE = '/client_application_configuration_scopes';

export interface IAgenciesPublicKeysService {
  create(publicKey: INewPublicKey): Promise<IPublicKey>;
  delete(publicKeyId: string): Promise<void>;
  getAll(agencyId: string): Promise<IPublicKey[]>;
  getScopes(): Promise<string[]>;
  update(publicKey: IPublicKey): Promise<IPublicKey>;
}

export const agenciesPublicKeysService = ($axios: LkAxiosInstance): IAgenciesPublicKeysService => ({
  create(publicKey: INewPublicKey): Promise<IPublicKey> {
    return $axios.$post<IPublicKey>(`${BASE_ENDPOINT}`, publicKey);
  },
  delete(publicKeyId: string): Promise<void> {
    return $axios.$delete(`${BASE_ENDPOINT}/${publicKeyId}`);
  },
  async getAll(agencyId: string): Promise<IPublicKey[]> {
    const { clientApplicationConfigurations } =
      await $axios.$get<IPublicKeysResponse>(`${BASE_ENDPOINT}/?agency_id=${agencyId}&order=created_at`);

    return clientApplicationConfigurations;
  },
  async getScopes(): Promise<string[]> {
    const { scopes } = await $axios.$get<IPublicScopeResponse>(BASE_ENDPOINT_SCOPE);

    return scopes.sort((a, b) => a.localeCompare(b));
  },
  update(publicKey: IPublicKey): Promise<IPublicKey> {
    return $axios.$post<IPublicKey>(`${BASE_ENDPOINT}/${publicKey.id}`, publicKey);
  },
});