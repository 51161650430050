
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Item } from '@vuex-orm/core';

import { IEntities } from '@/interfaces/entity/Entities.interface';
import { IErrorMessages } from '@/interfaces/ErrorMessages.interface';
import { IOption } from '@/interfaces/Option.interface';
import { IPropertyByAddress } from '@/api/dome/properties/Properties.interface';
import { IResultItem } from '@/interfaces/ResultItem.interface';
import { ISelectedEntities } from '@/interfaces/entity/SelectedEntities.interface';
import { parseError } from '@/utils/validation';
import { Property } from '@/models';

@Component
export default class PropertyStepHelper extends Vue {
  @Prop({ required: true, type: Object }) entities!: IEntities;
  @Prop({ type: Object }) errorMessages!: IErrorMessages;
  @Prop({ type: Object }) selectedEntities!: ISelectedEntities;

  isLoadingProperty = false;
  propertySearch = '';
  propertiesListByAddress: IPropertyByAddress[] = [];

  @Watch('selectedEntities', { deep: true, immediate: true })
  getSelectedProperty(): void {
    if (this.selectedEntities.property) {
      try {
        Property.api().getPropertyDetails(this.selectedEntities.property);
      } catch (error) {
        this.$snackbar.error(parseError(this, error));
      }
    }
  }

  get formattedPropertyResult(): IResultItem[] {
    return this.propertiesListByAddress.map((property) => ({
      ...property,
      content: `<span class="mr-2 group-hover:text-lk-blue">
          ${property.address}, ${property.zipCode} ${property.city}
        </span>
        <span class="text-xs text-lk-gray">
          ${this.$t(`property_type.${property.type}`)} - ${property.reference}
        </span>`,
    }));
  }

  get selectedProperty(): Item<Property> {
    if (!this.selectedEntities.property) {
      return null;
    }

    return Property.query()
      .whereId(this.selectedEntities.property)
      .first();
  }

  clearPropertyValue(): void {
    this.$emit('select-entity', 'property', null);
    this.propertySearch = '';
  }

  handlePropertySuggestion(item: IOption<string>): void {
    this.$emit('select-entity', 'property', item.value);
  }

  async searchProperties(value: string): Promise<void> {
    this.propertySearch = value;

    if (value === '') {
      this.propertiesListByAddress = [];
      this.clearPropertyValue();

      return;
    }

    try {
      this.isLoadingProperty = true;
      this.propertiesListByAddress = await this.$api.properties.searchByAddress(value);
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
      this.propertiesListByAddress = [];
    } finally {
      this.isLoadingProperty = false;
    }
  }

  selectProperty(value?: Property): void {
    if (value) {
      this.$emit('select-entity', 'property', value.id);
    } else {
      this.clearPropertyValue();
    }
  }
}
