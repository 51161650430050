import { axiosConfig, LkAxiosInstance } from '@/api';
import { createBlobUrl } from '@/utils/blobHelper';
import { getFileName } from '@/utils/file';
import { IFile, IFileResponse } from '@/interfaces/File.interface';
import {
  ICreateVisitPayload,
  ICreateVisitResponse,
  IVisitCancelPayload,
  IVisitCancelResponse,
  IVisitGetByIdResponse,
  IVisitPropertyResponse,
  IVisitUpsertFeedbackPayload,
  IVisitUpsertFeedbackResponse,
  IVisitVoucherPayload,
} from '@/api/dome/visits/Visits.interface';

const BASE_ENDPOINT = '/visits';

export interface IVisitsService {
  add(params: ICreateVisitPayload): Promise<ICreateVisitResponse>;
  cancel(id: string, payload: IVisitCancelPayload): Promise<IVisitCancelResponse>;
  downloadVoucher(params: IVisitVoucherPayload): Promise<IFile>;
  getById(id: string): Promise<IVisitGetByIdResponse>;
  getByPropertyId(propertyId: string): Promise<IVisitPropertyResponse>;
  upsertFeedback(id: string, payload: IVisitUpsertFeedbackPayload): Promise<IVisitUpsertFeedbackResponse>;
}

export const visitsService = ($axios: LkAxiosInstance): IVisitsService => ({
  add(payload) {
    return $axios.$post(`${BASE_ENDPOINT}`, payload);
  },
  async cancel(id, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/cancel`, payload);
  },
  async downloadVoucher(payload) {
    const response: IFileResponse = await $axios.post('/visit_vouchers', payload, axiosConfig.blob);

    return { name: getFileName(response.headers), url: createBlobUrl(response.data) };
  },
  getById(id) {
    return $axios.$get(`${BASE_ENDPOINT}/${id}`);
  },
  getByPropertyId(propertyId) {
    return $axios.$get(`${BASE_ENDPOINT}?property_id=${propertyId}`);
  },
  upsertFeedback(id, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/upsert_feedback`, payload);
  },
});
