

























































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { EPersonContactType } from '@/enums/PersonContactType.enum';
import { IListFormItem } from '@/components/common/molecules/items-list-form/ItemsListForm.interface';
import { INegotiationBuyers } from '@/views/entity/steps/NegotiationBuyers.interface';
import { IOption } from '@/interfaces/Option.interface';
import { IPerson } from '@/api/dome/persons/Persons.interface';
import { IPersonContact } from '@/api/dome/person-contacts/PersonContacts.interface';
import { IRecipientsBuyersErrors } from '@/components/common/organisms/recipients/buyers/RecipientsBuyer.interface';
import { IResultItem } from '@/interfaces/ResultItem.interface';
import { parseError } from '@/utils/validation';

import ItemsListForm from '@/components/common/molecules/items-list-form/ItemsListForm.vue';
import RecipientsBuyers from '@/components/common/organisms/recipients/buyers/RecipientsBuyers.vue';

interface IErrorMessages {
  negotiationBuyers: {
    mainBuyer: {
      email?: string;
      firstName?: string;
      lastName?: string;
      phone?: string;
    };
  };
}

@Component({
  components: {
    ItemsListForm,
    RecipientsBuyers,
  },
})
export default class NegotiationBuyers extends Vue {
  @Prop({ required: true, type: Object }) entities!: { negotiationBuyers: INegotiationBuyers };
  @Prop({ default: () => ({}), type: Object }) errorMessages!: IErrorMessages;
  @Prop({ default: false, type: Boolean }) isEmailUnique!: boolean;
  @Prop({ required: true, type: Object }) suggestionList!: Record<'person' | 'property', IOption<string>[]>;

  isLoading = false;
  personList: IPerson[] = [];
  personSearch = '';
  selectedPerson: IPerson | null = null;

  @Watch('entities.negotiationBuyers.mainBuyer.id', { immediate: true })
  async onMainBuyerChange(id: string): Promise<void> {
    await this.getSelectedPerson(id);
  }

  get formattedResult(): IResultItem[] {
    return this.personList.map(person => {
      const email = this.personEmail(person);

      return {
        ...person,
        content: `<span class="mr-2 group-hover:text-lk-blue">${person.firstName} ${person.lastName}</span>
        <span class="text-xs text-lk-gray">${email?.value || ''}</span>`,
      };
    });
  }

  get isPersonSelected(): boolean {
    return Boolean(this.entities.negotiationBuyers.mainBuyer.id);
  }

  get items(): IListFormItem[] {
    return this.entities.negotiationBuyers.recipientsBuyers.recipients.map(({ firstName, lastName }, index) => ({
      icon: this.$icons.contact_checked,
      id: index.toString(),
      isProtected: false,
      text: this.$t('recipients-buyers.form.completed'),
      title: `${firstName} ${lastName}`,
    }));
  }

  get recipientsBuyersErrors(): IRecipientsBuyersErrors {
    return {
      buyingCompanyName: this.errorMessages?.negotiationBuyers
        ? this.errorMessages.negotiationBuyers['recipientsBuyers.buyingCompanyName']
        : undefined,
    };
  }

  get selectedPersonEmail(): string {
    return this.personEmail(this.selectedPerson)?.value || '';
  }

  created(): void {
    if (this.entities.negotiationBuyers.mainBuyer.email) {
      this.validateMainBuyerEmail();
    }
  }

  clearPerson(): void {
    this.clearPersonValue();

    if (this.entities.negotiationBuyers.mainBuyer.email) {
      this.validateMainBuyerEmail();
    }
  }

  clearPersonValue(): void {
    this.updateMainBuyer('id', '');
    this.personSearch = '';
  }

  async getSelectedPerson(id: string) {
    if (!id) {
      this.selectedPerson = null;

      return;
    }

    try {
      this.selectedPerson = await this.$api.persons.getById(id);
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
      this.selectedPerson = null;
    }
  }

  getValue(key: string): string {
    return this.isPersonSelected ? '' : this.entities.negotiationBuyers.mainBuyer[key];
  }

  handleBuyerSuggestion({ value }: IOption<string>): void {
    this.personSearch = '';
    this.updateMainBuyer('id', value);
    this.validateMainBuyerEmail();

    Object.keys(this.errorMessages.negotiationBuyers).forEach(negotiationBuyersKey => {
      if (negotiationBuyersKey.includes('mainBuyer')) {
        this.removeErrorMessage(negotiationBuyersKey);
      }
    });
  }

  personEmail(person: IPerson | null): IPersonContact | undefined {
    if (!person || !person.personContacts) {
      return undefined;
    }

    return person.personContacts.find(contact => contact.type === EPersonContactType.Email && contact.isPrimary);
  }

  removeErrorMessage(key: string): void {
    this.$emit('remove-error', 'negotiationBuyers', key);
  }

  selectPersonResult(person: IPerson): void {
    if (person.id) {
      this.updateMainBuyer('id', person.id);
      this.updateMainBuyer('firstName', person.firstName);
      this.updateMainBuyer('lastName', person.lastName);
      this.updateMainBuyer('email', this.personEmail(person)?.value ?? '');
      this.validateMainBuyerEmail();
    } else {
      this.clearPersonValue();
    }
  }

  updateEntity(key: string, value: unknown): void {
    this.$emit('update-entity', 'negotiationBuyers', key, value);
  }

  updateMainBuyer(key: string, value: string): void {
    const mainBuyer = { ...this.entities.negotiationBuyers.mainBuyer, [key]: value };
    this.updateEntity('mainBuyer', mainBuyer);

    if (key === 'id' && value && this.errorMessages?.negotiationBuyers) {
      Object.keys(this.errorMessages.negotiationBuyers).forEach(negotiationBuyersKey => {
        if (negotiationBuyersKey.includes('mainBuyer')) {
          this.removeErrorMessage(negotiationBuyersKey);
        }
      });
    }
  }

  async updatePersonSearch(value: string): Promise<void> {
    this.personSearch = value;

    if (value === '') {
      this.personList = [];
      this.clearPersonValue();

      return;
    }

    this.isLoading = true;

    try {
      this.personList = await this.$api.persons.search(value);
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
      this.personList = [];
    } finally {
      this.isLoading = false;
    }
  }

  validateEntityKey(key: string): void {
    this.$emit('validate-entity-key', 'negotiationBuyers', key);
  }

  validateMainBuyerEmail(): void {
    this.validateEntityKey('mainBuyer.email');
  }
}
