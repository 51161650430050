
































import { Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { getOtherPersonContacts } from '@/helpers/contacts';
import { IDomeSettings } from '@/api/dome/agents/Agents.interface';
import { IPerson } from '@/api/dome/persons/Persons.interface';
import { IStep } from '@/components/common/molecules/stepper/StepperWithStatus.interface';
import { PersonEntityModel } from './models';

import PersonHelpers from './helpers/person.vue';
import Person from './steps/Person.vue';
import PersonContactComponent from './steps/PersonContact.vue';
import Wizard from '@/components/common/templates/wizard/Wizard.vue';

@Component({
  components: {
    Person,
    PersonContactComponent,
    Wizard,
  },
})
export default class SellerEntity extends PersonHelpers {
  @Prop({ required: true, type: Object }) entity!: IPerson;

  @State('settings') settings!: IDomeSettings;

  isAccountActivated = false;
  stepList = Object.freeze(['person']);

  get steps(): IStep[] {
    return this.stepList.map(step => ({
      hasError: false,
      isFilled: false,
      name: this.$t(`entity_edition.steps.${step === 'person' ? 'seller' : step}.title`),
      value: step,
    }));
  }

  async created(): Promise<void> {
    this.setData();

    if (this.settings.isCustomerSpaceEnabled) {
      const { isAccountActivated } = await this.$api.persons.getCustomerSpaceDetails(this.entity.id);
      this.isAccountActivated = isAccountActivated;
    }
  }

  async handleCloseWizard(): Promise<void> {
    this.resetIsEmailUnique();
    await this.closeWizard();
  }

  async createEntity(): Promise<void> {
    this.isSubmitting = true;

    await this.validatePersonEmail();

    if (!this.isEntityValid(this.stepList)) {
      this._handleError('invalid data', this.errorStepIndex);

      return;
    }

    if (!this.entities.person) {
      this._handleError('empty');

      return;
    }

    try {
      await this.updatePerson(this.entities.person);
      this.$emit('update-person', this.entity.id);
      this.handleCloseWizard();
    } catch (error) {
      this._handleError(error, 0, true);
    }
  }

  setData(): void {
    this.entities = { person: this.getFormattedPerson(this.entity) };
    this.currentPerson = { ...this.entities.person } as PersonEntityModel;
    this.otherContacts = getOtherPersonContacts(this.entity.personContacts);
  }
}
