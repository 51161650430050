import { IPortal, IPortalsResponse, IPortalsWithQuotaResponse, IPortalWithQuota } from './Portals.interface';
import { LkAxiosInstance } from '@/api';
import { toQueryString } from '@/utils/url';

export interface IPortalsService {
  deleteQuotaOfPortal(agencyId: string, portalKey: string): Promise<void>;
  getPortalWithQuota(agencyId: string, portalKey: string): Promise<IPortalWithQuota>;
  getPortals(): Promise<IPortal[]>;
  getPortalsWithQuota(agencyId: string): Promise<IPortalWithQuota[]>;
  upsertPortalWithQuota(agencyId: string, portal: IPortalWithQuota): Promise<void>;
}

export const portalsService = ($axios: LkAxiosInstance): IPortalsService => ({
  deleteQuotaOfPortal(agencyId, key) {
    return $axios.$delete('/settings/quota_of_portal', { data: { agencyId, key } });
  },

  getPortalWithQuota(agencyId, key) {
    const query = toQueryString({ agency_id: agencyId, key });

    return $axios.$get<IPortalWithQuota>(`/settings/portal_with_quota?${query}`);
  },

  async getPortals() {
    const { portals } = await $axios.$get<IPortalsResponse>('/portals');

    return portals;
  },

  async getPortalsWithQuota(agencyId) {
    const { portalsWithQuota } = await $axios.$get<IPortalsWithQuotaResponse>(
      `/settings/portals_with_quota?agency_id=${agencyId}`,
    );

    return portalsWithQuota;
  },

  upsertPortalWithQuota(agencyId, portal) {
    return $axios.$post('/settings/upsert_quota_of_portal', { agencyId, ...portal });
  },
});
