

































































import { Component, Prop } from 'vue-property-decorator';
import { IOption } from '@/interfaces/Option.interface';

import FieldComponent from '@/components/common/molecules/forms/FieldComponent.vue';

let segmentedBarId = 0;

@Component
export default class BaseSegmentedBar extends FieldComponent<string | number | boolean> {
  @Prop({ default: false, type: Boolean }) nullable!: boolean;
  @Prop({ required: true, type: Array }) options!: IOption[];

  focus = false;
  id = `segmented-bar-${segmentedBarId++}`;

  get showPreviousValue(): boolean {
    return this.previousValue !== null && this.value !== this.previousValue;
  }

  updateValue(value: string | number | boolean) {
    if (!this.disabled && !this.readonly) {
      if (this.nullable && value === this.value) {
        this.$emit('update');
      } else {
        this.$emit('update', value);
      }
    }
  }
}
