import Vue from 'vue';

import BaseAlert from '@/components/common/molecules/alert/BaseAlert.vue';
import BaseButton from '@/components/common/atoms/buttons/base-button/BaseButton.vue';
import BaseCheckbox from '@/components/common/atoms/forms/checkbox/BaseCheckbox.vue';
import BaseDatetime from '@/components/common/molecules/forms/date-input/BaseDatetime.vue';
import BaseDialog from '@/components/common/organisms/dialog/BaseDialog.vue';
import BaseIcon from '@/components/common/atoms/icon/BaseIcon.vue';
import BaseInput from '@/components/common/molecules/forms/input/BaseInput.vue';
import BasePanel from '@/components/common/molecules/panel/BasePanel.vue';
import BasePriceInput from '@/components/common/molecules/forms/input/BasePriceInput.vue';
import BaseSearch from '@/components/common/molecules/forms/search/BaseSearch.vue';
import BaseSegmentedBar from '@/components/common/molecules/forms/segmented-bar/BaseSegmentedBar.vue';
import BaseSelect from '@/components/common/molecules/forms/select/BaseSelect.vue';
import BaseTextarea from '@/components/common/molecules/forms/textarea/BaseTextarea.vue';
import CtaLink from '@/components/common/molecules/cta-link/CtaLink.vue';
import FormLabel from '@/components/common/atoms/forms/label/FormLabel.vue';
import PreciseNumber from '@/components/common/atoms/precise-number/PreciseNumber.vue';
import RadioRate from '@/components/common/molecules/forms/radio-rate/RadioRate.vue';
import StatusLabel from '@/components/common/atoms/labels/status-label/StatusLabel.vue';

Vue.component('BaseAlert', BaseAlert);
Vue.component('BaseButton', BaseButton);
Vue.component('BaseCheckbox', BaseCheckbox);
Vue.component('BaseDatetime', BaseDatetime);
Vue.component('BaseDialog', BaseDialog);
Vue.component('BaseIcon', BaseIcon);
Vue.component('BaseInput', BaseInput);
Vue.component('BasePanel', BasePanel);
Vue.component('BasePriceInput', BasePriceInput);
Vue.component('BaseSearch', BaseSearch);
Vue.component('BaseSegmentedBar', BaseSegmentedBar);
Vue.component('BaseSelect', BaseSelect);
Vue.component('BaseTextarea', BaseTextarea);
Vue.component('CtaLink', CtaLink);
Vue.component('FormLabel', FormLabel);
Vue.component('PreciseNumber', PreciseNumber);
Vue.component('RadioRate', RadioRate);
Vue.component('StatusLabel', StatusLabel);
