import { LkAxiosInstance } from '@/api';
import { IPortalWithRestrictions, IPortalsResponse } from './Portals.interface';

export interface IPortalsService {
  getAvailablePortals(agencyId: string): Promise<IPortalWithRestrictions[]>;
}

export const portalsService = ($axios: LkAxiosInstance): IPortalsService => ({
  async getAvailablePortals(agencyId: string): Promise<IPortalWithRestrictions[]> {
    const { portals } = await $axios.$get<IPortalsResponse>(
      `/publication_portals_by_agency_id?agency_id=${agencyId}`,
    );

    return portals;
  },
});

