import { EConditions } from '@/enums/Conditions.enum';

export class NegotiationConditions {
  constructor(
    public suspensiveConditions: EConditions[] = [],
    public sequestrationRate: number | null = null,
    public additionalSuspensiveConditions = '',
    public otherAdditionalModalities = '',
  ) {}
}
