import Vue from 'vue';
import { SnotifyPosition, SnotifyToast } from 'vue-snotify';
import { SnotifyToastConfig } from 'vue-snotify/interfaces';

export interface SnackbarService {
  error: (body: string) => void;
  simple: (body: string, title: string) => SnotifyToast;
  success: (body: string) => void;
  update: (body: string) => void;
}

const config: SnotifyToastConfig = {
  position: SnotifyPosition.centerBottom,
  showProgressBar: false,
  timeout: 5000,
};

const customPosition = window.innerWidth > 1200 ? SnotifyPosition.rightBottom : SnotifyPosition.centerBottom;

export default {
  install: ({ prototype }: typeof Vue) => {
    prototype.$snotify.setDefaults({ global: { preventDuplicates: true } });

    prototype.$snackbar = {
      error: (body: string): void => prototype.$snotify.error(body, config),
      simple: (body: string, title: string): void => prototype.$snotify.simple(body, title),
      success: (body: string): void => prototype.$snotify.success(body, config),
      update: (body: string): void => prototype.$snotify.simple(body, { ...config, position: customPosition }),
    };
  },
};
