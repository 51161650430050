




























































import { Vue, Component, Prop } from 'vue-property-decorator';

let panelId = 0;
const openedPanel: string[] = [];
const storybookBodyClass = 'sb-show-main';

@Component
export default class BasePanel extends Vue {
  @Prop({ default: true, type: Boolean }) addBodyClass!: boolean;
  @Prop({ default: null, type: Object }) chipStatus!: { status: string, text: string };
  @Prop({ default: true, type: Boolean }) hasPortals!: boolean;
  @Prop({ default: '', type: String }) title!: string;

  id = `panel-${panelId++}`;

  mounted(): void {
    if (this.addBodyClass
      && !document.body.classList.contains('panel-open')
      && !document.body.classList.contains(storybookBodyClass)
    ) {
      document.body.classList.add('panel-open');
    }

    openedPanel.push(this.id);
  }

  beforeDestroy(): void {
    openedPanel.splice(openedPanel.indexOf(this.id), 1);

    if (this.addBodyClass && !openedPanel.length && !document.body.classList.contains(storybookBodyClass)) {
      document.body.classList.remove('panel-open');
    }
  }
}
