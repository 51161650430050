







































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { IListFormItem } from './ItemsListForm.interface';

@Component
export default class ItemsListForm extends Vue {
  @Prop({ default: '', type: String }) addButtonText!: string;
  @Prop({ default: null, type: String }) dataTestId!: string | null;
  @Prop({ default: false, type: Boolean }) isRequired!: boolean;
  @Prop({ default: false, type: Boolean }) isDeletable!: boolean;
  @Prop({ required: true, type: Array }) items!: IListFormItem[];
}
