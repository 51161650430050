import { ERoomType } from '@/enums/RoomType.enum';
import { getFormattedRoom } from '..';
import {
  ICreatePropertyRoomPayload,
  IPropertyRoom,
  IUpdatePropertyRoomPayload,
} from './Rooms.interface';
import { LkAxiosInstance } from '@/api';

const BASE_ENDPOINT = '/property_rooms';
const FIELDS_NUMBER = 8;
const ROOM_TYPE = ERoomType.Room;

export interface IRoomsService {
  create(payload: ICreatePropertyRoomPayload): Promise<IPropertyRoom>;
  delete(id: string): Promise<void>;
  get(id: string): Promise<IPropertyRoom>;
  update(payload: IUpdatePropertyRoomPayload): Promise<IPropertyRoom>;
}

export const roomsService = ($axios: LkAxiosInstance): IRoomsService => ({
  async create(payload) {
    const response = await $axios.$post<IPropertyRoom>(BASE_ENDPOINT, payload);

    return getFormattedRoom(response, ROOM_TYPE, FIELDS_NUMBER);
  },
  delete(id) {
    return $axios.$delete(`${BASE_ENDPOINT}/${id}`);
  },
  async get(id) {
    const response = await $axios.$get<IPropertyRoom>(`${BASE_ENDPOINT}/${id}`);

    return getFormattedRoom(response, ROOM_TYPE, FIELDS_NUMBER);
  },
  async update(payload) {
    const response = await $axios.$post<IPropertyRoom>(`${BASE_ENDPOINT}/${payload.id}`, payload);

    return getFormattedRoom(response, ROOM_TYPE, FIELDS_NUMBER);
  },
});
