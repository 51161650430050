import i18n from '@/plugins/i18n';

interface Schema {
  fields: Record<string, unknown>;
}

export const getFormErrorMessage = (errors: (string | { error: string, value: string })[], isBo = false): string => {
  const base = isBo ? 'bo.errors' : 'errors';

  return errors.map(error => typeof error === 'string'
    ? i18n.t(`${base}.${error}`)
    : i18n.t(`${base}.${error.error}`, { value: error.value }),
  ).join(', ');
};

export const getErrorObjectFromSchema = <T = Record<string, string | null>>(schema: Schema) =>
  Object.keys(schema.fields).reduce((newObject, key): T => {
    newObject[key] = null;

    return newObject;
  }, {} as T);