

































import { Vue, Component, Prop } from 'vue-property-decorator';

import { INavItem } from './NavItem.interface';

@Component
export default class NavItemComponent extends Vue {
  @Prop({ default: false, type: Boolean }) compact!: boolean;
  @Prop({ default: false, type: Boolean }) disabled!: boolean;
  @Prop({ required: true, type: Object }) item!: INavItem;
}
