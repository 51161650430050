import { LkAxiosInstance } from '@/api';
import { IOffer, IOfferRequest, IOffersResponse } from './Offers.interface';

const BASE_ENDPOINT = '/offers';

export interface IOffersService {
  getOffer(offerId: string): Promise<IOffersResponse>;
  resendOffer(negotiationId: string): Promise<void>;
  sendEmail(offerId: string, type: string): Promise<void>;
  updateOffer(offerId: string, offer: IOfferRequest): Promise<IOffer>;
}

export const offersService = ($axios: LkAxiosInstance): IOffersService => ({
  async getOffer(offerId) {
    return $axios.$get(`${BASE_ENDPOINT}/${offerId}`);
  },
  async resendOffer(negotiationId) {
    return $axios.$post(`${BASE_ENDPOINT}/${negotiationId}/resend_esign_email`);
  },
  async sendEmail(offerId, type) {
    return $axios.$post(`${BASE_ENDPOINT}/${offerId}/send_email`, { type });
  },
  updateOffer(offerId, offer) {
    return $axios.$put(`${BASE_ENDPOINT}/${offerId}`, offer);
  },
});
