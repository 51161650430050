const isIOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

if (isIOS) {
  const el = document.querySelector('meta[name=\'viewport\']');

  if (el) {
    const currentMetaViewport = el.getAttribute('content');
    el.setAttribute('content', `${currentMetaViewport},maximum-scale=1.0`);
  }
}
