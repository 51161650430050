import { LkAxiosInstance } from '@/api';
import { ISearchAddress, ISearchAddressesPayload, ISearchAddressesResponse } from './Addresses.interface';

const BASE_ENDPOINT = '/addresses';

export interface IAddressesService {
  search(payload: ISearchAddressesPayload): Promise<ISearchAddress[]>;
}

export const addressesService = ($axios: LkAxiosInstance): IAddressesService => ({
  async search(payload) {
    return $axios.$post<ISearchAddressesResponse>(`${BASE_ENDPOINT}/search`, payload)
      .then(({ addresses }) => addresses);
  },
});
