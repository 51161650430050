const BuyerLeadsIndex = () => import(/* webpackChunkName: "leads" */ '@/views/properties/id/buyer-leads/index.vue');
const BuyerLeadsDetail = () => import(/* webpackChunkName: "leads" */ '@/views/buyer-leads/id/index.vue');

export default [
  {
    children: [
      {
        component: BuyerLeadsDetail,
        name: 'property_buyer_lead',
        path: ':buyerLeadId',
        props: true,
      },
    ],
    component: BuyerLeadsIndex,
    name: 'property_buyer_leads',
    path: '/biens/:propertyId/leads-acheteurs',
  },
];