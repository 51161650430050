import { Model } from '@vuex-orm/core';
import { v4 as uuidv4 } from 'uuid';
import Property from '@/models/Property.model';

const BASE_ENDPOINT = '/property_expense';

export class Expense extends Model {
  static entity = 'expense';

  id!: string;
  propertyId!: string;
  electricityMonthlyBill!: number;
  waterMonthlyBill!: number;
  gasMonthlyBill!: number;
  isRentable!: boolean;
  isRented!: boolean;
  rentMonthlyCharges!: number;
  rentMonthlyIncomes!: number;
  propertyTax!: number;
  condominiumPrice!: number;
  condominiumFrequency!: string;
  condominiumComment!: string;
  rentParkingMonthlyPrice!: number;
  hasCurrentProcedure?: boolean;
  currentProcedureComment!: string | null;
  comment!: string;

  /**
   * roomType is mandatory and should not be used during creation
   */
  static fields() {
    return {
      comment: this.string(null).nullable(),
      condominiumComment: this.string(null).nullable(),
      condominiumFrequency: this.string(null).nullable(),
      condominiumPrice: this.number(null).nullable(),
      currentProcedureComment: this.string(null).nullable(),
      electricityMonthlyBill: this.number(null).nullable(),
      gasMonthlyBill: this.number(null).nullable(),
      hasCurrentProcedure: this.boolean(null).nullable(),
      id: this.uid(() => uuidv4()),
      isRentable: this.boolean(null).nullable(),
      isRented: this.boolean(null).nullable(),
      property: this.belongsTo(Property, 'propertyId'),
      propertyId: this.uid(),
      propertyTax: this.number(null).nullable(),
      rentMonthlyCharges: this.number(null).nullable(),
      rentMonthlyIncomes: this.number(null).nullable(),
      rentParkingMonthlyPrice: this.number(null).nullable(),
      waterMonthlyBill: this.number(null).nullable(),
    };
  }

  static apiConfig = {
    actions: {
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      fetchById(propertyId: string): Promise<any> {
        return (this as any).get(`${BASE_ENDPOINT}?property_id=${propertyId}`, {
          dataKey: 'propertyExpenses',
        });
      },
      getPropertyExpense(id: string): void {
        return (this as any)
          .get(`${BASE_ENDPOINT}/${id}`)
          .catch((error: any) => {
            if (error.response.data.error.type === 'object-not-found') {
              Expense.create({
                data: {
                  propertyId: id,
                },
              });
            }
          });
      },
      savePropertyExpense(expense: Expense): Promise<any> {
        return (this as any).post(`${BASE_ENDPOINT}/${expense.id}`, expense);
      },
      /* eslint-enable  @typescript-eslint/no-explicit-any */
    },
  };
}
