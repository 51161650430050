




























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { EExclusivityOpinion } from '@/enums/ExclusivityOpinion.enum';
import { EPropertyType } from '@/enums/PropertyType.enum';
import { EPropertyUseType } from '@/enums/PropertyUseType.enum';
import { ESaleStrategy } from '@/enums/SaleStrategy.enum';
import { getYesNoOptions } from '@/utils/options';
import { IEntities } from '@/interfaces/entity/Entities.interface';
import { IErrorMessages } from '@/interfaces/ErrorMessages.interface';
import { IOption } from '@/interfaces/Option.interface';

@Component
export default class PropertyInformation extends Vue {
  @Prop({ required: true, type: Object }) entities!: IEntities;
  @Prop({ default: null, type: Object }) errorMessages!: IErrorMessages;

  EPropertyType = Object.freeze(EPropertyType);
  exclusivityOpinionOptions: Readonly<IOption[]> = Object.freeze(
    [EExclusivityOpinion.Favorable, EExclusivityOpinion.Doubtful, EExclusivityOpinion.Unfavorable]
      .map(value => ({ label: this.$t(`f95.form.exclusivity_opinion.${value}`), value })),
  );
  saleStrategyOptions: Readonly<IOption[]> = Object.freeze([
    {
      label: this.$t(`f95.form.sale_strategy.${ESaleStrategy.Sell}`),
      value: ESaleStrategy.Sell,
    },
    {
      label: this.$t('f95.form.sale_strategy.sell_then_buy'),
      value: ESaleStrategy.SellThenBuy,
    },
    {
      label: this.$t('f95.form.sale_strategy.buy_then_sell'),
      value: ESaleStrategy.BuyThenSell,
    },
  ]);
  useTypeOptions: Readonly<IOption[]> = Object.freeze([EPropertyUseType.Main, EPropertyUseType.Secondary]
    .map(value => ({ label: this.$t(`f95.form.use.${value}`), value })),
  );
  yesNoOptions: Readonly<IOption[]> = Object.freeze(getYesNoOptions());

  removeErrorMessage(key: string): void {
    this.$emit('remove-error', 'property', key);
  }

  updateEntity(key: string, value): void {
    this.$emit('update-entity', 'propertyInformation', key, value);
  }

  validateEntityKey(key: string): void {
    this.$emit('validate-entity-key', 'property', key);
  }
}
