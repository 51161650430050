
import { Component, Vue, Watch } from 'vue-property-decorator';
import { isEqual } from 'lodash';
import { State } from 'vuex-class';
import * as yup from 'yup';

import { EPropertyPurchasePlanSource } from '@/enums/PropertyPurchasePlanSource.enum';
import { EPropertyType } from '@/enums/PropertyType.enum';
import { FormGroup } from '@/utils/form';
import { IDomeSettings } from '@/api/dome/agents/Agents.interface';
import { IPropertyPurchasePlan, IPropertyPurchasePlanForm }
  from '@/api/dome/property-purchase-plans/PropertyPurchasePlans.interface';
import { parseError } from '@/utils/validation';
import { PropertyPurchasePlanEntityModel } from '../models';

const mandatoryParams = ['isDisabled', 'propertyType', '$isPropertyPurchasePlanMandatory'];

const isMandatory = (
  isDisabled: boolean, propertyType: EPropertyType | null, isPropertyPurchasePlanMandatory?: boolean,
) => isDisabled ? false : (isPropertyPurchasePlanMandatory || !!propertyType);

@Component
export default class PropertyPurchasePlanHelpers extends Vue {
  @State('settings') settings!: IDomeSettings;

  form = new FormGroup<IPropertyPurchasePlanForm>({
    buyerQualification: [null, yup.string().nullable().when(mandatoryParams, {
      is: isMandatory,
      then: schema => schema.required(),
    })],
    buyingPurpose: [null, yup.string().nullable()],
    comment: [null, yup.string().nullable()],
    fundingStatus: [null, yup.string().nullable()],
    geoCitiesIds: [[], yup.array().of(yup.string()).when(mandatoryParams, {
      is: isMandatory,
      then: schema => schema.min(1, 'selected_zones_required'),
    })],
    hasElevator: [null, yup.boolean().nullable()],
    hasExterior: [null, yup.boolean().nullable()],
    hasParking: [null, yup.boolean().nullable()],
    hasSaleProject: [false, yup.boolean()],
    isDisabled: [false, yup.boolean()],
    isGroundFloor: [null, yup.boolean().nullable()],
    isOpenToRenovations: [null, yup.boolean().nullable()],
    isSingleBuyer: [null, yup.boolean().nullable()],
    maximumBuyingPrice: [0, yup.number().when(mandatoryParams, {
      is: isMandatory,
      then: schema => schema.required().moreThan(0),
    })],
    minimumBedroomsCount: [0, yup.number().when(mandatoryParams, {
      is: isMandatory,
      then: schema => schema.when('minimumRoomsCount', (minimumRoomsCount: number) =>
        yup.number().min(0).max(minimumRoomsCount, 'bedrooms_must_be_lower_or_equal')),
    })],
    minimumRoomsCount: [0, yup.number().when(mandatoryParams, {
      is: isMandatory,
      then: schema => schema.required().moreThan(0),
    })],
    minimumSurface: [0, yup.number().when(mandatoryParams, {
      is: isMandatory,
      then: schema => schema.required().moreThan(0),
    })],
    personalContribution: [null, yup.number().nullable()],
    propertyAge: [null, yup.string().nullable()],
    propertySearchProgress: [null, yup.string().nullable()],
    propertyType: [null, yup.string().nullable().when(
      ['$isPropertyPurchasePlanMandatory'],
      (isPropertyPurchasePlanMandatory: boolean, schema: yup.StringSchema) =>
        isPropertyPurchasePlanMandatory ? schema.required() : schema,
    )],
    source: [EPropertyPurchasePlanSource.Agent, yup.string()],
  });

  @Watch('entities.propertyPurchasePlan')
  updateForm(propertyPurchasePlan: PropertyPurchasePlanEntityModel): void {
    if (propertyPurchasePlan) {
      this.form.get('geoCitiesIds').setValue(propertyPurchasePlan.geoCities.map(({ id }) => id));
      this.form.setValue(propertyPurchasePlan);
    }
  }

  async getPropertyPurchasePlan(personId?: string | null): Promise<IPropertyPurchasePlan | null> {
    if (!personId) {
      return null;
    }

    try {
      return await this.$api.propertyPurchasePlans.getByPersonId(personId);
    } catch (error) {
      this.$snackbar.error(parseError(this, error));

      return null;
    }
  }

  async getPropertyPurchasePlanEntity(personId?: string | null): Promise<PropertyPurchasePlanEntityModel> {
    const propertyPurchasePlan = await this.getPropertyPurchasePlan(personId);

    return new PropertyPurchasePlanEntityModel(propertyPurchasePlan);
  }

  async updatePropertyPurchasePlan(
    propertyPurchasePlanForm: IPropertyPurchasePlanForm,
    personId: string,
    propertyPurchasePlan?: PropertyPurchasePlanEntityModel,
  ): Promise<void> {
    const emptyPropertyPurchasePlan = new PropertyPurchasePlanEntityModel();

    if (propertyPurchasePlan?.id) {
      await this.$api.propertyPurchasePlans.update(propertyPurchasePlan.id, propertyPurchasePlanForm);

      if (propertyPurchasePlanForm.isDisabled) {
        await this.$api.propertyPurchasePlans.cancel(propertyPurchasePlan.id);
      }
    } else if (!isEqual({ ...propertyPurchasePlanForm, isDisabled: false }, emptyPropertyPurchasePlan)) {
      await this.$api.propertyPurchasePlans.create({ ...propertyPurchasePlanForm, personId });
    }
  }

  validateForm(): boolean {
    const { isPropertyPurchasePlanMandatory } = this.settings;

    return this.form.validate({ context: { isPropertyPurchasePlanMandatory } });
  }
}
