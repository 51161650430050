import { setLocale } from 'yup';
import * as yup from 'yup';

import { EAgencyFeesType } from '@/enums/AgencyFeesType.enum';
import { EContactRole } from '@/enums/ContactRole.enum';
import { EMandateType } from '@/enums/mandate/MandateType.enum';
import { EMandateSendMethod } from '@/enums/mandate/MandateSendMethod.enum';
import { EPersonContactContext } from '@/enums/PersonContactContext.enum';
import { EPersonContactType } from '@/enums/PersonContactType.enum';
import { EPropertyType } from '@/enums/PropertyType.enum';
import { getParsedPhoneNumber } from './phone';

setLocale({
  mixed: {
    default: 'field_invalid',
    required: 'field_required',
  },
  number: {
    lessThan: ({ less }) => ({ error: 'value_must_be_lower', value: less }),
    max: ({ max }) => ({ error: 'value_must_be_lower_or_equal', value: max }),
    min: ({ min }) => ({ error: 'value_must_be_higher_or_equal', value: min }),
    moreThan: ({ more }) => ({ error: 'value_must_be_higher', value: more }),
  },
  string: {
    email: 'email_invalid',
    max: ({ max }) => ({ error: 'input_length_must_be_lower_than', value: max }),
    url: 'url_invalid',
  },
});

export const parseError = (vm: Vue, error): string => {
  let errorInformations;
  let returnedError = '';

  if (error.type) {
    errorInformations = error;
  } else if (error.response?.data?.error) {
    errorInformations = error.response.data.error;
  } else {
    returnedError = vm.$root.$t('errors.something_happened').toString();
  }

  if (!returnedError) {
    const { code, message, type } = errorInformations;
    let errorTranslationKey = `${type}.default`;

    const isUnmarshalling = type === 'bad-payload' && message?.includes('unmarshalling');

    if (isUnmarshalling) {
      errorTranslationKey = `${type}.incorrect_format`;
    } else if (message) {
      errorTranslationKey = `${type}.${message}`;
    }

    returnedError = `${vm.$root.$t(`errors.${errorTranslationKey}`)}`;

    if (code) {
      returnedError += ` (${vm.$root.$t('errors.error_code', { code })})`;
    }
  }

  return returnedError ;
};

export const setErrorMessage = (
  vm: Vue,
  errors: (string | { error: string, value: string })[],
): string => errors
  .map(error => {
    if (typeof error === 'string') {
      return vm.$t(`errors.${error}`);
    }

    return vm.$t(`errors.${error.error}`, { value: error.value });

  })
  .join(', ');

export const setBoErrorMessage = (
  vm: Vue,
  errors: (string | { error: string, value: string })[],
): string => errors
  .map(error => {
    if (typeof error === 'string') {
      return vm.$t(`bo.errors.${error}`);
    }

    return vm.$t(`bo.errors.${error.error}`, { value: error.value });

  })
  .join(', ');

export const phone: yup.TestConfig = {
  message: 'phone_invalid',
  name: 'phone',
  test: (value: string): boolean => Boolean(getParsedPhoneNumber(value)?.isValid()),
};

export const personContactSchema = yup.object().shape({
  context: yup.mixed<EPersonContactContext>().oneOf(Object.values(EPersonContactContext)).required(),
  type: yup.mixed<EPersonContactType>().oneOf(Object.values(EPersonContactType)).required(),
  value: yup.string().required().when('type', (type: EPersonContactType) => type === EPersonContactType.Email
    ? yup.string().required().email()
    : yup.lazy(value => (value ? yup.string().test(phone) : yup.string().required())),
  ),
});

export const personContactSchemaArray = yup.array().of(personContactSchema);

export const personSchema = yup.object().shape({
  email: yup.string().required().email(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  manualSource: yup.string().when(
    ['$isLeadSourceMandatory', 'tags'],
    // TODO: remove once yup typing is fixed, see https://github.com/jquense/yup/issues/1529
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (isLeadSourceMandatory: boolean, tags: EContactRole[]) =>
      isLeadSourceMandatory && tags.includes(EContactRole.Seller)
        ? yup.string().nullable().required()
        : yup.string().nullable().notRequired(),
  ),
  personContacts: personContactSchemaArray,
  phone: yup.lazy(value => (value ? yup.string().test(phone) : yup.string())),
});

export const negotiationBuyersSchema = yup.object().shape({
  mainBuyer: yup.object().shape({
    email: yup.mixed().when('id', (id: string) => id
      ? yup.mixed().notRequired()
      : yup.string().required().email()),
    firstName: yup.mixed().when('id', (id: string) => id
      ? yup.mixed().notRequired()
      : yup.string().required()),
    lastName: yup.mixed().when('id', (id: string) => id
      ? yup.mixed().notRequired()
      : yup.string().required()),
    phone: yup.mixed().when('id', (id: string) => id
      ? yup.mixed().notRequired()
      : yup.lazy(value => (value ? yup.string().test(phone) : yup.string()))),
  }),
  recipientsBuyers: yup.object().shape({
    buyingCompanyName: yup.string().when('isCompanyBuying', (isCompanyBuying: boolean) => isCompanyBuying
      ? yup.string().trim().required()
      : yup.string().notRequired()),
  }),
});

export const recipientSellerSchema = yup.object().shape({
  birthDate: yup.string().ensure().required(),
  birthPlace: yup.string().required(),
  email: yup.string().required().email(),
  firstName: yup.string().required(),
  homeAddress: yup.string().required(),
  lastName: yup.string().required(),
  phoneNumber: yup.lazy(value => (value ? yup.string().test(phone) : yup.string())),
  proxyName: yup.string().when('isProxy', (isProxy: boolean) => isProxy
    ? yup.string().trim().required()
    : yup.mixed().notRequired()),
});

export const recipientBuyerSchema = yup.object().shape({
  email: yup.string().required().email(),
  firstName: yup.string().trim().required(),
  lastName: yup.string().trim().required(),
  phoneNumber: yup.lazy(value => (value ? yup.string().test(phone) : yup.string())),
  proxyName: yup.string().when('isProxy', (isProxy: boolean) => isProxy
    ? yup.string().trim().required()
    : yup.mixed().notRequired()),
});

export const negotiationParticipantsSchema = yup.object().shape({
  recipientsBuyers: yup.object().shape({
    buyingCompanyName: yup.string().when('isCompanyBuying', (isCompanyBuying: boolean) => isCompanyBuying
      ? yup.string().trim().required()
      : yup.string().notRequired()),
    recipients: yup.array().min(1).required().of(recipientBuyerSchema),
  }),
  recipientsSellers: yup.object().shape({
    companyOwningPropertyName: yup.string().when('isPropertyOwnedByCompany', (isPropertyOwnedByCompany: boolean) =>
      isPropertyOwnedByCompany
        ? yup.string().trim().required()
        : yup.string().notRequired()),
    recipients: yup.array().min(1).required().of(recipientSellerSchema),
  }),
});

export const propertySchema = yup.object().shape({
  address: yup.string().required(),
  carrezSize: yup.mixed().when('type', (type: string) => type === EPropertyType.Apartment
    ? yup.number().nullable().required().moreThan(0)
    : yup.mixed().notRequired()),
  propertySize: yup.mixed().when('type', (type: string) => type !== EPropertyType.Apartment
    ? yup.number().nullable().required().moreThan(0)
    : yup.mixed().notRequired()),
  type: yup.string().required(),
});

export const eventSchema = yup.object().shape({
  agentID: yup.string().required(),
  date: yup.string().required(),
  endTime: yup.string().notOneOf(['00:00'], { error: 'value_must_be_higher', value: 0 }),
  startTime: yup.string().required(),
});

export const agencyFeesSchema = yup.object().shape({
  fixedValue: yup.number().when(['type'], {
    is: (value: EAgencyFeesType) => value === EAgencyFeesType.Fixed,
    otherwise: () => yup.number().nullable().notRequired(),
    then: schema => schema.required().moreThan(0),
  }),
  percentageValue: yup.number().when(['type'], {
    is: (value: EAgencyFeesType) => value === EAgencyFeesType.Percentage,
    otherwise: () => yup.number().nullable().notRequired(),
    then: schema => schema.required().moreThan(0).lessThan(100),
  }),
  type: yup.string().required(),
  useDefault: yup.boolean().nullable(),
});

export const appointmentSchema = yup.object().shape({
  title: yup.string().required(),
});

export const mandateRecipientSchema = yup.object().shape({
  address: yup.string().required(),
  birthDate: yup.string().ensure().required(),
  birthPlace: yup.string().required(),
  civility: yup.string().required(),
  email: yup.string().when(
    ['$sendMethod'],
    (sendMethod: EMandateSendMethod) => sendMethod === 'esign'
      ? yup.string().required().email()
      : yup.lazy(value => (!value ? yup.string() : yup.string().email())),
  ),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phone: yup.lazy(value => (value ? yup.string().test(phone) : yup.string())),
});

export const mandateDocumentStepSchemas = {
  conditions: yup.object().shape({
    documentListToProvideDaysDelay: yup.number().required().moreThan(0),
    mandateIrrevocabilityMonthsDuration: yup.number().required().moreThan(0),
    mandateMonthsDuration: yup.number().required().moreThan(0),
    mandateStartAt: yup
      .string()
      .when('mandateStartAtSignature', (mandateStartAtSignature: boolean) => mandateStartAtSignature
        ? yup.mixed().notRequired()
        : yup.string().ensure().required()),
    sequestreOrder: yup.string().required(),
  }),
  designation: yup.object().shape({
    propertyAddress: yup.string().required(),
    propertyDesignation: yup.string().required(),
    propertyNature: yup.string().ensure().required(),
    propertyNatureOther: yup.string().when('propertyNature', (propertyNature: string) => propertyNature === 'other'
      ? yup.string().required()
      : yup.mixed().notRequired()),
  }),
  'mandate-recipient': yup.object().shape({
    mandateRecipients: yup.array().min(1).required().of(mandateRecipientSchema),
    owningSocietyName: yup.string().when('ownedBySociety', (ownedBySociety: boolean) =>
      ownedBySociety ? yup.string().required() : yup.mixed().notRequired()),
  }),
  'price-and-remuneration': yup.object().shape({
    agencyFeesFixedValue: yup
      .number()
      .when(
        ['agencyFeesType', 'sellingPrice'],
        // TODO: remove once yup typing is fixed, see https://github.com/jquense/yup/issues/1529
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (agencyFeesType: string, sellingPrice: number) => agencyFeesType === EAgencyFeesType.Fixed
          ? yup.number().required().moreThan(0).lessThan(sellingPrice, 'must_be_less_than_selling_price')
          : yup.mixed().notRequired()),
    agencyFeesPercentageValue: yup
      .number()
      .when('agencyFeesType', (agencyFeesType: string) => agencyFeesType === EAgencyFeesType.Percentage
        ? yup.number().required().moreThan(0).max(100)
        : yup.mixed().notRequired()),
    mandateSemiExclusivePercentageKept: yup.number().when('nature', (nature: string) =>
      nature === EMandateType.SemiExclusive
        ? yup.number().required().max(100)
        : yup.mixed().notRequired()),
    sellingPrice: yup.number().required().moreThan(0),
  }),
  type: yup.object().shape({
    agentId: yup.string().uuid('field_required').nullable().required(),
    nature: yup.string().ensure().required('type_field_required'),
  }),
};

export const mandateDocumentSchema = Object.values(
  mandateDocumentStepSchemas,
).reduce((mergedSchema, schema) =>
  // TODO: remove once yup typing is fixed, see https://github.com/jquense/yup/issues/1155
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  mergedSchema.concat(schema),
);

export const negotiationFundingSchema = yup.object().shape({
  expiresOn: yup.string().required(),
  funding: yup.array().of(
    yup.object().shape({
      amount: yup.number().required().min(0),
      duration: yup.number().required().min(0),
      maximumInterestRate: yup.number().required().min(0),
      type: yup.string().matches(/(cash|loan|bridging_loan)/),
    }),
  ),
  isSellerOffer: yup.boolean().required(),
  note: yup.string(),
  price: yup.number().required().moreThan(0),
});

export const negotiationConditionsSchema = yup.object().shape({
  sequestrationRate: yup.number().nullable().min(0).max(100),
});

export const signinSchema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required(),
});

const password = yup.string().required()
  .min(14, 'password_min_length')
  .matches(/[A-Z]/, 'password_uppercase')
  .matches(/[a-z]/, 'password_lowercase')
  .matches(/[0-9]/, 'password_number');

export const passwordSchema = yup.object().shape({
  password,
  passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'password_confirm_mismatch'),
});

export const boAccountSchema = yup.object().shape({
  email: yup.string().required().email(),
  name: yup.string().required(),
  password,
});

export const sellerLeadSchema = yup.object().shape({
  sellerEmail: yup.string().nullable().notRequired().email(),
  sellerLastName: yup.string().required(),
  sellerPhone: yup.lazy(value => value ? yup.string().test(phone) : yup.string().nullable()),
  source: yup.string().when(
    ['isLeadSourceMandatory'],
    (isLeadSourceMandatory: boolean) => isLeadSourceMandatory
      ? yup.string().nullable().required()
      : yup.string().nullable().notRequired(),
  ),
  sourceUrl: yup.string().nullable().notRequired().url(),
});

export const selectAgencySchema = yup.object().shape({
  agencyId: yup.string().required(),
});

export const taskSchema = yup.object().shape({
  date: yup.string().required(),
  time: yup.string().notRequired(),
  title: yup.string().required(),
});

export const agencySchema = yup.object().shape({
  name: yup.string().required(),
});

export const agentSchema = yup.object().shape({
  email: yup.string().required().email(),
  endedAt: yup.string().nullable(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  mandateIntroduction: yup.string().required(),
  phone: yup.string().required().test(phone),
  startedAt: yup.string().required(),
});

export const manageRightsSchema = yup.object().shape({
  estimationApproverAgentId: yup.string().when(
    ['hasEstimationApprover'],
    (hasEstimationApprover: boolean) => hasEstimationApprover
      ? yup.string().required()
      : yup.string().nullable(),
  ),
  hasEstimationApprover: yup.boolean(),
  hasMandateApprover: yup.boolean(),
  mandateApproverAgentId: yup.string().when(
    ['hasMandateApprover'],
    (hasMandateApprover: boolean) => hasMandateApprover
      ? yup.string().required()
      : yup.string().nullable(),
  ),
});

export const settingsStepSchema = {
  agency: yup.object().shape({
    agencyAddress: yup.string(),
    agencyCity: yup.string(),
    agencyPhone: yup.lazy(value => value ? yup.string().test(phone) : yup.string()),
    agencyProfessionalCardIdentifier: yup.string(),
    agencySiren: yup.string(),
    agencyTransactionalEmailAddress: yup.string().email(),
    agencyTransactionalEmailName: yup.string(),
    agencyZipCode: yup.string(),
  }),
  customerSpace: yup.object().shape({
    customerSpaceDomainName: yup.string(),
  }),
  docusign: yup.object().shape({
    docusignAccountId: yup.string(),
    docusignUniversalTemplateId: yup.string(),
    docusignUserId: yup.string(),
  }),
  estimation: yup.object().shape({
    apiKey: yup.string().nullable().when(['isEstimationReportEnabled'], {
      is: true,
      then: schema => schema.required(),
    }),
    isEstimationReportEnabled: yup.boolean(),
    mandataryNetworkId: yup.string().nullable().when(['isEstimationReportEnabled', 'yanportAgencyId'], {
      is: (isEnabled: boolean, yanportAgencyId: string | null) => isEnabled && !yanportAgencyId,
      then: schema => schema.required(),
    }),
    organizationId: yup.string().nullable().when(['isEstimationReportEnabled'], {
      is: true,
      then: schema => schema.required(),
    }),
    yanportAgencyId: yup.string().nullable().when(['isEstimationReportEnabled', 'mandataryNetworkId'], {
      is: (isEnabled: boolean, mandataryNetworkId: string | null) => isEnabled && !mandataryNetworkId,
      then: schema => schema.required(),
    }),
  }, [['mandataryNetworkId', 'yanportAgencyId']]),
  mandate: yup.object().shape({
    agencyFeesPercentageValue: yup.number(),
    mandateAgencyFeesFixedValue: yup.string(),
    mandateAgencyFeesType: yup.string(),
    mandateBenefitDiagnostics: yup.string(),
    mandateBenefitDiagnosticsOnlyOnSuccess: yup.string(),
    mandateBenefitOthers: yup.string(),
    mandateBenefitProfessionalPhotography: yup.string(),
    mandateBenefitPublish: yup.string(),
    mandateBenefitVisitsCarriedOut: yup.string(),
    mandateBenefitVisitsOrganized: yup.string(),
    mandateCompanyLegalDescription: yup.string(),
    mandateConditionsOthers: yup.string(),
    mandateDocumentListToProvide: yup.string(),
    mandateDocumentListToProvideDelay: yup.string(),
    mandateDpeDiagnostic: yup.string(),
    mandateDpeDiagnosticProvider: yup.string(),
    mandateFooter: yup.string(),
    mandateGdprEmail: yup.string(),
    mandateGdprPostalAddress: yup.string(),
    mandateHeader: yup.string(),
    mandateLogbookFormat: yup.string(),
    mandateMandateIrrevocabilityMonthsDuration: yup.string(),
    mandateMandateMonthsDuration: yup.string(),
    mandateMandateStartAtSignature: yup.string(),
    mandateSendMethod: yup.string(),
    mandateSequestreOrder: yup.string(),
    semiExclusivePercentageKept: yup.number(),
  }),
  mandateTemplate: yup.object().shape({
    mandateTemplate: yup.string(),
  }),
  messages: yup.object().shape({
    matchingEmailAgentMessage: yup.string(),
  }),
  offer: yup.object().shape({
    offersDocumentAgencyAgencyDesignation: yup.string(),
    offersDocumentAgencyDefaultSequestrationRate: yup.string(),
    offersDocumentAgencyLegalConditions: yup.string(),
    offersDocumentAgencyLogoUrl: yup.string(),
  }),
  others: yup.object().shape({
    hasManualReferenceDisabled: yup.string(),
  }),
  publication: yup.object().shape({
    advertisementBaseUrl: yup.string(),
    isPublicationDescriptionEnabled: yup.boolean(),
    model: yup.string().when(['isPublicationDescriptionEnabled'], {
      is: true,
      then: schema => schema.required(),
    }),
    prompt: yup.string().when(['isPublicationDescriptionEnabled'], {
      is: true,
      then: schema => schema.required(),
    }),
    publicationUbiflowFileName: yup.string(),
    publicationUbiflowLogin: yup.string(),
    publicationUbiflowMediasList: yup.string(),
    publicationUbiflowPassword: yup.string(),
  }),
  showcase: yup.object().shape({
    showcaseAgencyAddress: yup.string(),
    showcaseAgencyDescription: yup.string(),
    showcaseAgencyEmail: yup.string().email(),
    showcaseAgencyName: yup.string(),
    showcaseAgencyPhoneNumber: yup.string(),
    showcaseAgencyTitle: yup.string(),
    showcaseContactDescription: yup.string(),
    showcaseContactTitle: yup.string(),
    showcaseDomainName: yup.string(),
    showcaseFacebookUrl: yup.string(),
    showcaseFooterCopyright: yup.string(),
    showcaseHeaderPictureUrl: yup.string(),
    showcaseHeaderTitle: yup.string(),
    showcaseInstagramUrl: yup.string(),
    showcaseLegalNotice: yup.string(),
    showcaseLinkedinUrl: yup.string(),
    showcaseLogoOverlap: yup.string(),
    showcasePrimaryColor: yup.string(),
    showcaseSecondaryColor: yup.string(),
    showcaseTermsOfUse: yup.string(),
    showcaseTwitterUrl: yup.string(),
  }),
};

export const settingsSchema = Object.values(
  settingsStepSchema,
).reduce((mergedSchema, schema) =>
  // TODO: remove once yup typing is fixed, see https://github.com/jquense/yup/issues/1155
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  mergedSchema.concat(schema),
);
