
import { Component, Vue } from 'vue-property-decorator';

import { EConditions } from '@/enums/Conditions.enum';
import { EContactRole } from '@/enums/ContactRole.enum';
import { INegotiationOfferRequest } from '@/api/dome/negotiations/Negotiations.interface';
import { INegotiationRecipient } from '@/api/dome/negotiationRecipients/NegotiationRecipients.interface';
import { NegotiationConditions } from '@/views/entity/steps/NegotiationConditions';
import { NegotiationFunding } from '@/views/entity/steps/NegotiationFunding';

@Component
export default class NegotiationHelpers extends Vue {
  computeNegotiationRecipients(recipients: INegotiationRecipient[]): Record<EContactRole, INegotiationRecipient[]> {
    const sortRecipients = this.getNegotiationRecipientsSorted(recipients);

    return sortRecipients.reduce<Record<EContactRole, INegotiationRecipient[]>>((acc, recipient) => {
      acc[recipient.type].push(recipient);

      return acc;
    }, { [EContactRole.Buyer]: [], [EContactRole.Seller]: [] });
  }

  generateOfferDataForRequest(
    negotiationFunding: NegotiationFunding | undefined,
    negotiationConditions: NegotiationConditions | undefined,
  ): INegotiationOfferRequest {
    return {
      additionalSuspensiveConditions: negotiationConditions?.additionalSuspensiveConditions as string,
      expiresOn: negotiationFunding?.expiresOn as string,
      funding: negotiationFunding?.funding,
      isSellerOffer: negotiationFunding?.isSellerOffer as boolean,
      otherAdditionalModalities: negotiationConditions?.otherAdditionalModalities as string,
      price: negotiationFunding?.price as number,
      sequestrationRate: negotiationConditions?.sequestrationRate as number,
      suspensiveConditions: negotiationConditions?.suspensiveConditions as EConditions[],
    };
  }

  getNegotiationRecipientsSorted(recipients: INegotiationRecipient[]): INegotiationRecipient[] {
    return recipients.sort((a, b) => {
      if (a.personId !== null && b.personId === null) {
        return -1;
      } else if (a.personId === null && b.personId !== null) {
        return 1;
      }

      return 0;
    });
  }
}
