



















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class StatusLabel extends Vue {
  @Prop({ default: false, type: Boolean }) active!: boolean;
  @Prop({ default: null, type: String }) dataTestId!: string | null;
  @Prop({ default: false, type: Boolean }) isError!: boolean;
  @Prop({ default: false, type: Boolean }) isSmall!: boolean;
  @Prop({ default: false, type: Boolean }) secondary!: boolean;
  @Prop({ required: true, type: [String, Number] }) status!: [string, number];
}
