export enum EHeaterMechanismType {
  ConvectionHeater = 'convection_heater',
  Fireplace = 'fireplace',
  FireplaceInsert = 'fireplace_insert',
  HeatPump = 'heat_pump',
  HeatTransferFluid = 'heat_transfer_fluid',
  RadiantHeater = 'radiant_heater',
  Radiator = 'radiator',
  ReversibleAirConditioning = 'reversible_air_conditioning',
  StorageHeater = 'storage_heater',
  SubfloorHeater = 'subfloor_heater',
  WarmAirHeater = 'warm_air_heater',
  WoodStove = 'wood_stove',
}
