import {
  ICreatePropertyRenovationPayload,
  IPropertyRenovation,
  IPropertyRenovationsList,
  IUpdatePropertyRenovationPayload,
} from './Renovations.interface';
import { LkAxiosInstance } from '@/api';

const BASE_ENDPOINT = '/property_renovations';

export interface IRenovationsService {
  create(payload: ICreatePropertyRenovationPayload): Promise<IPropertyRenovation>;
  delete(id: string): Promise<void>;
  get(id: string): Promise<IPropertyRenovation>;
  getByPropertyId(propertyId: string): Promise<IPropertyRenovation[]>;
  update(payload: IUpdatePropertyRenovationPayload): Promise<IPropertyRenovation>;
}

export const renovationsService = ($axios: LkAxiosInstance): IRenovationsService => ({
  create(payload) {
    return $axios.$post(BASE_ENDPOINT, payload);
  },
  delete(id) {
    return $axios.$delete(`${BASE_ENDPOINT}/${id}`);
  },
  get(id) {
    return $axios.$get(`${BASE_ENDPOINT}/${id}`);
  },
  async getByPropertyId(propertyId) {
    const response = await $axios.$get<IPropertyRenovationsList>(`${BASE_ENDPOINT}/?property_id=${propertyId}`);

    return response.propertyRenovations;
  },
  update(payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${payload.id}`, payload);
  },
});
