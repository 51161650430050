import { LkAxiosInstance } from '@/api';
import { EPlanningTab } from '@/enums/PlanningTabs.enum';
import { IPlanning } from '@/interfaces/models/Planning.interface';
import { IPlanningEvent } from '@/interfaces/models/PlanningEvent.interface';

export interface IPlanningService {
  getPlanning(agentId: string, type: EPlanningTab): Promise<IPlanning>;
}

export const planningService = ($axios: LkAxiosInstance): IPlanningService => ({
  async getPlanning(agentId, type) {
    const planning = await $axios.$get<IPlanning>(`/planning_for_${type}_events?agent_id=${agentId}`);

    planning.events.forEach((event: IPlanningEvent) => {
      delete event.links;
    });

    return planning;
  },
});
