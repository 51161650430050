var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"stepper relative w-full sm:max-w-sm"},[_c('ul',{staticClass:"m-8"},_vm._l((_vm.steps),function(step,index){return _c('li',{key:("step-" + (step.name)),staticClass:"stepper__item",class:{
        'stepper__item--active': _vm.stepIndex === index,
        'stepper__item--filled': step.isFilled,
        'stepper__item--has-error': step.hasError,
        'stepper__item--has-warning': step.hasWarning,
        'pointer-events-none': _vm.disabled
      }},[_c('p',{staticClass:"relative flex items-center",class:{ 'cursor-pointer': _vm.stepIndex !== index },on:{"click":function($event){return _vm.$emit('update', index)}}},[(_vm.stepIndex !== index && (step.hasError || step.hasWarning || step.isFilled))?_c('span',{staticClass:"stepper__item__icon",class:{
            'text-lk-red': step.hasError,
            'text-lk-yellow': step.hasWarning,
            'text-lk-blue': step.isFilled
          }},[_c('BaseIcon',{attrs:{"icon":(step.hasError || step.hasWarning) ? _vm.$icons.warning : _vm.$icons.check,"size":_vm.$sizes.sm}})],1):_vm._e(),_c('span',{staticClass:"stepper__item__name rounded-md px-2 py-1",attrs:{"data-test-id":"funnel-step"}},[_vm._v(" "+_vm._s(step.name)+" ")])]),(step.isOptional)?_c('div',{staticClass:"absolute ml-8 font-semibold text-lk-gray"},[_vm._v(" "+_vm._s(_vm.$t('common.optional'))+" ")]):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }