import { ReleaseToggles } from './release-toggles';
import { ToggleRouter, Toggles } from './toggle-router';

export interface IFeatureTogglesConfig {
  release: Toggles;
}

export class FeatureToggles {
  readonly release: ReleaseToggles;

  constructor(config: IFeatureTogglesConfig) {
    this.release = new ReleaseToggles(new ToggleRouter(config.release));
  }
}