import { LkAxiosInstance } from '@/api';
import { EMatchStatus } from '@/enums/MatchStatus.enum';
import { IMatch, IMatchResponse, IMatchingDefaultMessage } from '@/api/dome/matching/Matching.interface';

export interface IMatchingService {
  generate(params: { personId?: string, propertyId?: string }): Promise<IMatch[]>;
  getDefaultMessage(): Promise<string>;
  sendEmailToAll(params: { agentMessage?: string, personId?: string, propertyId?: string }): Promise<IMatch[]>;
  sendEmailToOneMatch(matchId: string, agentMessage?: string): Promise<IMatch>;
  setNotInterested(matchId: string, token?: string): Promise<IMatch>;
}

const BASE_ENDPOINT = '/matching';

const getStatus = (match: IMatchResponse): EMatchStatus | null => {
  if (match.notInterested) {
    return EMatchStatus.NotInterested;
  } else if (match.emailSentAt) {
    return EMatchStatus.Contacted;
  }

  return null;
};

const formatMatch = (matchResponse: IMatchResponse): IMatch => ({
  ...matchResponse,
  status: getStatus(matchResponse),
});

export const matchingService = ($axios: LkAxiosInstance): IMatchingService => ({
  async generate(params) {
    const matches = await $axios.$post<IMatchResponse[]>('/matching/generate', params);

    return matches.map(match => formatMatch(match));
  },

  async getDefaultMessage() {
    const { agentMessage } = await $axios.$get<IMatchingDefaultMessage>('/matching_default_agent_message');

    return agentMessage;
  },

  async sendEmailToAll(params) {
    const matches = await $axios.$post<IMatchResponse[]>(`${BASE_ENDPOINT}/send_emails`, params);

    return matches.map(match => formatMatch(match));
  },

  async sendEmailToOneMatch(matchId, agentMessage) {
    const match = await $axios.$post<IMatchResponse>(`${BASE_ENDPOINT}/matches/${matchId}/send_email`, {
      agentMessage,
    });

    return formatMatch(match);
  },

  async setNotInterested(matchId, token) {
    let path = 'set_person_not_interested';

    if (token) {
      path += `_by_token?token=${token}`;
    }

    const match = await $axios.$post<IMatchResponse>(`${BASE_ENDPOINT}/matches/${matchId}/${path}`);

    return formatMatch(match);
  },
});