
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { EPersonContactType } from '@/enums/PersonContactType.enum';
import { getPrimaryContacts } from '@/helpers/contacts';
import { IOption } from '@/interfaces/Option.interface';
import { IPerson } from '@/api/dome/persons/Persons.interface';
import { IResultItem } from '@/interfaces/ResultItem.interface';
import { ISelectedEntities } from '@/interfaces/entity/SelectedEntities.interface';
import { parseError } from '@/utils/validation';

@Component
export default class PersonStepHelper extends Vue {
  @Prop({ type: Object }) selectedEntities!: ISelectedEntities;

  isLoadingPersons = false;
  personResults: IPerson[] = [];
  personSearch = '';
  selectedPerson: IPerson | null = null;

  @Watch('selectedEntities', { deep: true, immediate: true })
  async getSelectedPerson(): Promise<void> {
    try {
      this.selectedPerson = this.selectedEntities?.person
        ? await this.$api.persons.getById(this.selectedEntities.person)
        : null;
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
    }
  }

  get EPersonContactType(): typeof EPersonContactType {
    return EPersonContactType;
  }

  get formattedPersonResults(): IResultItem[] {
    return this.personResults.map(person => ({
      ...person,
      content: `<span class="mr-2 group-hover:text-lk-blue">${person.fullName}</span>
      <span class="text-xs text-lk-gray">${getPrimaryContacts(person.personContacts).email}</span>`,
    }));
  }

  clearPersonValue(): void {
    this.$emit('select-entity', 'person', null);
    this.personSearch = '';
  }

  handlePersonSuggestion(item: IOption<string>): void {
    this.$emit('select-entity', 'person', item.value);
  }

  async searchPerson(value: string, tags?: string[]): Promise<void> {
    this.personSearch = value;

    if (value === '') {
      this.personResults = [];
      this.clearPersonValue();

      return;
    }

    try {
      this.isLoadingPersons = true;
      this.personResults = await this.$api.persons.search(value, tags);
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
      this.personResults = [];
    } finally {
      this.isLoadingPersons = false;
    }
  }

  selectPerson(person: IPerson): void {
    if (person) {
      this.$emit('select-entity', 'person', person.id);
    } else {
      this.clearPersonValue();
    }
  }
}
