var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('NavigationTop',_vm._b({on:{"open-drawer":function($event){return _vm.toggleDrawer(true)}}},'NavigationTop',{ isBackButtonVisible: _vm.isBackButtonVisible, notificationBadge: _vm.notificationBadge, title: _vm.title },false),[_c('button',{staticClass:"flex px-2 py-4 text-lk-black hover:text-lk-blue",attrs:{"slot":"global-search","type":"button"},on:{"click":_vm.openGlobalSearch},slot:"global-search"},[_c('BaseIcon',{attrs:{"icon":_vm.$icons.search,"size":_vm.$sizes.lg}})],1)]),_c('Navigation',_vm._b({on:{"close-drawer":function($event){return _vm.toggleDrawer(false)},"toggle-account-dropdown":_vm.toggleAccountDropdown,"toggle-help-dropdown":_vm.toggleSupportDropdown,"update-entity-type":_vm.updateEntityType}},'Navigation',{
      accountEntities: _vm.accountEntities,
      bottomBarRoutes: _vm.bottomBarRoutes,
      creationEntities: _vm.creationEntities,
      isAccountDropdownVisible: _vm.isAccountDropdownVisible,
      isDrawerOpen: _vm.isDrawerOpen,
      isSupportDropdownVisible: _vm.isSupportDropdownVisible,
      primaryRoutes: _vm.primaryRoutes,
      secondaryRoutes: _vm.secondaryRoutes
    },false)),(!_vm.isLoading)?[_c('GlobalSearch'),_vm._l((_vm.creationEntities),function(entity){return _c(entity.value,{key:entity.value,tag:"component",attrs:{"is-wizard-visible":entity.value === _vm.entityType && _vm.isWizardVisible,"suggestion-list":_vm.formattedSuggestionList},on:{"close":_vm.closeEntityCreation,"email-sent":_vm.setEmailSent}})}),_c('portal',{attrs:{"to":"root"}},[_c('Notifications',{attrs:{"is-visible":_vm.isNotificationsMenuVisible},on:{"close":_vm.closeNotificationPanel}})],1)]:_vm._e(),_c('IssueReportDialog',{attrs:{"is-displayed":_vm.isIssueReportDialogVisible},on:{"close":function($event){return _vm.toggleIssueDialog(false)}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }