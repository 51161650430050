import { LkAxiosInstance } from '@/api';
import { ICancelMeeting, ICreateMeetingPayload, ICreateMeetingResponse } from '@/api/dome/meetings/Meetings.interface';

const BASE_ENDPOINT = '/meetings';

export interface IMeetingsService {
  cancel(id: string, payload: ICancelMeeting): Promise<void>;
  createEstimationMeeting(params: ICreateMeetingPayload): Promise<ICreateMeetingResponse>;
}

export const meetingsService = ($axios: LkAxiosInstance): IMeetingsService => ({
  cancel(id, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/cancel`, payload);
  },
  createEstimationMeeting(params) {
    return $axios.$post('/estimation_meetings', params);
  },
});
