

































import { Vue, Component, Prop } from 'vue-property-decorator';

import CircleLoader from '../../atoms/loaders/CircleLoader.vue';
import NoResultsPlaceholder from '@/components/common/molecules/placeholders/NoResultsPlaceholder.vue';
import NotificationItem from '@/components/common/molecules/notification/NotificationItem.vue';

@Component({
  components: {
    CircleLoader,
    NoResultsPlaceholder,
    NotificationItem,
  },
})
export default class NotificationPanel extends Vue {
  @Prop({ default: false, type: Boolean }) isLoading!: boolean;
  @Prop({ required: true, type: Array }) notificationList!: [];
}
