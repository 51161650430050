module.exports = {
  content: ['./src/**/*.{ts,js,vue}'],
  theme: {
    screens: {
      // https://www.freecodecamp.org/news/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862/
      mobile: {
        max: "600px"
      },
      sm: "600px",
      md: "900px",
      lg: "1024px",
      xl: "1280px"
    },
    fontFamily: {
      default: ["Nunito", "sans-serif"]
    },
    boxShadow: {
      "card": "0 1px 8px rgba(0, 32, 97, .15)",
      "panel": "-4px 0 7px rgba(0, 32, 97, .09)",
      "nav": "4px 0 7px rgba(0, 32, 97, .09)",
      "drop": "0px 1px 12px rgba(0, 32, 97, 0.2)",
    },
    fontSize: {
      xxs: '0.625rem', // -> 10px
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.625rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
    },
    extend: {
      colors: {
        "lk-black": "#383838",
        "lk-black-opacity": "rgba(56, 56, 56, 0.5)",
        "lk-black-light-opacity": "rgba(56, 56, 56, 0.7)",
        "lk-dark-gray": "#6B6B6B",
        "lk-gray": "#9E9E9E",
        "lk-gray-opacity": "rgba(149, 149, 149, 0.35)",
        "lk-gray-light": "#DEDEDE",
        "lk-gray-light-opacity": "rgba(222, 222, 222, 0.15)",
        "lk-gray-lighter": "#F7F7F7",
        "lk-gray-status": "#EBEBEB",
        "lk-gray-darkest": "#0D0D20",
        "lk-white-hover": "rgba(0, 0, 0, 0.04)",
        "lk-white-focus": "rgba(0, 0, 0, 0.12)",
        "lk-white-opacity": "rgba(255, 255, 255, 0.9)",
        "lk-blue": "var(--color-blue)",
        "lk-blue-hover": "var(--color-blue-hover)",
        "lk-blue-light-hover": "var(--color-blue-light-hover)",
        "lk-blue-focus": "var(--color-blue-focus)",
        "lk-blue-opacity": "var(--color-blue-light-opacity)",
        "lk-blue-selection": "var(--color-blue-selection)",
        "lk-blue-shadow": "var(--color-blue-shadow)",
        "lk-blue-shadow-light": "var(--color-blue-shadow-light)",
        "lk-blue-status": "var(--color-blue-status)",
        "lk-green": "var(--color-green)",
        "lk-green-status": "var(--color-green-status)",
        "lk-green-light": "var(--color-green-light)",
        "lk-orange": "var(--color-orange)",
        "lk-orange-light": "var(--color-orange-light)",
        "lk-purple": "var(--color-purple)",
        "lk-red": "var(--color-red)",
        "lk-red-hover": "var(--color-red-light)",
        "lk-red-light": "var(--color-red-lighter)",
        "lk-red-light-hover": "var(--color-red-lighter-opacity)",
        "lk-red-selection": "var(--color-red-hover-light-opacity)",
        "lk-red-selection-hover": "var(--color-red-hover)",
        "lk-yellow": "var(--color-yellow)",
        "lk-yellow-light": "var(--color-yellow-light)",
        "lk-snackbar-confirm": "rgba(56, 56, 56, 0.95)",
        "lk-snackbar-error": "rgba(234, 59, 98, 0.95)",
        "lk-snackbar-success": "rgba(0, 186, 108, 0.95)",
      },
      fill: theme => ({
        ...theme("colors")
      }),
      stroke: theme => ({
        ...theme("colors")
      }),
      zIndex: {
        "1": 1,
        "2": 2
      },
      minHeight: {
        "56": "3.5rem",
        "64": "4rem"
      },
      width: {
        "3/16": "18.75%",
        "6/16": "37.5%",
        "15/16": "93.75%",
      },
      maxWidth: {
        xxs: "13rem",
        "1/3": "33.33%",
        "panel-card": "28rem",
        "2xl": "44rem"
      },
      height: {
        "18": "4.5rem"
      },
      opacity: {
        "35": ".35"
      },
      flex: {
        "3/2": "1.5",
        "2": "2"
      }
    }
  }
};
