





































































import { Component, Prop, Watch } from 'vue-property-decorator';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import maskInput, { conformToMask } from 'vanilla-text-mask';

import BaseTips from '@/components/common/molecules/BaseTips.vue';
import FieldComponent from '@/components/common/molecules/forms/FieldComponent.vue';

let inputId = 0;

@Component({
  components: {
    BaseTips,
  },
})
export default class BasePriceInput extends FieldComponent<number | null> {
  @Prop({ default: 0, type: Number }) debounceDelay!: number;
  @Prop({ default: false, type: Boolean }) hasError!: boolean;
  @Prop({ default: () => ([]), type: Array }) inputClass!: string[];
  @Prop({ default: false, type: Boolean }) isFloat!: boolean;
  @Prop({ default: false, type: Boolean }) isValueWatched!: boolean;
  @Prop({ default: Number.MAX_SAFE_INTEGER, type: Number }) max!: number;
  @Prop({ default: false, type: Boolean }) nullable!: boolean;
  @Prop({ type: String }) unit?: string;

  error = '';
  fieldValue = '';
  id = `price-input-${inputId++}`;
  isFieldClearable = false;
  isInitialValueHidden = false;
  numberMask = createNumberMask({
    allowDecimal: true,
    decimalSymbol: ',',
    prefix: '',
    thousandsSeparatorSymbol: ' ',
  });
  timeout: ReturnType<typeof setTimeout> | null = null;

  @Watch('value')
  manageValueUpdate(): void {
    if (this.isValueWatched) {
      this.fieldValue = this.getFormattedValue();
    }
  }

  get displayedUnit(): string {
    return this.unit || this.$t('units.euros');
  }

  get showPreviousValue(): boolean {
    return !!this.previousValue && this.value !== this.previousValue;
  }

  created() {
    if (this.value === null || (!this.nullable && this.value === 0)) {
      this.isInitialValueHidden = true;
    } else {
      this.fieldValue = this.getFormattedValue();
    }
  }

  mounted(): void {
    maskInput({ inputElement: this.$refs.input, mask: this.numberMask });
  }

  beforeDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  getFormattedValue(): string {
    if (this.value === null) {
      return '';
    }

    const value = this.isFloat ? this.value : this.value / 100;

    return conformToMask(
      value.toString().replace(/[.]/g, ','),
      this.numberMask,
      { guide: false },
    ).conformedValue;
  }

  manageBlur({ target }: { target: HTMLInputElement }): void {
    this.fieldValue = target.value || '';

    if (this.isFieldClearable && this.nullable) {
      this.update(null);
      this.isFieldClearable = false;
    }

    this.$emit('blur');
  }

  manageFocus({ target }: { target: HTMLInputElement }): void {
    this.isFieldClearable = target.value === '0';
    this.fieldValue = this.isFieldClearable ? '' : target.value;
    this.$emit('focus');
  }

  manageInput({ target }: { target: HTMLInputElement }): void {
    this.isFieldClearable = false;
    this.fieldValue = target.value;

    let rawValue = parseFloat(target.value.replace(/\s/g, '').replace(/,/g, '.'));
    let max = this.max;

    if (!this.isFloat) {
      rawValue = Math.trunc(rawValue * 100);
      max = max * 100;
    }

    this.error = rawValue > max
      ? this.$t('errors.value_must_be_lower_or_equal', { value: this.$n(this.max) })
      : '';

    let finalValue: number | null = rawValue;

    if (target.value === '') {
      finalValue = this.nullable ? null : 0;
    }

    this.update(finalValue);
  }

  manageKeyPress(keyEvent: KeyboardEvent): void {
    const inputPattern = /^[0-9.,]*$/;

    if (!keyEvent.key.match(inputPattern)) {
      keyEvent.preventDefault();
    } else if (keyEvent.key === '.') {
      (this.$refs.input as HTMLInputElement).value += ',';
    }
  }

  update(value: number | null): void {
    if (this.debounceDelay) {
      this.clearTimeout();

      this.timeout = setTimeout(() => {
        this.$emit('update', value);
      }, this.debounceDelay);

    } else {
      this.$emit('update', value);
    }
  }
}
