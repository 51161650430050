import { ELoanStatus } from '@/enums/LoanStatus.enum';
import { EPropertyType } from '@/enums/PropertyType.enum';
import { IBuyersSearchCriterias } from '@/api/dome/buyers-search/BuyersSearch.interface';
import { IBuyersSearchCriteriasForm, IBuyersSearchRouteQuery } from '@/interfaces/BuyersSearchFilters.interface';

export const getCriteriasAsQuery = (
  criterias: IBuyersSearchCriterias, propertyId?: string,
): IBuyersSearchRouteQuery => ({
  fundingStatus: criterias.fundingStatus?.toString(),
  geoCityIds: criterias.geoCityIds?.toString(),
  maxPrice: criterias.maxPrice?.toString(),
  maxSurface: criterias.maxSurface?.toString(),
  minPrice: criterias.minPrice?.toString(),
  minSurface: criterias.minSurface?.toString(),
  propertyId,
  roomsCount: criterias.roomsCount?.toString(),
  types: criterias.types?.toString(),
});

export const getCriteriasAsQueryFromForm = (
  criterias: IBuyersSearchCriteriasForm, propertyId?: string,
): IBuyersSearchRouteQuery => ({
  fundingStatus: criterias.fundingStatus.length ? criterias.fundingStatus.toString() : undefined,
  geoCityIds: criterias.geoCities.map(({ id }) => id).toString(),
  maxPrice: criterias.price.max ? criterias.price.max.toString() : undefined,
  maxSurface: criterias.surface.max ? criterias.surface.max.toString() : undefined,
  minPrice: criterias.price.min ? criterias.price.min.toString() : undefined,
  minSurface: criterias.surface.min ? criterias.surface.min.toString() : undefined,
  propertyId,
  roomsCount: criterias.roomsCount.length ? criterias.roomsCount.toString() : undefined,
  types: criterias.types.length ? criterias.types.toString() : undefined,
});

export const getCriteriasAsProp = (
  query: IBuyersSearchRouteQuery,
): IBuyersSearchCriterias => ({
  fundingStatus: query.fundingStatus?.split(',') as ELoanStatus[],
  geoCityIds: query.geoCityIds?.split(','),
  maxPrice: query.maxPrice ? Number(query.maxPrice) : undefined,
  maxSurface: query.maxSurface ? Number(query.maxSurface) : undefined,
  minPrice: query.minPrice ? Number(query.minPrice) : undefined,
  minSurface: query.minSurface ? Number(query.minSurface) : undefined,
  propertyId: query.propertyId,
  roomsCount: query.roomsCount?.split(',').map(room => Number(room)),
  types: query.types?.split(',') as EPropertyType[],
});