export enum EPersonManualSource {
  BuyerWithSaleProject = 'buyer_with_sale_project',
  DoorToDoor = 'door_to_door',
  Droppy = 'droppy',
  Other = 'other',
  Partners = 'partners',
  Portal = 'portal',
  PreviousBuyer = 'previous_buyer',
  Prospectus = 'prospectus',
  Recommendation = 'recommendation',
  Showcase = 'showcase',
  Sponsorship = 'sponsorship',
  UrbanAdvertising = 'urban_advertising'
}
