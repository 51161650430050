














































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { AisHighlight } from 'vue-instantsearch';

import { ISellerLeadResult } from '@/interfaces/SearchLeadHit.interface';

@Component({
  components: {
    AisHighlight,
  },
})
export default class BuyerLeadCardSearch extends Vue {
  @Prop({ required: true, type: Object }) lead!: ISellerLeadResult;
}
