var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown-container mobile:fixed mobile:inset-0 mobile:bottom-16 mobile:bg-lk-black-opacity",attrs:{"data-test-id":"dropdown-container"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.$emit('close')}}},[_c('div',{staticClass:"dropdown border-b border-lk-gray-light bg-white text-left text-lk-black mobile:fixed sm:border-none sm:shadow-drop"},[(_vm.title)?_c('p',{staticClass:"border-b border-lk-gray-light px-4 py-3 text-2xl font-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('div',{class:{
        'sm:flex': _vm.distributedItems.left.length,
        'py-2 sm:py-4': _vm.items.length > 1,
        'py-0': _vm.items.length === 1
      }},[(_vm.distributedItems.left.length)?_c('ul',{staticClass:"border-b border-lk-gray-light sm:w-1/2 sm:border-b-0 sm:border-r"},_vm._l((_vm.distributedItems.left),function(item,index){return _c('li',{key:("dropdown-item-" + index)},[_c('button',{staticClass:"group flex w-full items-center px-4 py-3 leading-6 hover:bg-lk-blue-selection hover:text-lk-blue",class:{ 'font-bold': _vm.hasContentBolded },attrs:{"data-test-id":("dropdown-item-" + (item.value)),"type":"button"},on:{"click":function($event){return _vm.$emit('item-click', item.value)}}},[_c('BaseIcon',{staticClass:"mr-6 text-lk-gray group-hover:text-lk-blue",attrs:{"icon":item.icon}}),_c('span',{staticClass:"truncate"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)])}),0):_vm._e(),_c('ul',{class:{ 'sm:w-1/2': _vm.distributedItems.left.length }},_vm._l((_vm.distributedItems.right),function(ref){
      var icon = ref.icon;
      var name = ref.name;
      var userName = ref.userName;
      var value = ref.value;
return _c('li',{key:("dropdown-item-" + value)},[_c('button',{staticClass:"group flex w-full items-center px-4 py-3 leading-6 hover:bg-lk-blue-selection hover:text-lk-blue",class:{ 'font-bold': _vm.hasContentBolded, 'sm:w-52': _vm.items.length === 1 },attrs:{"data-test-id":("dropdown-item-" + value),"type":"button"},on:{"click":function($event){return _vm.$emit('item-click', value)}}},[_c('BaseIcon',{staticClass:"mr-6 text-lk-gray group-hover:text-lk-blue",attrs:{"icon":icon}}),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(name))]),(userName)?_c('span',{staticClass:"ml-1 text-lk-gray"},[_vm._v(_vm._s(userName))]):_vm._e()],1)])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }