import { ECondominiumFrequency } from '@/enums/CondominiumFrequency.enum';
import { EExchangeComments } from '@/enums/ExchangeComments.enum';
import { EHeaterMechanismType } from '@/enums/HeaterMechanismType.enum';
import { EHeaterModeType } from '@/enums/HeaterModeType.enum';
import { EHeaterType } from '@/enums/HeaterType.enum';
import { EPersonContactContext } from '@/enums/PersonContactContext.enum';
import { EPersonContactType } from '@/enums/PersonContactType.enum';
import { EPropertyKitchenEquipment } from '@/enums/PropertyKitchenEquipment.enum';
import { EPropertyKitchenType } from '@/enums/PropertyKitchenType.enum';
import { EPropertyProgress } from '@/enums/PropertyProgress.enum';
import { EPropertyStatus } from '@/enums/PropertyStatus.enum';
import { EPropertyTransactionMode } from '@/enums/PropertyTransactionMode.enum';
import { EPropertyType } from '@/enums/PropertyType.enum';
import { ERentChargesRecoveryMethod } from '@/enums/RentChargesRecoveryMethod.enum';
import { EWindowGlazing } from '@/enums/WindowGlazing.enum';
import { IOption } from '@/interfaces/Option.interface';
import i18n from '@/plugins/i18n';

const currentYear = new Date().getFullYear();

type Options<T = string> = readonly IOption<T>[];

export const contactContextOptions: IOption<EPersonContactContext>[] =
  Object.values(EPersonContactContext).map(value => ({
    label: i18n.t(`person.contact.context.${value}`),
    value,
  }));

export const contactTypeOptions: IOption<EPersonContactType>[] =
  Object.values(EPersonContactType).map(value => ({
    label: i18n.t(`person.contact.types.${value}`),
    value,
  }));

export const exchangeCommentsOptions: Options<EExchangeComments> = Object.freeze([
  EExchangeComments.DidNotAnswer,
  EExchangeComments.SmsSent,
  EExchangeComments.EmailSent,
  EExchangeComments.Voicemail,
].map(value => ({
  label: i18n.t(`common.reminder.comments.${value}`),
  value,
})));

export const getConditionOptions = (): IOption[] => [
  {
    label: i18n.t('f95.state.bad'),
    value: 'awful',
  },
  {
    label: i18n.t('f95.state.quite_good'),
    value: 'passable',
  },
  {
    label: i18n.t('f95.state.good'),
    value: 'good',
  },
  {
    label: i18n.t('f95.state.very_good'),
    value: 'great',
  },
  {
    label: i18n.t('f95.state.perfect'),
    value: 'perfect',
  },
];

export const getCondominiumFrequencyOptions = (): IOption<ECondominiumFrequency>[] => [
  {
    label: i18n.t('f95.form.condominium_frequencies.monthly'),
    value: ECondominiumFrequency.Monthly,
  },
  {
    label: i18n.t('f95.form.condominium_frequencies.quarterly'),
    value: ECondominiumFrequency.Quarterly,
  },
  {
    label: i18n.t('f95.form.condominium_frequencies.annual'),
    value: ECondominiumFrequency.Annual,
  },
];

export const getCustomYesNoOptions = <Value>(trueValue: Value, falseValue: Value): IOption<Value>[] => [
  { label: i18n.t('base.yes'), value: trueValue },
  { label: i18n.t('base.no'), value: falseValue },
];

export const getHeaterMechanismTypeOptions = (): IOption<EHeaterMechanismType>[] => [
  {
    label: i18n.t('f95.form.heater_mechanism_types.radiator'),
    value: EHeaterMechanismType.Radiator,
  },
  {
    label: i18n.t('f95.form.heater_mechanism_types.storage_heater'),
    value: EHeaterMechanismType.StorageHeater,
  },
  {
    label: i18n.t('f95.form.heater_mechanism_types.warm_air_heater'),
    value: EHeaterMechanismType.WarmAirHeater,
  },
  {
    label: i18n.t('f95.form.heater_mechanism_types.subfloor_heater'),
    value: EHeaterMechanismType.SubfloorHeater,
  },
  {
    label: i18n.t('f95.form.heater_mechanism_types.reversible_air_conditioning'),
    value: EHeaterMechanismType.ReversibleAirConditioning,
  },
  {
    label: i18n.t('f95.form.heater_mechanism_types.fireplace'),
    value: EHeaterMechanismType.Fireplace,
  },
  {
    label: i18n.t('f95.form.heater_mechanism_types.convection_heater'),
    value: EHeaterMechanismType.ConvectionHeater,
  },
  {
    label: i18n.t('f95.form.heater_mechanism_types.heat_transfer_fluid'),
    value: EHeaterMechanismType.HeatTransferFluid,
  },
  {
    label: i18n.t('f95.form.heater_mechanism_types.fireplace_insert'),
    value: EHeaterMechanismType.FireplaceInsert,
  },
  {
    label: i18n.t('f95.form.heater_mechanism_types.wood_stove'),
    value: EHeaterMechanismType.WoodStove,
  },
  {
    label: i18n.t('f95.form.heater_mechanism_types.heat_pump'),
    value: EHeaterMechanismType.HeatPump,
  },
  {
    label: i18n.t('f95.form.heater_mechanism_types.radiant_heater'),
    value: EHeaterMechanismType.RadiantHeater,
  },
];

export const getHeaterModeTypeOptions = (): IOption<EHeaterModeType>[] => [
  {
    label: i18n.t('f95.form.heater_mode_types.electric'),
    value: EHeaterModeType.Electric,
  },
  {
    hasSeparator: true,
    label: i18n.t('f95.form.heater_mode_types.gas'),
    value: EHeaterModeType.Gas,
  },
  {
    label: i18n.t('f95.form.heater_mode_types.aquathermal'),
    value: EHeaterModeType.Aquathermal,
  },
  {
    label: i18n.t('f95.form.heater_mode_types.aerothermy'),
    value: EHeaterModeType.Aerothermy,
  },
  {
    label: i18n.t('f95.form.heater_mode_types.biofuel'),
    value: EHeaterModeType.Biofuel,
  },
  {
    label: i18n.t('f95.form.heater_mode_types.biomass'),
    value: EHeaterModeType.Biomass,
  },
  {
    label: i18n.t('f95.form.heater_mode_types.wood'),
    value: EHeaterModeType.Wood,
  },
  {
    label: i18n.t('f95.form.heater_mode_types.coal'),
    value: EHeaterModeType.Coal,
  },
  {
    label: i18n.t('f95.form.heater_mode_types.reversible_air_conditioning'),
    value: EHeaterModeType.ReversibleAirConditioning,
  },
  {
    label: i18n.t('f95.form.heater_mode_types.fuel_oil'),
    value: EHeaterModeType.FuelOil,
  },
  {
    label: i18n.t('f95.form.heater_mode_types.wood_pellet'),
    value: EHeaterModeType.WoodPellet,
  },
  {
    label: i18n.t('f95.form.heater_mode_types.geothermal'),
    value: EHeaterModeType.Geothermal,
  },
  {
    label: i18n.t('f95.form.heater_mode_types.heat_pump'),
    value: EHeaterModeType.HeatPump,
  },
  {
    label: i18n.t('f95.form.heater_mode_types.solar'),
    value: EHeaterModeType.Solar,
  },
  {
    label: i18n.t('f95.form.heater_mode_types.wind_turbine'),
    value: EHeaterModeType.WindTurbine,
  },
];

export const getHeaterTypeOptions = (): IOption<EHeaterType>[] => [
  {
    label: i18n.t('f95.form.heater_types.individual'),
    value: EHeaterType.Individual,
  },
  {
    label: i18n.t('f95.form.heater_types.shared'),
    value: EHeaterType.Shared,
  },
];

export const getKitchenEquipmentOptions = (): IOption<EPropertyKitchenEquipment>[] => [
  {
    label: i18n.t('f95.form.kitchen.equipments.full'),
    value: EPropertyKitchenEquipment.Full,
  },
  {
    label: i18n.t('f95.form.kitchen.equipments.some'),
    value: EPropertyKitchenEquipment.Some,
  },
  {
    label: i18n.t('f95.form.kitchen.equipments.none'),
    value: EPropertyKitchenEquipment.None,
  },
];

export const getKitchenTypeOptions = (): IOption<EPropertyKitchenType>[] => [
  {
    label: i18n.t('f95.form.kitchen.types.classic'),
    value: EPropertyKitchenType.Classic,
  },
  {
    label: i18n.t('f95.form.kitchen.types.american'),
    value: EPropertyKitchenType.American,
  },
  {
    label: i18n.t('f95.form.kitchen.types.kitchenette'),
    value: EPropertyKitchenType.Kitchenette,
  },
];

export const getRentChargesRecoveryMethodOptions = (): IOption[] => [
  {
    label: i18n.t('f95.form.rent_charges_recovery_methods.provision_with_annual_regularization'),
    value: ERentChargesRecoveryMethod.ProvisionWithAnnualRegularization,
  },
  {
    label: i18n.t('f95.form.rent_charges_recovery_methods.flat_rate'),
    value: ERentChargesRecoveryMethod.FlatRate,
  },
  {
    label: i18n.t('f95.form.rent_charges_recovery_methods.supporting_documents_reimbursement'),
    value: ERentChargesRecoveryMethod.SupportingDocumentsReimbursement,
  },
];

export const getStatusModeOptions = (isPlural = true): IOption[] => [
  {
    label: i18n.tc(`common.status.${EPropertyTransactionMode.Selling}`, isPlural ? 2 : 1),
    value: EPropertyTransactionMode.Selling,
  },
  {
    label: i18n.tc(`common.status.${EPropertyStatus.Lost}`, isPlural ? 2 : 1),
    value: EPropertyStatus.Lost,
  },
  {
    label: i18n.tc(`common.status.${EPropertyTransactionMode.Renting}`, isPlural ? 2 : 1),
    value: EPropertyTransactionMode.Renting,
  },
];

export const getTransactionModeOptions = (isPlural = false): IOption[] => [
  {
    label: i18n.tc(`common.status.${EPropertyTransactionMode.Selling}`, isPlural ? 2 : 1),
    value: EPropertyTransactionMode.Selling,
  },
  {
    label: i18n.tc(`common.status.${EPropertyTransactionMode.Renting}`, isPlural ? 2 : 1),
    value: EPropertyTransactionMode.Renting,
  },
];

export const getWindowGlazingOptions = (): IOption<EWindowGlazing>[] => [
  {
    label: i18n.t('f95.form.window_glazings.simple'),
    value: EWindowGlazing.Simple,
  },
  {
    label: i18n.t('f95.form.window_glazings.double'),
    value: EWindowGlazing.Double,
  },
  {
    label: i18n.t('f95.form.window_glazings.triple'),
    value: EWindowGlazing.Triple,
  },
];

export const getWindowShutterOptions = (): IOption[] => [
  {
    label: i18n.t('f95.form.window_shutters.none'),
    value: 'none',
  },
  {
    label: i18n.t('f95.form.window_shutters.manual'),
    value: 'manual',
  },
  {
    label: i18n.t('f95.form.window_shutters.electrical'),
    value: 'electrical',
  },
];

export const getYesNoOptions = (): IOption<boolean>[] => getCustomYesNoOptions(true, false);

export const mandateHorizonYears: Options<number> = Object.freeze(Array.from({ length: 10 }, (_v, i: number) =>
  ({ label: (currentYear + i).toString(), value: currentYear + i })));

export const monthOptions: Options<number> = Object.freeze([
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
].map((month, value) => ({ label: i18n.t(`base.date.${month}`), value })));

export const propertyStatusOptions: IOption<EPropertyProgress | EPropertyStatus>[] = [
  {
    label: i18n.t(`common.status.${EPropertyProgress.ToEstimate}`),
    value: EPropertyProgress.ToEstimate,
  },
  {
    label: i18n.t(`common.status.${EPropertyProgress.Estimated}`),
    value: EPropertyProgress.Estimated,
  },
  {
    label: i18n.t(`common.status.${EPropertyProgress.WithMandate}`),
    value: EPropertyProgress.WithMandate,
  },
  {
    label: i18n.t(`common.status.${EPropertyProgress.AcceptedOffer}`),
    value: EPropertyProgress.AcceptedOffer,
  },
  {
    label: i18n.t(`common.status.${EPropertyProgress.Sold}`),
    value: EPropertyProgress.Sold,
  },
  {
    label: i18n.tc(`common.status.${EPropertyStatus.Lost}`, 1),
    type: 'warning',
    value: EPropertyStatus.Lost,
  },
];

export const propertyTypeOptions = [
  EPropertyType.Apartment,
  EPropertyType.House,
  EPropertyType.Land,
  EPropertyType.CarPark,
  EPropertyType.Garage,
  EPropertyType.CommercialPremises,
  EPropertyType.Office,
  EPropertyType.Building,
].map(value => ({ label: i18n.t(`property_type.${value}`), value }));

export const rentingPropertyStatusOptions: IOption<EPropertyStatus>[] = [
  {
    label: i18n.tc(`common.status.${EPropertyTransactionMode.Renting}`, 1),
    value: EPropertyStatus.Active,
  },
  {
    label: i18n.tc(`common.status.${EPropertyStatus.Lost}`, 1),
    type: 'warning',
    value: EPropertyStatus.Lost,
  },
];

export const rentingPropertyTypeOptions = [
  EPropertyType.Apartment,
  EPropertyType.House,
].map(value => ({ label: i18n.t(`property_type.${value}`), value }));