































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IRecentSearch } from '@/interfaces/RecentSearch.interface';

@Component
export default class SearchRecents extends Vue {
  @Prop({ required: true, type: Object }) globalSearchRecents!: {
    person: IRecentSearch[];
    property: IRecentSearch[];
  };

  get initialState() {
    return [
      {
        algoliaIndex: 'persons',
        recents: this.globalSearchRecents['person'],
        title: this.$t('global_search.contacts.recents'),
      },
      {
        algoliaIndex: 'properties',
        recents: this.globalSearchRecents['property'],
        title: this.$t('global_search.properties.recents'),
      },
    ];
  }

  get displayedState() {
    return this.initialState.filter(state => state.recents.length > 0);
  }
}
