import { v4 as uuid } from 'uuid';

import { LkAxiosInstance } from '@/api';
import {
  IPropertyInformation,
  IPropertyInformationList,
  IUpsertPropertyInformationPayload,
} from './Information.interface';

const BASE_ENDPOINT = '/property_information';

const getDefaultUpsertInformationPayload = (propertyId: string): IUpsertPropertyInformationPayload => ({
  accessNote: null,
  buildingCount: null,
  buildingFloors: null,
  buildingNumber: null,
  buildingYear: null,
  carrezSize: null,
  ceilingHeight: null,
  digitalCode: null,
  doorNumber: null,
  floorCount: null,
  floorNumber: null,
  homeownerAssociationEmail: null,
  homeownerAssociationPhone: null,
  id: uuid(),
  intercom: null,
  keyLocation: null,
  lotCountHabitation: null,
  lotCountTotal: null,
  lotNumber: null,
  propertyId,
  propertySize: null,
  type: null,
});

export interface IInformationService {
  create(propertyId: string): Promise<IPropertyInformation>;
  get(id: string): Promise<IPropertyInformation>;
  getByPropertyId(propertyId: string): Promise<IPropertyInformation | null>;
  upsert(payload: IUpsertPropertyInformationPayload): Promise<IPropertyInformation>;
}

export const informationService = ($axios: LkAxiosInstance): IInformationService => ({
  create(propertyId) {
    return this.upsert(getDefaultUpsertInformationPayload(propertyId));
  },
  get(payload) {
    return $axios.$post(BASE_ENDPOINT, payload);
  },
  async getByPropertyId(propertyId) {
    const { propertyInformations } = await $axios.$get<IPropertyInformationList>(
      `${BASE_ENDPOINT}?property_id=${propertyId}`,
    );

    return propertyInformations[0] ?? null;
  },
  upsert(payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${payload.id}`, payload);
  },
});
