
import { descriptionsService, IDescriptionsService } from './descriptions/descriptions';
import { diagnosticsService, IDiagnosticsService } from './diagnostics/diagnostics';
import { ERoomType } from '@/enums/RoomType.enum';
import { IBaseRoomsService, baseRoomsService } from './rooms';
import {
  IF95InfoWithTitle,
  IF95InfoWithTitleAndType,
  IPropertySummary,
  IPropertySummaryResponse,
} from './F95.interface';
import { IInformationService, informationService } from './information/information';
import { IMarketingService, marketingService } from './marketing/marketing';
import { IRenovationsService, renovationsService } from './renovations/renovations';
import { LkAxiosInstance } from '@/api';

const getFormattedRoom = <Room extends IF95InfoWithTitle | IF95InfoWithTitleAndType<string>>
  (rooms: Room[] | null, roomType: ERoomType): Room[] => rooms?.map(room => ({ ...room, $roomType: roomType })) ?? [];

export interface IF95Service extends IBaseRoomsService {
  descriptions: IDescriptionsService;
  diagnostics: IDiagnosticsService;
  information: IInformationService;
  marketing: IMarketingService;
  renovations: IRenovationsService;
  getSummary(propertyId: string): Promise<IPropertySummary>;
}

export const f95service = ($axios: LkAxiosInstance): IF95Service => ({
  descriptions: descriptionsService($axios),
  diagnostics: diagnosticsService($axios),
  information: informationService($axios),
  marketing: marketingService($axios),
  renovations: renovationsService($axios),
  ...baseRoomsService($axios),
  async getSummary(propertyId: string) {
    const response = await $axios.$get<IPropertySummaryResponse>(`properties/${propertyId}/summary`);

    return {
      description: response.propertyDescription,
      diagnostics: response.propertyDiagnostics,
      expense: response.propertyExpense,
      id: response.propertyId,
      information: response.propertyInformation,
      links: response.links,
      marketing: response.propertyMarketing,
      renovations: response.propertyRenovations,
      rentingExpense: response.propertyRentingExpense,
      rooms: [
        ...getFormattedRoom(response.propertyCellars, ERoomType.Cellar),
        ...getFormattedRoom(response.propertyExteriors, ERoomType.Exterior),
        ...getFormattedRoom(response.propertyKitchens, ERoomType.Kitchen),
        ...getFormattedRoom(response.propertyOtherRooms, ERoomType.OtherRoom),
        ...getFormattedRoom(response.propertyParkings, ERoomType.Parking),
        ...getFormattedRoom(response.propertyRooms, ERoomType.Room),
        ...getFormattedRoom(response.propertyWaterRooms, ERoomType.WaterRoom),
      ].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()),
    };
  },
});
