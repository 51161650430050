





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { IAgent } from '@/api/dome/agents/Agents.interface';
import { INotification } from '@/interfaces/Notification.interface';
import { parseError } from '@/utils/validation';
import { redirectTo } from '@/utils/notifications';

import NotificationPanel from '@/components/common/organisms/notification/NotificationPanel.vue';

@Component({
  components: {
    NotificationPanel,
  },
})
export default class Notifications extends Vue {
  @Prop({ default: false, type: Boolean }) isVisible!: boolean;

  @State('loggedInAgent') loggedInAgent!: IAgent;

  isLoading = false;
  notificationList: INotification[] = [];

  @Watch('isVisible', { immediate: true })
  async manageNotificationsOpened(isVisible: boolean): Promise<void> {
    if (isVisible) {
      await this.getNotifications();
    }
  }

  async getNotifications(): Promise<void> {
    this.isLoading = true;

    try {
      await this.$api.notifications.markAsSeen(this.loggedInAgent.id);
      this.notificationList = await this.$api.notifications.getAll();
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
    } finally {
      this.isLoading = false;
    }
  }

  handleNotificationClick(notification: INotification): void {
    try {
      if (notification.extra) {
        if (!notification.clicked) {
          this.$api.notifications.markAsClicked(notification.id);
        }

        redirectTo(notification);
        this.$emit('close');
      }
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
    }
  }
}
