import { DateTimeFormatOptions, NumberFormatOptions } from 'vue-i18n';

interface NumberFormats {
  [key: string]: {
    [key: string]: NumberFormatOptions;
  };
}

interface DateTimeFormat {
  [key: string]: {
    [key: string]: DateTimeFormatOptions;
  };
}

export const dateTimeFormats: DateTimeFormat = {
  fr: {
    datetime: {
      // ex: [Date Object] -> 13 février 11:54
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      month: 'long',
    },
    long: {
      // ex: [Date Object] -> 13 février 2020
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    },
    short: {
      day: 'numeric',
      // ex: [Date Object] -> 13 févr.
      month: 'short',
    },
    time: {
      // ex: [Date Object] -> 11:54
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};

export const numberFormats: NumberFormats = {
  fr: {
    currency: {
      // ex: [Number] -> 150 000 €
      currency: 'EUR',
      minimumFractionDigits: 0,
      style: 'currency',
    },
    fileSize: {
      maximumFractionDigits: 1,
      notation: 'compact',
      style: 'unit',
      unit: 'byte',
    },
    integerPercent: {
      // ex: [Number] -> 15 %
      maximumFractionDigits: 0,
      style: 'percent',
    },
    percent: {
      // ex: [Number] -> 15 %
      maximumFractionDigits: 2,
      style: 'percent',
    },
  },
};