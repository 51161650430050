
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaseButton extends Vue {
  @Prop({ default: 'submit', type: String }) buttonType!: string;
  @Prop({ type: String }) dataTestId?: string;
  @Prop({ default: false, type: Boolean }) hasLoadingState!: boolean;
  @Prop({ default: false, type: Boolean }) isLoading!: boolean;
}
