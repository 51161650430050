













































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { IEntities } from '@/interfaces/entity/Entities.interface';
import { IErrorMessages } from '@/interfaces/ErrorMessages.interface';

@Component
export default class AgentStep extends Vue {
  @Prop({ required: true, type: Object }) entities!: IEntities;
  @Prop({ default: () => ({}), type: Object }) errorMessages!: IErrorMessages;

  removeErrorMessage(key: string): void {
    this.$emit('remove-error', 'agent', key);
  }

  updateEntity(key: string, value): void {
    this.$emit('update-entity', 'agent', key, value);
  }

  updateEntityDate(key: string, value: string | null): void {
    const date = value ? new Date(value) : null;
    this.updateEntity(key, date);
  }

  validateEntityKey(key: string): void {
    this.$emit('validate-entity-key', 'agent', key);
  }
}
