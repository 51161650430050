const PlanningIndex = () => import(/* webpackChunkName: "planning" */ '@/views/planning/index.vue');
const PlanningPanelMeeting = () => import(/* webpackChunkName: "planning" */ '@/views/planning/panels/meeting.vue');
const PlanningPanelVisit = () => import(/* webpackChunkName: "planning" */ '@/views/planning/panels/visit.vue');
// eslint-disable-next-line vue/max-len
const PlanningPanelAppointment = () => import(/* webpackChunkName: "planning" */ '@/views/planning/panels/appointment.vue');
const PlanningPanelExport = () => import(/* webpackChunkName: "planning" */ '@/views/planning/panels/export.vue');

import { createFollowUpRoute } from './property-purchase-plan';
import { createPersonRoute } from './person';
import { createSellerLeadRoute } from './leads/seller';

export enum EPlanningRoute {
  Appointment = 'appointment',
  Export = 'export',
  Index = 'planning',
  Meeting = 'meeting',
  Visit = 'visit',
}

export default [
  {
    children: [
      createPersonRoute(EPlanningRoute.Index),
      createFollowUpRoute(EPlanningRoute.Index),
      createSellerLeadRoute(EPlanningRoute.Index),
      {
        component: PlanningPanelMeeting,
        name: EPlanningRoute.Meeting,
        path: 'estimation/:eventId',
      },
      {
        component: PlanningPanelVisit,
        name: EPlanningRoute.Visit,
        path: 'visite/:eventId',
      },
      {
        component: PlanningPanelAppointment,
        name: EPlanningRoute.Appointment,
        path: 'appointment/:eventId',
      },
      {
        component: PlanningPanelExport,
        name: EPlanningRoute.Export,
        path: 'export',
      },
    ],
    component: PlanningIndex,
    name: EPlanningRoute.Index,
    path: '/planning',
  },
];