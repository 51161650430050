import { LkAxiosInstance } from '@/api';
import { INegotiationRecipient, INegotiationRecipientsRequest, INegotiationRecipientsResponse }
  from '@/api/dome/negotiationRecipients/NegotiationRecipients.interface';

export interface INegotiationRecipientsService {
  get(negotiationId: string): Promise<INegotiationRecipient[]>;
  upsert(negotiationRecipientsRequest: INegotiationRecipientsRequest): Promise<INegotiationRecipient[]>;
}

export const negotiationRecipientsService = ($axios: LkAxiosInstance): INegotiationRecipientsService => ({
  async get(negotiationId) {
    const { negotiationRecipients } =
      await $axios.$get<INegotiationRecipientsResponse>(`/negotiation_recipients?negotiation_id=${negotiationId}`);

    return negotiationRecipients;
  },
  async upsert(negotiationRecipientsRequest) {
    const { negotiationRecipients } = await $axios.$post<INegotiationRecipientsResponse>(
      '/update_negotiation_recipients',
      negotiationRecipientsRequest,
    );

    return negotiationRecipients;
  },
});
