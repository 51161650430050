import { LkAxiosInstance } from '@/api';

import { EContactRole } from '@/enums/ContactRole.enum';
import { EPersonContactContext } from '@/enums/PersonContactContext.enum';
import { EPersonContactType } from '@/enums/PersonContactType.enum';
import { getPhoneNumber } from '@/utils/phone';
import {
  IPerson,
  IPersonCustomerSpaceDetails,
  IPersonEditionPayload,
  IPersonProperties,
  IPersonsResponse,
} from './Persons.interface';
import { PersonContactModel } from '../person-contacts/PersonContacts.interface';
import { PersonEntityModel } from '@/views/entity/models';
import { toQueryString } from '@/utils/url';

const primaryContactBase = { context: EPersonContactContext.Default, isPrimary: true };

export interface IPersonsService {
  create(person: PersonEntityModel): Promise<IPerson>;
  getAll(agentID: string, tags: 'all' | EContactRole, page: number): Promise<IPersonsResponse>;
  getById(id: string): Promise<IPerson>;
  getCustomerSpaceDetails(id: string): Promise<IPersonCustomerSpaceDetails>;
  getProperties(id: string): Promise<IPersonProperties>;
  search(q: string, tags?: string[]): Promise<IPerson[]>;
  sendOnboardingEmail(id: string): Promise<void>;
  update(person: { id: string } & IPersonEditionPayload): Promise<IPerson>;
}

const BASE_ENDPOINT = '/persons';

export const getFormattedPerson = (person: IPerson): IPerson => ({
  ...person,
  fullName: `${person.firstName} ${person.lastName}`,
});

export const personsService = ($axios: LkAxiosInstance): IPersonsService => ({
  async create(person) {
    const personContacts: PersonContactModel[] = [
      { ...primaryContactBase, type: EPersonContactType.Email, value: person.email },
      { ...primaryContactBase, type: EPersonContactType.Phone, value: getPhoneNumber(person.phone) ?? '' },
    ].filter(contact => contact.value);

    const response = await $axios.$post<IPerson>(`${BASE_ENDPOINT}`, { person: { ...person, personContacts } });

    return getFormattedPerson(response);
  },
  async getAll(agentId, tags, page) {
    const query = toQueryString({
      agent_id: agentId,
      filter_on_agency: agentId === '',
      page,
      tags: tags === 'all' ? undefined : tags,
    });

    const response = await $axios.$get<IPersonsResponse>(`${BASE_ENDPOINT}?${query}`);
    response.persons = response.persons.map(getFormattedPerson);

    return response;
  },
  async getById(id) {
    const person = await $axios.$get<IPerson>(`${BASE_ENDPOINT}/${id}`);

    return getFormattedPerson(person);
  },
  getCustomerSpaceDetails(id) {
    return $axios.$get(`${BASE_ENDPOINT}/${id}/customer_space_details`);
  },
  getProperties(id) {
    return $axios.$get(`/view_person_panel/${id}`);
  },
  async search(q, tags) {
    const query = toQueryString({ q, tags });
    const { persons } = await $axios.$get<IPersonsResponse>(`/search_persons?${query}`);

    return persons.map(getFormattedPerson);
  },
  sendOnboardingEmail(id) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/customer_space_send_onboarding_email`);
  },
  async update({ id, ...person }) {
    const response = await $axios.$post<IPerson>(`${BASE_ENDPOINT}/${id}`, { person });

    return getFormattedPerson(response);
  },
});
