import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { numberFormats, dateTimeFormats } from '@/utils/i18nFormatters';

Vue.use(VueI18n);

export default new VueI18n({
  dateTimeFormats,
  fallbackLocale: 'fr',
  locale: 'fr',
  messages: {
    fr: {
      ...require('@/locales/fr.json'),
      bo: require('@/locales/bo.fr.json'),
    },
  },
  numberFormats,
});
