// the value of the enum should be the translation key
export enum EDialogType {
  MeetingCreation = 'email.dialog.meeting.creation',
  MeetingEdition = 'email.dialog.meeting.changes',
  MeetingCancellation = 'email.dialog.meeting.cancelled',
  VisitCreation = 'email.dialog.visit.creation',
  VisitEdition = 'email.dialog.visit.changes',
  VisitCancellation = 'email.dialog.visit.cancelled',
  VisitReport = 'email.dialog.visit.report',
  OfferCreation = 'email.dialog.offer.creation',
  OfferCounterOfferSeller = 'email.dialog.offer.counter_offer_seller',
  OfferCounterOfferBuyer = 'email.dialog.offer.counter_offer_buyer',
}
