





















import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { createWidgetMixin } from 'vue-instantsearch';
import { connectInfiniteHits } from 'instantsearch.js/es/connectors';

@Component
export default class SearchList extends Mixins(createWidgetMixin({ connector: connectInfiniteHits })) {
  @Prop({ type: Function }) transformItems!: () => void;

  declare state;

  @Watch('totalHits')
  onTotalHitsChange(newVal) {
    this.$emit('set-number-of-items', newVal);
  }

  get totalHits() {
    return this.state !== null && this.state.hits.length;
  }

  get widgetParams() {
    return {
      transformItems: this.transformItems,
    };
  }

  loadMore() {
    if (this.state !== null) {
      this.state.showMore();
    }
  }
}
