import App from '@/main';
import VueRouter, { Location } from 'vue-router';
const { isNavigationFailure, NavigationFailureType } = VueRouter;

import { ENotificationTopic } from '@/enums/NotificationTopic.enum';

export interface INotificationData {
  extra: Record<string, string>;
  topic: ENotificationTopic | string;
}

export const redirectTo = ({ topic, extra }: INotificationData): void => {
  let route: Location | null = null;

  switch (topic) {
    case ENotificationTopic.AppointmentAgentUpdated:
      route = { name: 'appointment', params: { eventId: extra.appointmentId } };
      break;
    case ENotificationTopic.LeadBuyerAgentUpdated:
      route = { name: 'buyer_lead', params: { buyerLeadId: extra.buyerLeadId } };
      break;
    case ENotificationTopic.MandateDocumentValidate:
      route = { name: 'mandate', params: { mandateId: extra.mandateId } };
      break;
    case ENotificationTopic.MeetingAgentUpdated:
      route = { name: 'meeting', params: { eventId: extra.meetingId } };
      break;
    case ENotificationTopic.NewSellerLeadAssignedBySystem:
      route = { name: 'seller_lead', params: { sellerLeadId: extra.sellerLeadId } };
      break;
    case ENotificationTopic.TaskAssigned:
      route = { name: 'task', params: { taskId: extra.taskId } };
      break;
    case ENotificationTopic.VisitAgentUpdated:
      route = { name: 'visit', params: { eventId: extra.visitId } };
      break;
    case ENotificationTopic.CustomerSpaceDataroomDocumentsUploaded:
    case ENotificationTopic.CustomerSpaceCounterOffer:
    case ENotificationTopic.EstimationValidated:
    case ENotificationTopic.MandateDocumentCanceled:
    case ENotificationTopic.MandateDocumentSignedByEverybody:
    case ENotificationTopic.MandateDocumentSignedByRecipientsButAgent:
    case ENotificationTopic.NegotiationOfferAccepted:
    case ENotificationTopic.NegotiationOfferDeclined:
    case ENotificationTopic.NegotiationOfferExpired:
    case ENotificationTopic.NegotiationOfferSignedByAll:
    case ENotificationTopic.NegotiationOfferExpiresIn48h:
      route = { name: 'property', params: { propertyId: extra.propertyId } };
      break;
    default:
      break;
  }

  if (route) {
    App.$router.push(route).catch(failure => {
      if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
        console.error(failure);
      }
    });
  }
};
