import {
  IAgent,
  IAgentResponse,
  IAgentSellerLeadsSettings,
  IAgentsResponse,
  IIsAgentAllowedToEditSources,
  IIsAgentAllowedToSeeOtherAgentsBusiness,
} from './Agents.interface';
import { ILink } from '@/api/dome/links/Links.interface';
import { LkAxiosInstance } from '@/api';
import { toQueryString } from '@/utils/url';

export interface IAgentsService {
  backfillSellerLeads(agentId: string, startDate: string): Promise<void>;
  create(agent: Partial<IAgent>): Promise<IAgent>;
  getIsAgentAllowedToEditSources(agentId: string): Promise<IIsAgentAllowedToEditSources>;
  getOneAgent(id: string): Promise<IAgent>;
  getSellerLeadsSettings(agentId: string): Promise<IAgentSellerLeadsSettings>;
  getSettings(agentId: string): Promise<IIsAgentAllowedToSeeOtherAgentsBusiness>;
  hateoasRequest(link: ILink): Promise<void>;
  search(params: { agencyId?: string, page?: number, q?: string }): Promise<IAgentsResponse>;
  setIsAgentAllowedToEditSources(agentId: string, isAgentAllowedToEditSources: boolean): Promise<void>;
  setSettings(agentId: string, isAgentAllowedToSeeOtherAgentsBusiness: boolean): Promise<void>;
  update(agent: Partial<IAgent>): Promise<IAgent>;
  updateSellerLeadsSettings(agentId: string, sellerLeadsSettings: IAgentSellerLeadsSettings): Promise<void>;
}

const BASE_ENDPOINT = '/agents';

const formatAgent = (agent: IAgent): IAgent => ({ ...agent, fullName: `${agent.firstName} ${agent.lastName}` });

export const agentsService = ($axios: LkAxiosInstance): IAgentsService => ({
  backfillSellerLeads(agentId, startDate) {
    return $axios.$post(`${BASE_ENDPOINT}/${agentId}/backfill_seller_leads_from_date`, { startDate });
  },
  async create(newAgent) {
    const { agent } = await $axios.$post<IAgentResponse>(BASE_ENDPOINT, newAgent);

    return agent;
  },
  async getIsAgentAllowedToEditSources(agentId) {
    return $axios.$get(`${BASE_ENDPOINT}/${agentId}/is_agent_allowed_to_edit_seller_sources`);
  },
  async getOneAgent(id) {
    const { agent } = await $axios.$get<IAgentResponse>(`${BASE_ENDPOINT}/${id}`);

    return formatAgent(agent);
  },
  getSellerLeadsSettings(agentId) {
    return $axios.$get(`${BASE_ENDPOINT}/${agentId}/seller_lead_settings`);
  },
  getSettings(agentId) {
    return $axios.$get(`${BASE_ENDPOINT}/${agentId}/get_settings`);
  },
  hateoasRequest(link) {
    return $axios.$request({ ...link, url: link.href });
  },
  async search({ agencyId, page, q }) {
    const query = toQueryString({ agency_id: agencyId, page, q });
    const { agents, pages } = await $axios.$get<IAgentsResponse>(`${BASE_ENDPOINT}?${query}`);

    return { agents: agents.map(formatAgent), pages };
  },
  setIsAgentAllowedToEditSources(agentId, isAgentAllowedToEditSellerSources) {
    return $axios.$post(`${BASE_ENDPOINT}/${agentId}/is_agent_allowed_to_edit_seller_sources`, {
      isAgentAllowedToEditSellerSources,
    });
  },
  async setSettings(agentId, isAgentAllowedToSeeOtherAgentsBusiness) {
    return $axios.$post(`${BASE_ENDPOINT}/${agentId}/set_settings`, { isAgentAllowedToSeeOtherAgentsBusiness });
  },
  async update(editedAgent) {
    const { agent } = await $axios.$put<IAgentResponse>(`${BASE_ENDPOINT}/${editedAgent.id}`, editedAgent);

    return agent;
  },
  updateSellerLeadsSettings(agentId, sellerLeadsSettings) {
    return $axios.$post(`${BASE_ENDPOINT}/${agentId}/seller_lead_settings`, sellerLeadsSettings);
  },
});
