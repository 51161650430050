



















import { Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { ETasksTab } from '@/enums/TasksTab.enum';
import { getISODate, getTime } from '@/utils/date';
import { IAgent } from '@/api/dome/agents/Agents.interface';
import { ICreatedTask, ITask } from '@/api/dome/tasks/Tasks.interface';
import { IOption } from '@/interfaces/Option.interface';
import { IStep } from '@/components/common/molecules/stepper/StepperWithStatus.interface';
import { TaskEntityModel } from './models';

import EntityCreationWizard from './wizard.vue';
import DefineTask from './steps/DefineTask.vue';
import Wizard from '@/components/common/templates/wizard/Wizard.vue';

@Component({
  components: {
    DefineTask,
    Wizard,
  },
})
export default class TaskEntity extends EntityCreationWizard {
  @Prop({ type: Object }) suggestionList?: Record<'agency' | 'person' | 'property', IOption<string>[]>;
  @Prop({ type: Object }) task?: ITask;

  @State('loggedInAgent') loggedInAgent!: IAgent;

  stepList: string[] = ['define-task'];

  get steps(): IStep[] {
    return this.stepList.map(step => ({
      hasError: false,
      isFilled: false,
      name: this.$t(`entity_creation.steps.${step}.title_${this.titleType}`),
      value: step,
    }));
  }

  get titleType(): string {
    return this.task ? 'edit' : 'add';
  }

  setData(): void {
    if (this.task) {
      const task: TaskEntityModel = {
        date: this.task.date,
        notes: this.task.notes || undefined,
        time: this.task.datetime || undefined,
        title: this.task.title,
      };

      if (task.time) {
        task.time = getTime(task.time);
      }

      this.entities = { task };
      this.selectedEntities = { person: this.task.person?.id, property: this.task.property?.id };
    } else {
      this.entities = { task: new TaskEntityModel() };
      this.selectedEntities = { person: undefined, property: undefined };
    }
  }

  /************************************************/
  /**************** Business logic ****************/
  /************************************************/

  async createEntity(): Promise<void> {
    this.isSubmitting = true;

    if (!this.isEntityValid(this.stepList)) {
      this._handleError('invalid data', this.errorStepIndex);

      return;
    }

    // Task
    try {
      if (this.entities.task) {
        const { date, time, title, notes } = this.entities.task;
        const { person: personId, property: propertyId } = this.selectedEntities;
        const datetime = time ? getISODate(date, time) : undefined;
        const newTask: ICreatedTask = { date, datetime, notes, personId, propertyId, title };

        if (this.task) {
          this.$emit('update-task', newTask);
        } else {
          await this.$api.tasks.create(newTask);
        }
      }
    } catch (error) {
      this._handleError(error, this.stepList.indexOf('define-task'), true);

      return;
    }

    const { name, query } = this.$route;
    const isLoggedInAgent = query?.agent_id === this.loggedInAgent.id;
    const isToday = query?.tab === ETasksTab.Today;

    await this.closeWizard();

    if (!this.task && !(name === 'tasks' && isToday && isLoggedInAgent)) {
      await this.$router.push({
        hash: undefined,
        name: 'tasks',
        query: { agent_id: this.loggedInAgent.id, tab: ETasksTab.Today },
      });
    }
  }
}
