



















import { Component } from 'vue-property-decorator';

import { AgencyEntityModel } from '@/views/bo/entity/models';
import { IStep } from '@/components/common/molecules/stepper/StepperWithStatus.interface';

import Agency from '@/views/bo/entity/steps/Agency.vue';
import EntityCreationWizard from '@/views/entity/wizard.vue';
import Wizard from '@/components/common/templates/wizard/Wizard.vue';

@Component({
  components: {
    Agency,
    Wizard,
  },
})
export default class AgencyEntity extends EntityCreationWizard {
  get stepList() {
    return ['agency'];
  }

  get steps(): IStep[] {
    return this.stepList.map(step => ({
      hasError: false,
      isFilled: false,
      name: this.$t(`bo.entity_creation.steps.${step}.title`),
      value: step,
    }));
  }

  created() {
    this.setData();
  }

  setData() {
    this.entities = { agency: new AgencyEntityModel() };
  }

  async createEntity() {
    this.isSubmitting = true;

    if (!this.isEntityValid(this.stepList)) {
      this._handleError('invalid data', this.errorStepIndex);

      return;
    }

    try {
      const { id: agencyId } = await this.$boApi.agencies.create((this.entities.agency as AgencyEntityModel).name);

      if (!agencyId) {
        this._handleError('empty', this.stepList.indexOf('agency'));

        return;
      }

      await this.closeWizard();

      await this.$router.replace({
        hash: undefined,
        name: 'bo_agency',
        params: { agencyId },
      });
    } catch (error) {
      this._handleError(error, this.stepList.indexOf('agency'), true);

      return;
    }
  }
}
