import { ERoomType } from '@/enums/RoomType.enum';
import { hasNullValues } from '@/utils/objectHelpers';
import { ICellarsService, cellarsService } from './cellars/cellars';
import { IExteriorsService, exteriorsService } from './exteriors/exteriors';
import { IKitchensService, kitchensService } from './kitchens/kitchens';
import { IOtherRoomsService, otherRoomsService } from './other-rooms/otherRooms';
import { IParkingsService, parkingsService } from './parkings/parkings';
import { IRoomsService, roomsService } from './rooms/rooms';
import { IWaterRoomsService, waterRoomsService } from './water-rooms/waterRooms';
import { LkAxiosInstance } from '@/api';

export interface IRoomHelper {
  $isEmpty: boolean;
  $roomType: ERoomType;
}

export const getFormattedRoom = <Room extends object>(
  room: Room,
  roomType: ERoomType,
  relevantFieldsLength: number,
): Room & IRoomHelper => ({
    ...room,
    $isEmpty: hasNullValues(room, relevantFieldsLength),
    $roomType: roomType,
  });

export interface IBaseRoomsService {
  cellars: ICellarsService;
  exteriors: IExteriorsService;
  kitchens: IKitchensService;
  otherRooms: IOtherRoomsService;
  parkings: IParkingsService;
  rooms: IRoomsService;
  waterRooms: IWaterRoomsService;
}

export const baseRoomsService = ($axios: LkAxiosInstance): IBaseRoomsService => ({
  cellars: cellarsService($axios),
  exteriors: exteriorsService($axios),
  kitchens: kitchensService($axios),
  otherRooms: otherRoomsService($axios),
  parkings: parkingsService($axios),
  rooms: roomsService($axios),
  waterRooms: waterRoomsService($axios),
});