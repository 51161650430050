import { axiosConfig, LkAxiosInstance } from '@/api';
import { createBlobUrl } from '@/utils/blobHelper';
import { EImageSettingsType } from '@/enums/ImageSettingsType.enum';
import { toQueryString } from '@/utils/url';
import { IAgenciesResponse } from '@/interfaces/bo/dto/agenciesService/AgenciesResponse.interface';
import { IAgency } from '@/interfaces/bo/models/Agency.interface';
import { IAgencyResponse } from '@/interfaces/bo/dto/agenciesService/AgencyResponse.interface';
import { IImageUploadResponse } from '@/interfaces/bo/dto/agenciesService/ImageUploadResponse.interface';
import { IMandateSettings } from '@/interfaces/bo/models/MandateSettings.interface';
import { IProxyConfigurationParam } from '@/interfaces/bo/dto/agenciesService/ProxyConfigurationRequest.interface';

export interface IAgenciesService {
  create(agencyName: string): Promise<IAgency>;
  getAgency(agencyId: string): Promise<IAgency>;
  getMandatePreview(agencyId: string): Promise<string>;
  getMandateSettings(agencyId: string): Promise<IMandateSettings>;
  postProxyConfiguration(configuration: IProxyConfigurationParam): Promise<void>;
  search(params: { page?: number, q?: string }): Promise<IAgenciesResponse>;
  updateMandateSettings(agencyId: string, payload: IMandateSettings): Promise<IMandateSettings>;
  uploadImage(agencyId: string, imageType: EImageSettingsType, formData: FormData): Promise<string>;
}

const BASE_ENDPOINT = '/agencies';

export const agenciesService = ($axios: LkAxiosInstance): IAgenciesService => ({
  async create(agencyName) {
    const { agency } = await $axios.$post<IAgencyResponse>(BASE_ENDPOINT, { name: agencyName });

    return agency;
  },
  async getAgency(agencyId) {
    const { agency } = await $axios.$get<IAgencyResponse>(`${BASE_ENDPOINT}/${agencyId}`);

    return agency;
  },
  async getMandatePreview(agencyId) {
    const response = await $axios.$get<Blob>(`${BASE_ENDPOINT}/${agencyId}/preview_mandate_template`, axiosConfig.blob);

    return createBlobUrl(response);
  },
  getMandateSettings(agencyId) {
    return $axios.$get(`/agencies/${agencyId}/mandates_settings`);
  },
  postProxyConfiguration(configuration) {
    return $axios.$post('/proxy_configurations', configuration);
  },
  search(params) {
    const query = toQueryString(params);

    return $axios.$get<IAgenciesResponse>(`${BASE_ENDPOINT}?${query}`);
  },
  updateMandateSettings(agencyId, mandateSettings) {
    return $axios.$put(`/agencies/${agencyId}/mandates_settings`, mandateSettings);
  },
  async uploadImage(agencyId, imageType, formData) {
    const { value } = await $axios.$post<IImageUploadResponse>(
      `${BASE_ENDPOINT}/${agencyId}/${imageType}`, formData, axiosConfig.formData,
    );

    return value;
  },
});
