import { EBuyerQualification } from '@/enums/PropertyPurchasePlan.enum';
import { Icons, icons } from '@/helpers/icons';
import i18n from '@/plugins/i18n';

const TRANSLATION_KEY = 'entity_creation.steps.property-purchase-plan.qualification.options';

export const buyerQualifications: Record<EBuyerQualification, {
  background: string; color: string; icon: Icons; label: string;
}> = {
  [EBuyerQualification.Hot]: {
    background: 'bg-lk-red-light',
    color: 'text-lk-red',
    icon: icons.fire,
    label: i18n.t(`${TRANSLATION_KEY}.${EBuyerQualification.Hot}`),
  },
  [EBuyerQualification.Lukewarm]: {
    background: 'bg-lk-orange-light',
    color: 'text-lk-orange',
    icon: icons.sunrise,
    label: i18n.t(`${TRANSLATION_KEY}.${EBuyerQualification.Lukewarm}`),
  },
  [EBuyerQualification.Cold]: {
    background: 'bg-lk-blue-status',
    color: 'text-lk-blue',
    icon: icons.snowflake,
    label: i18n.t(`${TRANSLATION_KEY}.${EBuyerQualification.Cold}`),
  },
};

