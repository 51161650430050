import {
  IPropertiesParams,
  IPropertiesResponse,
  IPropertyByAddress,
  IPropertyCreationPayload,
  IPropertyEstimated,
  IPropertyExchangePayload,
  IPropertyExchangeWithReminderPayload,
  IPropertyForPanel,
  IPropertyLost,
  IPropertyLostReason,
  IPropertyOfferAccepted,
  IPropertyResponse,
  IPropertySaleProjectQualification,
  IPropertyToEstimate,
} from './Properties.interface';
import { IProperty } from '@/api/dome/properties/Properties.interface';
import { LkAxiosInstance } from '@/api';
import { toQueryString } from '@/utils/url';

const BASE_ENDPOINT = '/properties';

export interface IPropertiesService {
  create(payload: IPropertyCreationPayload): Promise<IProperty>;
  createExchange(id: string, payload: IPropertyExchangePayload): Promise<void>;
  createExchangeWithReminder(id: string, payload: IPropertyExchangeWithReminderPayload): Promise<void>;
  createSaleProjectQualification(id: string, payload: IPropertySaleProjectQualification): Promise<void>;
  getById(id: string): Promise<IProperty>;
  getPropertiesAcceptedOffer(params: IPropertiesParams): Promise<IPropertiesResponse<IPropertyOfferAccepted>>;
  getPropertiesEstimated(params: IPropertiesParams): Promise<IPropertiesResponse<IPropertyEstimated>>;
  getPropertiesLost(params: IPropertiesParams): Promise<IPropertiesResponse<IPropertyLost>>;
  getPropertiesToEstimate(params: IPropertiesParams): Promise<IPropertiesResponse<IPropertyToEstimate>>;
  getPropertyForPanel(id: string): Promise<IPropertyForPanel>;
  modifyAddress(address: string, propertyId: string): Promise<void>;
  searchByAddress(address: string): Promise<IPropertyByAddress[]>;
  setProgressToEstimate(id: string): Promise<void>;
  setRentalToLost(id: string): Promise<void>;
  setToLost(id: string, payload: IPropertyLostReason): Promise<void>;
  updateExchangeWithReminder(id: string, payload: IPropertyExchangeWithReminderPayload): Promise<void>;
}

export const propertiesService = ($axios: LkAxiosInstance): IPropertiesService => ({
  async create(payload) {
    const { property } = await $axios.$post<IPropertyResponse>(`${BASE_ENDPOINT}`, { property: payload });

    return property;
  },
  createExchange(id, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/create_exchange`, payload);
  },
  createExchangeWithReminder(id, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/create_exchange_with_reminder`, payload);
  },
  createSaleProjectQualification(id, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/sale_project_qualifications`, payload);
  },
  async getById(id) {
    const { property } = await $axios.$get<IPropertyResponse>(`${BASE_ENDPOINT}/${id}`);

    return property;
  },
  getPropertiesAcceptedOffer({ agentId, page }) {
    const query = toQueryString({ agent_id: agentId, page });

    return $axios.$get(`${BASE_ENDPOINT}_accepted_offer?${query}`);
  },
  getPropertiesEstimated({ agentId, page }) {
    const query = toQueryString({ agent_id: agentId, page });

    return $axios.$get(`${BASE_ENDPOINT}_estimated?${query}`);
  },
  getPropertiesLost({ agentId, page, transactionMode }) {
    const query = toQueryString({ agent_id: agentId, page, transaction_mode: transactionMode });

    return $axios.$get(`${BASE_ENDPOINT}_lost?${query}`);
  },
  getPropertiesToEstimate({ agentId, page }) {
    const query = toQueryString({ agent_id: agentId, page });

    return $axios.$get(`${BASE_ENDPOINT}_to_estimate?${query}`);
  },
  getPropertyForPanel(id) {
    return $axios.$get(`${BASE_ENDPOINT}/${id}/panel_view`);
  },
  modifyAddress(address, propertyId) {
    return $axios.$post(`${BASE_ENDPOINT}/${propertyId}/modify_address`, { address });
  },
  searchByAddress(address) {
    const query = toQueryString({ address });

    return $axios.$get(`/list_properties_by_address?${query}`);
  },
  setProgressToEstimate(id) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/set_progress_to_estimate`);
  },
  setRentalToLost(id) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/set_rental_to_lost`);
  },
  setToLost(id, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/set_to_lost`, payload);
  },
  updateExchangeWithReminder(id, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/update_exchange_with_reminder`, payload);
  },
});