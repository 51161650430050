

















































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { AisHighlight } from 'vue-instantsearch';

import { EPropertyStatus } from '@/enums/PropertyStatus.enum';
import { IPropertyResult } from './PropertyCardSearch.interface';

@Component({
  components: {
    AisHighlight,
  },
})
export default class PropertyCardSearch extends Vue {
  @Prop({ required: true, type: Object }) property!: IPropertyResult;

  get isActive(): boolean {
    return this.property.status === EPropertyStatus.Active;
  }

  get propertyInfos(): string[] {
    return [
      this.property.surface,
      this.property.displayedRoomsCount,
      this.property.displayedPrice,
    ].filter(data => data) as string[];
  }
}
