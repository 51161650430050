import { ERoomType } from '@/enums/RoomType.enum';
import { getFormattedRoom } from '..';
import {
  ICreatePropertyExteriorPayload,
  IPropertyExterior,
  IUpdatePropertyExteriorPayload,
} from './Exteriors.interface';
import { LkAxiosInstance } from '@/api';

const BASE_ENDPOINT = '/property_exteriors';
const FIELDS_NUMBER = 4;
const ROOM_TYPE = ERoomType.Exterior;

export interface IExteriorsService {
  create(payload: ICreatePropertyExteriorPayload): Promise<IPropertyExterior>;
  delete(id: string): Promise<void>;
  get(id: string): Promise<IPropertyExterior>;
  update(payload: IUpdatePropertyExteriorPayload): Promise<IPropertyExterior>;
}

export const exteriorsService = ($axios: LkAxiosInstance): IExteriorsService => ({
  async create(payload) {
    const response = await $axios.$post<IPropertyExterior>(BASE_ENDPOINT, payload);

    return getFormattedRoom(response, ROOM_TYPE, FIELDS_NUMBER);
  },
  delete(id) {
    return $axios.$delete(`${BASE_ENDPOINT}/${id}`);
  },
  async get(id) {
    const response = await $axios.$get<IPropertyExterior>(`${BASE_ENDPOINT}/${id}`);

    return getFormattedRoom(response, ROOM_TYPE, FIELDS_NUMBER);
  },
  async update(payload) {
    const response = await $axios.$post<IPropertyExterior>(`${BASE_ENDPOINT}/${payload.id}`, payload);

    return getFormattedRoom(response, ROOM_TYPE, FIELDS_NUMBER);
  },
});
