















import { Vue, Component } from 'vue-property-decorator';
import { AisHighlight } from 'vue-instantsearch';

import { IPropertyResult } from '@/components/common/organisms/property-card/PropertyCardSearch.interface';

import PropertyCardSearch from '@/components/common/organisms/property-card/PropertyCardSearch.vue';
import SearchList from '@/components/common/molecules/search-list/SearchList.vue';

@Component({
  components: {
    AisHighlight,
    PropertyCardSearch,
    SearchList,
  },
})
export default class SearchPropertyResults extends Vue {
  transformPropertyItems(items: IPropertyResult[]): IPropertyResult[] {
    return items.map(item => {
      // Format surface
      const surface = item.property_size || item.carrez_size;

      if (surface) {
        item.surface = this.$t('units.square_meter_number', { number: this.$n(surface) });
      }

      // Format price
      if (item.price) {
        item.displayedPrice = this.$n(item.price, 'currency');
      }

      // Format rooms count
      if (item.rooms_count) {
        item.displayedRoomsCount = this.$tc('units.rooms', item.rooms_count);
      }

      // Format type
      if (item.type) {
        item.displayedType = this.$t(`f95.form.subtypes.${item.type}`);
      }

      // Format status
      if (item.status === 'lost') {
        item.displayedStatus = this.$tc('common.status.lost', 1);
      } else {
        item.displayedStatus = this.$tc(`listing_properties.progress.${item.progress || 'lead'}`, 1);
      }

      return item;
    });
  }
}
