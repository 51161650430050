
































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { EOfferType } from '@/enums/OfferType.enum';
import { EPropertyProgress } from '@/enums/PropertyProgress.enum';
import { NegotiationFunding } from '@/views/entity/steps/NegotiationFunding';
import Property from '@/models/Property.model';

@Component
export default class NegotiationDialog extends Vue {
  @Prop({ required: true, type: Boolean }) isNegotiationConfirmVisible!: boolean;
  @Prop({ type: Object }) negotiationFunding?: NegotiationFunding;
  @Prop({ default: EOfferType.Simple, type: String }) offerType!: EOfferType;
  @Prop({ type: String }) personFullName?: string;
  @Prop({ type: Object }) property?: Property;

  EPropertyProgress = Object.freeze(EPropertyProgress);
}
