




































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { ISearchLeadHit } from '@/interfaces/SearchLeadHit.interface';

import { AisHighlight } from 'vue-instantsearch';

@Component({
  components: {
    AisHighlight,
  },
})
export default class BuyerLeadCardSearch extends Vue {
  @Prop({ required: true, type: Object }) lead!: ISearchLeadHit;
}
