import axios, { AxiosInstance, AxiosRequestConfig, AxiosStatic, RawAxiosRequestHeaders, ResponseType } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { handleError } from '@/utils/errorHandler';

export interface LkAxiosInstance extends AxiosStatic {
  $delete<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>;
  $get<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>;
  $head<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>;
  $options<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<T>;
  $patch<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T>;
  $post<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T>;
  $put<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T>;
  $request<T = unknown>(config: AxiosRequestConfig): Promise<T>;
}

export const axiosConfig: {
  arrayBuffer: { responseType: ResponseType };
  blob: { responseType: ResponseType };
  formData: { headers: RawAxiosRequestHeaders };
} = {
  arrayBuffer: { responseType: 'arraybuffer' },
  blob: { responseType: 'blob' },
  formData: { headers: { 'Content-Type': 'multipart/form-data' } },
};

export function createApi(baseURL: string, authTokenName: string): LkAxiosInstance {
  const api = axios.create({
    baseURL,
    withCredentials: true,
  });

  // Set headers

  if (process.env.VUE_APP_COMMIT_HASH) {
    api.defaults.headers.common['X-Front-Commit-Hash'] = process.env.VUE_APP_COMMIT_HASH;
  }

  const authToken = localStorage.getItem(authTokenName);

  if (authToken) {
    api.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
  }

  // Add '$request', '$delete', '$get', '$head', '$options', '$post', '$put' and '$patch'
  extendAxiosInstance(api);

  // Error handling

  const errorHandler = error => handleError(error);

  // Interceptors

  api.interceptors.request.use(config => {
    if (config.headers?.['Content-Type'] === 'multipart/form-data' || !config.data) {
      return config;
    }

    config.data = decamelizeKeys(config.data);

    return config;
  }, errorHandler);

  api.interceptors.response.use(response => {
    if (response.request.responseType !== 'blob') {
      response.data = camelizeKeys(response.data);
    }

    return response;
  }, errorHandler);

  return api as LkAxiosInstance;
}

function extendAxiosInstance(instance: AxiosInstance): LkAxiosInstance {
  const axiosExtra = {};

  for (const method of ['request', 'delete', 'get', 'head', 'options', 'post', 'put', 'patch']) {
    axiosExtra[`$${method}`] = function(...args) {
      return this[method](...args).then(res => res && res.data);
    };
  }

  for (const key in axiosExtra) {
    instance[key] = axiosExtra[key].bind(instance);
  }

  return instance as LkAxiosInstance;
}