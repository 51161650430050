export enum EMandateType {
  Exclusive = 'exclusive',
  SemiExclusive = 'semi_exclusive',
  Simple = 'simple'
}

export enum EMandateStatus {
  Canceled = 'canceled',
  Draft = 'draft',
  Signed = 'signed',
  WaitingForApproval = 'waiting_for_approval',
  WaitingForSignature = 'waiting_for_signature',
}