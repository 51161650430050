import { IEditObjectivePayload, IObjectivePayload, IObjectives } from './AgentPerformances.interface';
import { LkAxiosInstance } from '@/api';
import { toQueryString } from '@/utils/url';

export interface IAgentPerformancesService {
  createObjective(objective: IObjectivePayload): Promise<void>;
  editObjective(objective: IEditObjectivePayload, objectiveId: string): Promise<IObjectives>;
  getObjectives(agentId?: string): Promise<IObjectives>;
}

export const agentPerformancesService = ($axios: LkAxiosInstance): IAgentPerformancesService => ({
  createObjective(objective) {
    return $axios.$post('agent_objectives', objective);
  },
  editObjective(objective, objectiveId) {
    return $axios.$post(`agent_objectives/${objectiveId}`, objective);
  },
  getObjectives(agentId) {
    const query = agentId ? `?${toQueryString({ agent_id: agentId })}` : '';

    return $axios.$get(`agent_performances_view${query}`);
  },
});
