import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import store from '@/store';

import { routes as domeRoutes, HashRoutes } from './dome';
import { routes as boRoutes } from './bo';

export interface IDomeRoute {
  meta?: {
    bypassAuth?: boolean;
    isAuthenticationRequired?: boolean;
    isBo: boolean;
    isLobby?: boolean;
    isNavigationHidden?: boolean;
  };
  name?: string | null;
}

/* eslint-disable vue/max-len */
const Error404 = () => import(/* webpackChunkName: "global" */ '@/views/error/error404.vue');
const MatchingCancel = () => import(/* webpackChunkName: "matching" */ '@/views/properties/id/matching/cancel-matching.vue');
/* eslint-enable vue/max-len */

const routes: RouteConfig[] = [
  ...domeRoutes,
  ...boRoutes,
  {
    component: MatchingCancel,
    meta: { bypassAuth: true, isNavigationHidden: true },
    name: 'property_matching_panel_cancelation',
    path: '/property_purchase_plans/:matchId/cancel_by_token',
  },
  {
    component: Error404,
    meta: { bypassAuth: true, isLobby: true },
    name: 'error404',
    path: '/*',
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
});

router.beforeEach(({ meta, name }: IDomeRoute, from: Route, next: NavigationGuardNext) => {
  if (!name) {
    return;
  }

  if (!meta?.bypassAuth) {
    const isBo = Boolean(meta?.isBo);
    const isAuthenticated = isBo ? store.state.bo!.authenticated : store.state.authenticated;
    const isAuthenticationRequired = meta?.isAuthenticationRequired ?? true;

    if (isAuthenticated) {
      if (!isAuthenticationRequired) {
        return next({ name: isBo ? 'bo_home' : 'home' });
      }

      if (!isBo) {
        void store.dispatch('getBadgesData');
      }
    } else if (isAuthenticationRequired) {
      return next({ name: isBo ? 'bo_log_in' : 'log_in' });
    }
  }

  // Avoid access to globalSearch with previous button if left from result
  if (from.hash === HashRoutes.globalSearch) {
    history.replaceState({}, '', from.fullPath.replace(HashRoutes.globalSearch, ''));
  }

  router.app.$mixpanel.track(`${name}_viewed`, from.name ? { from: from.name } : {});

  next();
});

// Check for Dome Update
router.afterEach(() => {
  void store.dispatch('updateServiceWorker');
});

Vue.use(VueRouter);
export default router;