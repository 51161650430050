import { createApi, LkAxiosInstance } from '@/api';

import { authService, IAuthService } from './auth';
import { ELocalStorageKeys } from '@/enums/Storage.enum';
import { IAccountsService, accountsService } from './accounts';
import { IAgenciesPublicKeysService, agenciesPublicKeysService } from './publicKeys';
import { IAgenciesService, agenciesService } from './agencies';
import { IAgenciesSettingsService, agenciesSettingsService } from './settings/settings';
import { IAgentsService, agentsService } from './agents/agents';
import { IGeoLocationsService, geoLocationsService } from './geoLocations';
import { IPortalsService, portalsService } from './portals/portals';
import { ITasksAutoService, tasksAutoService } from './tasksAuto';

export const boApi: LkAxiosInstance = createApi(
  process.env.VUE_APP_BO_API_URL || '',
  ELocalStorageKeys.BoAuthToken,
);

export interface IBoService {
  accounts: IAccountsService;
  agencies: IAgenciesService;
  agenciesPublicKeys: IAgenciesPublicKeysService;
  agenciesSettings: IAgenciesSettingsService;
  agents: IAgentsService;
  auth: IAuthService;
  geoLocations: IGeoLocationsService;
  portals: IPortalsService;
  tasksAuto: ITasksAutoService;
}

export default {
  accounts: accountsService(boApi),
  agencies: agenciesService(boApi),
  agenciesPublicKeys: agenciesPublicKeysService(boApi),
  agenciesSettings: agenciesSettingsService(boApi),
  agents: agentsService(boApi),
  auth: authService(boApi),
  geoLocations: geoLocationsService(boApi),
  portals: portalsService(boApi),
  tasksAuto: tasksAutoService(boApi),
};
