var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseSearch',_vm._b({attrs:{"data-test-id-context":_vm.dataTestId,"error-message":_vm.errorMessage,"is-value-visible":!!_vm.value,"placeholder":_vm.placeholder || _vm.$t('address.search.placeholder'),"results":_vm.addresses,"search":_vm.fieldValue},on:{"blur":_vm.handleBlur,"clear-value":_vm.handleClear,"focus":function($event){return _vm.$emit('focus')},"result-click":function (result) { return _vm.updateValue(result.content.toString()); },"suggestion-click":function (suggestion) { return _vm.updateValue(suggestion.value); },"update":_vm.handleSearch}},'BaseSearch',{
    autocomplete: _vm.autocomplete,
    disabled: _vm.disabled,
    id: _vm.id,
    isLoading: _vm.isLoading,
    label: _vm.label,
    previousValue: _vm.previousValue,
    previousValueText: _vm.previousValueText,
    readonly: _vm.readonly,
    required: _vm.required,
    suggestionList: _vm.suggestionList,
  },false),[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.value)+" ")]},{"value":_vm.value})],2)}
var staticRenderFns = []

export { render, staticRenderFns }