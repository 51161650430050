import { EPersonContactContext } from '@/enums/PersonContactContext.enum';
import { EPersonContactType } from '@/enums/PersonContactType.enum';

export class PersonContactModel {
  context: EPersonContactContext;
  id?: string;
  isPrimary: boolean;
  personId?: string;
  type: EPersonContactType;
  value: string;

  constructor() {
    this.context = EPersonContactContext.Default;
    this.isPrimary = false;
    this.type = EPersonContactType.Email;
    this.value = '';
  }
}

export interface IPersonContact {
  context: EPersonContactContext;
  id: string;
  isPrimary: boolean;
  personId: string;
  type: EPersonContactType;
  value: string;
}

export interface IPersonContactCreationPayload {
  context: EPersonContactContext;
  isPrimary: boolean;
  personId: string;
  type: EPersonContactType;
  value: string;
}