const VisitsIndex = () => import(/* webpackChunkName: "visits" */ '@/views/properties/id/visits/index.vue');
const VisitsDetail = () => import(/* webpackChunkName: "visits" */ '@/views/properties/id/visits/id/index.vue');

import { createFollowUpRoute } from '../property-purchase-plan';
import { createPersonRoute } from '../person';

export default [
  {
    component: VisitsIndex,
    name: 'visits',
    path: '/biens/:propertyId/visites',
  },
  {
    children: [
      createPersonRoute('visit_planning'),
      createFollowUpRoute('visit_planning'),
    ],
    component: VisitsDetail,
    name: 'visit_planning',
    path: '/biens/:propertyId/visites/:visit',
  },
];