import { AxiosResponse } from 'axios';
import { createApi, LkAxiosInstance } from '@/api';
import { ELocalStorageKeys } from '@/enums/Storage.enum';
import { ICheckTokenStatusResponse, ILoginResponse } from '@/interfaces/dto/authService/authResponse.interface';
import { ICheckTokenStatus, ILoginRequest, IResetPasswordRequest }
  from '@/interfaces/dto/authService/authRequest.interface';

const api: LkAxiosInstance = createApi(
  process.env.VUE_APP_API_URL || '',
  ELocalStorageKeys.AuthToken,
);

// TODO(refactor: integrate directly in dome folder)
const endpoints = {
  checkTokenStatus(params: ICheckTokenStatus): Promise<AxiosResponse<ICheckTokenStatusResponse>> {
    return api.post('/check_token_status', params);
  },
  logIn(params: ILoginRequest): Promise<AxiosResponse<ILoginResponse>> {
    return api.post('/login', params);
  },
  resetPassword(params: IResetPasswordRequest): Promise<AxiosResponse<void>> {
    return api.post('/reset_password', params);
  },
  sendResetPasswordEmail(email: string): Promise<AxiosResponse<void>> {
    return api.post('/send_reset_password_email', { email });
  },
};

export { api, endpoints };
