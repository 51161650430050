import { createFollowUpRoute } from './property-purchase-plan';
import { createPersonRoute } from './person';
import { getCriteriasAsProp } from '@/helpers/buyers-search/query';
import { IBuyersSearchRouteQuery } from '@/interfaces/BuyersSearchFilters.interface';
import { Route } from 'vue-router';

/* eslint-disable vue/max-len */
const BuyersSearch = () => import(/* webpackChunkName: "buyers-search" */ '@/views/buyers-search/index.vue');
const BuyersSearchByCriterias = () => import(/* webpackChunkName: "buyers-search" */ '@/views/buyers-search/by-criterias/index.vue');
const BuyersSearchByProperty = () => import(/* webpackChunkName: "buyers-search" */ '@/views/buyers-search/by-property/index.vue');
const BuyersSearchResults = () => import(/* webpackChunkName: "buyers-search" */ '@/views/buyers-search/results/index.vue');
/* eslint-enable vue/max-len */

export enum EBuyersSearchRoutes {
  Home = 'buyers_search',
  ByCriterias = 'buyers_search_by_criterias',
  ByProperty = 'buyers_search_by_property',
  Results = 'buyers_search_results',
  ResultsWithPerson = 'buyers_search_results_person',
  ResultsWithFollowUp = 'buyers_search_results_person_follow_up',
}

export default [
  {
    children: [
      {
        component: BuyersSearchByCriterias,
        name: EBuyersSearchRoutes.ByCriterias,
        path: 'par_criteres',
      },
      {
        component: BuyersSearchByProperty,
        name: EBuyersSearchRoutes.ByProperty,
        path: 'rapprochement',
      },
      {
        children: [
          createPersonRoute(EBuyersSearchRoutes.Results),
          createFollowUpRoute(EBuyersSearchRoutes.Results),
        ],
        component: BuyersSearchResults,
        name: EBuyersSearchRoutes.Results,
        path: 'resultats',
        props: ({ query }: Route) => ({ criterias: getCriteriasAsProp(query as IBuyersSearchRouteQuery) }),
      },
    ],
    component: BuyersSearch,
    name: EBuyersSearchRoutes.Home,
    path: '/recherche_acheteurs',
  },
];
