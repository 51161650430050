const ContactsIndex = () => import(/* webpackChunkName: "contacts" */ '@/views/contacts/index.vue');

const ContactsPersonMatching =
  () => import(/* webpackChunkName: "contacts" */ '@/views/contacts/id/matching/index.vue');

const ContactsPersonMatchingPanel = () => import(/* webpackChunkName: "contacts" */ '@/views/panels/match.vue');
const ContactsPersonMatchingPanelPerson = () => import(/* webpackChunkName: "contacts" */ '@/views/panels/person.vue');

import { createFollowUpRoute } from './property-purchase-plan';
import { createPersonRoute } from './person';

export enum EContactRoutes {
  Home = 'contacts',
  MatchPanel = 'person_matching_panel',
  MatchPanelPerson = 'person_matching_panel_person',
  MatchingPanel = 'person_matching',
}

export default [
  {
    children: [
      createPersonRoute('contacts'),
      createFollowUpRoute('contacts'),
    ],
    component: ContactsIndex,
    name: EContactRoutes.Home,
    path: '/contacts',
  },
  {
    children: [
      {
        component: ContactsPersonMatchingPanel,
        name: EContactRoutes.MatchPanel,
        path: ':matchId',
      },
      {
        component: ContactsPersonMatchingPanelPerson,
        name: EContactRoutes.MatchPanelPerson,
        path: ':matchId/personne/',
      },
    ],
    component: ContactsPersonMatching,
    name: EContactRoutes.MatchingPanel,
    path: '/contacts/personne/:personId/matching',
  },
];