


























import { Component, Prop } from 'vue-property-decorator';
import { IOption } from '@/interfaces/Option.interface';
import PropertyStepHelper from './helpers/property-step-helper.vue';

@Component
export default class SelectProperty extends PropertyStepHelper {
  @Prop({ required: true, type: Object }) suggestionList!: Record<'person' | 'property', IOption<string>[]>;
}
