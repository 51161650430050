export enum EBuyerQualification {
  Cold = 'cold',
  Hot = 'hot',
  Lukewarm = 'lukewarm',
}

export enum EPropertySearchProgress {
  Advanced = 'advanced',
  Beginning = 'beginning',
  Recent = 'recent',
}