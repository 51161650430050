import { ELocalStorageKeys } from '@/enums/Storage.enum';
import { Module } from 'vuex';
import { RootState } from './index';
import services, { boApi } from '@/api/bo';

export interface BoStoreState {
  authenticated: boolean;
}

export const boModule: Module<BoStoreState, RootState> = {
  actions: {
    async logIn({ commit }, params: { email: string, password: string }): Promise<void> {
      const { token } = await services.auth.login(params);

      if (token) {
        localStorage.setItem(ELocalStorageKeys.BoAuthToken, token);
        boApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        commit('SET_AUTHENTICATED', true);
      }
    },

    logOut({ commit }): void {
      // Remove authorization token
      localStorage.removeItem(ELocalStorageKeys.BoAuthToken);
      boApi.defaults.headers.common['Authorization'] = '';
      commit('SET_AUTHENTICATED', false);
    },
  },
  mutations: {
    SET_AUTHENTICATED(state, value: boolean) {
      state.authenticated = value;
    },
  },
  namespaced: true,
  state: {
    authenticated: Boolean(localStorage.getItem(ELocalStorageKeys.BoAuthToken)),
  },
};
