



















import { Component, Mixins } from 'vue-property-decorator';

import { EPropertyTransactionMode } from '@/enums/PropertyTransactionMode.enum';
import { IStep } from '@/components/common/molecules/stepper/StepperWithStatus.interface';
import { PropertyEntityModel } from './models';

import EntityCreationWizard from './wizard.vue';
import PropertyHelpers from './helpers/property.vue';
import RentingProperty from './steps/RentingProperty.vue';
import Wizard from '@/components/common/templates/wizard/Wizard.vue';

@Component({
  components: {
    RentingProperty,
    Wizard,
  },
})
export default class RentingPropertyEntity extends Mixins(EntityCreationWizard, PropertyHelpers) {
  get stepList(): string[] {
    return ['renting-property'];
  }

  get steps(): IStep[] {
    return this.stepList.map(step => ({
      hasError: false,
      isFilled: false,
      name: this.$t('entity_creation.steps.property.title'),
      value: step,
    }));
  }

  created(): void {
    this.setData();
  }

  setData(): void {
    this.entities = {
      property: new PropertyEntityModel(EPropertyTransactionMode.Renting),
    };
  }

  /************************************************/
  /**************** Business logic ****************/
  /************************************************/

  async createEntity(): Promise<void> {
    this.isSubmitting = true;
    let propertyId!: string | undefined;

    if (!this.isEntityValid(this.stepList)) {
      this._handleError('invalid data', this.errorStepIndex);

      return;
    }

    // Property
    try {
      propertyId = await this.createRentingProperty(this.entities.property as PropertyEntityModel);

      if (!propertyId) {
        this._handleError('empty', this.stepList.indexOf('property'));

        return;
      }
    } catch (error) {
      this._handleError(error, this.stepList.indexOf('property'), true);

      return;
    }

    await this.closeWizard();

    await this.$router.push({
      hash: undefined,
      name: 'property',
      params: { propertyId },
    });
  }
}
