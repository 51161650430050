import { LkAxiosInstance } from '@/api';
import { toQueryString } from '@/utils/url';
import { EPropertyPriceContext } from '@/enums/PropertyPriceContext.enum';
import {
  INetSellingPriceResponse,
  IPropertyPrice,
  IPropertyPricesResponse,
} from '@/api/dome/property-prices/PropertyPrices.interface';

export interface IPropertyPricesService {
  fetchPricesByProperty(
      propertyId: string, context: EPropertyPriceContext | EPropertyPriceContext[]
  ): Promise<IPropertyPrice[]>;
  getNetSellingPrice(propertyPrice: IPropertyPrice): Promise<INetSellingPriceResponse>;
  setPrices(propertyPrices: IPropertyPrice[]): Promise<void>;
}

export const propertyPricesService = ($axios: LkAxiosInstance): IPropertyPricesService => ({
  async fetchPricesByProperty(propertyId, context) {
    const query = toQueryString({ context, property_id: propertyId });
    const { propertyPrices } = await $axios.$get<IPropertyPricesResponse>(`/property_prices?${query}`);

    return propertyPrices;
  },
  getNetSellingPrice(propertyPrice) {
    return $axios.$post('/compute_property_net_selling_price', propertyPrice);
  },
  setPrices(propertyPrices) {
    return $axios.$post('/property_prices', { propertyPrices });
  },
});
