import { Icons, icons } from '@/helpers/icons';
import { RouteConfig } from 'vue-router';
import agentPerformanceRoutes from './dome/agent-performance';
import buyersSearchRoutes from './dome/buyers-search';
import contactsRoutes from './dome/contacts';
import followedPropertyPurchasePlansRoutes from './dome/followed-property-purchase-plan';
import leadsRoutes from './dome/leads';
import lobbyRoutes from './dome/lobby';
import mandateRoutes from './dome/mandate';
import planningRoutes from './dome/planning';
import propertiesRoutes from './dome/properties';
import tasksRoutes from './dome/tasks';

export const routeIcons: Record<string, Icons> = {
  buyer_leads: icons.menu_visits,
  buyers_search: icons.menu_buyers_search,
  contacts: icons.menu_contacts,
  followed_property_purchase_plan: icons.menu_followed_property_purchase_plan,
  planning: icons.calendar,
  properties: icons.menu_properties,
  seller_leads: icons.prospecting,
  tasks: icons.task,
};

export const bottomBarRoutes = [
  'properties',
  'create_entity',
  'planning',
];

export const primaryRoutes = [
  'properties',
  'seller_leads',
  'buyer_leads',
  'followed_property_purchase_plan',
  'buyers_search',
  'tasks',
  'planning',
  'contacts',
];

export enum HashRoutes {
  amendMandate = '#amend-mandate',
  createEntity = '#create-entity',
  createMandate = '#create-mandate',
  createMeeting = '#create-meeting',
  createPoster = '#create-poster',
  createPublication = '#create-publication',
  createSummarySheet = '#create-summary-sheet',
  createVisitReport = '#create-visit-report',
  displayFilters = '#display-filters',
  editEntity = '#edit-entity',
  editLead = '#edit-lead',
  editNegotiation = '#edit-negotiation',
  editPhotos = '#edit-photos',
  editTask = '#edit-task',
  globalSearch = '#global-search',
  navOpen = '#nav-open',
  validateOffer = '#validate-offer',
  verifyOffer = '#verify-offer',
}

export const routes: RouteConfig[] = [
  {
    name: 'home',
    path: '/',
    redirect: { name: 'properties' },
  },
  ...lobbyRoutes,
  ...agentPerformanceRoutes,
  ...buyersSearchRoutes,
  ...followedPropertyPurchasePlansRoutes,
  ...propertiesRoutes,
  ...mandateRoutes,
  ...contactsRoutes,
  ...leadsRoutes,
  ...planningRoutes,
  ...tasksRoutes,
];