










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ActionableItem extends Vue {
  @Prop({ required: true, type: String }) readonly text!: string;
}
