



















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { getFormattedFileSize } from '@/utils/file';
import { parseError } from '@/utils/validation';

let fileUploaderId = 0;
const MAX_BYTES_FILES_ALLOWED = 100 * 1048576;

@Component
export default class FileUploader extends Vue {
  @Prop({ default: 'image/jpeg,image/png,image/gif,image/webp', type: String }) accept!: string;
  @Prop({ default: false, type: Boolean }) compact!: boolean;
  @Prop({ default: false, type: Boolean }) dropzoneInside!: boolean;
  @Prop({ default: '', type: String }) label!: string;
  @Prop({ default: 100000000, type: Number }) maxFileSize!: number;
  @Prop({ default: false, type: Boolean }) multiple!: boolean;
  @Prop({ default: '', type: String }) placeholderCta!: string;
  @Prop({ default: '', type: String }) placeholderFileSize!: string;
  @Prop({ default: '', type: String }) placeholderText!: string;
  @Prop({ default: '', type: String }) placeholderTextMobile!: string;

  files!: File[];
  id = `file-uploader-${fileUploaderId++}`;
  isDraggingOver = false;
  images = null as string[] | ArrayBuffer[] | null;

  created(): void {
    window.addEventListener('dragover', event => {
      event.preventDefault();
      this.isDraggingOver = true;
    });

    window.addEventListener('drop', e => {
      e.preventDefault();
      this.isDraggingOver = false;
    });

    window.addEventListener('dragend', () => {
      this.isDraggingOver = false;
    });
  }

  get maxFileSizeText(): string {
    return `${this.$t('base.upload_file.max_file_size')} ${getFormattedFileSize(this.maxFileSize)}`;
  }

  updateFiles(): void {
    this.$emit('change', this.multiple ? this.files : this.files[0]);
  }

  manageNewFiles(e: Event, isDragged = false): void {
    const target = e.target as HTMLInputElement;

    if (isDragged) {
      const draggedFiles = [...((e as DragEvent).dataTransfer as DataTransfer).files];
      this.files = draggedFiles.filter(file => this.validateFile(file));
    } else {
      this.files = [...target.files!].filter(file => this.validateFile(file));
    }

    target.value = '';
    const totalFilesBytesSize = this.files.reduce((acc, file) => acc += file.size, 0);

    if (totalFilesBytesSize <= MAX_BYTES_FILES_ALLOWED) {
      this.updateFiles();
    } else {
      this.$snackbar.error(this.$t('errors.max_files_size_exceeded'));
    }
  }

  private validateFile(file: File): boolean {
    let error;
    const fileType = file.type !== '' ? file.type : file.name.split('.').pop() as string;

    if (this.accept.indexOf(fileType) === -1) {
      error = { message: 'file_upload.invalid_type', type: 'bad-payload' };
    } else if (this.maxFileSize && file.size > this.maxFileSize) {
      const message = this.maxFileSize === 10000000
        ? 'file_upload.invalid_file_size_ten_mo' : 'file_upload.invalid_file_size';

      error = { message, type: 'bad-payload' };
    }

    if (error) {
      this.$snackbar.error(parseError(this, error));
    }

    return !error;
  }
}
