import { LkAxiosInstance } from '@/api';
import { INotary, INotaryCreationPayload, INotaryUpdatePayload } from './Notaries.interface';

const BASE_ENDPOINT = '/notaries';

export interface INotariesService {
  createNotary(payload: INotaryCreationPayload): Promise<{ id: string }>;
  getNotary(notaryId: string): Promise<INotary>;
  updateNotary(notaryId: string, payload: INotaryUpdatePayload): Promise<void>;
}

export const notariesService = ($axios: LkAxiosInstance): INotariesService => ({
  createNotary(payload) {
    return $axios.$post(`${BASE_ENDPOINT}`, payload);
  },
  getNotary(notaryId: string) {
    return $axios.$get(`${BASE_ENDPOINT}/${notaryId}`);
  },
  updateNotary(notaryId: string, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${notaryId}`, payload);
  },
});