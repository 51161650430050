import { Route, RouteConfig } from 'vue-router';

const PropertyPurchasePlan = () =>
  import(/* webpackChunkName: "property-purchase-plan" */ '@/views/panels/property-purchase-plan.vue');

export const createFollowUpRoute = (namePrefix: string): RouteConfig => ({
  component: PropertyPurchasePlan,
  name: `${namePrefix}_person_follow_up`,
  path: ':personId/suivi/:propertyPurchasePlanId',
  props: ({ params }: Route) => ({ propertyPurchasePlanId: params.propertyPurchasePlanId }),
});