const OffersIndex = () => import(/* webpackChunkName: "offers" */ '@/views/properties/id/offers/index.vue');
const OffersDetail = () => import(/* webpackChunkName: "offers" */ '@/views/properties/id/offers/id/index.vue');
// eslint-disable-next-line vue/max-len
const OffersHistory = () => import(/* webpackChunkName: "offers" */ '@/views/properties/id/offers/id/panels/history.vue');

import { createFollowUpRoute } from '../property-purchase-plan';
import { createPersonRoute } from '../person';

export default [
  {
    component: OffersIndex,
    name: 'offers',
    path: '/biens/:propertyId/offres',
  },
  {
    children: [
      createPersonRoute('offer'),
      createFollowUpRoute('offer'),
      {
        component: OffersHistory,
        name: 'offer_history',
        path: 'historique',
      },
    ],
    component: OffersDetail,
    name: 'offer',
    path: '/biens/:propertyId/offres/:negotiationId',
  },
];