const BuyerLeadsIndex = () => import(/* webpackChunkName: "buyer-leads" */ '@/views/buyer-leads/index.vue');
const BuyerLeadsDetail = () => import(/* webpackChunkName: "buyer-leads" */ '@/views/buyer-leads/id/index.vue');

export default [
  {
    children: [
      {
        component: BuyerLeadsDetail,
        name: 'buyer_lead',
        path: ':buyerLeadId',
        props: true,
      },
    ],
    component: BuyerLeadsIndex,
    name: 'buyer_leads',
    path: '/leads-acheteurs',
  },
];