
































































































































import { Component, Prop, Emit, Watch, Vue } from 'vue-property-decorator';
import { IOption } from '@/interfaces/Option.interface';
import { IResultItem } from '@/interfaces/ResultItem.interface';

import FieldComponent from '@/components/common/molecules/forms/FieldComponent.vue';
import FormChip from '@/components/common/atoms/forms/chips/FormChip.vue';

let searchInputId = 0;

@Component({
  components: {
    FormChip,
  },
})
export default class BaseSearch extends FieldComponent<string> {
  @Prop({ default: false, type: Boolean }) areResultsHidden!: boolean;
  @Prop({ default: false, type: Boolean }) closeOnSelect!: boolean;
  @Prop({ default: 300, type: Number }) debounceDelay!: number;
  @Prop({ default: false, type: Boolean }) isFixed!: boolean;
  @Prop({ default: false, type: Boolean }) isLoading!: boolean;
  @Prop({ default: false, type: Boolean }) isOrientationTop!: boolean;
  @Prop({ default: false, type: Boolean }) isValueVisible!: boolean;
  @Prop({ default: false, type: Boolean }) isValueWatched!: boolean;
  @Prop({ default: false, type: Boolean }) multiple!: boolean;
  @Prop({ default: () => ([]), type: Array }) results!: IResultItem[];
  @Prop({ required: true, type: String }) search!: string;
  @Prop({ default: () => ([]), type: Array }) suggestionList!: IOption<string>[];

  activeItem = 0;
  key = new Date().getTime();
  searchInputRef = `input${searchInputId++}`;
  showResult = false;
  timeout: ReturnType<typeof setTimeout> | null = null;

  @Watch('search')
  onSearchChange(search: string): void {
    this.showResult = !this.areResultsHidden && !this.isValueVisible && search.length > 0;

    if (this.showResult && this.isFixed && this.$refs.dropdown) {
      this.setDropdownPosition();
    }
  }

  @Emit()
  clearValue(): void {
    this.key = new Date().getTime();
  }

  @Emit('result-click')
  selectResult(item: IResultItem): IResultItem {
    if (!this.multiple || this.closeOnSelect) {
      this.showResult = false;
    }

    return item;
  }

  get inputClass(): string {
    if (!this.showLoadedResults) {
      return 'search';
    }

    return this.isOrientationTop
      ? 'search border-t-transparent rounded-t-none'
      : 'search border-b-transparent rounded-b-none';
  }

  get showLoadedResults(): boolean {
    return this.showResult && !this.isLoading;
  }

  beforeDestroy(): void {
    this.clearTimeout();
  }

  checkClickOutside(event): boolean {
    return !event.composedPath()?.some(({ id }) => id === 'basesearch-dropdown');
  }

  clearTimeout(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  focus(): void {
    this.showResult = !this.areResultsHidden && !this.isValueVisible && this.search.length > 0;
    this.$emit('focus');
  }

  focusNext(): void {
    if (this.results?.length) {
      this.activeItem = (this.activeItem + 1) % this.results.length;
      this.scrollToActiveItem(false);
    }
  }

  focusPrevious(): void {
    if (this.results?.length) {
      this.activeItem = (this.activeItem - 1 + this.results.length) % this.results.length;
      this.scrollToActiveItem();
    }
  }

  hideResult(): void {
    this.showResult = false;
    this.$emit('click-outside');
  }

  scrollToActiveItem(alignTop?: boolean): void {
    (this.$refs[`searchItem${this.activeItem}`] as HTMLElement)[0].scrollIntoView(alignTop);
  }

  setDropdownPosition(): void {
    const baseInput = ((this.$refs[this.searchInputRef] as Vue).$el as HTMLInputElement).getBoundingClientRect();
    const dropdown = this.$refs.dropdown as HTMLElement;
    const input = ((this.$refs[this.searchInputRef] as Vue).$refs.input as HTMLElement).getBoundingClientRect();

    if (this.isOrientationTop) {
      dropdown.style.bottom = `${window.innerHeight - input.top}px`;
    } else {
      dropdown.style.top = `${baseInput.top + baseInput.height}px`;
    }

    dropdown.style.left = `${baseInput.left}px`;
    dropdown.style.width = `${baseInput.width}px`;
  }

  update(value: string): void {
    if (this.debounceDelay) {
      this.clearTimeout();

      this.timeout = setTimeout(() => {
        this.$emit('update', value);
      }, this.debounceDelay);

    } else {
      this.$emit('update', value);
    }
  }
}
