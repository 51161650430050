import { RouteConfig } from 'vue-router';

import { createPersonRoute } from '../person';

const Index = () => import(/* webpackChunkName: "seller-leads" */ '@/views/seller-leads/index.vue');
const Detail = () => import(/* webpackChunkName: "seller-leads" */ '@/views/seller-leads/id/index.vue');
const Meeting = () => import(/* webpackChunkName: "seller-leads" */ '@/views/planning/panels/meeting.vue');
const SellerLeadPanel = () => import(/* webpackChunkName: "seller-leads" */ '@/views/panels/seller-lead.vue');

export const createSellerLeadRoute = (namePrefix: string): RouteConfig => ({
  component: SellerLeadPanel,
  name: `${namePrefix}_seller_lead`,
  path: 'prospect/:sellerLeadId',
});

export default [
  {
    children: [
      createPersonRoute('seller_lead'),
      {
        component: Detail,
        name: 'seller_lead',
        path: ':sellerLeadId',
      },
      {
        component: Meeting,
        name: 'meeting_seller_lead',
        path: 'estimation/:eventId',
      },
    ],
    component: Index,
    name: 'seller_leads',
    path: '/leads-vendeurs',
  },
];