import { LkAxiosInstance, axiosConfig } from '@/api';
import { createBlobUrl } from '@/utils/blobHelper';
import { getFileName } from '@/utils/file';
import { IFile, IFileResponse } from '@/interfaces/File.interface';
import {
  IAgencyFees,
  INegotiation,
  INegotiationCancellation,
  INegotiationOfferDefaultResponse,
  INegotiationOfferRequest,
  INegotiationRequest,
  INegotiationResponse,
  INegotiationUpdatePayload,
  IOfferCreationResponse,
} from '@/api/dome/negotiations/Negotiations.interface';
import { IOffer } from '@/api/dome/offers/Offers.interface';

const BASE_ENDPOINT = '/negotiations';

export interface INegotiationsService {
  acceptNegotiation(negotiationId: string): Promise<INegotiation>;
  add(negotiation: INegotiationRequest): Promise<INegotiationResponse>;
  addOffer(negotiationId: string, offer: INegotiationOfferRequest): Promise<IOfferCreationResponse>;
  cancel(negotiationId: string, payload: INegotiationCancellation): Promise<INegotiationResponse>;
  generateESignedOfferPdf(negotiationId: string): Promise<IFile>;
  generateOfferPdf(negotiationId: string): Promise<IFile>;
  getAgencyFeesStep(negotiationId: string): Promise<IAgencyFees>;
  getNegotiation(negotiationId: string): Promise<INegotiation>;
  getNegotiationHistory(negotiationId: string): Promise<IOffer[]>;
  getNegotiationOfferDefault(propertyId: string): Promise<INegotiationOfferDefaultResponse>;
  getNegotiations(propertyId: string): Promise<INegotiation[]>;
  previewPdf(negotiationId: string): Promise<string>;
  sendEsignOffer(negotiationId: string): Promise<void>;
  updateNegotiation(negotiationId: string, negotiation: Partial<INegotiationUpdatePayload>): Promise<INegotiation>;
  validateNegotiationAcceptation(negotiationId: string): Promise<void>;
}

export const negotiationsService = ($axios: LkAxiosInstance): INegotiationsService => ({
  acceptNegotiation(negotiationId) {
    return $axios.$post(`${BASE_ENDPOINT}/${negotiationId}/accept`);
  },
  add(negotiation) {
    return $axios.$post(BASE_ENDPOINT, negotiation);
  },
  async addOffer(negotiationId, offer) {
    return $axios.$post(`${BASE_ENDPOINT}/${negotiationId}/offers`, offer);
  },
  cancel(negotiationId, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${negotiationId}/cancel`, payload);
  },
  async generateESignedOfferPdf(negotiationId) {
    const response: IFileResponse = await $axios.get(
      `${BASE_ENDPOINT}/${negotiationId}/fetch_signed_offer_from_docusign`,
      axiosConfig.blob,
    );

    return { name: getFileName(response.headers), url: createBlobUrl(response.data) };
  },
  async generateOfferPdf(negotiationId) {
    const response: IFileResponse = await $axios.post(
      `${BASE_ENDPOINT}/${negotiationId}/generate_offer_pdf`,
      {},
      axiosConfig.blob,
    );

    return { name: getFileName(response.headers), url: createBlobUrl(response.data) };
  },
  async getAgencyFeesStep(negotiationId) {
    return $axios.$get(`${BASE_ENDPOINT}/${negotiationId}/agency_fees_mandate_view`);
  },
  getNegotiation(negotiationId) {
    return $axios.$get(`${BASE_ENDPOINT}/${negotiationId}`);
  },
  async getNegotiationHistory(negotiationId) {
    return $axios.$get(`${BASE_ENDPOINT}/${negotiationId}/offers`);
  },
  getNegotiationOfferDefault(propertyId) {
    return $axios.$get(`/negotiation_offer_defaults?property_id=${propertyId}`);
  },
  getNegotiations(propertyId) {
    return $axios.$get<INegotiation[]>(`${BASE_ENDPOINT}?property_id=${propertyId}`);
  },
  async previewPdf(id) {
    const response = await $axios.post<Blob>(`${BASE_ENDPOINT}/${id}/preview_offer_pdf`, {}, axiosConfig.blob);

    return createBlobUrl(response.data);
  },
  sendEsignOffer(negotiationId) {
    return $axios.$post(`${BASE_ENDPOINT}/${negotiationId}/send_offer_docusign`);
  },
  updateNegotiation(negotiationId, negotiation) {
    return $axios.$post(`${BASE_ENDPOINT}/${negotiationId}`, negotiation);
  },
  validateNegotiationAcceptation(negotiationId) {
    return $axios.$post(`${BASE_ENDPOINT}/${negotiationId}/validate_acceptation`);
  },
});
