import { Email, IEmailResponse } from './Emails.interface';
import { LkAxiosInstance } from '@/api';

const BASE_ENDPOINT = '/emails';

export interface IEmailsService {
  generate(targetId: string | number, targetType: string): Promise<Email>;
}

export const emailsService = ($axios: LkAxiosInstance): IEmailsService => ({
  async generate(targetId, targetType) {
    const response = await $axios.$post<IEmailResponse>(`${BASE_ENDPOINT}/generate`, {
      targetId,
      targetType: targetType.toLowerCase(),
    });

    return new Email(response);
  },
});