import { LkAxiosInstance } from '@/api';
import {
  ICreatePropertyDiagnosticsPayload,
  IPropertyDiagnostics,
  IPropertyDiagnosticsList,
  IUpdatePropertyDiagnosticsPayload,
} from './Diagnostics.interface';

const BASE_ENDPOINT = '/property_diagnostics';

export interface IDiagnosticsService {
  create(payload: ICreatePropertyDiagnosticsPayload): Promise<IPropertyDiagnostics>;
  getByProperty(propertyId: string): Promise<IPropertyDiagnostics | undefined>;
  update(payload: IUpdatePropertyDiagnosticsPayload): Promise<IPropertyDiagnostics>;
}

export const diagnosticsService = ($axios: LkAxiosInstance): IDiagnosticsService => ({
  create(payload) {
    return $axios.$post(BASE_ENDPOINT, payload);
  },
  async getByProperty(propertyId) {
    const { propertyDiagnostics } = await $axios.$get<IPropertyDiagnosticsList>(
      `${BASE_ENDPOINT}?property_id=${propertyId}`,
    );

    return propertyDiagnostics[0];
  },
  update(payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${payload.id}`, payload);
  },
});
