import { LkAxiosInstance } from '@/api';
import { INotification } from '@/interfaces/Notification.interface';

const BASE_ENDPOINT = '/agent_notifications';
const BASE_ENDPOINT_SUBSCRIPTION = '/agent_subscriptions';

interface INotificationsResponse {
  agentNotifications: INotification[];
}

export interface INotificationsService {
  getAll(): Promise<INotification[]>;
  markAsClicked(notificationId: string): Promise<void>;
  markAsSeen(agentId: string): Promise<void>;
  subscribe(token: string): Promise<void>;
  unsubscribe(token: string): Promise<void>;
}

export const notificationsService = ($axios: LkAxiosInstance): INotificationsService => ({
  async getAll() {
    const { agentNotifications } = await $axios.$get<INotificationsResponse>(`${BASE_ENDPOINT}/feed`);

    return agentNotifications;
  },

  markAsClicked(notificationId) {
    return $axios.$post(`${BASE_ENDPOINT}/mark_as_clicked`, { id: notificationId });
  },

  markAsSeen(agentId) {
    return $axios.$post(`${BASE_ENDPOINT}/mark_as_seen`, { agentId });
  },

  subscribe(token) {
    return $axios.$post(`${BASE_ENDPOINT_SUBSCRIPTION}/subscribe`, { token });
  },

  unsubscribe(token) {
    return $axios.$post(`${BASE_ENDPOINT_SUBSCRIPTION}/subscribe`, { token });
  },
});
