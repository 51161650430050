























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
  @Prop({ default: false, type: Boolean }) darkOverlay!: boolean;
  @Prop({ default: false, type: Boolean }) isVisible!: boolean;
}
