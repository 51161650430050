const MandateIndex = () => import(/* webpackChunkName: "mandate" */ '@/views/mandate/id/index.vue');
const MandateHistory = () => import(/* webpackChunkName: "mandate" */ '@/views/mandate/id/panels/history.vue');

export default [
  {
    children: [
      {
        component: MandateHistory,
        name: 'mandate_history',
        path: 'historique',
      },
    ],
    component: MandateIndex,
    name: 'mandate',
    path: '/mandat/:mandateId',
  },
];