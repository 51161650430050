import { AxiosResponse } from 'axios';

import {
  ICreateSummarySheetPayload,
  ISummarySheet,
  ISummarySheetPropertyPhotoLinkList,
  ISummarySheetPropertyPhotoLinksPayload,
  ISummarySheetsList,
} from './SummarySheets.interface';
import { LkAxiosInstance, axiosConfig } from '@/api';

const BASE_ENDPOINT = '/summary_sheets';

export interface ISummarySheetsService {
  fetchByPropertyId(propertyId: string): Promise<ISummarySheet[]>;
  generatePdf(id: string): Promise<AxiosResponse<ArrayBuffer>>;
  save(summarySheet: ICreateSummarySheetPayload): Promise<ISummarySheet>;
  setPhotos(
    summarySheetId: string,
    payload: ISummarySheetPropertyPhotoLinksPayload
  ): Promise<ISummarySheetPropertyPhotoLinkList>;
  update(summarySheet: ISummarySheet): Promise<ISummarySheet>;
}

export const summarySheetsService = ($axios: LkAxiosInstance): ISummarySheetsService => ({
  async fetchByPropertyId(propertyId) {
    const { summarySheets } =
      await $axios.$get<ISummarySheetsList>(`${BASE_ENDPOINT}?property_id=${propertyId}`);

    return summarySheets;
  },
  async generatePdf(id) {
    return $axios.post(`${BASE_ENDPOINT}/${id}/generate_pdf`, {}, axiosConfig.arrayBuffer);
  },
  save(summarySheet) {
    return $axios.$post(BASE_ENDPOINT, summarySheet);
  },
  setPhotos(summarySheetId, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${summarySheetId}/set_photos`, payload);
  },
  update(summarySheet) {
    return $axios.$post(`${BASE_ENDPOINT}/${summarySheet.id}`, summarySheet);
  },
});
