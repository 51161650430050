





















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IEntities } from '@/interfaces/entity/Entities.interface';
import { IErrorMessages } from '@/interfaces/ErrorMessages.interface';

import PasswordInput from '@/components/common/organisms/password-input/PasswordInput.vue';

@Component({
  components: {
    PasswordInput,
  },
})
export default class BoAccountStep extends Vue {
  @Prop({ required: true, type: Object }) entities!: IEntities;
  @Prop({ type: Object }) errorMessages?: IErrorMessages;

  removeErrorMessage(key: string) {
    this.$emit('remove-error', 'account', key);
  }

  updateEntity(key: string, value) {
    this.$emit('update-entity', 'account', key, value);
  }

  validateEntityKey(key: string) {
    this.$emit('validate-entity-key', 'account', key);
  }
}
