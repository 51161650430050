import { axiosConfig, LkAxiosInstance } from '@/api';
import { createBlobUrl } from '@/utils/blobHelper';
import {
  ILogbookRefValidationResponse, IMandate, IMandateResponse, IMandatesResponse, IRegisterDirectoryNumberResponse,
} from '@/api/dome/mandates/Mandates.interface';

const BASE_ENDPOINT = '/mandates';

export interface IMandatesService {
  cancel(id: string): Promise<IMandate>;
  create(propertyId: string): Promise<IMandate>;
  generateMandateLogbook(): Promise<string>;
  getActiveMandate(propertyId: string): Promise<IMandate | undefined>;
  getById(mandateId: string): Promise<IMandate>;
  updateDraftLogbookRef(mandateId: string, logbookRef: number | null): Promise<IMandate>;
  updateRegisterDirectoryNumber(mandateId: string, directoryNumber: string): Promise<string>;
  validateLogbookRef(mandateId: string, logbookRef: number): Promise<ILogbookRefValidationResponse>;
}

export const mandatesService = ($axios: LkAxiosInstance): IMandatesService => ({
  async cancel(mandateId) {
    const { mandate } = await $axios.$post<IMandateResponse>(`${BASE_ENDPOINT}/${mandateId}/cancel_contract`);

    return mandate;
  },

  async create(propertyId) {
    const { mandate } = await $axios.$post<IMandateResponse>(BASE_ENDPOINT, { propertyId });

    return mandate;
  },

  async generateMandateLogbook() {
    const response = await $axios.$post<Blob>('/generate_mandates_logbook', {}, axiosConfig.blob);

    return createBlobUrl(response);
  },

  async getActiveMandate(propertyId) {
    const { mandates } = await $axios.$get<IMandatesResponse>(`${BASE_ENDPOINT}?property_id=${propertyId}`);

    return mandates.find(mandate => mandate.canceledAt === null);
  },

  async getById(mandateId) {
    const { mandate } = await $axios.$get<IMandateResponse>(`${BASE_ENDPOINT}/${mandateId}`);

    return mandate;
  },

  async updateDraftLogbookRef(mandateId, logbookRef) {
    const { mandate } = await $axios.$post<IMandateResponse>(
      `${BASE_ENDPOINT}/${mandateId}/update_draft_logbook_ref`,
      { draft_logbook_ref: logbookRef },
    );

    return mandate;
  },

  async updateRegisterDirectoryNumber(mandateId, directoryNumber) {
    const { registerDirectoryNumber } = await $axios.$post<IRegisterDirectoryNumberResponse>(
      `${BASE_ENDPOINT}/${mandateId}/update_register_directory_number`,
      { registerDirectoryNumber: directoryNumber },
    );

    return registerDirectoryNumber;
  },

  validateLogbookRef(mandateId, logbookRef) {
    return $axios.$post(
      `${BASE_ENDPOINT}/${mandateId}/validate_draft_logbook_ref`,
      { draft_logbook_ref: logbookRef },
    );
  },
});
