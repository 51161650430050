const PropertiesIndex = () => import(/* webpackChunkName: "properties" */ '@/views/properties/index.vue');
const PropertyDetail = () => import(/* webpackChunkName: "properties" */ '@/views/properties/id/index.vue');
const PropertyPanel = () => import(/* webpackChunkName: "properties" */ '@/views/properties/panel/index.vue');

import { createFollowUpRoute } from './property-purchase-plan';
import { createPersonRoute } from './person';
import { createSellerLeadRoute } from './leads/seller';
import documentsRoutes from './properties/documents';
import estimationRoutes from './properties/estimation';
import f95Routes from './properties/f95';
import leadsRoutes from './properties/leads';
import matchingRoutes from './properties/matching';
import offersRoutes from './properties/offers';
import preContractRoutes from './properties/pre-contract';
import publicationRoutes from './properties/publication';
import visitsRoutes from './properties/visits';

export default [
  {
    children: [
      {
        component: PropertyPanel,
        name: 'property_panel',
        path: 'panel/:propertyId',
      },
    ],
    component: PropertiesIndex,
    name: 'properties',
    path: '/biens',
  },
  {
    children: [
      createPersonRoute('property'),
      createSellerLeadRoute('property'),
      createFollowUpRoute('property'),
    ],
    component: PropertyDetail,
    name: 'property',
    path: '/biens/:propertyId',
  },
  ...documentsRoutes,
  ...estimationRoutes,
  ...f95Routes,
  ...leadsRoutes,
  ...matchingRoutes,
  ...offersRoutes,
  ...preContractRoutes,
  ...publicationRoutes,
  ...visitsRoutes,
];