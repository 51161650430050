import { Model } from '@vuex-orm/core';
import { v4 as uuidv4 } from 'uuid';

import { EEstimationStatus } from '@/enums/EstimationStatus.enum';
import { EPropertyPriceContext } from '@/enums/PropertyPriceContext.enum';
import { EPropertyPriceUnitType } from '@/enums/PropertyPriceUnitType.enum';
import { EPropertyProgress } from '@/enums/PropertyProgress.enum';
import { EPropertyStatus } from '@/enums/PropertyStatus.enum';
import { EPropertyTransactionMode } from '@/enums/PropertyTransactionMode.enum';
import { EPropertyType } from '@/enums/PropertyType.enum';
import { Expense } from './f95/Expense.model';
import { getFormattedAgent } from '@/api/dome/agents/agents';
import { getFormattedPerson } from '@/api/dome/persons/persons';
import { IAgent } from '@/api/dome/agents/Agents.interface';
import { ILink } from '@/api/dome/links/Links.interface';
import { IMatch } from '@/api/dome/matching/Matching.interface';
import { IPerson } from '@/api/dome/persons/Persons.interface';
import { IPhotoUrl } from '@/interfaces/models/PhotoUrl.interface';
import { IPropertiesParams } from '@/api/dome/properties/Properties.interface';
import { IRecentSearch } from '@/interfaces/RecentSearch.interface';
import { RentingExpense } from './f95/RentingExpense.model';
import { toQueryString } from '@/utils/url';

export default class Property extends Model {
  static entity = 'properties';

  acceptedNegotiationOfferId!: string | null;
  accessNote!: string;
  address!: string;
  agent!: IAgent;
  buyerId?: string;
  carrezSize!: number;
  city!: string;
  createdAt!: string;
  estimate?: {
    meetingTime: string | null;
    status: EEstimationStatus;
  };
  hasLeadBuyers!: boolean;
  id!: string;
  isPublished!: boolean;
  leadBuyersNotArchivedCount!: number;
  links!: ILink[];
  mainPhoto!: IPhotoUrl;
  mainPhotoUrl?: string;
  match!: IMatch[];
  negotiationsCount!: number;
  photosCount!: number;
  price!: number;
  priceContext!: EPropertyPriceContext;
  progress!: EPropertyProgress;
  propertyExpense!: Expense;
  propertyRentingExpense!: RentingExpense;
  propertySize!: number;
  reference!: string;
  roomsCount!: number;
  seller!: IPerson | null;
  sellerDisplayName?: string;
  sellerLeadId!: string | null;
  slug!: string;
  status!: EPropertyStatus;
  surface?: number;
  title!: string;
  transactionMode!: EPropertyTransactionMode;
  type!: EPropertyType;
  visitsCount!: number;
  visitsDoneCount!: number;
  zipCode!: string;

  static fields() {
    return {
      acceptedNegotiationOfferId: this.string(null).nullable(),
      address: this.string(null).nullable(),
      agent: this.attr(null).nullable(),
      buyerId: this.string(null).nullable(),
      carrezSize: this.number(null).nullable(),
      city: this.string(null).nullable(),
      createdAt: this.string(null).nullable(),
      hasLeadBuyers: this.boolean(false),
      id: this.uid(() => uuidv4()),
      isPublished: this.boolean(false),
      leadBuyersNotArchivedCount: this.number(0),
      links: this.attr([]),
      mainPhoto: this.attr(null).nullable(),
      negotiationsCount: this.number(0),
      photosCount: this.number(0),
      price: this.number(null).nullable(),
      priceContext: this.string(null).nullable(),
      progress: this.string(''),
      propertyExpense: this.hasOne(Expense, 'propertyId'),
      propertyRentingExpense: this.hasOne(RentingExpense, 'propertyId'),
      propertySize: this.number(null).nullable(),
      reference: this.string(''),
      roomsCount: this.number(null).nullable(),
      seller: this.attr(null).nullable(),
      sellerLeadId: this.string(null).nullable(),
      slug: this.string(''),
      status: this.string(null).nullable(),
      title: this.string(''),
      transactionMode: this.string(null).nullable(),
      type: this.string(null).nullable(),
      visitsCount: this.number(0),
      visitsDoneCount: this.number(0),
      zipCode: this.string(null).nullable(),
    };
  }

  get fullAddress(): string {
    return this.zipCode && this.city
      ? `${this.address}, ${this.zipCode} ${this.city}`
      : this.address;
  }

  get isRentingProperty(): boolean {
    return this.transactionMode === EPropertyTransactionMode.Renting;
  }

  get priceUnitType(): EPropertyPriceUnitType {
    return this.isRentingProperty ? EPropertyPriceUnitType.MonthlyPrice : EPropertyPriceUnitType.Price;
  }

  get recentSearchFormat(): IRecentSearch {
    return { content: this.fullAddress, id: this.id };
  }

  static apiConfig = {
    actions: {
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      getProperties(params: IPropertiesParams): Promise<any> {
        const query = toQueryString({
          agent_id: params.agentId,
          page: params.page,
          progress: params.progress,
          seller_person_id: params.sellerPersonId,
          transaction_mode: params.transactionMode,
          visitor_person_id: params.visitorPersonId,
        });

        return (this as any).get(`/properties?${query}`, { dataTransformer: ({ data }) =>
          (data.properties = data.properties ?? []),
        });
      },
      getProperty(id: string): Promise<any> {
        return (this as any).get(`/properties/${id}/summary`,
          {
            dataTransformer: ({ data }) => {
              data.id = data.propertyId;
              const property = Property.find(id);

              if (property) {
                data.links = [ ...property.links, ...data.links ];
              }

              return data;
            },
          },
        );
      },
      getPropertyDetails(id: string): Promise<any> {
        return (this as any).get(`/properties/${id}`, {
          dataTransformer: (response) => {
            response.data = { ...response.data.property };
            response.data.agent = getFormattedAgent(response.data.agent);

            if (response.data.seller) {
              response.data.seller = getFormattedPerson(response.data.seller);
            }

            return response.data;
          },
        });
      },
      setAgent(id: string, agentId: string): Promise<any> {
        return (this as any).post(`/properties/${id}/set_agent`, { agentId })
          .then(() => Property.update({ data: { agentId }, where: id }));
      },
      setProgress(id: string, progress: EPropertyProgress): Promise<any> {
        return (this as any).post(`/properties/${id}/set_progress`, { progress });
      },
      /* eslint-enable  @typescript-eslint/no-explicit-any */
    },
  };
}
