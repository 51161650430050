import { LkAxiosInstance } from '@/api';
import { IPublication, IUnpublishPublicationPayload, UpdatePublicationPayload } from './Publications.interface';
import { IPublicationRecord } from './PublicationRecords.interface';

const BASE_ENDPOINT = '/publications';

export interface IPublicationsService {
  create(propertyId: string): Promise<IPublication>;
  generateAutoDescription(publicationId: string): Promise<{ description: string }>;
  getByPropertyId(propertyId: string): Promise<IPublication[]>;
  getRecords(publicationId: string): Promise<IPublicationRecord[]>;
  publish(id: string): Promise<IPublication>;
  unpublish(id: string, payload: IUnpublishPublicationPayload): Promise<IPublication>;
  update(id: string, payload: UpdatePublicationPayload): Promise<IPublication>;
}

export const publicationsService = ($axios: LkAxiosInstance): IPublicationsService => ({
  create(propertyId) {
    return $axios.$post(BASE_ENDPOINT, { propertyId });
  },
  generateAutoDescription(publicationId) {
    return $axios.$post(`${BASE_ENDPOINT}/${publicationId}/generate_property_description`);
  },
  getByPropertyId(propertyId) {
    return $axios.$get(`${BASE_ENDPOINT}_by_property_id?property_id=${propertyId}`);
  },
  getRecords(publicationId) {
    return $axios.$get(`${BASE_ENDPOINT}/${publicationId}/records`);
  },
  publish(id) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/publish`);
  },
  unpublish(id, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}/unpublish`, payload);
  },
  update(id, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}`, payload);
  },
});
