import {
  AgentHelper,
  IActiveAgent,
  IActiveAgentResponse,
  IBasicAgentResponse,
  IBasicAgent,
} from '@/api/dome/agents/Agents.interface';
import { IBadges } from '@/interfaces/Badges.interface';
import { LkAxiosInstance } from '@/api';

export const getFormattedAgent = <T extends IBasicAgentResponse>(agent: T): T & AgentHelper => ({
  ...agent,
  fullName: `${agent.firstName} ${agent.lastName}`,
  initials: `${agent.firstName[0]}${agent.lastName[0]}`,
});

export interface IAgentsService {
  getActiveAgent(): Promise<IActiveAgent>;
  getAgents(): Promise<IBasicAgent[]>;
  getBadges(): Promise<IBadges>;
}

export const agentsService = ($axios: LkAxiosInstance): IAgentsService => ({
  async getActiveAgent() {
    const { agent, ...data } = await $axios.$get<IActiveAgentResponse>('/me');

    return { ...data, agent: getFormattedAgent(agent) };
  },

  async getAgents() {
    const agents = await $axios.$get<IBasicAgent[]>('/agents');

    return agents.map(getFormattedAgent).sort((a, b) => a.fullName.localeCompare(b.fullName));
  },

  getBadges(): Promise<IBadges> {
    return $axios.$get<IBadges>('/badges');
  },
});
