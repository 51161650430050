import { ERoomType } from '@/enums/RoomType.enum';
import { getFormattedRoom } from '..';
import {
  ICreatePropertyParkingPayload,
  IPropertyParking,
  IUpdatePropertyParkingPayload,
} from './Parkings.interface';
import { LkAxiosInstance } from '@/api';

const BASE_ENDPOINT = '/property_parkings';
const FIELDS_NUMBER = 12;
const ROOM_TYPE = ERoomType.Parking;

export interface IParkingsService {
  create(payload: ICreatePropertyParkingPayload): Promise<IPropertyParking>;
  delete(id: string): Promise<void>;
  get(id: string): Promise<IPropertyParking>;
  update(payload: IUpdatePropertyParkingPayload): Promise<IPropertyParking>;
}

export const parkingsService = ($axios: LkAxiosInstance): IParkingsService => ({
  async create(payload) {
    const response = await $axios.$post<IPropertyParking>(BASE_ENDPOINT, payload);

    return getFormattedRoom(response, ROOM_TYPE, FIELDS_NUMBER);
  },
  delete(id) {
    return $axios.$delete(`${BASE_ENDPOINT}/${id}`);
  },
  async get(id) {
    const response = await $axios.$get<IPropertyParking>(`${BASE_ENDPOINT}/${id}`);

    return getFormattedRoom(response, ROOM_TYPE, FIELDS_NUMBER);
  },
  async update(payload) {
    const response = await $axios.$post<IPropertyParking>(`${BASE_ENDPOINT}/${payload.id}`, payload);

    return getFormattedRoom(response, ROOM_TYPE, FIELDS_NUMBER);
  },
});
