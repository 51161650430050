




















import { Component, Prop } from 'vue-property-decorator';

import { ESellerLeadsTab } from '@/enums/SellerLeadsTab.enum';
import { ESellerLeadType } from '@/enums/SellerLeadType.enum';
import { ISellerLead, ISellerLeadForPanel } from '@/api/dome/seller-leads/SellerLeads.interface';
import { IStep } from '@/components/common/molecules/stepper/StepperWithStatus.interface';
import { SellerLeadEntityModel } from './models';

import EntityCreationWizard from './wizard.vue';
import SellerLead from './steps/SellerLead.vue';
import Wizard from '@/components/common/templates/wizard/Wizard.vue';

@Component({
  components: {
    SellerLead,
    Wizard,
  },
})
export default class SellerLeadEntity extends EntityCreationWizard {
  @Prop({ type: Object }) sellerLead?: ISellerLeadForPanel;

  get isManualLead(): boolean {
    return this.entities.sellerLead?.leadType === ESellerLeadType.Manual;
  }

  get stepList(): string[] {
    return ['seller-lead'];
  }

  get steps(): IStep[] {
    return this.stepList.map(step => ({
      hasError: false,
      isFilled: false,
      name: this.$t(`entity_creation.steps.${step.split('-').join('_')}.title`),
      value: step,
    }));
  }

  created(): void {
    this.setData();
  }

  async createEntity(): Promise<void> {
    this.isSubmitting = true;

    if (!this.isEntityValid(this.stepList)) {
      this._handleError('invalid data', this.errorStepIndex);

      return;
    }

    let sellerLead!: ISellerLead | undefined;

    try {
      const sellerLeadPayload = this.entities.sellerLead!;

      if (this.sellerLead) {
        sellerLead = await this.$api.sellerLeads.update(sellerLeadPayload);
        this.$emit('refresh-lead');
      } else {
        sellerLead = await this.$api.sellerLeads.create(sellerLeadPayload);
      }
    } catch (error) {
      this._handleError(error, this.stepList.indexOf('seller-lead'), true);

      return;
    }

    await this.closeWizard();

    // Redirect to seller_lead
    const agent_id = sellerLead.agent?.id || this.$route.query.agent_id;
    const tab = this.sellerLead ? (this.$route.query.tab || ESellerLeadsTab.Assigned) : ESellerLeadsTab.Assigned;

    await this.$router.push({
      hash: undefined,
      name: 'seller_lead',
      params: { sellerLeadId: sellerLead.id },
      query: { agent_id, tab },
    });
  }

  setData(): void {
    this.entities = { sellerLead: new SellerLeadEntityModel(this.sellerLead) };
  }
}
