import { LkAxiosInstance } from '@/api';

const BASE_ENDPOINT = '/share_documents_with_buyer';

export interface IBuyerDocumentsService {
  shareDocumentsWithBuyer(propertyId: string, buyerPersonId: string): Promise<void>;
}

export const buyerDocumentsService = ($axios: LkAxiosInstance): IBuyerDocumentsService => ({
  shareDocumentsWithBuyer(propertyId, buyerPersonId) {
    return $axios.$post(BASE_ENDPOINT, { buyerPersonId, propertyId });
  },
});
