




import { Component, Prop, Vue } from 'vue-property-decorator';

import { EPreciseNumberType } from '@/enums/PreciseNumberType.enum';
import { getPreciseNumber } from '@/utils/numberConversion';

const itemsToDivide = [EPreciseNumberType.MonthlyPrice, EPreciseNumberType.Price, EPreciseNumberType.Surface];

@Component
export default class PreciseNumber extends Vue {
  @Prop({ default: null, type: String }) dataTestId!: string;
  @Prop({ default: false, type: Boolean }) isShowingZero!: boolean;
  @Prop({ default: null, type: String }) type!: EPreciseNumberType;
  @Prop({ default: null, type: Number }) value!: number;

  get content(): string {
    if (!this.value && !this.isShowingZero) {
      return this.$t('common.null');
    }

    const number = itemsToDivide.includes(this.type) ? getPreciseNumber(this.value) : this.value;

    switch (this.type) {
      case EPreciseNumberType.Price:
      case EPreciseNumberType.PriceFloat:
        return this.$n(number, 'currency');
      case EPreciseNumberType.MonthlyPrice:
      case EPreciseNumberType.MonthlyPriceFloat:
        return this.$t('units.euros_by_month', { number: this.$n(number) });
      case EPreciseNumberType.Surface:
      case EPreciseNumberType.SurfaceFloat:
      default:
        return this.$t('units.square_meter_number', { number: this.$n(number) });
    }
  }
}
