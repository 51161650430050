















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State, namespace } from 'vuex-class';
import { Component as VueComponent } from 'vue';
import * as yup from 'yup';

import { debounce } from './utils/debounce';
import { ESessionStorageKeys } from './enums/Storage.enum';
import { FormGroup } from './utils/form';
import { IAgent } from './api/dome/agents/Agents.interface';
import { IDomeRoute } from './router';
import { parseError } from '@/utils/validation';

import AppNavigation from '@/components/navigation/AppNavigation.vue';
import BaseSwitch from './components/common/atoms/forms/switch/BaseSwitch.vue';
import BoNavigation from '@/components/bo/navigation/BoNavigation.vue';
import CircleLoader from '@/components/common/atoms/loaders/CircleLoader.vue';

const boModule = namespace('bo');

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);

@Component({
  components: {
    AppNavigation,
    BaseSwitch,
    BoNavigation,
    CircleLoader,
  },
})
export default class App extends Vue {
  @State('appIntercomId') appIntercomId!: string;
  @State('authenticated') authenticated!: boolean;
  @boModule.State('authenticated') boAuthenticated!: boolean;
  @State('loggedInAgent') loggedInAgent!: IAgent;

  @Action('fetchInitData') fetchInitData!: () => Promise<void>;
  @Action('manageBreakpoints') manageBreakpoints!: () => void;

  featuresDialog = {
    form: new FormGroup<Record<string, boolean>>(this.$features.release.entries().reduce((acc, [key, value]) => {
      acc[key] = [value, yup.boolean()];

      return acc;
    }, {})),
    isVisible: false,
  };
  isLoading = false;

  @Watch('authenticated', { immediate: true })
  async resetInitData(): Promise<void> {
    await this.getInitData();
  }

  get isAuthenticated(): boolean {
    return this.isBo ? this.boAuthenticated : this.authenticated;
  }

  get isBo(): boolean {
    return Boolean(this.routeMeta?.isBo);
  }

  get isLobby(): boolean {
    return Boolean(this.routeMeta?.isLobby);
  }

  get isNavigationHidden(): boolean {
    return Boolean(this.routeMeta?.isNavigationHidden);
  }

  get routeMeta() {
    return (this.$route as IDomeRoute).meta;
  }

  get navigationComponent(): VueComponent {
    return this.isBo ? BoNavigation : AppNavigation;
  }

  created(): void {
    this.addBreakpointListener();
    this.addServiceWorkerListener();
    this.handlePostUpdateNotification();
  }

  beforeDestroy(): void {
    window.removeEventListener('resize', this.manageBreakpoints);
  }

  addBreakpointListener(): void {
    window.addEventListener('resize', debounce(this.manageBreakpoints));
  }

  addServiceWorkerListener(): void {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        sessionStorage.setItem(ESessionStorageKeys.Refreshing, 'true');
        window.location.reload();
      }, { once: true });
    }
  }

  async getInitData(): Promise<void> {
    if (this.isBo || !this.isAuthenticated) {
      return;
    }

    try {
      this.isLoading = true;
      await this.fetchInitData();
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
    } finally {
      this.isLoading = false;
    }
  }

  handlePostUpdateNotification(): void {
    if (sessionStorage.getItem(ESessionStorageKeys.Refreshing) === 'true') {
      this.$snackbar.update(this.$t('toasts.success.update'));
      sessionStorage.removeItem(ESessionStorageKeys.Refreshing);
    }
  }

  // Features Dialog

  closeFeaturesDialog(): void {
    this.featuresDialog.isVisible = false;
    this.featuresDialog.form.reset();
  }

  showFeaturesDialog(): void {
    this.featuresDialog.isVisible = true;
  }

  submitFeaturesDialog(): void {
    if (this.featuresDialog.form.validate()) {
      Object.entries(this.featuresDialog.form.value).forEach(([key, value]) => {
        this.$features.release.set(key, value);
      });
    } else {
      this.featuresDialog.form.reset();
    }

    this.featuresDialog.isVisible = false;
  }
}
