import {
  IPropertyMarketing,
  IPropertyMarketingList,
  IUpsertPropertyMarketingPayload,
} from './Marketing.interface';
import { LkAxiosInstance } from '@/api';

const BASE_ENDPOINT = '/property_marketing';

export interface IMarketingService {
  getByPropertyId(propertyId: string): Promise<IPropertyMarketing | null>;
  upsert(payload: IUpsertPropertyMarketingPayload): Promise<IPropertyMarketing>;
}

export const marketingService = ($axios: LkAxiosInstance): IMarketingService => ({
  async getByPropertyId(propertyId) {
    const response = await $axios.$get<IPropertyMarketingList>(`${BASE_ENDPOINT}?property_id=${propertyId}`);

    return response.propertyMarketings?.[0] ?? null;
  },
  upsert(payload) {
    return $axios.$post<IPropertyMarketing>(`${BASE_ENDPOINT}/${payload.id}`, payload);
  },
});