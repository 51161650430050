
import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { AnySchema, ValidationError } from 'yup';

import {
  agencyFeesSchema,
  agencySchema,
  agentSchema,
  appointmentSchema,
  boAccountSchema,
  eventSchema,
  manageRightsSchema,
  negotiationBuyersSchema,
  negotiationConditionsSchema,
  negotiationFundingSchema,
  negotiationParticipantsSchema,
  personSchema,
  propertySchema,
  selectAgencySchema,
  sellerLeadSchema,
  setErrorMessage,
  taskSchema,
} from '@/utils/validation';
import { FormGroup } from '@/utils/form';
import { getDeepObjectCopy } from '@/utils/objectHelpers';
import { IDomeSettings } from '@/api/dome/agents/Agents.interface';
import { IEntities } from '@/interfaces/entity/Entities.interface';
import { PersonContactModel } from '@/api/dome/person-contacts/PersonContacts.interface';
import { PersonEntityModel } from './models';
import { ISelectedEntities } from '@/interfaces/entity/SelectedEntities.interface';

interface IErrorMessage extends Record<string, string | undefined> {}

class ErrorMessages implements Record<keyof IEntities, IErrorMessage> {
  account: IErrorMessage = {};
  agency: IErrorMessage = {};
  agencyFees: IErrorMessage = {};
  agent: IErrorMessage = {};
  appointment: IErrorMessage = {};
  event: IErrorMessage = {};
  manageRights: IErrorMessage = {};
  negotiationBuyers: IErrorMessage = {};
  negotiationConditions: IErrorMessage = {};
  negotiationDesignation: IErrorMessage = {};
  negotiationFunding: IErrorMessage = {};
  negotiationParticipants: IErrorMessage = {};
  person: IErrorMessage = {};
  property: IErrorMessage = {};
  propertyInformation: IErrorMessage = {};
  propertyPurchasePlan: IErrorMessage = {};
  sellerLead: IErrorMessage = {};
  task: IErrorMessage = {};
}

const getSchema = (entity: keyof ErrorMessages): AnySchema => {
  switch (entity) {
    case 'person':
      return personSchema;
    case 'property':
      return propertySchema;
    case 'event':
      return eventSchema;
    case 'agencyFees':
      return agencyFeesSchema;
    case 'appointment':
      return appointmentSchema;
    case 'negotiationFunding':
      return negotiationFundingSchema;
    case 'negotiationConditions':
      return negotiationConditionsSchema;
    case 'sellerLead':
      return sellerLeadSchema;
    case 'task':
      return taskSchema;
    case 'account':
      return boAccountSchema;
    case 'agency':
      return agencySchema;
    case 'agent':
      return agentSchema;
    case 'manageRights':
      return manageRightsSchema;
    case 'negotiationBuyers':
      return negotiationBuyersSchema;
    case 'negotiationParticipants':
      return negotiationParticipantsSchema;
    default:
      throw new Error('Entity schema is not defined');
  }
};

@Component
export default class EntityValidation extends Vue {
  @State('settings') settings!: IDomeSettings | null;

  currentPerson: PersonEntityModel | null = null;
  entities: IEntities = {};
  errorMessages = getDeepObjectCopy(new ErrorMessages());
  errorStepIndex?: number;
  isEmailUnique = false;
  otherContacts: FormGroup<PersonContactModel>[] = [];
  selectedEntities: ISelectedEntities = {};
  type!: string;

  isEntityValid(steps: string[] | Readonly<string[]>): boolean {
    let isValid = true;
    let hasError = false;

    steps.forEach((myStep, index) => {
      if (!this.isStepValid(myStep)) {
        isValid = false;
      }

      if (!isValid && !hasError) {
        hasError = true;
        this.errorStepIndex = index;
      }
    });

    return isValid;
  }

  isStepValid(step: string | undefined): boolean {
    switch (step) {
      case 'person':
        return !this.selectedEntities.person
          ? this.validateEntity(personSchema, 'person') && this.validateContacts() && this.isEmailUnique
          : true;
      case 'property':
      case 'renting-property':
        return this.validateEntity(propertySchema, 'property');
      case 'select-property':
      case 'select-property-with-designation':
        return !!this.selectedEntities.property;
      case 'define-property':
        return !this.selectedEntities.property ? this.validateEntity(propertySchema, 'property') : true;
      case 'event':
        return this.validateEntity(eventSchema, 'event');
      case 'define-appointment':
        return this.validateEntity(appointmentSchema, 'appointment');
      case 'agency_fees':
        return this.validateEntity(agencyFeesSchema, 'agencyFees');
      case 'negotiation_funding':
        return this.validateEntity(negotiationFundingSchema, 'negotiationFunding');
      case 'negotiation_conditions':
        return this.validateEntity(negotiationConditionsSchema, 'negotiationConditions');
      case 'seller-lead':
        return this.validateEntity(sellerLeadSchema, 'sellerLead');
      case 'bo-account':
        return this.validateEntity(boAccountSchema, 'account');
      case 'agency':
        return this.validateEntity(agencySchema, 'agency');
      case 'agent':
        return this.validateEntity(agentSchema, 'agent');
      case 'manage_rights':
        return this.validateEntity(manageRightsSchema, 'manageRights', 'agent');
      case 'select_agency':
        return this.validateEntity(selectAgencySchema, 'agent');
      case 'negotiation_buyers':
        return this.validateEntity(negotiationBuyersSchema, 'negotiationBuyers') && this.isEmailUnique;
      case 'negotiation_participants':
        return this.validateEntity(negotiationParticipantsSchema, 'negotiationParticipants');
      case 'define-task':
        return this.validateEntity(taskSchema, 'task');
      default:
        return true;
    }
  }

  private refreshErrorMessage(
    entity: keyof ErrorMessages, key: string, error?: string,
  ): void {
    this.errorMessages[entity][key] = error;
    // Makes component reactive to errorMessages
    this.errorMessages = Object.assign({}, this.errorMessages);
  }

  private refreshErrorMessages(entity: keyof ErrorMessages, errors: ValidationError[]): void {
    errors.forEach(error => {
      if (error.path && !this.errorMessages[entity][error.path]) {
        this.errorMessages[entity][error.path] = setErrorMessage(this, error.errors);
      }
    });

    // Makes component reactive to errorMessages
    this.errorMessages = Object.assign({}, this.errorMessages);
  }

  removeErrorMessage(entity: keyof ErrorMessages, key: string): void {
    this.refreshErrorMessage(entity, key);
  }

  resetErrors(): void {
    this.errorMessages = getDeepObjectCopy(new ErrorMessages());
  }

  validateContacts(): boolean {
    return this.otherContacts.map(contact => contact.validate()).every(isValid => isValid);
  }

  validateEntity(schema: AnySchema, errorKey: keyof ErrorMessages, entityKey?: keyof IEntities): boolean {
    try {
      const isLeadSourceMandatory = this.settings?.isLeadSourceMandatory ?? false;
      const isPropertyPurchasePlanMandatory = this.settings?.isPropertyPurchasePlanMandatory ?? false;

      schema.validateSync({ ...this.entities[entityKey || errorKey], isLeadSourceMandatory }, {
        abortEarly: false, context : { isLeadSourceMandatory, isPropertyPurchasePlanMandatory },
      });

      return true;
    } catch (error) {
      if (error instanceof ValidationError) {
        this.refreshErrorMessages(errorKey, error.inner);
      }

      return false;
    }
  }

  validateEntityKey(errorKey: keyof ErrorMessages, key: string, entityKey?: keyof IEntities): void {
    try {
      const isLeadSourceMandatory = this.settings?.isLeadSourceMandatory ?? false;
      const isPropertyPurchasePlanMandatory = this.settings?.isPropertyPurchasePlanMandatory ?? false;

      getSchema(errorKey).validateSyncAt(key, this.entities[entityKey || errorKey], {
        context: { isLeadSourceMandatory, isPropertyPurchasePlanMandatory },
      });

      this.refreshErrorMessage(errorKey, key);
    } catch (error) {
      if (error instanceof ValidationError && error.path) {
        this.refreshErrorMessage(errorKey, error.path, setErrorMessage(this, error.errors));
      }
    }
  }
}
