

























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PasswordInput extends Vue {
  @Prop({ default: 'password', type: String }) autocomplete!: string;
  @Prop({ default: '', type: String }) dataTestId!: string;
  @Prop({ default: '', type: String }) errorMessage!: string;
  @Prop({ default: '', type: String }) hint!: string;
  @Prop({ default: false, type: Boolean }) isOnBlueBg!: boolean;
  @Prop({ default: '', type: String }) label!: string;
  @Prop({ default: false, type: Boolean }) readonly!: boolean;
  @Prop({ required: true, type: String }) value!: string;

  isVisible = false;

  toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }
}
