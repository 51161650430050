import { AxiosResponse } from 'axios';

import {
  ICreatePosterPayload,
  IPoster,
  IPosterList,
  IPosterPropertyPhotoLink,
  IPosterPropertyPhotoLinkList,
  IPosterPropertyPhotoLinksPayload,
  IUpdatePosterPayload,
} from './Posters.interface';
import { IPaginationParams } from '@/interfaces/models/Pagination.interface';
import { LkAxiosInstance, axiosConfig } from '@/api';
import { toQueryString } from '@/utils/url';

const BASE_ENDPOINT = '/posters';

export interface IPostersService {
  create(payload: ICreatePosterPayload): Promise<IPoster>;
  generatePdf(id: string): Promise<AxiosResponse<ArrayBuffer>>;
  getByPropertyId(propertyId: string, params?: IPaginationParams): Promise<IPoster | null>;
  setPhotos(posterId: string, payload: IPosterPropertyPhotoLinksPayload): Promise<IPosterPropertyPhotoLink[]>;
  update(id: string, payload: IUpdatePosterPayload): Promise<IPoster>;
}

export const postersService = ($axios: LkAxiosInstance): IPostersService => ({
  create(poster) {
    return $axios.$post(BASE_ENDPOINT, poster);
  },
  generatePdf(id) {
    return $axios.post(`${BASE_ENDPOINT}/${id}/generate_pdf`, {}, axiosConfig.arrayBuffer);
  },
  async getByPropertyId(propertyId, params) {
    const query = toQueryString({ property_id: propertyId, ...params });
    const { posters } = await $axios.$get<IPosterList>(`${BASE_ENDPOINT}?${query}`);

    return posters?.[0] ?? null;
  },
  async setPhotos(posterId, payload) {
    const { posterPropertyPhotoLinks } = await $axios.$post<IPosterPropertyPhotoLinkList>
    (`${BASE_ENDPOINT}/${posterId}/set_photos`, payload);

    return posterPropertyPhotoLinks;
  },
  update(id, payload) {
    return $axios.$post(`${BASE_ENDPOINT}/${id}`, payload);
  },
});
