


















































































import { Component, Prop, Watch } from 'vue-property-decorator';

import BaseTips from '@/components/common/molecules/BaseTips.vue';
import FieldComponent from '@/components/common/molecules/forms/FieldComponent.vue';
import FormHelper from '@/components/common/atoms/forms/helper/FormHelper.vue';

let textareaId = 0;

@Component({
  components: {
    BaseTips,
    FormHelper,
  },
})
export default class BaseTextarea extends FieldComponent<string> {
  @Prop({ default: false, type: Boolean }) isValueWatched!: boolean;
  @Prop({ default: 0, type: Number }) maxLength?: number;
  @Prop({ default: false, type: Boolean }) resizable!: boolean;

  fieldValue = this.value;
  focus = false;
  id = `textarea-${textareaId++}`;

  @Watch('value')
  manageValueUpdate(value: string): void {
    if (this.isValueWatched) {
      this.fieldValue = value;
    }
  }

  get showPreviousValue(): boolean {
    return (
      this.previousValue !== null &&
      ((this.fieldValue === undefined && this.previousValue === '') ||
        this.fieldValue.trim() !== this.previousValue.trim())
    );
  }

  onBlur() {
    this.focus = false;
    this.$emit('blur');
  }

  onFocus() {
    this.focus = true;
    this.$emit('focus');
  }

  onInput({ target }: { target: HTMLInputElement }) {
    this.fieldValue = target.value;
    this.$emit('update', target.value);
  }
}
