



































import { Vue, Component, Prop } from 'vue-property-decorator';
import type { Location } from 'vue-router';

import { icons, Icons } from '@/helpers/icons';

@Component
export default class CtaLink extends Vue {
  @Prop({ default: false, type: Boolean }) compact!: boolean;
  @Prop({ default: icons.chevron_right, type: String }) icon?: Icons;
  @Prop({ default: true, type: Boolean }) isActive!: boolean;
  @Prop({ default: false, type: Boolean }) isDisabled!: boolean;
  @Prop({ default: false, type: Boolean }) replace!: boolean;
  @Prop({ type: Object }) to?: Location;
}
