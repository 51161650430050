import { LkAxiosInstance } from '@/api';
import {
  ICreatedSellerLead,
  ISellerLeadArchiveReason,
  ISellerLeadAssignedFilters,
  ISellerLeadExchange,
  ISellerLeadExchangePayload,
  ISellerLeadForPanel,
  ISellerLeadForPanelResponse,
  ISellerLeadResponse,
  ISellerLeadsArchived,
  ISellerLeadsAssignedPayload,
  ISellerLeadsAssignedResponse,
  ISellerLeadsCallLaterResponse,
  ISellerLeadSource,
  ISellerLeadsUnassignedPayload,
  ISellerLeadsUnassignedResponse,
  ISellerLeadUnassignedFilters,
  IUpdatedSellerLead,
} from './SellerLeads.interface';
import { SellerLeadEntityModel } from '@/views/entity/models';
import { toQueryString } from '@/utils/url';

const BASE_ENDPOINT = '/seller_lead';

export interface ISellerLeadsService {
  archive(sellerLeadId: string, payload: ISellerLeadArchiveReason): Promise<void>;
  assignSellerLead(sellerLeadId: string): Promise<void>;
  create(sellerLead: SellerLeadEntityModel): Promise<ICreatedSellerLead>;
  createExchange(sellerLeadId: string, exchange: ISellerLeadExchangePayload): Promise<ICreatedSellerLead>;
  deleteLastExchange(sellerLeadId: string): Promise<void>;
  getArchivedLeads(agentId: string, signal: AbortSignal, page?: number): Promise<ISellerLeadsArchived>;
  getAssignedFilters(agentId?: string): Promise<ISellerLeadAssignedFilters>;
  getAssignedLeads(payload: ISellerLeadsAssignedPayload, orderByProjectQualification: boolean, signal: AbortSignal):
    Promise<ISellerLeadsAssignedResponse>;
  getAssignedLeadsToCall(
    payload: ISellerLeadsAssignedPayload,
    orderByProjectQualification: boolean,
    signal: AbortSignal
  ): Promise<ISellerLeadsCallLaterResponse>;
  getLastExchange(sellerLeadId: string): Promise<ISellerLeadExchange>;
  getSellerLeadForPanel(id: string): Promise<ISellerLeadForPanel>;
  getSources(): Promise<{sources: ISellerLeadSource[]}>;
  getUnassignedFilters(agentId?: string): Promise<ISellerLeadUnassignedFilters>;
  getUnassignedLeads(payload: ISellerLeadsUnassignedPayload, signal: AbortSignal, page?: number):
    Promise<ISellerLeadsUnassignedResponse>;
  reassignSellerLead(sellerLeadId: string, agentId: string | undefined): Promise<void>;
  unassignSellerLead(sellerLeadId: string): Promise<void>;
  update(sellerLead: SellerLeadEntityModel): Promise<IUpdatedSellerLead>;
  updateLastExchange(sellerLeadId: string, exchange: ISellerLeadExchangePayload): Promise<IUpdatedSellerLead>;
}

export const sellerLeadsService = ($axios: LkAxiosInstance): ISellerLeadsService => ({
  archive(id, payload) {
    return $axios.$post(`${BASE_ENDPOINT}s/${id}/archive`, payload);
  },
  assignSellerLead(sellerLeadId) {
    return $axios.post(`${BASE_ENDPOINT}s/${sellerLeadId}/assign`);
  },
  async create(sellerLeadPayload) {
    const { sellerLead } = await $axios.$post<ISellerLeadResponse>(`${BASE_ENDPOINT}s`, sellerLeadPayload);

    return sellerLead;
  },
  createExchange(sellerLeadId, payload) {
    return $axios.$post(`${BASE_ENDPOINT}s/${sellerLeadId}/create_exchange`, payload);
  },
  deleteLastExchange(sellerLeadId) {
    return $axios.$delete(`${BASE_ENDPOINT}s/${sellerLeadId}/last_exchange`);
  },
  getArchivedLeads(agentId, signal, page = 1) {
    const query = toQueryString({ agent_id: agentId, page });

    return $axios.$get(`${BASE_ENDPOINT}s_archived?${query}`, { signal });
  },
  getAssignedFilters(agentId?: string) {
    return $axios.$get(`${BASE_ENDPOINT}_assigned_filters?${toQueryString({ agent_id: agentId })}`);
  },
  getAssignedLeads(payload, orderByProjectQualification, signal) {
    const query = toQueryString({ order_by_project_qualification: orderByProjectQualification });

    return $axios.$post(`${BASE_ENDPOINT}s_assigned_to_process?${query}`, payload, { signal });
  },
  getAssignedLeadsToCall(payload, orderByProjectQualification, signal) {
    const query = toQueryString({ order_by_project_qualification: orderByProjectQualification });

    return $axios.$post(`${BASE_ENDPOINT}s_assigned_to_call_later?${query}`, payload, { signal });
  },
  getLastExchange(sellerLeadId) {
    return $axios.$get(`${BASE_ENDPOINT}s/${sellerLeadId}/last_exchange`);
  },
  async getSellerLeadForPanel(id) {
    const { sellerLeadForPanel } = await $axios.$get<ISellerLeadForPanelResponse>(`${BASE_ENDPOINT}_for_panel/${id}`);

    return sellerLeadForPanel;
  },
  getSources() {
    return $axios.$get(`${BASE_ENDPOINT}_sources`);
  },
  getUnassignedFilters(agentId?: string) {
    return $axios.$get(`${BASE_ENDPOINT}_unassigned_filters?${toQueryString({ agent_id: agentId })}`);
  },
  getUnassignedLeads(payload, signal, page = 1) {
    return $axios.$post<ISellerLeadsUnassignedResponse>
    (`${BASE_ENDPOINT}s_unassigned?limit=50&page=${page}`, payload, { signal });
  },
  reassignSellerLead(sellerLeadId, agentId) {
    return $axios.post(`${BASE_ENDPOINT}s/${sellerLeadId}/reassign`, { agentId });
  },
  unassignSellerLead(sellerLeadId) {
    return $axios.post(`${BASE_ENDPOINT}s/${sellerLeadId}/unassign`);
  },
  async update(sellerLeadPayload) {
    const { sellerLead } = await $axios.$put<ISellerLeadResponse<IUpdatedSellerLead>>(
      `${BASE_ENDPOINT}s/${sellerLeadPayload.id}`,
      sellerLeadPayload,
    );

    return sellerLead;
  },
  updateLastExchange(sellerLeadId, payload) {
    return $axios.$post(`${BASE_ENDPOINT}s/${sellerLeadId}/update_last_exchange`, payload);
  },
});
