import {
  ICreatePropertyDescriptionPayload,
  IPropertyDescription,
  IPropertyDescriptionList,
  IUpdatePropertyDescriptionPayload,
} from './Descriptions.interface';
import { LkAxiosInstance } from '@/api';

const BASE_ENDPOINT = '/property_description';

export interface IDescriptionsService {
  create(payload: ICreatePropertyDescriptionPayload): Promise<IPropertyDescription>;
  getByPropertyId(propertyId: string): Promise<IPropertyDescription | null>;
  update(payload: IUpdatePropertyDescriptionPayload): Promise<IPropertyDescription>;
}

export const descriptionsService = ($axios: LkAxiosInstance): IDescriptionsService => ({
  create(payload) {
    return $axios.$post<IPropertyDescription>(BASE_ENDPOINT, payload);
  },
  async getByPropertyId(propertyId) {
    const response = await $axios.$get<IPropertyDescriptionList>(`${BASE_ENDPOINT}?property_id=${propertyId}`);

    return response.propertyDescriptions?.[0] ?? null;
  },
  update(payload) {
    return $axios.$post<IPropertyDescription>(`${BASE_ENDPOINT}/${payload.id}`, payload);
  },
});