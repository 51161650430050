
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import i18n from '@/plugins/i18n';

import Modal from '@/components/common/molecules/modal/Modal.vue';

@Component({
  components: {
    Modal,
  },
})
export default class BaseDialog extends Vue {
  @Prop({ default: i18n.t('common.close'), type: String }) closeText?: string;
  @Prop({ default: null, type: String }) dataTestIdContext!: string | null;
  @Prop({ default: false, type: Boolean }) hasNoPadding!: boolean;
  @Prop({ default: false, type: Boolean }) hideCloseButton!: boolean;
  @Prop({ default: true, type: Boolean }) isClosable!: boolean;
  @Prop({ default: false, type: Boolean }) isLarge!: boolean;
  @Prop({ default: false, type: Boolean }) isLoading!: boolean;
  @Prop({ default: true, type: Boolean }) isSubmittable!: boolean;
  @Prop({ default: false, type: Boolean }) isVisible!: boolean;
  @Prop({ default: false, type: Boolean }) isWarning!: boolean;
  @Prop({ default: false, type: Boolean }) scrollable!: boolean;
  @Prop({ default: i18n.t('common.validate'), type: String }) submitText?: string;
  @Prop({ type: String }) title?: string;

  handleClickOutside(event: Event): void {
    if (this.isClosable && (event.target as Element).classList.contains('modal-container')) {
      this.$emit('close');
    }
  }
}
