/* eslint-disable vue/max-len */
const AgentPerformanceIndex = () => import(/* webpackChunkName: "agent-performance" */ '@/views/agent-performance/index.vue');
/* eslint-enable vue/max-len */

export enum EAgentPerformanceRoutes {
  Home = 'agent_performance',
}

export default [
  {
    component: AgentPerformanceIndex,
    name: EAgentPerformanceRoutes.Home,
    path: '/ma-performance',
  },
];