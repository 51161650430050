



















import { Component } from 'vue-property-decorator';

import { AccountEntityModel } from '@/views/bo/entity/models';
import { IStep } from '@/components/common/molecules/stepper/StepperWithStatus.interface';

import BoAccount from '@/views/bo/entity/steps/BoAccount.vue';
import EntityCreationWizard from '@/views/entity/wizard.vue';
import Wizard from '@/components/common/templates/wizard/Wizard.vue';

@Component({
  components: {
    BoAccount,
    Wizard,
  },
})
export default class BoAccountEntity extends EntityCreationWizard {
  get stepList() {
    return ['bo-account'];
  }

  get steps(): IStep[] {
    return this.stepList.map(step => ({
      hasError: false,
      isFilled: false,
      name: this.$t(`bo.entity_creation.steps.${step.split('-').join('_')}.title`),
      value: step,
    }));
  }

  created() {
    this.setData();
  }

  setData() {
    this.entities = {
      account: new AccountEntityModel(),
    };
  }

  /************************************************/
  /**************** Business logic ****************/
  /************************************************/

  async createEntity() {
    this.isSubmitting = true;

    if (!this.isEntityValid(this.stepList)) {
      this._handleError('invalid data', this.errorStepIndex);

      return;
    }

    try {
      await this.$boApi.accounts.create(this.entities.account as AccountEntityModel);
    } catch (error) {
      this._handleError(error, this.stepList.indexOf('bo-account'), true);

      return;
    }

    await this.closeWizard();
    await this.$router.push({ hash: undefined, name: 'bo_home' });
  }
}
