












































































import { Component, Prop } from 'vue-property-decorator';

import FieldComponent from '@/components/common/molecules/forms/FieldComponent.vue';
import FormHelper from '@/components/common/atoms/forms/helper/FormHelper.vue';

let inputId = 0;

@Component({
  components: {
    FormHelper,
  },
})
export default class BaseInputPercent extends FieldComponent<number | null> {
  @Prop({ default: 2, type: Number }) decimalPlacesLimit!: number;
  @Prop({ default: false, type: Boolean }) isNullable!: boolean;
  @Prop({ default: 100, type: Number }) max!: number;
  @Prop({ default: 0, type: Number }) min!: number;

  error = null as string | null;
  fieldValue: number | null = this.isNullable ? this.value : Number(this.value);
  focus = false;
  id = `input-${inputId++}`;

  get isErrorVisible(): boolean {
    return !this.focus && Boolean(this.error || this.errorMessage);
  }

  get showPreviousValue(): boolean {
    return this.previousValue !== null && this.fieldValue !== this.previousValue;
  }

  private getInternalErrorMessage(): null | string {
    if (this.fieldValue === null) {
      return null;
    }

    if (this.fieldValue < this.min) {
      return this.$t('errors.value_must_be_higher_or_equal', { value: this.min }) as string;
    }

    if (this.fieldValue > this.max) {
      return this.$t('errors.value_must_be_lower_or_equal', { value: this.max }) as string;
    }

    return null;
  }

  manageKeyPress(keyEvent: KeyboardEvent): void {
    this.preventLetterTyping(keyEvent);

    if (this.decimalPlacesLimit >= 0) {
      this.preventDecimalPlacesTyping(keyEvent);
    }
  }

  onBlur(): void {
    this.focus = false;
    this.$emit('blur');
  }

  onFocus(): void {
    this.focus = true;
    this.$emit('focus');
  }

  onInput({ target }: { target: HTMLInputElement }): void {
    this.fieldValue = this.isNullable && target.value === '' ? null : Number(target.value);
    this.error = this.getInternalErrorMessage();

    this.$emit('update', this.fieldValue);
  }

  private preventDecimalPlacesTyping(keyEvent: KeyboardEvent): void {
    if (this.fieldValue === null) {
      return;
    }

    const currentValue = this.fieldValue.toString();
    let decimalPlaces!: string[];

    if (currentValue.indexOf('.')) {
      decimalPlaces = currentValue.split('.');
    } else if (currentValue.indexOf(',')) {
      decimalPlaces = currentValue.split(',');
    }

    if (decimalPlaces[1]?.length === this.decimalPlacesLimit) {
      keyEvent.preventDefault();
    }
  }

  private preventLetterTyping(keyEvent: KeyboardEvent): void {
    const inputPattern = /^[0-9.,]*$/;

    if (!keyEvent.key.match(inputPattern)) {
      keyEvent.preventDefault();
    }
  }
}
