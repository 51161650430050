
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { EHttpErrorName } from '@/enums/HttpErrorName.enum';
import { HashRoutes } from '@/router/bo';
import { IAgent } from '@/api/dome/agents/Agents.interface';
import { IItemWithAction } from '../../molecules/menu/DropdownList.interface';

@Component
export default class NavigationHelper extends Vue {
  @State('loggedInAgent') loggedInAgent!: IAgent;

  entityType = '';
  isAccountDropdownVisible = false;
  isIssueReportDialogVisible = false;
  isSupportDropdownVisible = false;

  get accountEntities(): IItemWithAction[] {
    return [];
  }

  get isDrawerOpen(): boolean {
    return this.$route.hash === HashRoutes.navOpen;
  }

  get isWizardVisible(): boolean {
    return this.$route.hash === HashRoutes.createEntity;
  }

  async closeEntityCreation(resolve: () => void): Promise<void> {
    this.entityType = '';

    await this.$router.replace({ hash: undefined, query: this.$route.query })
      .catch((error) => {
        if (error.name !== EHttpErrorName.NavigationDuplicated) {
          console.error(error);
        }
      });

    resolve();
  }

  handleAccountEntityClick(value: string): void {
    this.accountEntities.find(item => item.value === value)?.action();
    this.toggleAccountDropdown(false);
  }

  showFeaturesDialog(): void {
    this.$emit('show-features-dialog');
  }

  async toggleDrawer(value: boolean): Promise<void> {
    await this.$router.push({ hash: value ? HashRoutes.navOpen : undefined, query: this.$route.query });
  }

  async updateEntityType(type: string): Promise<void> {
    this.entityType = type;
    await this.$router.push({ hash: HashRoutes.createEntity, query: this.$route.query });
  }

  toggleAccountDropdown(value: boolean): void {
    this.isAccountDropdownVisible = value;
  }

  toggleIssueDialog(value: boolean): void {
    this.isIssueReportDialogVisible = value;
  }

  toggleSupportDropdown(value: boolean): void {
    this.isSupportDropdownVisible = value;
  }
}
