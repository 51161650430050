type QueryParamable = string | number | boolean | undefined | null | QueryParamable[];
export type QueryParamableRecord = Record<string, QueryParamable>;

export interface IQueryStringOptions {
  // string will be used in `join` function
  // undefined means that the query param will be split in multiple instance (foo=1&foo=2&foo=3)
  arraySeparator: string | undefined;
}

const defaultOptions: IQueryStringOptions = { arraySeparator: ',' };

const isNullish = (value: unknown): value is null | undefined => value === null || value === undefined;

export const toQueryString = (obj: QueryParamableRecord, options?: IQueryStringOptions): string => {
  const innerOptions: IQueryStringOptions = { ...defaultOptions, ...options };
  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(obj)) {
    if (isNullish(value) || value === '') {
      continue;
    }

    if (!Array.isArray(value)) {
      params.append(key, value.toString());

      continue;
    }

    if (innerOptions.arraySeparator) {
      const param = value.reduce<string[]>((array, currentValue) => {
        if (!isNullish(currentValue)) {
          array.push(currentValue.toString());
        }

        return array;
      }, []);

      if (param.length) {
        params.append(key, param.join(innerOptions.arraySeparator));
      }
    } else {
      value.forEach(currentValue => {
        if (!isNullish(currentValue)) {
          params.append(key, currentValue.toString());
        }
      });
    }
  }

  return params.toString();
};
