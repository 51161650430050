


























































































































































import { Component, Prop, Watch } from 'vue-property-decorator';

import { EBaseInputType } from './BaseInputType.enum';
import { getPreciseNumber } from '@/utils/numberConversion';
import { IOption } from '@/interfaces/Option.interface';

import BaseTips from '@/components/common/molecules/BaseTips.vue';
import FieldComponent from '@/components/common/molecules/forms/FieldComponent.vue';
import FormChip from '@/components/common/atoms/forms/chips/FormChip.vue';
import FormHelper from '@/components/common/atoms/forms/helper/FormHelper.vue';

let inputId = 0;

@Component({
  components: {
    BaseTips,
    FormChip,
    FormHelper,
  },
})
export default class BaseInput extends FieldComponent<string | number> {
  @Prop({ type: String }) belowText?: string;
  @Prop({ default: 0, type: Number }) debounceDelay!: number;
  @Prop({ default: 0, type: Number }) decimalPlacesLimit!: number;
  @Prop({ default: false, type: Boolean }) hasError!: boolean;
  @Prop({ type: String }) inputClass?: string;
  @Prop({ default: false, type: Boolean }) isFloatAllowed?: boolean;
  @Prop({ default: false, type: Boolean }) isMaxLengthDisplayed?: boolean;
  @Prop({ type: Boolean }) isOnBlueBg?: boolean;
  @Prop({ default: false, type: Boolean }) isValueWatched!: boolean;
  @Prop({ default: undefined, type: Number }) max?: number;
  @Prop({ type: Number }) maxLength?: number;
  @Prop({ type: Number }) min?: number;
  @Prop({ default: false, type: Boolean }) readonly nullable!: boolean;
  @Prop({ default: 'any', type: [Number, String] }) step!: string | number;
  @Prop({ default: () => ([]), type: Array }) suggestionList!: IOption[];
  @Prop({ default: EBaseInputType.Text, type: String }) type!: string;
  @Prop({ default: null, type: String }) unit!: string | null;

  error: string | null = null;
  fieldValue = this.value;
  focus = false;
  id = `input-${inputId++}`;
  timeout: ReturnType<typeof setTimeout> | null = null;

  @Watch('value')
  manageValueUpdate(): void {
    if (this.isValueWatched) {
      this.fieldValue = this.getFormattedValue();
    }
  }

  get errored(): boolean {
    return !!this.error || !!this.errorMessage || this.hasError;
  }

  get hasAppendContent(): boolean {
    return !!(this.$slots.append?.[0].text || this.$slots.append?.[0].tag);
  }

  get hasPrependContent(): boolean {
    return !!(this.unit || this.$slots.prepend?.[0].text || this.$slots.prepend?.[0].tag || this.isMaxLengthDisplayed);
  }

  get inputRole(): string | undefined {
    // Hack for chrome to stop autofilling field with "autocomplete=off"
    return this.autocomplete === 'off' ? 'presentation' : undefined;
  }

  get isAppendContentText(): boolean {
    return (!!this.$slots.prepend?.[0].text || this.$slots.append?.[0].tag === 'span');
  }

  get isNumber(): boolean {
    return ['number', 'precise_number', 'precise_float'].includes(this.type);
  }

  get isPrecise(): boolean {
    return ['precise_number', 'precise_float'].includes(this.type);
  }

  get maximum() {
    return this.max || this.isNumber && Number.MAX_SAFE_INTEGER || undefined;
  }

  get showPreviousValue(): boolean {
    let formattedValue = this.fieldValue;

    if (this.isNumber) {
      formattedValue = Number(formattedValue);

      if (this.type === EBaseInputType.PreciseNumber) {
        formattedValue = formattedValue * 100;
      }
    }

    const isPreviousDifferent = (typeof formattedValue === 'string' && typeof this.previousValue === 'string')
      ? formattedValue.trim() !== this.previousValue.trim()
      : formattedValue !== this.previousValue;

    return (
      this.previousValue !== null &&
      ((formattedValue === undefined && this.previousValue === '') || isPreviousDifferent)
    );
  }

  created(): void {
    this.fieldValue = this.getFormattedValue();
  }

  beforeDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  getFormattedValue(): string | number {
    if (this.isPrecise && this.value > 0) {
      const value = Number(this.value);

      return this.type === EBaseInputType.PreciseNumber ? getPreciseNumber(value) : value;
    }

    return this.value !== null ? this.value : '';
  }

  manageKeyPress(keyEvent: KeyboardEvent): void {
    if (this.isNumber) {
      this.preventLetterTyping(keyEvent);

      if (!this.isFloatAllowed) {
        this.preventFloatTyping(keyEvent);
      } else if (this.decimalPlacesLimit) {
        this.preventDecimalPlacesTyping(keyEvent);
      }
    }

    if (this.maxLength && this.fieldValue && !this.isMaxLengthDisplayed) {
      this.preventMaxLengthExceeding(keyEvent);
    }
  }

  onBlur(): void {
    this.focus = false;
    this.$emit('blur');
  }

  onFocus(): void {
    this.focus = true;
    this.$emit('focus');
  }

  onInput({ target }: { target: HTMLInputElement }): void {
    this.fieldValue = target.value;

    if (this.min !== undefined && Number(this.fieldValue) < this.min) {
      this.error = this.$t('errors.value_must_be_higher_or_equal', { value: this.min }) as string;
    } else if (this.maximum !== undefined && Number(this.fieldValue) > this.maximum) {
      this.error = this.$t('errors.value_must_be_lower_or_equal', { value: this.maximum }) as string;
    } else {
      this.error = null;
    }

    let value: null | number | string = this.fieldValue;

    if (this.type === EBaseInputType.Number && value === '') {
      value = null;
    } else if (this.type === EBaseInputType.PreciseNumber) {
      value = Math.round(Number(this.fieldValue) * 100);
    } else if (this.type === EBaseInputType.PreciseFloat) {
      value = Number(this.fieldValue);
    }

    if (!value) {
      value = this.nullable ? null : value;
    }

    this.update(value);
  }

  preventDecimalPlacesTyping(keyEvent: KeyboardEvent): void {
    const currentValue = this.fieldValue.toString();
    let decimalPlaces!: string[];

    if (currentValue.indexOf('.')) {
      decimalPlaces = currentValue.split('.');
    } else if (currentValue.indexOf(',')) {
      decimalPlaces = currentValue.split(',');
    }

    if (decimalPlaces[1]?.length === this.decimalPlacesLimit) {
      keyEvent.preventDefault();
    }
  }

  preventFloatTyping(keyEvent: KeyboardEvent): void {
    const integerPattern = /^[0-9]*$/;

    if (!keyEvent.key.match(integerPattern)) {
      keyEvent.preventDefault();
    }
  }

  preventLetterTyping(keyEvent: KeyboardEvent): void {
    const inputPattern = /^[0-9.,]*$/;

    if (!keyEvent.key.match(inputPattern)) {
      keyEvent.preventDefault();
    }
  }

  preventMaxLengthExceeding(keyEvent: KeyboardEvent): void {
    const fieldValueLength = this.isNumber
      ? (this.fieldValue as number).toString().length
      : (this.fieldValue as string).length;

    if (fieldValueLength === this.maxLength) {
      keyEvent.preventDefault();
    }
  }

  update(value: string | number | null): void {
    if (this.debounceDelay) {
      this.clearTimeout();

      this.timeout = setTimeout(() => {
        this.$emit('update', value);
      }, this.debounceDelay);

    } else {
      this.$emit('update', value);
    }
  }
}
