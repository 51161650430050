
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import { EPersonContactContext } from '@/enums/PersonContactContext.enum';
import { EPersonContactType } from '@/enums/PersonContactType.enum';
import { getDeepObjectCopy } from '@/utils/objectHelpers';
import { getPersonContactForm, getPrimaryContacts } from '@/helpers/contacts';
import { getPhoneNumber } from '@/utils/phone';
import { IEntities } from '@/interfaces/entity/Entities.interface';
import { IPerson } from '@/api/dome/persons/Persons.interface';
import { IPersonContact, PersonContactModel } from '@/api/dome/person-contacts/PersonContacts.interface';
import { IRecentSearch } from '@/interfaces/RecentSearch.interface';
import { ISelectedEntities } from '@/interfaces/entity/SelectedEntities.interface';
import { parseError } from '@/utils/validation';
import { PersonEntityModel } from '../models';

import EntityCreationWizard from '../wizard.vue';

const keysToCompare: (keyof IPersonContact)[] = ['context', 'type', 'value'];

@Component
export default class PersonHelpers extends EntityCreationWizard {
  @Getter('getContentOfRecentSearchByIndex') getContentOfRecentSearchByIndex!: (
    { entity, index }: { entity: string, index: number }
  ) => string;
  @Getter('getIndexOfRecentSearchById') getIndexOfRecentSearchById!: (
    { entity, id }: { entity: string, id: string }
  ) => number;
  @Action('updateRecentPersonSearch') updateRecentPersonSearch!: (recent: IRecentSearch) => void;

  currentPerson: PersonEntityModel | null = null;
  contactIdsToDelete: string[] = [];
  isEmailUnique = true;

  async createPerson(selectedEntities: ISelectedEntities, entities: IEntities): Promise<string | undefined> {
    if (selectedEntities.person?.length) {
      return selectedEntities.person;
    } else if (entities.person) {
      const { person } = entities;

      /* If email already exists, we update the existing user
         TODO: investigate to understand why is this necessary
      */
      try {
        const existingPerson = await this.getPersonFromEmail(person.email);

        if (existingPerson) {
          const tags = [...new Set([...existingPerson.tags, ...person.tags])];
          const response = await this.$api.persons.update({ ...existingPerson, ...person, tags });

          this.updatePersonInRecentSearch(response);
          await this.managePrimaryPhoneContact(person.phone, existingPerson.personContacts, response.id);

          return response.id;
        }

        const response = await this.$api.persons.create(person);
        this.updatePersonInRecentSearch(response);

        return response.id;
      } catch (error) {
        this.$snackbar.error(parseError(this, error));
      }
    }
  }

  getFormattedPerson(person: IPerson): PersonEntityModel {
    return getDeepObjectCopy({ ...person, ...getPrimaryContacts(person?.personContacts) });
  }

  async getIsEmailUnique(email: string): Promise<boolean> {
    const value = email.toLowerCase();

    if (this.currentPerson?.email.toLowerCase() === value) {
      return true;
    }

    try {
      const person = await this.getPersonFromEmail(value);

      if (!person) {
        return true;
      } else if (this.currentPerson) {
        return this.currentPerson.id === person.id;
      }

      return getPrimaryContacts(person.personContacts).email.toLowerCase() !== value;
    } catch (error) {
      this.$snackbar.error(parseError(this, error));

      return true;
    }
  }

  async getPersonFromEmail(email: string): Promise<IPerson | null> {
    try {
      const results = await this.$api.persons.search(email);
      const [ person ] = results.filter(({ personContacts }) => personContacts.find(({ value }) => value === email));

      return person ?? null;
    } catch (error) {
      this.$snackbar.error(parseError(this, error));

      return null;
    }
  }

  handleRemoveErrorMessage(entity: keyof IEntities, key: string): void {
    if (entity === 'person' && key === 'email') {
      this.resetIsEmailUnique();
    }

    this.removeErrorMessage(entity, key);
  }

  async handleValidateEntityKey(entity: keyof IEntities, key: string): Promise<void> {
    if (entity === 'person' && key === 'email') {
      await this.validatePersonEmail();
    } else {
      this.validateEntityKey(entity, key);
    }
  }

  resetIsEmailUnique(): void {
    this.isEmailUnique = true;
  }

  async updatePerson({ email, phone, ...person }: PersonEntityModel): Promise<void> {
    try {
      const response = await this.$api.persons.update(person as IPerson);
      await this.updatePrimaryContact(EPersonContactType.Email, email, response.personContacts);
      await this.managePrimaryPhoneContact(phone, response.personContacts, response.id);
      await this.updateOtherContacts(response.id, response.personContacts);
      this.updatePersonInRecentSearch(response);
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
    }
  }

  updatePersonInRecentSearch(person: IPerson): void {
    if (person?.id) {
      const index = this.getIndexOfRecentSearchById({ entity: 'person', id: person.id });

      if (index > -1 && this.getContentOfRecentSearchByIndex({ entity: 'person', index }) !== person.fullName) {
        this.updateRecentPersonSearch({ content: person.fullName, id: person.id });
      }
    }
  }

  async validatePersonEmail(): Promise<void> {
    this.validateEntityKey('person', 'email');

    if (!this.entities.person || this.errorMessages.person.email) {
      return;
    }

    if (this.isWizardVisible) {
      this.isEmailUnique = await this.getIsEmailUnique(this.entities.person.email);
    }
  }

  // Primary contacts

  async managePrimaryPhoneContact(value: string, personContacts: IPersonContact[], personId: string): Promise<void> {
    const primaryContact = personContacts?.find(contact =>
      contact.isPrimary && contact.type === EPersonContactType.Phone,
    );

    if (primaryContact) {
      if (value) {
        await this.updatePrimaryContact(EPersonContactType.Phone, value, personContacts);
      } else {
        await this.$api.personContacts.delete(primaryContact.id);
      }
    } else if (value) {
      await this.$api.personContacts.create({
        context: EPersonContactContext.Default,
        isPrimary: true,
        personId,
        type: EPersonContactType.Phone,
        value,
      });
    }
  }

  async updatePrimaryContact(type: EPersonContactType, value: string, personContacts: IPersonContact[]): Promise<void> {
    const primaryContact = personContacts?.find(contact => contact.isPrimary && contact.type === type);

    if (!primaryContact) {
      return;
    }

    let newValue = value;
    let originalValue = primaryContact?.value;

    if (type === EPersonContactType.Phone) {
      newValue = getPhoneNumber(newValue) ?? '';
      originalValue = getPhoneNumber(originalValue) ?? '';
    }

    if (newValue !== originalValue) {
      await this.$api.personContacts.edit({ ...primaryContact, value: newValue });
    }
  }

  // Other contacts

  addPersonContact(): void {
    this.otherContacts.push(getPersonContactForm());
  }

  async createContact(contact: PersonContactModel, personId: string): Promise<void> {
    try {
      await this.$api.personContacts.create({ ...contact, personId });
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
    }
  }

  deletePersonContact(index: number): void {
    const deletedContact = this.otherContacts[index].value;

    if (deletedContact.id) {
      this.contactIdsToDelete.push(deletedContact.id);
    }

    this.otherContacts.splice(index, 1);
  }

  async editContact(contact: IPersonContact, originalContacts?: IPersonContact[]): Promise<void> {
    const originalContact = originalContacts?.find(({ id }) => id === contact.id);

    if (keysToCompare.every(key => contact[key] === originalContact?.[key])) {
      return;
    }

    try {
      await this.$api.personContacts.edit(contact);
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
    }
  }

  async updateOtherContacts(personId: string, originalContacts?: IPersonContact[]): Promise<void> {
    for (const { value: contact } of this.otherContacts) {
      if (contact.id) {
        await this.editContact(contact as IPersonContact, originalContacts);
      } else {
        await this.createContact(contact, personId);
      }
    }

    for (const id of this.contactIdsToDelete) {
      await this.$api.personContacts.delete(id);
    }

    this.contactIdsToDelete = [];
  }
}
