












import { Component, Vue, Prop } from 'vue-property-decorator';
import { IEntities } from '@/interfaces/entity/Entities.interface';
import { IErrorMessages } from '@/interfaces/ErrorMessages.interface';

@Component
export default class AgencyStep extends Vue {
  @Prop({ required: true, type: Object }) entities!: IEntities;
  @Prop({ type: Object }) errorMessages?: IErrorMessages;

  updateEntity(key: string, value) {
    this.$emit('update-entity', 'agency', key, value);
  }

  validateEntityKey(key: string) {
    this.$emit('validate-entity-key', 'agency', key);
  }

  removeErrorMessage(key: string) {
    this.$emit('remove-error', 'agency', key);
  }
}
