/**
 * Hypertext Transfer Protocol (HTTP) response status codes.
 *
 * @see {@link https://en.wikipedia.org/wiki/List_of_HTTP_status_codes}
 */
export enum EHttpStatusCode {
  // 2xx successful
  Ok = 200,
  Created = 201,
  Accepted = 202,
  NoContent = 204,

  // 3xx redirection
  MovedPermanently = 301,

  // 4xx client error
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  PayloadTooLarge = 413,
  TooManyRequests = 429,
  InvalidToken = 498,
  TokenRequired = 499,

  // 5xx server error
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
}
