import App from '@/main';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexOrm from '@vuex-orm/core';
import VuexOrmAxios from '@vuex-orm/plugin-axios';
import algoliasearch from 'algoliasearch/lite';
import { AxiosResponse } from 'axios';
import { SearchClient } from 'algoliasearch/dist/algoliasearch-lite';

import { api, endpoints } from '@/api/domeApi';
import { boModule, BoStoreState } from './bo';
import { ELocalStorageKeys } from '@/enums/Storage.enum';
import { ENodeEnv } from '@/enums/NodeEnv.enum';
import { IAgency, IAgent, IBasicAgent, IDomeSettings } from '@/api/dome/agents/Agents.interface';
import { IBadges } from '@/interfaces/Badges.interface';
import { ICheckTokenStatus, IResetPasswordRequest } from '@/interfaces/dto/authService/authRequest.interface';
import { ICheckTokenStatusResponse } from '@/interfaces/dto/authService/authResponse.interface';
import { initPushNotifications, setupPushNotificationReceived } from '@/utils/pushNotifications';
import { IPerson } from '@/api/dome/persons/Persons.interface';
import { IProperty } from '@/api/dome/properties/Properties.interface';
import { theme } from '@/../tailwind.config.js';
import database from '@/models';
import { IRecentSearch } from '@/interfaces/RecentSearch.interface';
import services from '@/api/dome';

Vue.use(Vuex);
VuexOrm.use(VuexOrmAxios, { axios: api });

export interface RootState {
  agency: IAgency | null;
  agents: IBasicAgent[];
  algoliaClient: SearchClient | null;
  appIntercomId: string | null;
  authenticated: boolean;
  badges: IBadges;
  bo?: BoStoreState;
  breakpoints: { md: boolean, sm: boolean };
  dialogOpenedFromOtherView: boolean;
  globalSearchRecents: { person: IRecentSearch[], property: IRecentSearch[] };
  isNotificationsMenuVisible: boolean;
  loggedInAgent: IAgent | null;
  savedAt: Date | null;
  serviceWorkerRegistration: ServiceWorkerRegistration | null;
  settings: IDomeSettings | null;
  suggestionList: { agency: string[], person: IPerson[], property: IProperty[] };
}

export default new Vuex.Store<RootState>({
  actions: {
    checkTokenStatus(_, params: ICheckTokenStatus): Promise<AxiosResponse<ICheckTokenStatusResponse>> {
      return endpoints.checkTokenStatus(params);
    },

    async fetchInitData({ dispatch }): Promise<void> {
      await dispatch('getBadgesData');

      if (process.env.NODE_ENV !== ENodeEnv.Development) {
        initPushNotifications();
        setupPushNotificationReceived();
      }

      await dispatch('getActiveAgent');
      await dispatch('getAgents');
    },

    async getActiveAgent({ commit }): Promise<void> {
      const { agency, agent, algoliaKey, settings } = await services.agents.getActiveAgent();

      if (process.env.VUE_APP_ALGOLIA_APP_ID) {
        commit('INIT_ALGOLIA_CLIENT', [ process.env.VUE_APP_ALGOLIA_APP_ID, algoliaKey ]);
      }

      if (process.env.VUE_APP_INTERCOM_APP_ID) {
        commit('INIT_INTERCOM_APP_ID', process.env.VUE_APP_INTERCOM_APP_ID);
      }

      commit('SET_AGENCY', agency);
      commit('SET_SUGGESTIONS', { entity: 'agency', suggestions: [agency.address] });
      commit('SET_LOGGED_IN_AGENT', agent);
      App.$mixpanel.identify(agent);
      commit('SET_SETTINGS', settings);
    },

    async getAgents({ commit }): Promise<void> {
      const agents = await services.agents.getAgents();
      commit('SET_AGENTS', agents);
    },

    async getBadgesData({ commit }): Promise<void> {
      const badges = await services.agents.getBadges();
      commit('SET_BADGES', badges);
    },

    async logIn({ commit }, params: { email: string, password: string }): Promise<void> {
      const { data: { token } } = await endpoints.logIn(params);

      if (token) {
        localStorage.setItem(ELocalStorageKeys.AuthToken, token);
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        commit('SET_AUTHENTICATED', true);
      }
    },

    async logOut({ commit, dispatch }): Promise<void> {
      const token = localStorage.getItem(ELocalStorageKeys.NotificationsToken);

      // Unsubscribe from push notifications
      if (token) {
        services.notifications.unsubscribe(token);
      }

      localStorage.removeItem(ELocalStorageKeys.AuthToken);
      commit('SET_AUTHENTICATED', false);
      commit('SET_LOGGED_IN_AGENT', null);
      api.defaults.headers.common['Authorization'] = '';
      await dispatch('entities/deleteAll');
    },

    manageBreakpoints({ commit }): void {
      commit('MANAGE_BREAKPOINTS');
    },

    resetPassword(_, params: IResetPasswordRequest): Promise<AxiosResponse<void>> {
      return endpoints.resetPassword(params);
    },

    resetSuggestions({ commit }): void {
      commit('SET_SUGGESTIONS', { entity: 'person', suggestions: [] });
      commit('SET_SUGGESTIONS', { entity: 'property', suggestions: [] });
    },

    sendResetPasswordEmail(_, email: string): Promise<AxiosResponse<void>> {
      return endpoints.sendResetPasswordEmail(email);
    },

    setDialogTriggeredFromOtherView({ commit }): void {
      commit('SET_DIALOG_TRIGGERED_FROM_OTHER_VIEW', true);
    },

    setDialogTriggeredFromOtherViewClosed({ commit }): void {
      commit('SET_DIALOG_TRIGGERED_FROM_OTHER_VIEW', false);
    },

    setSaved({ commit }, isSaved = false): void {
      commit('SET_IS_SAVED', isSaved);
    },

    setServiceWorkerRegistration({ commit }, serviceWorkerRegistration: ServiceWorkerRegistration): void {
      commit('SET_SERVICE_WORKER_REGISTRATION', serviceWorkerRegistration);
    },

    toggleNotificationsMenu({ commit }, value: boolean): void {
      commit('TOGGLE_IS_NOTIFICATION_MENU_VISIBLE', value);
    },

    updateRecentGlobalSearch({ commit, state, getters }, { entity, recent }: {
      entity: 'person' | 'property'; recent: IRecentSearch;
    }): void {
      const index = getters.getIndexOfRecentSearchById({ entity, id: recent.id });

      if (index > -1) {
        commit('REMOVE_RECENT_GLOBAL_SEARCH', { entity, index });
      } else if (state.globalSearchRecents[entity].length === 5) {
        commit('REMOVE_RECENT_GLOBAL_SEARCH', { entity, index: 4 });
      }

      commit('ADD_RECENT_GLOBAL_SEARCH', { entity, recent });
      localStorage.setItem(ELocalStorageKeys.GlobalSearchRecents, JSON.stringify(state.globalSearchRecents));
    },

    updateRecentPersonSearch({ commit, state }, recent: IRecentSearch): void {
      commit('UPDATE_RECENT_PERSON_SEARCH', { recent });
      localStorage.setItem(ELocalStorageKeys.GlobalSearchRecents, JSON.stringify(state.globalSearchRecents));
    },

    updateServiceWorker({ commit }): void {
      commit('UPDATE_SERVICE_WORKER');
      commit('SET_SERVICE_WORKER_REGISTRATION', null);
    },

    updateSuggestions({ commit }, { entity, suggestions }: {
      entity: 'property' | 'person' | 'address'; suggestions: IProperty[] & IPerson[] & string[];
    }): void {
      commit('SET_SUGGESTIONS', { entity, suggestions });
    },
  },
  getters: {
    getAgentById: (state) => (
      agentId: string,
    ): IBasicAgent | undefined => state.agents.find(({ id }) => id === agentId),

    getContentOfRecentSearchByIndex: (state) => (
      { entity, index }: { entity: 'person' | 'property', index: number },
    ): string => state.globalSearchRecents[entity][index].content,

    getIndexOfRecentSearchById: (state) => (
      { entity, id }: { entity: 'person' | 'property', id: string },
    ): number => state.globalSearchRecents[entity].findIndex(item => item.id === id),

    isDesktop: (state): boolean => state.breakpoints.md,
  },
  modules: { bo: boModule },
  mutations: {
    ADD_RECENT_GLOBAL_SEARCH(state, { entity, recent }: {
      entity: 'person' | 'property'; recent: IRecentSearch;
    }): void {
      state.globalSearchRecents[entity].unshift(recent);
    },

    INIT_ALGOLIA_CLIENT(state, keys: [string, string]): void {
      state.algoliaClient = algoliasearch(...keys);
    },

    INIT_INTERCOM_APP_ID(state, id: string): void {
      state.appIntercomId = id;
    },

    MANAGE_BREAKPOINTS(state): void {
      state.breakpoints.sm = window.matchMedia(`(min-width: ${theme.screens.sm})`).matches;
      state.breakpoints.md = window.matchMedia(`(min-width: ${theme.screens.md})`).matches;
    },

    REMOVE_RECENT_GLOBAL_SEARCH(state, { entity, index }: { entity: 'person' | 'property', index: number }): void {
      state.globalSearchRecents[entity].splice(index, 1);
    },

    SET_AGENCY(state, agency: IAgency): void {
      state.agency = agency;
    },

    SET_AGENTS(state, agents: IBasicAgent[]): void {
      state.agents = agents;
    },

    SET_AUTHENTICATED(state, value: boolean): void {
      state.authenticated = value;
    },

    SET_BADGES(state, badges: IBadges): void {
      state.badges = badges;
    },

    SET_DIALOG_TRIGGERED_FROM_OTHER_VIEW(state, value: boolean): void {
      state.dialogOpenedFromOtherView = value;
    },

    SET_IS_SAVED(state, isSaved): void {
      state.savedAt = isSaved ? new Date() : null;
    },

    SET_LOGGED_IN_AGENT(state, agent: IAgent): void {
      state.loggedInAgent = agent;
    },

    SET_SERVICE_WORKER_REGISTRATION(state, serviceWorkerRegistration: ServiceWorkerRegistration | null): void {
      state.serviceWorkerRegistration = serviceWorkerRegistration;
    },

    SET_SETTINGS(state, settings: IDomeSettings): void {
      state.settings = settings;
    },

    SET_SUGGESTIONS(state, { entity, suggestions }: {
      entity: 'person' | 'property' | 'agency'; suggestions: IPerson[] & IProperty[] & string[];
    }): void {
      state.suggestionList[entity] = suggestions;
    },

    TOGGLE_IS_NOTIFICATION_MENU_VISIBLE(state, value: boolean): void {
      state.isNotificationsMenuVisible = value;
    },

    UPDATE_RECENT_PERSON_SEARCH(state, { index, recent }: { index: number, recent: IRecentSearch }): void {
      state.globalSearchRecents.person.splice(index, 1, recent);
    },

    UPDATE_SERVICE_WORKER(state): void {
      state.serviceWorkerRegistration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
    },
  },
  plugins: [VuexOrm.install(database)],
  state: {
    agency: null,
    agents: [],
    algoliaClient: null,
    appIntercomId: null,
    authenticated: Boolean(localStorage.getItem(ELocalStorageKeys.AuthToken)),
    badges: {
      leadBuyersCount: 0,
      notificationsCount: 0,
      propertiesToFollowUpCount: 0,
      sellerLeadsCount: 0,
      tasksCount: 0,
    },
    breakpoints: {
      md: window.matchMedia(`(min-width: ${theme.screens.md})`).matches,
      sm: window.matchMedia(`(min-width: ${theme.screens.sm})`).matches,
    },
    dialogOpenedFromOtherView: false,
    globalSearchRecents: JSON.parse(
      localStorage.getItem(ELocalStorageKeys.GlobalSearchRecents) ||
      JSON.stringify({ person: [], property: [] }),
    ),
    isNotificationsMenuVisible: false,
    loggedInAgent: null,
    savedAt: null,
    serviceWorkerRegistration: null,
    settings: null,
    suggestionList: {
      agency: [],
      person: [],
      property: [],
    },
  },
});
