
























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import i18n from '@/plugins/i18n';

import { EDialogType } from '@/enums/DialogType.enum';
import { IManualEmailContact } from '@/interfaces/ManualEmailContact.interface';

const dialogsWithoutDescription = [
  EDialogType.OfferCreation,
  EDialogType.OfferCounterOfferSeller,
  EDialogType.OfferCounterOfferBuyer,
  EDialogType.VisitReport,
];

@Component
export default class ManualEmailDialog extends Vue {
  @Prop({ default: i18n.t('email.dialog.close'), type: String }) closeText?: string;
  @Prop({ required: true, type: Array }) contacts!: IManualEmailContact[];
  @Prop({ type: String }) dialogType?: EDialogType;
  @Prop({ default: () => ([]), type: Array }) disclaimers!: string[];
  @Prop({ required: true, type: Boolean }) isVisible!: boolean;
  @Prop({ default: i18n.t('email.dialog.send'), type: String }) submitText?: string;

  checkedContacts: Set<string | number> = new Set(this.contacts.map(contact => contact.id));

  get text(): { description: string | null, title: string | null } {
    return this.dialogType ? {
      description: !dialogsWithoutDescription.includes(this.dialogType)
        ? this.$t(`${this.dialogType}.description`)
        : null,
      title: this.$t(`${this.dialogType}.title`),
    } : { description: null, title: null };
  }

  @Watch('contacts')
  onContactsChanged(currentValue: IManualEmailContact[]): void {
    this.checkedContacts = new Set(currentValue.map(contact => contact.id));
  }

  onCheckboxUpdate(value: string): void {
    if (this.checkedContacts.has(value)) {
      this.checkedContacts.delete(value);
    } else {
      this.checkedContacts.add(value);
    }

    this.checkedContacts = new Set([...this.checkedContacts]);
  }

  submit(): void {
    const contacts = this.contacts.filter(contact => this.checkedContacts.has(contact.id));
    this.$emit('submit', contacts);
  }
}
