import { ValidationError } from 'yup';

import { getFormErrorMessage } from '@/helpers/form';
import { IControl } from './control';
import { IControlConfig } from './form-group';
import { Validator, ValueChangesFn } from '.';

interface ParentConnection {
  setValidator: (key: string, validator: Validator) => void;
  validator: () => Validator;
  value: () => unknown;
  valueChangesFn: () => void;
}

export class FormControl<T = unknown> implements IControl<T> {
  private _value: T;
  private _errors: string[] = [];
  private key?: string;
  private parent?: ParentConnection;
  private valueChangesFn?: ValueChangesFn<T>;

  get error(): string {
    return getFormErrorMessage(this.errors);
  }

  get errors(): string[] {
    return this._errors;
  }

  get hasError(): boolean {
    return !!this._errors.length;
  }

  get isInvalid(): boolean {
    return !!this._errors.length;
  }

  get value(): T {
    return this._value;
  }

  constructor(public defaultValue: T, public validator?: Validator, public config?: IControlConfig) {
    this._value = defaultValue;
  }

  connect(key: string, parent: ParentConnection) {
    this.key = key;
    this.parent = parent;
  }

  reset(config?: { emit?: boolean }): void {
    this.setValue(this.defaultValue, config);
    this.resetErrors();
  }

  resetErrors(): void {
    this.setErrors([]);
  }

  setCurrentValueAsDefault(): void {
    this.defaultValue = this.value;
  }

  setErrors(errors: string[]): void {
    this._errors = errors;
  }

  setValidator(validator: Validator): void {
    this.validator = validator;

    if (this.key && this.parent) {
      this.parent.setValidator(this.key, this.validator);
    }
  }

  setValue(value: T, config?: { emit?: boolean, validate?: boolean }): void {
    this._value = value;

    if (config?.emit !== false) {
      this.valueChangesFn?.(value);
      this.parent?.valueChangesFn?.();
    }

    if (config?.validate || this.config?.validateOnSetValue) {
      this.validate();
    }
  }

  validate(options?: { context: Record<string, unknown> }): boolean {
    if (!this.validator) {
      return true;
    }

    this.resetErrors();

    try {
      if (this.key && this.parent) {
        this.parent.validator().validateSyncAt(this.key, this.parent.value(), options);
      } else {
        this.validator.validateSync(this._value, options);
      }

      return true;
    } catch (error) {
      if (error instanceof ValidationError) {
        this._errors = error.errors;
      }

      return false;
    }
  }

  valueChanges(fn: ValueChangesFn<T>): void {
    this.valueChangesFn = fn;
  }
}
