






























































import { Vue, Component, Prop } from 'vue-property-decorator';

import { ITab } from './BaseTabs.interface';

let tabsId = 0;
const TAB_SPACE_VALUE = 64;

@Component
export default class BaseTabs extends Vue {
  @Prop({ required: true, type: Number }) currentTab!: number;
  @Prop({ default: null, type: String }) dataTestIdContext!: string;
  @Prop({ default: false, type: Boolean }) hasLabelPadding!: boolean;
  // INFO: using isThin for now because of reduced space in sellerLeads design requirements and awaiting filter refacto
  @Prop({ default: false, type: Boolean }) isThin!: boolean;
  @Prop({ required: true, type: Array }) tabs!: ITab[];

  id = `tabs-${tabsId++}`;
  focus = false;

  mounted(): void {
    if (this.currentTab === 0) {
      return;
    }

    const offset =
      document.querySelector(`#tabs-item-${this.id}-${this.currentTab}`)
        ?.parentElement?.offsetLeft || 0;

    this.scrollTo(offset, 'auto');
  }

  // eslint-disable-next-line
  scrollTo(value: number, behavior: any): void {
    (this.$refs.baseTabs as HTMLElement).scroll({
      behavior,
      left: (value || 0) - TAB_SPACE_VALUE,
    });
  }

  onChange({ target }: { target: HTMLInputElement }): void {
    const value = target.parentElement?.offsetLeft || 0;
    this.scrollTo(value, 'smooth');
    this.$emit('update', Number(target.value));
  }
}
