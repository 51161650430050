export type Toggles = Record<string, boolean>;

export class ToggleRouter {
  constructor(private readonly toggles: Toggles) {}

  areAllEnabled(): boolean {
    return !Object.values(this.toggles).some(isEnabled => !isEnabled);
  }

  disableAll(): void {
    this.setAllIsEnabled(false);
  }

  enableAll(): void {
    this.setAllIsEnabled(true);
  }

  entries(): [string, boolean][] {
    return Object.entries(this.toggles);
  }

  isEnabled(toggleName: string): boolean {
    return this.toggles[toggleName] ?? false;
  }

  set(toggleName: string, isEnabled: boolean): void {
    this.toggles[toggleName] = isEnabled;
  }

  private setAllIsEnabled(isEnabled: boolean): void {
    for (const toggleName in this.toggles) {
      this.set(toggleName, isEnabled);
    }
  }
}