export enum EEstimationStatus {
  Saved = 'saved',
  Draft = 'draft',
  WaitingForApproval = 'waiting_for_approval',
  WaitingForCorrection = 'waiting_for_correction',
  Approved = 'approved',
}

export enum EEstimationEventType {
  Email = 'email',
  Estimation = 'estimation',
}