



















































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Component as VueComponent } from 'vue';
import { State } from 'vuex-class';
import { Route } from 'vue-router';
import {
  AisInstantSearch,
  AisHits,
  AisHighlight,
  AisIndex,
  AisStats,
  AisConfigure,
} from 'vue-instantsearch';
import type { SearchClient } from 'algoliasearch/lite';

import { HashRoutes } from '@/router/dome';
import { IAgent, IDomeSettings } from '@/api/dome/agents/Agents.interface';
import type { IRecentSearch } from '@/interfaces/RecentSearch.interface';

import BaseTabs from '@/components/common/molecules/tabs/BaseTabs.vue';
import Modal from '@/components/common/molecules/modal/Modal.vue';
import SearchBoxDebounce from '@/components/common/molecules/search-box-debounce/SearchBoxDebounce.vue';
import SearchBuyerLeadResults from '@/components/common/templates/search/buyer-leads/SearchBuyerLeadResults.vue';
import SearchPersonResults from '@/components/common/templates/search/persons/SearchPersonResults.vue';
import SearchList from '@/components/common/molecules/search-list/SearchList.vue';
import SearchPropertyResults from '@/components/common/templates/search/properties/SearchPropertyResults.vue';
import SearchRecents from '@/components/common/templates/search/recents/SearchRecents.vue';
import SearchSellerLeadResults from '@/components/common/templates/search/seller-leads/SearchSellerLeadResults.vue';

type TabType = {
  algoliaIndex: string;
  component: VueComponent;
  disabled: boolean;
  name: string;
  numberOfItems: number;
  status: string;
};

const tabsHelper = Object.freeze([
  { algoliaIndex: 'properties', component: SearchPropertyResults, name: 'properties' },
  { algoliaIndex: 'persons', component: SearchPersonResults, name: 'contacts' },
  { algoliaIndex: 'buyer_leads', component: SearchBuyerLeadResults, name: 'buyer_leads' },
  { algoliaIndex: 'seller_leads', component: SearchSellerLeadResults, name: 'seller_leads' },
]);

const emptySearchRequestObject = {
  exhaustiveNbHits: false,
  hits: [],
  hitsPerPage: 0,
  nbHits: 0,
  nbPages: 0,
  page: 0,
  params: '',
  processingTimeMS: 0,
  query: '',
};

let globalSearchId = 0;

@Component({
  components: {
    AisConfigure,
    AisHighlight,
    AisHits,
    AisIndex,
    AisInstantSearch,
    AisStats,
    BaseTabs,
    Modal,
    SearchBoxDebounce,
    SearchBuyerLeadResults,
    SearchList,
    SearchPersonResults,
    SearchPropertyResults,
    SearchRecents,
    SearchSellerLeadResults,
  },
})

export default class GlobalSearch extends Vue {
  @State('algoliaClient') algoliaClient!: SearchClient;
  @State('globalSearchRecents') globalSearchRecents!: { person: IRecentSearch[], property: IRecentSearch[] };
  @State('loggedInAgent') loggedInAgent!: IAgent;
  @State('settings') settings!: IDomeSettings;

  activeTab = 0;
  id = `global-search-${globalSearchId++}`;
  savedState = { activeTab: 0, searchInputText: '' };
  searchInputText = '';
  tabs: TabType[] = tabsHelper.map(({ algoliaIndex, component, name }) => ({
    algoliaIndex,
    component,
    disabled: false,
    name: this.$t(`navigation.routes.${name}`),
    numberOfItems: 0,
    status: 'raw',
  }));

  @Watch('isVisible')
  retrieveState(value: boolean): void {
    document.body.classList.toggle('overflow-hidden');

    if (value) {
      this.searchInputText = this.savedState.searchInputText;
      this.activeTab = this.savedState.activeTab;

      if (this.algoliaClient) {
        this.algoliaClient.clearCache();
      }
    }
  }

  @Watch('$route')
  updateState({ hash: newHash }: Route, { hash: oldHash }: Route): void {
    if (newHash !== oldHash && oldHash === HashRoutes.globalSearch) {
      const { searchInputText, activeTab } = this;
      this.savedState = { activeTab, searchInputText };
    } else {
      this.savedState = { activeTab: 0, searchInputText: '' };
    }
  }

  get facetFilters(): string {
    return this.settings.isAllowedToSeeOtherAgentsBusiness ? '' : this.loggedInAgent.id;
  }

  get isResultVisible(): boolean {
    return !!this.searchInputText.length;
  }

  get isVisible(): boolean {
    return this.$route.hash === HashRoutes.globalSearch;
  }

  get searchClient(): SearchClient {
    return {
      ...this.algoliaClient,
      search: (requests) => {
        if (requests.every(({ params }) => !params?.query)) {
          return Promise.resolve({
            results: requests.map(() => emptySearchRequestObject),
          });
        }

        return this.algoliaClient.search(requests);
      },
    };
  }

  changeTab(index: number): void {
    this.activeTab = index;
  }

  closeGlobalSearch(): void {
    this.$router.replace({
      hash: undefined,
      params: this.$route.params,
      query: this.$route.query,
    });
  }

  handleClickOutside({ target }: { target: Element }): void {
    if (target.classList.contains('modal-container')) {
      this.closeGlobalSearch();
    }
  }

  searchFunction(helper): void {
    if (!helper.state.query) {
      this.changeTab(0);
    }

    return helper.search();
  }

  setNumberOfItems(index: number, value: number): void {
    this.tabs[index].numberOfItems = value;
    this.tabs[index].disabled = value === 0;
    this.updateActiveTab();
  }

  updateActiveTab(): void {
    if (
      this.activeTab === -1 ||
      this.tabs[this.activeTab].disabled ||
      this.searchInputText === ''
    ) {
      const index = this.tabs.findIndex(tab => !tab.disabled);

      if (this.activeTab !== index) {
        this.changeTab(index);
      }
    }
  }
}
