import { IPhoto, IPhotoList, IPropertyPhotosOrderPayload } from './PropertyPhotos.interface';
import { LkAxiosInstance, axiosConfig } from '@/api';

const BASE_ENDPOINT = '/property_photos';

export interface IPropertyPhotosService {
  delete(id: string): Promise<void>;
  getAllByPropertyId(propertyId: string): Promise<IPhoto[]>;
  setOrder(payload: IPropertyPhotosOrderPayload): Promise<IPhoto[]>;
  upload(file: File, propertyId: string, order: number): Promise<IPhoto>;
}

export const propertyPhotosService = ($axios: LkAxiosInstance): IPropertyPhotosService => ({
  delete(id) {
    return $axios.$delete(`${BASE_ENDPOINT}/${id}`);
  },
  async getAllByPropertyId(propertyId) {
    const { photos } = await $axios.$get<IPhotoList>(`${BASE_ENDPOINT}?property_id=${propertyId}`);

    return photos;
  },
  async setOrder(payload) {
    const { photos } = await $axios.$post<IPhotoList>(`${BASE_ENDPOINT}/set_photos_order`, payload);

    return photos;
  },
  upload(file, propertyId, order) {
    const formData = new FormData();
    formData.append('photo', file);
    formData.append('property_id', propertyId);
    formData.append('order', order.toString());

    return $axios.$post(BASE_ENDPOINT, formData, axiosConfig.formData);
  },
});
