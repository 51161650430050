import { api } from '@/api/domeApi';
import { IActivitiesService, activitiesService } from './activities/activities';
import { IAddressesService, addressesService } from './addresses/addresses';
import { IAgentsService, agentsService } from './agents/agents';
import { IAgentPerformancesService, agentPerformancesService } from './agent-performances/agentPerformances';
import { IAppointmentsService, appointmentsService } from './appointments/appointments';
import { IBuyerDocumentsService, buyerDocumentsService } from './buyer-documents/buyerDocuments';
import { IBuyerLeadsService, buyerLeadsService } from './buyer-leads/buyer-leads';
import { IBuyersSearchService, buyersSearchService } from './buyers-search/buyersSearch';
import { IEmailsService, emailsService } from './emails/emails';
import { IEstimationsService, estimationsService } from './estimations/estimations';
import { IEventsService, eventsService } from './events/events';
import { IF95Service, f95service } from './f95';
import { IGeoLocationService, geoLocationService } from './geoLocation';
import { ILinksService, linksService } from './links/links';
import { IMandateAnnexesService, mandateAnnexesService } from './mandates/mandateAnnexes';
import { IMandateDocumentsService, mandateDocumentsService } from './mandates/mandateDocuments';
import { IMandateRecipientsService, mandateRecipientsService } from './mandates/mandateRecipients';
import { IMandatesService, mandatesService } from './mandates/mandates';
import { IMatchingService, matchingService } from './matching/matching';
import { IMeetingsService, meetingsService } from './meetings/meetings';
import { INegotiationRecipientsService, negotiationRecipientsService }
  from './negotiationRecipients/negotiationRecipients';
import { INegotiationsService, negotiationsService } from './negotiations/negotiations';
import { INotariesService, notariesService } from './notaries/notaries';
import { INotificationsService, notificationsService } from './notifications';
import { IOffersService, offersService } from './offers/offers';
import { IPersonContactsService, personContactsService } from './person-contacts/personContacts';
import { IPersonsService, personsService } from './persons/persons';
import { IPlanningService, planningService } from './planning/planning';
import { IPortalsService, portalsService } from './portals/portals';
import { IPostersService, postersService } from './posters/posters';
import { IPreContractService, preContractService } from './pre-contract/pre-contract';
import { IPropertiesService, propertiesService } from './properties/properties';
import { IPropertyDocumentsService, propertyDocumentsService } from './property-documents/propertyDocuments';
import { IPropertyPhotosService, propertyPhotosService } from './property-photos/propertyPhotos';
import { IPropertyPricesService, propertyPricesService } from './property-prices/propertyPrices';
import { IPropertyPurchasePlansService, propertyPurchasePlansService }
  from './property-purchase-plans/propertyPurchasePlans';
import { IPublicationsService, publicationsService } from './publications/publications';
import { ISellerLeadGeoLocationsService, sellerLeadGeoLocationsService } from './sellerLeadGeoLocations';
import { ISellerLeadsService, sellerLeadsService } from './seller-leads/sellerLeads';
import { ISummarySheetsService, summarySheetsService } from './summary-sheets/summary-sheets';
import { ISupportRequestsService, supportService } from './support/support';
import { ITasksService, tasksService } from './tasks/tasks';
import { IVisitsService, visitsService } from './visits/visits';

export interface IDomeService {
  activities: IActivitiesService;
  addresses: IAddressesService;
  agentPerformances: IAgentPerformancesService;
  agents: IAgentsService;
  appointments: IAppointmentsService;
  buyerDocuments: IBuyerDocumentsService;
  buyerLeads: IBuyerLeadsService;
  buyersSearch: IBuyersSearchService;
  emails: IEmailsService;
  estimations: IEstimationsService;
  events: IEventsService;
  f95: IF95Service;
  geoLocation: IGeoLocationService;
  links: ILinksService;
  mandateAnnexes: IMandateAnnexesService;
  mandateDocuments: IMandateDocumentsService;
  mandateRecipients: IMandateRecipientsService;
  mandates: IMandatesService;
  matching: IMatchingService;
  meetings: IMeetingsService;
  negotiationRecipients: INegotiationRecipientsService;
  negotiations: INegotiationsService;
  notaries: INotariesService;
  notifications: INotificationsService;
  offers: IOffersService;
  personContacts: IPersonContactsService;
  persons: IPersonsService;
  planning: IPlanningService;
  portals: IPortalsService;
  posters: IPostersService;
  preContract: IPreContractService;
  properties: IPropertiesService;
  propertyDocuments: IPropertyDocumentsService;
  propertyPhotos: IPropertyPhotosService;
  propertyPrices: IPropertyPricesService;
  propertyPurchasePlans: IPropertyPurchasePlansService;
  publications: IPublicationsService;
  sellerLeadGeoLocations: ISellerLeadGeoLocationsService;
  sellerLeads: ISellerLeadsService;
  summarySheets: ISummarySheetsService;
  support: ISupportRequestsService;
  tasks: ITasksService;
  visits: IVisitsService;
}

const services: IDomeService = {
  activities: activitiesService(api),
  addresses: addressesService(api),
  agentPerformances: agentPerformancesService(api),
  agents: agentsService(api),
  appointments: appointmentsService(api),
  buyerDocuments: buyerDocumentsService(api),
  buyerLeads: buyerLeadsService(api),
  buyersSearch: buyersSearchService(api),
  emails: emailsService(api),
  estimations: estimationsService(api),
  events: eventsService(api),
  f95: f95service(api),
  geoLocation: geoLocationService(api),
  links: linksService(api),
  mandateAnnexes: mandateAnnexesService(api),
  mandateDocuments: mandateDocumentsService(api),
  mandateRecipients: mandateRecipientsService(api),
  mandates: mandatesService(api),
  matching: matchingService(api),
  meetings: meetingsService(api),
  negotiationRecipients: negotiationRecipientsService(api),
  negotiations: negotiationsService(api),
  notaries: notariesService(api),
  notifications: notificationsService(api),
  offers: offersService(api),
  personContacts: personContactsService(api),
  persons: personsService(api),
  planning: planningService(api),
  portals: portalsService(api),
  posters: postersService(api),
  preContract: preContractService(api),
  properties: propertiesService(api),
  propertyDocuments: propertyDocumentsService(api),
  propertyPhotos: propertyPhotosService(api),
  propertyPrices: propertyPricesService(api),
  propertyPurchasePlans: propertyPurchasePlansService(api),
  publications: publicationsService(api),
  sellerLeadGeoLocations: sellerLeadGeoLocationsService(api),
  sellerLeads: sellerLeadsService(api),
  summarySheets: summarySheetsService(api),
  support: supportService(api),
  tasks: tasksService(api),
  visits: visitsService(api),
};

export default services;
