
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class FieldComponent<ValueType=unknown> extends Vue {
  @Prop({ default: 'off', type: String }) autocomplete!: string;
  @Prop({ default: null, type: String }) readonly errorMessage?: string | null;
  @Prop({ default: null, type: String }) readonly dataTestId!: string | null;
  @Prop({ default: null, type: String }) readonly dataTestIdContext!: string | null;
  @Prop({ default: false, type: Boolean }) readonly disabled!: boolean;
  @Prop({ default: false, type: Boolean }) readonly exportWarningMessage!: boolean;
  @Prop({ default: null, type: String }) readonly helper!: string | null;
  @Prop({ default: null, type: String }) readonly hint!: string | null;
  @Prop({ default: null, type: String }) readonly label!: string;
  @Prop({ default: null, type: String }) readonly labelClass!: string | null;
  @Prop({ default: null, type: String }) readonly placeholder?: string;
  @Prop({ default: null, type: [String, Number, Boolean] }) readonly previousValue!: ValueType | null;
  @Prop({ default: null, type: String }) readonly previousValueText!: string | null;
  @Prop({ default: false, type: Boolean }) readonly readonly!: boolean;
  @Prop({ default: false, type: Boolean }) readonly required!: boolean;
  @Prop({ default: null, type: String }) readonly tips!: string | null;
  @Prop({ default: false, type: Boolean }) readonly tipsCentered!: boolean;
  @Prop({ default: false, type: Boolean }) readonly tipsInHelper!: boolean;
  @Prop() readonly value!: ValueType;
  @Prop({ default: null, type: String }) readonly warningMessage!: string | null;

  fieldValue: unknown = null;
}
