import { ToggleRouter } from './toggle-router';

export abstract class BaseToggles {
  constructor(protected readonly router: ToggleRouter) {}

  areAllEnabled(): boolean {
    return this.router.areAllEnabled();
  }

  disableAll(): void {
    this.router.disableAll();
  }

  enableAll(): void {
    this.router.enableAll();
  }

  entries(): [string, boolean][] {
    return this.router.entries();
  }

  set(toggleName: string, isEnabled: boolean): void {
    this.router.set(toggleName, isEnabled);
  }
}