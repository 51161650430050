
































import { Component, Prop, Vue } from 'vue-property-decorator';

import { IStep } from '@/components/common/molecules/stepper/StepperWithStatus.interface';

@Component
export default class StepperTopBar extends Vue {
  @Prop({ default: false, type: Boolean }) disabled!: boolean;
  @Prop({ required: true, type: Number }) stepIndex!: number;
  @Prop({ required: true, type: Array }) steps!: IStep[];

  get width(): string {
    const width = (this.stepIndex * 100) / (this.steps.length - 1);

    return `calc(${width}% - ${width / 100} * 2rem)`;
  }
}
