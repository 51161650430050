import { Database } from '@vuex-orm/core';
import { registerF95 } from '@/models/f95';
import Property from './Property.model';

const database = new Database();

database.register(Property);

registerF95(database);

export default database;

export {
  Property,
};
