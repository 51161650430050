import { axiosConfig, LkAxiosInstance } from '@/api';
import { IIssueReport } from './Support.interface';

const BASE_ENDPOINT = '/support_requests';

export interface ISupportRequestsService {
  sendSupportRequest(issueReport: IIssueReport): Promise<void>;
}

export const supportService = ($axios: LkAxiosInstance): ISupportRequestsService => ({
  sendSupportRequest(issueReport) {
    const formData = new FormData();
    issueReport.attachments.forEach(file => formData.append('attachments', file));
    formData.append('body', issueReport.body);
    formData.append('screen_size', `${window.screen.width}x${window.screen.height}`);
    formData.append('subject', issueReport.subject);
    formData.append('user_agent', navigator.userAgent);

    return $axios.post(BASE_ENDPOINT, formData, axiosConfig.formData);
  },
});
