


















import { Vue, Component } from 'vue-property-decorator';

import { getLocalePhoneNumber } from '@/utils/phone';
import { ISearchLeadHit } from '@/interfaces/SearchLeadHit.interface';

import { AisHighlight } from 'vue-instantsearch';
import BuyerLeadCardSearch from '@/components/common/organisms/buyer-lead/BuyerLeadCardSearch.vue';
import SearchList from '@/components/common/molecules/search-list/SearchList.vue';

@Component({
  components: {
    AisHighlight,
    BuyerLeadCardSearch,
    SearchList,
  },
})
export default class SearchBuyerLeadResults extends Vue {
  transformBuyerItems(items: ISearchLeadHit[]): ISearchLeadHit[] {
    return items.map(lead => {
      // Format property price
      if (lead.property.price) {
        lead.property.displayedPrice = this.$n(lead.property.price, 'currency');
      }

      // Format phone number
      const phoneNumber = lead._phones ? lead._phones[2] : lead.phone;

      if (phoneNumber) {
        lead.displayedPhone = getLocalePhoneNumber(phoneNumber);
      }

      return lead;
    });
  }
}
