import { Model } from '@vuex-orm/core';
import { v4 as uuidv4 } from 'uuid';
import { ERentChargesRecoveryMethod } from '@/enums/RentChargesRecoveryMethod.enum';

const BASE_ENDPOINT = '/property_renting_expenses';

export class RentingExpense extends Model {
  static entity = 'renting_expenses';

  id!: string;
  propertyId!: string;
  monthlyRentIncludingCharges!: number;
  monthlyRentSupplement!: number;
  monthlyRentCharges!: number;
  rentChargesRecoveryMethod!: ERentChargesRecoveryMethod;
  securityDeposit!: number;
  tenantAgencyFees!: number;
  tenantInventoryAgencyFees!: number;

  /**
   * roomType is mandatory and should not be used during creation
   */
  static fields() {
    return {
      id: this.uid(() => uuidv4()),
      monthlyRentCharges: this.number(0).nullable(),
      monthlyRentIncludingCharges: this.number(0).nullable(),
      monthlyRentSupplement: this.number(0).nullable(),
      propertyId: this.uid(),
      rentChargesRecoveryMethod: this.string(null).nullable(),
      securityDeposit: this.number(0).nullable(),
      tenantAgencyFees: this.number(0).nullable(),
      tenantInventoryAgencyFees: this.number(0).nullable(),
    };
  }

  static apiConfig = {
    actions: {
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      getPropertyRentingExpense(id: string): void {
        return (this as any)
          .get(`${BASE_ENDPOINT}/${id}`)
          .catch((error: any) => {
            if (error.response.data.error.type === 'object-not-found') {
              RentingExpense.create({
                data: {
                  propertyId: id,
                },
              });
            }
          });
      },
      savePropertyRentingExpense(rentingExpense: RentingExpense): Promise<any> {
        return (this as any).post(
          `${BASE_ENDPOINT}/${rentingExpense.id}`,
          rentingExpense,
        );
      },
      /* eslint-enable  @typescript-eslint/no-explicit-any */
    },
  };
}
