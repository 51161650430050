
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class EventHelpers extends Vue {
  protected formatEvent(entity, propertyId: string, personId: string, agentId?: string, sellerLeadId?: string) {
    return {
      agentId,
      endTime: new Date(`${entity.date}T${entity.endTime}`).toISOString(),
      personId,
      propertyId,
      sellerLeadId,
      startTime: new Date(`${entity.date}T${entity.startTime}`).toISOString(),
    };
  }
}
