
import { Component, Prop, Watch } from 'vue-property-decorator';

import { IPerson } from '@/api/dome/persons/Persons.interface';
import { IStep } from '@/components/common/molecules/stepper/StepperWithStatus.interface';
import { parseError } from '@/utils/validation';
import { PersonEntityModel } from './models';
import { Property } from '@/models';

import EntityValidation from './validation.vue';

@Component
export default class EntityCreationWizard extends EntityValidation {
  @Prop({ required: true, type: Boolean }) isWizardVisible!: boolean;

  isSubmitting = false;
  person: IPerson | PersonEntityModel | null = null;
  stepIndex = 0;
  stepKey = new Date().getTime();

  @Watch('isWizardVisible')
  updateWizard(isWizardVisible: boolean): void {
    if (isWizardVisible) {
      this.stepIndex = 0;
      this.setData();
    } else {
      this.entities = {};
      this.selectedEntities = {};
      this.isSubmitting = false;
      this.resetErrors();

      this.steps.forEach(step => {
        step.isFilled = false;
        step.hasError = false;
      });
    }
  }

  @Watch('selectedEntities.person', { immediate: true })
  async updateSelectedPerson(personId: string | undefined): Promise<void> {
    try {
      this.person = personId ? await this.$api.persons.getById(personId) : (this.entities.person ?? null);
    } catch (error) {
      this.$snackbar.error(parseError(this, error));
    }
  }

  get steps(): IStep[] {
    console.error('steps must be set');

    return [];
  }

  get property() {
    if (!this.selectedEntities.property) {
      return null;
    }

    return Property.query()
      .whereId(this.selectedEntities.property)
      .first();
  }

  changeStep(step: number) {
    const currentStep = this.steps[this.stepIndex];

    // TODO: stop mutating computed data (bad practice)
    currentStep.hasError = !this.isStepValid(currentStep.value);
    currentStep.isFilled = true;
    this.stepIndex = step;

    if (this.steps[step].value === 'property-information') {
      if (this.selectedEntities.property) {
        Property.api().getProperty(this.selectedEntities.property).then(({ response }) => {
          const values = response.data;

          this.entities.propertyInformation = Object.assign(
            {
              bridgeLoan: values.propertyMarketing?.bridgeLoan,
              exclusivityOpinion: values.propertyMarketing?.exclusivityOpinion,
              isRented: values.propertyExpense?.isRented,
              lastBuyingDate: values.propertyMarketing?.lastBuyingDate,
              preEstimatedSellingPrice: values.propertyMarketing?.preEstimatedSellingPrice,
              saleReason: values.propertyMarketing?.saleReason,
              saleStrategy: values.propertyMarketing?.saleStrategy,
              stepsTaken: values.propertyMarketing?.stepsTaken,
              usage: values.propertyMarketing?.usage,
            },
            this.entities.propertyInformation,
          );

          this.stepKey = new Date().getTime();
        });
      }
    }
  }

  async closeWizard(): Promise<void> {
    await new Promise(resolve => this.$emit('close', resolve));
  }

  selectEntity(name: string, id: string): void {
    this.selectedEntities[name] = id;
    this.errorMessages[name] = {};
  }

  setData(): void {
    throw new Error('setData must be implemented');
  }

  updateEntity(name: string, key: string, value): void {
    this.entities[name][key] = value;
  }

  _handleError(error, index?: number, notify = false) {
    this.isSubmitting = false;

    if (index !== undefined) {
      this.changeStep(index);
    }

    if (notify) {
      this.$snackbar.error(parseError(this, error));
    }
  }
}
