import { Route } from 'vue-router';
import { createPersonRoute } from './person';

/* eslint-disable vue/max-len */
const FollowedPurchasePlans = () => import(/* webpackChunkName: "followed-property-purchase-plan" */ '@/views/followed-property-purchase-plans/index.vue');
const PurchasePlanPanel = () => import(/* webpackChunkName: "followed-property-purchase-plan" */ '@/views/panels/property-purchase-plan.vue');
/* eslint-enable vue/max-len */

export enum EFollowedPropertyPurchasePlanRoutes {
  Index = 'followed_property_purchase_plan',
  Panel = 'followed_property_purchase_plan_panel',
}

export default [
  {
    children: [
      createPersonRoute(EFollowedPropertyPurchasePlanRoutes.Index),
      {
        component: PurchasePlanPanel,
        name: EFollowedPropertyPurchasePlanRoutes.Panel,
        path: '/projets-suivis/:propertyPurchasePlanId',
        props: ({ params }: Route) => ({ propertyPurchasePlanId: params.propertyPurchasePlanId }),
      },
    ],
    component: FollowedPurchasePlans,
    name: EFollowedPropertyPurchasePlanRoutes.Index,
    path: '/projets-suivis',
  },
];
