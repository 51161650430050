import { LkAxiosInstance } from '@/api';
import { toQueryString } from '@/utils/url';
import {
  IBuyersSearchCriterias,
  IBuyersSearchDefaultAgentMessage,
  IBuyersSearchDefaultValuesForProperty,
  IBuyersSearchEmailPayload,
  IBuyersSearchProperty,
  IBuyersSearchResultsResponse,
} from './BuyersSearch.interface';

const BASE_ENDPOINT = '/buyers_search';

export interface IBuyersSearchService {
  getCriteriasForProperty(propertyId: string): Promise<IBuyersSearchCriterias>;
  getDefaultAgentMessage(): Promise<string>;
  searchBuyers(criterias: IBuyersSearchCriterias, page: number): Promise<IBuyersSearchResultsResponse>;
  searchByAddress(address: string): Promise<IBuyersSearchProperty[]>;
  sendEmailToBuyers(payload: IBuyersSearchEmailPayload): Promise<void>;
}

export const buyersSearchService = ($axios: LkAxiosInstance): IBuyersSearchService => ({
  async getCriteriasForProperty(propertyId) {
    const { geoCityId, type, ...criterias } = await $axios.$get<IBuyersSearchDefaultValuesForProperty>
    (`${BASE_ENDPOINT}/defaults_values_for_property/${propertyId}`);

    return { ...criterias, geoCityIds: [geoCityId], types: [type] };
  },
  async getDefaultAgentMessage() {
    const { agentMessage } = await $axios.$get<IBuyersSearchDefaultAgentMessage>
    (`${BASE_ENDPOINT}/default_agent_message`);

    return agentMessage;
  },
  searchBuyers(criterias, page) {
    return $axios.$post(`${BASE_ENDPOINT}/search_by_criteria?page=${page}`, criterias);
  },
  searchByAddress(address) {
    const query = toQueryString({ address });

    return $axios.$get(`${BASE_ENDPOINT}/list_properties_by_address?${query}`);
  },
  sendEmailToBuyers(payload) {
    return $axios.$post(`${BASE_ENDPOINT}/send_property_by_email`, payload);
  },
});