

















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as yup from 'yup';

import { ESessionStorageKeys } from '@/enums/Storage.enum';
import { FormControl, FormGroup } from '@/utils/form';
import { getFormattedFileSize } from '@/utils/file';
import { IIssueReport } from '@/api/dome/support/Support.interface';

import FileUploader from '@/components/common/molecules/forms/file-upload/FileUploader.vue';

@Component({
  components: {
    FileUploader,
  },
})
export default class IssueReportDialog extends Vue {
  @Prop({ default: false, type: Boolean }) isDisplayed!: boolean;

  form = new FormGroup<IIssueReport>({
    attachments: [[], yup.array()],
    body: ['', yup.string().required()],
    subject: ['', yup.string().required()],
  });
  isIssueSending = false;

  get attachments(): FormControl<File[]> {
    return this.form.get('attachments');
  }

  @Watch('isDisplayed')
  manageDisplay(): void {
    const savedIssueReport = sessionStorage.getItem(ESessionStorageKeys.IssueReport);

    if (this.isDisplayed && savedIssueReport) {
      const issueReport = JSON.parse(savedIssueReport) as Partial<IIssueReport>;
      this.form.setValue(issueReport);
    }
  }

  addAttachments(files: File[]): void {
    this.attachments.setValue([...this.attachments.value, ...files]);
  }

  getFileSize({ size }: File): string {
    return getFormattedFileSize(size);
  }

  manageClose(submitting = false): void {
    if (submitting) {
      sessionStorage.removeItem(ESessionStorageKeys.IssueReport);
    } else {
      this.saveForm();
    }

    this.form.reset();
    this.$emit('close');
  }

  removeAttachment(index: number) {
    const { value } = this.attachments;
    value.splice(index, 1);
    this.attachments.setValue(value);
  }

  saveForm(): void {
    const { body, subject } = this.form.value;
    sessionStorage.setItem(ESessionStorageKeys.IssueReport, JSON.stringify({ body, subject }));
  }

  async submit(): Promise<void> {
    if (!this.form.validate()) {
      return;
    }

    this.isIssueSending = true;
    await this.$api.support.sendSupportRequest(this.form.value);
    this.$snackbar.success(this.$t('support.issue_report_form.success'));
    this.isIssueSending = false;
    this.manageClose(true);
  }
}
