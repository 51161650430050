










































































































































import { Component, Prop } from 'vue-property-decorator';

import { EPropertyType } from '@/enums/PropertyType.enum';
import { IOption } from '@/interfaces/Option.interface';
import { propertyTypeOptions } from '@/utils/options';

import BaseAddressAutocomplete from '@/components/common/molecules/forms/input/BaseAddressAutocomplete.vue';
import PropertyStepHelper from './helpers/property-step-helper.vue';

@Component({
  components: {
    BaseAddressAutocomplete,
  },
})
export default class DefinePropertyStep extends PropertyStepHelper {
  @Prop({ required: true, type: Object }) suggestionList!: Record<'person' | 'property', IOption<string>[]>;

  EPropertyType = Object.freeze(EPropertyType);
  typeOptions = Object.freeze(propertyTypeOptions);

  get isPropertySelected(): boolean {
    return this.propertySearch.length > 0 || !!this.selectedEntities.property;
  }

  get propertyType(): EPropertyType | undefined {
    return this.entities.property?.type;
  }

  onAddressUpdate(value: string): void {
    this.removeErrorMessage('address');
    this.updateEntity('address', value);
  }

  removeErrorMessage(key: string): void {
    this.$emit('remove-error', 'property', key);
  }

  updateEntity(key: string, value: unknown): void {
    this.$emit('update-entity', 'property', key, value);
  }

  updatePropertyType(value: EPropertyType): void {
    if (value === EPropertyType.House) {
      if (this.entities.property?.carrezSize !== 0) {
        this.updateEntity('propertySize', this.entities.property?.carrezSize);
        this.updateEntity('carrezSize', null);
      }
    } else {
      this.updateEntity('carrezSize', this.entities.property?.propertySize);
      this.updateEntity('propertySize', null);
    }

    this.updateEntity('type', value);
  }

  validateEntityKey(key: string): void {
    this.$emit('validate-entity-key', 'property', key);
  }
}
