import { LkAxiosInstance } from '@/api';
import { IAccountRequest } from '@/interfaces/bo/dto/accountsService/AccountRequest.interface';

const BASE_ENDPOINT = '/admins';

export interface IAccountsService {
  create(params: IAccountRequest): Promise<void>;
}

export const accountsService = ($axios: LkAxiosInstance): IAccountsService => ({
  create(params: IAccountRequest): Promise<void> {
    return $axios.$post<void>(BASE_ENDPOINT, params);
  },
});
