var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"baseTabs",staticClass:"tabs sm:flex",class:{ '!px-2 mobile:overflow-x-auto mobile:overflow-y-hidden mobile:whitespace-nowrap': _vm.isThin }},_vm._l((_vm.tabs),function(tab,index){
var _obj;
return _c('div',{key:tab.name,staticClass:"tabs__item relative flex grow text-center text-lk-dark-gray outline-none transition-all duration-200 ease-in-out sm:mr-4",class:{
      'selected font-bold text-lk-blue': index === _vm.currentTab,
      'hover:text-lk-blue-hover': index !== _vm.currentTab && !tab.disabled,
      'opacity-35': index !== _vm.currentTab && tab.disabled,
      'mr-4': _vm.isThin
    },attrs:{"tabindex":"0"}},[_c('input',{staticClass:"tabs__input hidden",attrs:{"id":(_vm.id + "-item-" + index),"disabled":tab.disabled,"type":"radio"},domProps:{"checked":index === _vm.currentTab,"value":index},on:{"change":_vm.onChange}}),_c('label',{staticClass:"w-full py-4 md:py-6",class:{
        'cursor-pointer': !tab.disabled,
        'px-2': _vm.hasLabelPadding,
        'flex justify-center': tab.hasNotification
      },attrs:{"data-test-id":(_vm.dataTestIdContext + "-tab-item"),"for":(_vm.id + "-item-" + index)}},[_c('span',[_vm._v(_vm._s(tab.name))]),(tab.numberOfItems !== undefined)?_c('StatusLabel',{staticClass:"ml-2",class:( _obj = {}, _obj[("label--" + (tab.status))] = tab.status, _obj['font-bold'] =  index === _vm.currentTab, _obj ),attrs:{"data-test-id":(_vm.dataTestIdContext + "-tab-count"),"status":tab.numberOfItems}}):_vm._e(),(tab.hasNotification)?_c('div',{staticClass:"ml-2 flex h-5 w-5 items-center justify-center rounded-full bg-lk-blue p-1"},[_c('BaseIcon',{staticClass:"fill-white",attrs:{"icon":_vm.$icons.warning}})],1):_vm._e()],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }