import { EPersonManualSource } from '@/enums/PersonManualSource.enum';

export const sourceList = [
  EPersonManualSource.Recommendation,
  EPersonManualSource.Sponsorship,
  EPersonManualSource.Partners,
  EPersonManualSource.PreviousBuyer,
  EPersonManualSource.BuyerWithSaleProject,
  EPersonManualSource.UrbanAdvertising,
  EPersonManualSource.Prospectus,
  EPersonManualSource.DoorToDoor,
  EPersonManualSource.Droppy,
  EPersonManualSource.Showcase,
  EPersonManualSource.Portal,
  // EPersonManualSource.Other, // INFO: Marketing wants it hidden but it's still present in the back-end
];