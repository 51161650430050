









import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CircleLoader extends Vue {
  @Prop({ default: 'gray', type: String }) color!: string;
}
