import { EAgencyFeesType } from '@/enums/AgencyFeesType.enum';
import { EFeesRepartitionType } from '@/enums/FeesRepartitionType.enum';
import { IAgency } from '@/interfaces/bo/models/Agency.interface';
import { IMandateAgencyFees } from '@/api/dome/negotiations/Negotiations.interface';

export class AccountEntityModel {
  name: string;
  email: string;
  password: string;

  constructor() {
    this.name = '';
    this.email = '';
    this.password = '';
  }
}

export class AgencyFeesEntityModel {
  fixedValue: number;
  percentageSeller: EFeesRepartitionType;
  percentageValue: string;
  type: EAgencyFeesType;
  useDefault?: boolean;

  constructor(value: IMandateAgencyFees | null) {
    this.useDefault = true;
    this.fixedValue = value?.fixedValue ?? 0;
    this.percentageSeller = value?.percentageSeller ?? 0;
    this.percentageValue = value?.percentageValue ?? '';
    this.type = value?.type ?? EAgencyFeesType.Percentage;
  }
}

export class AgencyEntityModel {
  name: string;

  constructor() {
    this.name = '';
  }
}

export class AgentEntityModel {
  $id?: unknown;
  agency?: IAgency;
  agencyId: string;
  email: string;
  endedAt: string | null;
  estimationApproverAgentId: string | null;
  firstName: string;
  fullName: string;
  hasEstimationApprover: boolean;
  hasMandateApprover: boolean;
  id?: string;
  lastName: string;
  mandateApproverAgentId: string | null;
  mandateIntroduction: string;
  phone: string;
  rsacPlaceOfRegistration: string | null;
  rsacRegistrationNumber: string | null;
  startedAt: string;

  constructor() {
    this.agencyId = '';
    this.email = '';
    this.endedAt = null;
    this.estimationApproverAgentId = null;
    this.firstName = '';
    this.fullName = '';
    this.hasEstimationApprover = false;
    this.hasMandateApprover = false;
    this.lastName = '';
    this.mandateApproverAgentId = null;
    this.mandateIntroduction = '';
    this.phone = '';
    this.rsacPlaceOfRegistration = null;
    this.rsacRegistrationNumber = null;
    this.startedAt = '';
  }
}
