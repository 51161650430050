














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FormChip extends Vue {
  @Prop({ default: false, type: Boolean }) readonly isSelected!: boolean;
  @Prop({ type: String }) readonly value!: string;
}
