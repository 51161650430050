
import { ISelectedEntities } from '@/interfaces/entity/SelectedEntities.interface';
import { Vue, Component } from 'vue-property-decorator';
import { PropertyEntityModel } from '../models';

@Component
export default class PropertyHelpers extends Vue {
  protected async createProperty(
    selectedEntities: ISelectedEntities,
    property: PropertyEntityModel,
    sellerId: string,
  ): Promise<string | undefined> {
    if (selectedEntities.property?.length) {
      return selectedEntities.property;
    }

    const propertyResponse = await this.$api.properties.create({
      ...property,
      carrezSize: Number(property.carrezSize) || null,
      floorNumber: Number(property.floorNumber),
      propertySize: Number(property.propertySize) || null,
      sellerPersonId: sellerId,
    });

    return propertyResponse.id;

  }

  protected async createRentingProperty(property: PropertyEntityModel): Promise<string | undefined> {
    const propertyResponse = await this.$api.properties.create({
      ...property,
      carrezSize: Number(property.carrezSize) || null,
      propertySize: Number(property.propertySize) || null,
    });

    return propertyResponse.id;
  }
}
