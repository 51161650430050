var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',{staticClass:"input flex flex-col",class:{ 'opacity-35': _vm.disabled }},[(_vm.label)?_c('FormLabel',_vm._b({attrs:{"text":_vm.label,"unselectable":_vm.readonly || _vm.disabled,"tag":"legend"},nativeOn:{"click":function($event){return _vm.$refs.hours[0].focus()}}},'FormLabel',{ focus: _vm.focus, required: _vm.required },false)):_vm._e(),_c('div',{staticClass:"relative mb-4 flex"},[_c('div',{staticClass:"w-full rounded-md border border-lk-gray-light bg-white px-4 py-2 leading-6",class:Object.assign({}, {error: _vm.errorMessage,
        'transition-all duration-300 ease-in-out': !_vm.readonly,
        'cursor-default bg-lk-gray-lighter': _vm.readonly,
        'border-lk-blue': !_vm.readonly && _vm.focus,
        'select-none': _vm.readonly || _vm.disabled},
        _vm.inputClass),attrs:{"data-test-id":_vm.dataTestId},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.$refs.hours[0].focus()}}},[_vm._l((['hours', 'minutes']),function(key){return [_c('input',{key:key,ref:key,refInFor:true,staticClass:"text-right outline-none",class:{
            'transition-all duration-300 ease-in-out': !_vm.readonly,
            'cursor-default bg-lk-gray-lighter': _vm.readonly,
            'w-3': key === 'hours',
            'w-5': key !== 'hours',
          },attrs:{"aria-label":key,"data-test-id":(_vm.dataTestId + "-" + key),"disabled":_vm.disabled,"readonly":_vm.readonly,"maxlength":"2","pattern":"\\d*","type":"text"},domProps:{"value":_vm.fieldValue[key]},on:{"blur":_vm.onBlur,"focus":_vm.onFocus,"input":function($event){return _vm.onInput($event, key)}}}),_c('span',{key:(key + "-unit"),staticClass:"ml-1 text-lk-gray",on:{"click":function($event){return _vm.$refs[key][0].focus()}}},[_vm._v(_vm._s(_vm.$t(("base.duration." + key + "-unit"))))])]})],2),(_vm.errorMessage)?_c('p',{staticClass:"error-message absolute top-full mt-1 text-xs text-lk-red",class:{ 'whitespace-nowrap': !_vm.isShort }},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()]),_c('div',{staticClass:"flex flex-wrap gap-2",class:{ 'mt-4': _vm.errorMessage }},_vm._l((_vm.options),function(option,index){return _c('FormChip',{key:("option-" + index),attrs:{"is-selected":_vm.selectedOption === index,"value":_vm.getLabel(option),"data-testid":"option","tabindex":"-1"},nativeOn:{"click":function($event){return _vm.updateDuration(option)}}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }