























import { Component, Prop, Mixins } from 'vue-property-decorator';

import { EContactRole } from '@/enums/ContactRole.enum';
import { IOption } from '@/interfaces/Option.interface';
import { IStep } from '@/components/common/molecules/stepper/StepperWithStatus.interface';
import { PersonEntityModel, PropertyEntityModel } from './models';

import Person from './steps/Person.vue';
import PersonHelpers from './helpers/person.vue';
import Property from './steps/Property.vue';
import PropertyHelpers from './helpers/property.vue';
import Wizard from '@/components/common/templates/wizard/Wizard.vue';

@Component({
  components: {
    Person,
    Property,
    Wizard,
  },
})
export default class PropertyEntity extends Mixins(PersonHelpers, PropertyHelpers) {
  @Prop({ required: true, type: Object }) suggestionList!: Record<'agency' | 'person' | 'property', IOption<string>[]>;

  stepList = Object.freeze(['person', 'property']);

  get steps(): IStep[] {
    return this.stepList.map(step => ({
      hasError: false,
      isFilled: false,
      name: this.$t(`entity_creation.steps.${step === 'person' ? 'seller' : step}.title`),
      value: step,
    }));
  }

  created(): void {
    this.setData();
  }

  async createEntity(): Promise<void> {
    this.isSubmitting = true;
    let personId!: string | undefined;
    let propertyId!: string | undefined;

    if (!this.isEntityValid(this.stepList)) {
      this._handleError('invalid data', this.errorStepIndex);

      return;
    }

    // Person
    try {
      personId = await this.createPerson(this.selectedEntities, this.entities);

      if (!personId) {
        this._handleError('empty', this.stepList.indexOf('person'));

        return;
      }
    } catch (error) {
      this._handleError(error, this.stepList.indexOf('person'), true);

      return;
    }

    // Property
    try {
      propertyId = await this.createProperty(
        this.selectedEntities,
        this.entities.property as PropertyEntityModel,
        personId,
      );

      if (!propertyId) {
        this._handleError('empty', this.stepList.indexOf('property'));

        return;
      }
    } catch (error) {
      this._handleError(error, this.stepList.indexOf('property'), true);

      return;
    }

    await this.handleCloseWizard();

    await this.$router.push({
      hash: undefined,
      name: 'property',
      params: { propertyId },
    });
  }

  async handleCloseWizard(): Promise<void> {
    this.resetIsEmailUnique();
    await this.closeWizard();
  }

  setData(): void {
    this.entities = {
      person: new PersonEntityModel(EContactRole.Seller),
      property: new PropertyEntityModel(),
    };

    this.selectedEntities = { person: '' };
  }
}
