export const timeDurationToNumber = (value: Duration): number => (value.hours || 0) * 60 + (value.minutes || 0);

export const timeStringToDuration = (value: string): Duration => {
  const splitIndex = value.indexOf(':');
  const hours = parseInt(value.substring(0, splitIndex) || '0');
  const minutes = parseInt(value.substring(splitIndex + 1) || '0');

  return { hours, minutes };
};

export const timeNumberToDuration = (value: number): Duration => ({
  hours: value >= 60 ? Math.floor(value / 60) : 0,
  minutes: value % 60,
});

export const timeStringToNumber = (value: string): number => {
  const duration = timeStringToDuration(value);

  return timeDurationToNumber(duration);
};

export const calculateDurationFromProps = (
  endTime: string,
  startTime: string,
): Duration => {
  const previousEndTime = timeStringToNumber(endTime);
  const previousStartTime = timeStringToNumber(startTime);
  const duration = previousEndTime - previousStartTime;

  return timeNumberToDuration(duration);
};

export const timeNumberToString = (value: number): string => {
  const duration = timeNumberToDuration(value);

  const hour =
    (duration.hours as number) >= 10
      ? `${duration.hours}`
      : `0${duration.hours}`;

  const minutes =
    (duration.minutes as number) >= 10
      ? `${duration.minutes}`
      : `0${duration.minutes}`;

  return `${hour}:${minutes}`;
};
