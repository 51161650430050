export enum ENotificationTopic {
  AppointmentAgentUpdated = 'appointment-agent-updated',
  CustomerSpaceCounterOffer = 'customer-space-counter-offer',
  CustomerSpaceDataroomDocumentsUploaded = 'customer-space-dataroom-documents-uploaded',
  EstimationValidated = 'estimation-validated',
  LeadBuyerAgentUpdated = 'lead-buyer-agent-updated',
  MandateDocumentCanceled = 'mandate-document-canceled',
  MandateDocumentSignedByEverybody = 'mandate-document-signed-by-everybody',
  MandateDocumentSignedByRecipientsButAgent = 'mandate-document-signed-by-recipients-but-agent',
  MandateDocumentValidate = 'mandate-document-validate',
  MeetingAgentUpdated = 'meeting-agent-updated',
  NegotiationOfferAccepted = 'negotiation-offer-signed',
  NegotiationOfferDeclined = 'negotiation-offer-declined',
  NegotiationOfferExpired = 'negotiation-offer-expired',
  NegotiationOfferExpiresIn48h = 'negotiation-offer-expires-in-less-than-48-hours',
  NegotiationOfferSignedByAll = 'negotiation-offer-signed-by-all-buyers',
  NewSellerLeadAssignedBySystem = 'new-seller-lead-assigned-by-system',
  TaskAssigned = 'task-assigned',
  VisitAgentUpdated = 'visit-agent-updated',
}
